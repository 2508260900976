import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMaxOrder,
  fetchResource,
  updateResource,
} from '../../../redux/actions/resource';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';
import { validateOrder } from 'containers/SuperAdmin/redux/actions/resource';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditResource({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading, resource, orderNumberStatus, maxOrder } = useSelector(
    ({ admin }) => admin.resource
  );

  useEffect(() => {
    dispatch(fetchResource(id));
    dispatch(validateOrder({ status: true }));
    dispatch(fetchMaxOrder({ status: 0 }));
  }, [dispatch, id]);

  const handleSave = (d) => {
    let update = { ...d };
    if (update.group === '') {
      update.group = null;
    }
    dispatch(updateResource(id, update));
  };

  const validateOrderNumber = (data) => {
    dispatch(validateOrder(data));
  };

  const getMaximumOrderNumber = (data) => {
    let update = {
      topics: data.topics.toString(),
      group: data.group,
    };
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {resource && (
        <Form
          initialData={resource}
          onSave={handleSave}
          titleField={true}
          multipleField={'content'}
          multipleFieldTitle={'Images'}
          dropZoneParams={{
            maxFiles: 100,
            multiple: true,
          }}
          showTopicPicker
          maximumOrderValue={maxOrder > 0 ? maxOrder + 1 : resource.order}
          onSelectedData={getMaximumOrderNumber}
          validateOrderNo={(order) => validateOrderNumber(order)}
          fieldError={orderNumberStatus}
        />
      )}
    </div>
  );
}
