/**
 * * Guide Reducer
 */

import {
  GUIDEACTION_ATTEMPT,
  GUIDELIST_ATTEMPT,
  GUIDE_ERROR,
  FETCHALLGUIDES_SUCCESS,
  FETCHGUIDE_SUCCESS,
  ADDGUIDE_SUCCESS,
  UPDATEGUIDE_SUCCESS,
  DELETEGUIDE_SUCCESS,
  DELETEALLGUIDES_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  guides: null,
  guide: null,
};

export default function guide(state = initialState, action) {
  switch (action.type) {
    case GUIDELIST_ATTEMPT:
      return { ...state, fetching: true };
    case GUIDEACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLGUIDES_SUCCESS:
      return { ...state, fetching: false, guides: action.payload.data };
    case FETCHGUIDE_SUCCESS:
      return { ...state, fetching: false, guide: action.payload.guide };
    case ADDGUIDE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        guides: {
          ...state.guides,
          docs: [...state.guides.docs, action.payload.guide],
        },
      };
    case UPDATEGUIDE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        guides: {
          ...state.guides,
          docs: state.guides.docs.map((x) => {
            if (x._id === action.payload.guide._id) {
              return action.payload.guide;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEGUIDE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        guides: {
          ...state.guides,
          docs: state.guides.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case DELETEALLGUIDES_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        guides: null,
      };
    default:
      return state;
  }
}
