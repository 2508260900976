/**
 * * Teacher Reducer
 */

import {
  TEACHERS_ATTEMPT,
  TEACHERS_ERROR,
  GETSTUDENTSBYGRADE_SUCCESS,
  GETTEACHERGRADES_SUCCESS,
  ADDSTUDENTBYGRADE_SUCCESS,
  CREATETEACHERGRADE_SUCCESS,
  GETGUIDES_SUCCESS,
  GETGUIDE_SUCCESS,
  GETGUIDEPAGE_SUCCESS,
  GETWORKBOOKS_SUCCESS,
  GETWORKBOOK_SUCCESS,
  GETWORKBOOKPAGE_SUCCESS,
  GETMANUALS_SUCCESS,
  GETMANUALCONTENT_SUCCESS,
  FINDUSERNAME_SUCCESS,
  GETREPORT,
  GENERATEREPORT,
  UPDATESTUDENTBYGRADE_SUCCESS,
  DELETESTUDENTBYGRADE_SUCCESS,
  ARCHIVETEACHERSTUDENTS_SUCCESS,
  UPDATETEACHERGRADE_SUCCESS,
  DELETETEACHERGRADE_SUCCESS,
  FETCHRESOURCESBYTOPIC,
  FETCHVIDEOSBYCATEGORY,
  RESETUSERNAME_SUCCESS,
  GETARCHIVEDSTUDENTS,
  DELETEGROUPSTUDENT,
  GETALLGRADES,
  GETACTIVETEACHERS,
  DELETEGROUPSTUDENTS,
  RESTOREGROUPSTUDENT,
  RESTOREGROUPSTUDENTS,
  GETUSERNAMES,
  IMPORTSTUDENTS,
  GETUSERNAMES_ATTEMPT,
  GETTEACHERGRADEORDER_SUCCESS,
} from '../constants';

const initialState = {
  loading: false,
  error: null,
  students: null,
  archivedStudents: [],
  grades: null,
  guides: null,
  guide: null,
  workbooks: null,
  workbook: null,
  workbookPage: null,
  manuals: null,
  manual: null,
  message: null,
  status: null,
  report: null,
  image: null,
  resource: null,
  videos: null,
  activeTeachers: [],
  allGrades: [],
  usernames: [],
  order: [],
};

export default function students(state = initialState, action) {
  switch (action.type) {
    case TEACHERS_ATTEMPT:
      return {
        ...state,
        loading: true,
        manual: false,
        manuals: false,
        guides: null,
        guide: null,
        status: null,
        message: null,
        error: null,
        resource: null,
      };
    case GETUSERNAMES_ATTEMPT:
      return {
        ...state,
        usernamesLoading: true,
      };
    case TEACHERS_ERROR:
      return {
        ...state,
        loading: false,
        usernamesLoading: false,
        error: action.payload,
      };
    case GETREPORT:
      return { ...state, loading: false, report: action.payload.data };
    case GENERATEREPORT:
      return { ...state, loading: false };
    case GETSTUDENTSBYGRADE_SUCCESS:
      return { ...state, loading: false, students: action.payload.data };
    case GETARCHIVEDSTUDENTS:
      return {
        ...state,
        loading: false,
        archivedStudents: action.payload.data,
      };
    case GETTEACHERGRADES_SUCCESS:
      return { ...state, loading: false, grades: action.payload.data, order: action.payload.order };
    case GETTEACHERGRADEORDER_SUCCESS:
      return { ...state, loading: false, order: action.payload.data };
    case ADDSTUDENTBYGRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: state.students ? [...state.students, action.payload.student] : [action.payload.student],
      };
    case UPDATESTUDENTBYGRADE_SUCCESS:
      let updatedStudents = state.students.map((x) => {
        if (x._id === action.payload.student._id) {
          return action.payload.student;
        } else {
          return x;
        }
      });
      return {
        ...state,
        loading: false,
        students: updatedStudents,
      };
    case ARCHIVETEACHERSTUDENTS_SUCCESS:
      let archivedBulkStudents = state.students?.filter((x) => action.payload.ids?.includes(x._id) === false);
      return {
        ...state,
        loading: false,
        students: archivedBulkStudents,
      };

    case DELETESTUDENTBYGRADE_SUCCESS:
      let deletedStudents = state.students?.filter((x) => x._id !== action.payload.id);
      return {
        ...state,
        loading: false,
        students: deletedStudents,
      };
    case RESTOREGROUPSTUDENT:
      let updatedArchivedStudents = state.archivedStudents?.filter((x) => x._id !== action.payload.id);
      const newStudent = {
        ...action.payload?.student,
        grade: action.payload?.student?.grade?._id,
        teacher: action.payload?.student?.teacher?._id,
        school: action.payload?.student?.school?._id,
      };
      let updatedCurrentStudents = state.students ? [...state.students, newStudent] : [newStudent];
      return {
        ...state,
        loading: false,
        archivedStudents: updatedArchivedStudents,
        students: updatedCurrentStudents,
      };
    case RESTOREGROUPSTUDENTS:
      let updatedArchivedBulkStudents = state.archivedStudents?.filter(
        (x) => action.payload.ids?.includes(x._id) === false,
      );
      let restoredBulkStudents = state.archivedStudents?.filter((x) => action.payload.ids?.includes(x._id) === true);
      let updatedBulkStudents = state.students
        ? [...state.students, ...restoredBulkStudents]
        : [...restoredBulkStudents];
      return {
        ...state,
        loading: false,
        archivedStudents: updatedArchivedBulkStudents,
        students: updatedBulkStudents,
      };
    case DELETEGROUPSTUDENT:
      let archivedStudents = state.archivedStudents?.filter((x) => x._id !== action.payload.id);
      return {
        ...state,
        loading: false,
        archivedStudents: archivedStudents,
      };
    case DELETEGROUPSTUDENTS:
      let deletedBulkStudents = state.archivedStudents?.filter((x) => action.payload.ids?.includes(x._id) === false);
      return {
        ...state,
        loading: false,
        archivedStudents: deletedBulkStudents,
      };
    case CREATETEACHERGRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        grades: state.grades.docs
          ? {
              ...state.grades,
              docs: [...state.grades.docs, action.payload.grade],
              totalDocs: state.grades.totalDocs + 1,
            }
          : { ...state.grades, docs: [action.payload.grade], totalDocs: 1 },
        allGrades: state.allGrades ? [...state.allGrades, action.payload.grade] : [action.payload.grade],
        order: action.payload.order,
      };
    case UPDATETEACHERGRADE_SUCCESS:
      let updatedGrades = state.grades.docs.map((x) => {
        if (x._id === action.payload.grade._id) {
          return action.payload.grade;
        } else {
          return x;
        }
      });
      return {
        ...state,
        loading: false,
        grades: { ...state.grades, docs: updatedGrades },
      };
    case DELETETEACHERGRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        grades: {
          ...state.grades,
          docs: state.grades.docs.filter((x) => x._id !== action.payload.id),
        },
        allGrades: state.allGrades.filter((x) => x._id !== action.payload.id),
        order: action.payload.order?.filter((x) => x !== action.payload.id),
      };
    case GETGUIDES_SUCCESS:
      return { ...state, loading: false, guides: action.payload.data };
    case GETGUIDE_SUCCESS:
      return { ...state, loading: false, guide: action.payload.data };
    case GETGUIDEPAGE_SUCCESS:
      return { ...state, loading: false, image: action.payload.data };
    case GETWORKBOOKS_SUCCESS:
      return { ...state, loading: false, workbooks: action.payload.data };
    case GETWORKBOOK_SUCCESS:
      return { ...state, loading: false, workbook: action.payload.data };
    case GETWORKBOOKPAGE_SUCCESS:
      return { ...state, loading: false, workbookPage: action.payload.data };
    case GETMANUALS_SUCCESS:
      return { ...state, loading: false, manuals: action.payload.data };
    case GETMANUALCONTENT_SUCCESS:
      return { ...state, loading: false, manual: action.payload.data };
    case FINDUSERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    case RESETUSERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        message: null,
        status: null,
      };
    case FETCHRESOURCESBYTOPIC:
      return {
        ...state,
        loading: false,
        resource: action.payload.resource,
      };
    case FETCHVIDEOSBYCATEGORY:
      return {
        ...state,
        loading: false,
        videos: action.payload.videos,
      };
    case GETACTIVETEACHERS:
      return {
        ...state,
        loading: false,
        activeTeachers: action.payload.teachers,
      };
    case GETALLGRADES:
      return {
        ...state,
        loading: false,
        allGrades: action.payload.data,
      };
    case GETUSERNAMES:
      return {
        ...state,
        usernamesLoading: false,
        usernames: action.payload.data,
      };
    case IMPORTSTUDENTS:
      return {
        ...state,
        archivedStudents: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
