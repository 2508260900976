import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  APRENDERACTION_ATTEMPT,
  APRENDERLIST_ATTEMPT,
  APRENDER_ERROR,
  FETCHALLAPRENDERS_SUCCESS,
  FETCHAPRENDER_SUCCESS,
  ADDAPRENDER_SUCCESS,
  UPDATEAPRENDER_SUCCESS,
  DELETEAPRENDER_SUCCESS,
  SETAPRENDER,
  FETCHORDERNUMBER_SUCCESS,
  VALIDATEORDER_SUCCESS,
  BULKUPDATEAPRENDER_SUCCESS,
  BULKDELETEAPRENDER_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const apprenderUrl = 'admin/aprenders';

export const fetchAllAprenders = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERLIST_ATTEMPT));
      const body = await axios.get(`${apprenderUrl}`, { params });
      dispatch(successAction(FETCHALLAPRENDERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchMaxOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.get(`${apprenderUrl}/maxOrder`, { params });
      dispatch(successAction(FETCHORDERNUMBER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const validateOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}/validateOrder`, params);
      dispatch(successAction(VALIDATEORDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAprender = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERLIST_ATTEMPT));
      const body = await axios.get(`${apprenderUrl}/${id}`);
      dispatch(successAction(FETCHAPRENDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const addAprender = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}`, data);
      dispatch(successAction(ADDAPRENDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const updateAprender = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.put(`${apprenderUrl}/${id}`, data);
      dispatch(successAction(UPDATEAPRENDER_SUCCESS, body.data));
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data?.message || 'Successfully updated!',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const archiveAprender = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.put(`${apprenderUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEAPRENDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const restoreAprender = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.put(`${apprenderUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEAPRENDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveAprender = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEAPRENDER_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreAprender = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEAPRENDER_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteAprender = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETEAPRENDER_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const deleteAprender = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.delete(`${apprenderUrl}/${id}`);
      dispatch(successAction(DELETEAPRENDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};

export const setAprender = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(APRENDERACTION_ATTEMPT));
      const body = await axios.post(`${apprenderUrl}/order`, { data });
      dispatch(successAction(SETAPRENDER, body.data.aprender));
    } catch (err) {
      dispatch(errorAction(APRENDER_ERROR, err));
    }
  };
  return thunk;
};
