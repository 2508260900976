import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';

import { COLORS } from 'constants';

function TransitionComponent(props) {
  return <Collapse {...props} />;
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
      {
        backgroundColor: 'transparent',
      },
    margin: theme.spacing(0.5, 0),
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    width: 20,
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    color: 'inherit',
  },
  labelTextIndentation: ({ level }) => ({
    margin: `5px ${20 * level}px`,
    flexGrow: 1,
    fontWeight: level === 1 ? 'bold' : 'inherit',
    color: 'black',
    alignItems: 'center',
  }),
  iconContainer: {
    width: 24,
  },
}));

function StyledTreeItem(props) {
  const {
    labelText,
    color = COLORS.TEXT,
    bgColor = COLORS.BG,
    indent = false,
    indentLevel = 1,
    actions = [],
    ...other
  } = props;
  const classes = useTreeItemStyles({ level: indentLevel });

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography
            variant='body2'
            className={
              indent ? classes.labelTextIndentation : classes.labelText
            }
          >
            {labelText}
          </Typography>
          {actions}
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      TransitionComponent={TransitionComponent}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default StyledTreeItem;
