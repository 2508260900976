import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { COLORS } from 'constants';
import { fetchAllUsers } from 'containers/SuperAdmin/redux/actions/user';
import EmailField from '../EmailField';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'normal',
    color: COLORS.DARKBLUE,
  },
  spacing: {
    margin: theme.spacing(2, 0),
  },
}));

const TeacherForm = ({ form, setForm, handleDone, school }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete='fname'
          name='firstName'
          variant='outlined'
          required
          fullWidth
          id='firstName'
          label='First Name'
          autoFocus
          InputLabelProps={{ shrink: true }}
          value={form?.firstName}
          onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          variant='outlined'
          required
          fullWidth
          id='lastName'
          label='Last Name'
          name='lastName'
          autoComplete='lname'
          InputLabelProps={{ shrink: true }}
          value={form?.lastName}
          onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailField
          email={form?.email}
          setEmail={(d) => setForm({ ...form, email: d })}
          className={classes.email}
          school={school}
          setLoader={setLoading}
          setEmailError={setError}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Button
          onClick={handleDone}
          className={classes.confirm}
          variant={'contained'}
          disabled={
            form?.firstName === '' ||
            form?.lastName === '' ||
            form?.email === '' ||
            loading ||
            error
          }
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

const ChooseExistingTeacher = ({
  teachers,
  selectedTeacher,
  onSelect,
  handleDone,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12} sm={12}>
        <InputLabel id='teacher-label'>Choose a Teacher</InputLabel>
        <FormControl style={{ width: 300 }}>
          <Select
            labelId='teacher-label'
            id='teacher-select'
            value={selectedTeacher}
            onChange={(e) => onSelect(e.target.value)}
            displayEmpty={true}
            IconComponent={ArrowDropDown}
          >
            {teachers?.map((x) => (
              <MenuItem value={x._id} key={x._id}>
                {x.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Button
          onClick={handleDone}
          className={classes.confirm}
          variant={'contained'}
          disabled={!selectedTeacher}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default function ChangeHeadTeacherDialog({
  open,
  handleClose,
  handleConfirm,
  school,
  isArchived,
}) {
  const dispatch = useDispatch();
  const { users } = useSelector(({ admin }) => admin.users);
  const classes = useStyles();

  const [newForm, setNewForm] = useState(false);
  const [existingTeacher, setExistingTeacher] = useState(false);
  const [teacherId, setTeacherId] = useState('');

  const [teacherData, setTeacherData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    if (!school) return;
    setNewForm(false);
    setExistingTeacher(false);
    setTeacherId('');
    setTeacherData({
      firstName: '',
      lastName: '',
      email: '',
    });
    dispatch(
      fetchAllUsers({
        access: 'teacher',
        school,
        isArchived,
      })
    );
  }, [dispatch, school, isArchived]);

  const Options = () => {
    return (
      <Grid container justifyContent='space-around' className={classes.spacing}>
        <Button
          className={
            !newForm && existingTeacher ? classes.confirm : classes.cancel
          }
          variant={!newForm && existingTeacher ? 'contained' : 'outlined'}
          onClick={() => {
            setNewForm(false);
            setExistingTeacher(true);
          }}
          disabled={users?.docs?.filter((x) => !x.isHeadTeacher)?.length === 0}
        >
          Select Existing
        </Button>
        <Button
          className={
            newForm && !existingTeacher ? classes.confirm : classes.cancel
          }
          variant={newForm && !existingTeacher ? 'contained' : 'outlined'}
          onClick={() => {
            setNewForm(true);
            setExistingTeacher(false);
          }}
        >
          Create New
        </Button>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-dialog-title'
      maxWidth={'sm'}
    >
      <DialogContent className={classes.root}>
        <Typography>
          Would you like to select an existing teacher from this license or
          create a new one?
        </Typography>
        <Options />
        {newForm && (
          <TeacherForm
            form={teacherData}
            setForm={setTeacherData}
            school={school}
            handleDone={() => {
              handleConfirm({
                updateType: 'new',
                teacherData,
              });
            }}
          />
        )}
        {existingTeacher && (
          <ChooseExistingTeacher
            teachers={users?.docs?.filter((x) => !x.isHeadTeacher)}
            selectedTeacher={teacherId}
            onSelect={setTeacherId}
            handleDone={() => {
              handleConfirm({
                teacherId,
                updateType: 'existing',
              });
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
