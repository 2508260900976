import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTopics } from '../../redux/actions/topic';
import { fetchAllGroups } from '../../redux/actions/group';

import { sortBy } from 'utils/sort';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TreeView from '@material-ui/lab/TreeView';

import StyledTreeItem from './item';

import {
  ExpandMoreRounded,
  ChevronRightRounded,
  Cancel,
} from '@material-ui/icons';
import { DialogActions, Grid, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 400,
    flexGrow: 1,
    maxWidth: 600,
  },
  add: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default function TopicPicker({
  selectedTopic,
  selectedGroup,
  handleSelect,
  enableGroups = true,
  onlyChild = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { topics } = useSelector(({ admin }) => admin.topic);
  const { groups } = useSelector(({ admin }) => admin.group);

  const [expanded, setExpanded] = useState([]);
  const [topic, setTopic] = useState(null);
  const [childTopic, setChildTopic] = useState(null);
  const [group, setGroup] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setExpanded([]);
    dispatch(fetchAllTopics({ pagination: false }));
    dispatch(fetchAllGroups({ pagination: false }));
  }, [dispatch]);

  const handleToggle = (event, nodeIds) => {
    event.persist();
    let iconClicked =
      event.target.closest('.MuiTreeItem-iconContainer') ||
      event.target.closest('.MuiTypography-root.makeStyles-labelText') ||
      event.target.closest('.MuiSvgIcon-root.makeStyles-labelIcon');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  const getName = () => {
    if (selectedGroup) {
      const g =
        groups?.docs?.find((x) => x._id === selectedGroup)?.name || 'N/A';
      return `Topic: ${g}`;
    }
    if (selectedTopic) {
      const t =
        topics?.docs?.find((x) => selectedTopic.includes(x._id))?.name || 'N/A';
      return `Topic: ${t}`;
    }
    return 'Pick Topic';
  };

  const handleTopic = () => {
    handleSelect({
      topics: onlyChild ? [childTopic] : [topic, childTopic],
      group,
    });
    setOpenDialog(false);
  };

  return (
    <Grid container>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setOpenDialog(true)}
        className={classes.button}
      >
        {getName()}
      </Button>
      {topic !== '' && childTopic !== '' && (
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setTopic('');
            setChildTopic('');
            setGroup('');
            handleSelect({
              topics: [],
              group: '',
            });
          }}
        >
          <Cancel />
        </IconButton>
      )}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Pick Topic</DialogTitle>
        <DialogContent>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreRounded />}
            defaultExpandIcon={<ChevronRightRounded />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            expanded={expanded}
            onNodeToggle={handleToggle}
          >
            {topics?.docs
              ?.filter((x) => !x.isChild && !x.enableContentOnly)
              ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
              ?.map((topic) => (
                <StyledTreeItem
                  nodeId={topic?._id}
                  key={topic?._id}
                  labelText={topic.name}
                  onClick={() => {
                    if (expanded.indexOf(topic._id) > -1) {
                      setExpanded(expanded.filter((x) => x !== topic._id));
                    } else {
                      setExpanded([...expanded, topic?._id]);
                    }
                    setChildTopic('');
                    setGroup('');
                  }}
                >
                  {topics?.docs
                    ?.filter((x) => x.parent === topic._id)
                    ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
                    ?.map((childTopic) => {
                      if (!childTopic.hasGroup) {
                        return (
                          <StyledTreeItem
                            nodeId={childTopic?._id}
                            key={childTopic?._id}
                            labelText={childTopic.name}
                            onClick={() => {
                              setTopic(topic._id);
                              setChildTopic(childTopic._id);
                              setGroup('');
                            }}
                          />
                        );
                      }
                      return (
                        <StyledTreeItem
                          nodeId={childTopic?._id}
                          key={childTopic?._id}
                          labelText={childTopic.name}
                          onClick={() => {
                            if (enableGroups) {
                              setTopic('');
                              setChildTopic('');
                            } else {
                              setTopic(topic._id);
                              setChildTopic(childTopic._id);
                            }
                            if (expanded.indexOf(childTopic._id) > -1) {
                              setExpanded(
                                expanded.filter((x) => x !== childTopic._id)
                              );
                            } else {
                              setExpanded([...expanded, childTopic?._id]);
                            }
                            setGroup('');
                          }}
                        >
                          {enableGroups &&
                            childTopic?.hasGroup &&
                            groups?.docs
                              ?.filter((x) => x.topics.includes(childTopic._id))
                              ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
                              ?.map((group) => (
                                <StyledTreeItem
                                  nodeId={group?._id}
                                  key={group?._id}
                                  labelText={group.name}
                                  onClick={() => {
                                    setTopic(topic._id);
                                    setChildTopic(childTopic._id);
                                    setGroup(group._id);
                                  }}
                                />
                              ))}
                        </StyledTreeItem>
                      );
                    })}
                </StyledTreeItem>
              ))}
          </TreeView>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleTopic}
            disabled={!childTopic}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
