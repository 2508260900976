import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addAprender,
  fetchMaxOrder,
  validateOrder,
} from '../../../redux/actions/aprender';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialAprender = {
  order: 0,
  name: '',
  spanishName: '',
  color: '',
  buttonColor: '',
  group: '',
  image: '',
  transparent: '',
  audio: '',
  topics: [],
  highlightWords: [],
  isRound: false,
  imagePercentage: 0.85,
  horizontalPercentage: 50,
  verticalPercentage: 50,
};

export default function NewAprender({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading, aprenders, maxOrder, orderNumberStatus } = useSelector(
    ({ admin }) => admin.aprender
  );
  const [aprenderData, setAprenderData] = useState(initialAprender);

  const handleSave = (d) => {
    setAprenderData(d);
    dispatch(addAprender(d));
    history.push('/superadmin/apprender');
  };

  useEffect(() => {
    dispatch(validateOrder({ status: true }));
    dispatch(fetchMaxOrder({ status: 0 }));
  }, [dispatch]);

  const getMaximumOrderNumber = (data) => {
    let update = {
      topics: data.topics.toString(),
      group: data.group,
    };
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  const validateOrderNumber = (data) => {
    dispatch(validateOrder(data));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={aprenderData}
        content={['audio', 'image', 'transparent']}
        onSave={handleSave}
        color
        buttonColor
        showTopicPicker
        spanishField
        highlightField
        switchFields={['isRound']}
        numberFields={[
          'imagePercentage',
          'horizontalPercentage',
          'verticalPercentage',
          'borderTopRightRadius',
          'borderTopLeftRadius',
          'borderBottomRightRadius',
          'borderBottomLeftRadius',
        ]}
        orderNumberData={aprenders}
        maximumOrderValue={maxOrder + 1}
        onSelectedData={getMaximumOrderNumber}
        validateOrderNo={(order) => validateOrderNumber(order)}
        fieldError={orderNumberStatus}
      />
    </div>
  );
}
