import * as colors from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { Audiotrack, Photo, FileCopy, PictureAsPdf, YouTube, Cloud } from '@material-ui/icons';

const CLEVERID = process.env.REACT_APP_CLEVERID;
const REDIRECT_URI = process.env.REACT_APP_CLEVER_REDIRECT_URI;

export const openCleverLink = () => {
  const url = `https://clever.com/oauth/authorize?response_type=code&client_id=${CLEVERID}&redirect_uri=${REDIRECT_URI}/oauth/clever`;
  window.open(url);
};

export const isProd = window.location.href.includes('membership') || window.location.href.includes('master');

export const CLIENTID = process.env.REACT_APP_ADOBE_CLIENTID;

export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const BASE_S3_URL = process.env.REACT_APP_BASE_S3_URL;

export const BASE_CLOUDFRONT_URL = process.env.REACT_APP_BASE_CLOUDFRONT_URL;

export const attemptAction = (actionType) => ({
  type: actionType,
});

export const successAction = (actionType, data) => ({
  type: actionType,
  payload: data,
});

export const errorAction = (actionType, err) => ({
  type: actionType,
  payload: err.message ? err.message : JSON.stringify(err),
});

export const Aprender = {
  name: 'Aprender',
  englishName: 'Learn',
  description: 'Click & Speak Pictures',
};
export const Practicar = {
  name: 'Practicar',
  englishName: 'Practice',
  description: 'Practice Sentences',
};
export const Conversar = {
  name: 'Conversar',
  englishName: 'Conversations',
  description: 'Practice Skills',
};
export const Hablar = {
  name: 'Hablar',
  englishName: 'Conversations',
  description: 'Practice Skills',
};
export const Leer = {
  name: 'Leer',
  englishName: 'Read',
  description: 'Interactive Flashcards',
};
export const Jugar = {
  name: 'Jugar',
  englishName: 'Play',
  description: 'Games!',
};

export const BUTTONS = {
  Aprender: 'Aprender',
  Practicar: 'Practicar',
  Leer: 'Leer',
  Conversar: 'Conversar',
  Jugar: 'Jugar',
  Cantar: 'Cantar',
};

// Light Blue (Background): DDF5FF
// Dark Blue: 1052BA
// Pink: ED268F
// Light Purple: 7859C0
// Dark Purple: 6233BD
// Light Yellow: FDF6AC

export const COLORS = {
  PRIMARY: `rgb(120,88,191)`,
  SECONDARY: `rgb(218,56,135)`,
  TEXT: `rgb(34,84,179)`,
  TEXTBLUE: `rgb(60,143,222)`,
  BG: '#DDF5FF',
  TRANSPARENT: '#ddf5ff85',
  DARKBLUE: '#1052BA',
  PINK: '#ED268F',
  LIGHTPURPLE: '#7859C0',
  DARKPURPLE: '#6233BD',
  LIGHTYELLOW: '#FDF6AC',
  GREY: '#b5b3b3',
  ...colors,
};

export const aprenderColorMap = {
  '#1052BA': 'Blue',
  '#104CCC': 'Blue',
  '#E737BE': 'Pink',
  '#ED268F': 'Pink',
  '#48CE33': 'Green',
  '#FF8A00': 'Orange',
  '#00CDA5': 'Seafoam',
  '#00b2d6': 'LightBlue',
  '#00B2D6': 'LightBlue',
  '#6233bd': 'Purple',
};

export const playAudio = (url) => {
  const sound = new Audio(url);
  sound.play().catch(console.log);
};

export const playClick = () => {
  playAudio('/click.mp3');
};

export const playPop = () => {
  playAudio('/pop.mp3');
};

export const playFlip = () => {
  playAudio('/flip-page.mp3');
};

export const fontFamily = [
  'Janda Manatee Solid',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const guide = (level, min, max) => {
  let array = [];
  for (min; min < max; min++) {
    array.push(`${BASE_URL}/uploads/guides/Level${level}/${min + 1}.png`);
  }
  return array;
};

export const openWindow = (url, specs = {}, target = '_blank') => {
  window.open(url, target, { width: 800, height: 800, ...specs });
};

export const useQuery = (param) => {
  const { search } = useLocation();
  return new URLSearchParams(search).get(param);
};

export const videoCategories = [
  {
    title: 'Practicar',
    content: 'Develop language skills by encouraging repetition through engaging activities.',
  },
  {
    title: 'Conversar',
    content: 'Elevate the experience of learning a language by creating space for interactive conversation.',
  },
  {
    title: 'Cantar',
    content: "Rapidly develop students' Spanish vocabulary through the power of music.",
  },
  {
    title: 'Jugar',
    content: 'Games create endless fun in the classroom while challenging students to expand their knowledge.',
  },
];

export const resourceTypes = [
  {
    title: 'Class Trivia',
    link: '/teachers/resources/class-trivia',
    external: false,
  },
  {
    title: 'External Resources',
    link: '/teachers/external',
    external: true,
  },
  {
    title: 'YouTube Channel',
    link: '/teachers/guides/youtube',
    external: false,
  },
];

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
      dark: COLORS.PRIMARY,
      light: '#000',
    },
    secondary: {
      main: COLORS.SECONDARY,
      dark: COLORS.SECONDARY,
    },
  },
  typography: {
    fontFamily,
  },
  overrides: {
    MuiButton: {
      label: { textTransform: 'none' },
    },
  },
});

export const getFileIcon = (ext) => {
  const iconSpacing = { marginRight: 5 };
  switch (ext) {
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'aac':
    case 'flac':
    case 'wma':
    case 'm4a':
    case 'aiff':
    case 'aif':
    case 'aifc':
    case 'au':
    case 'mp4':
      return <Audiotrack style={iconSpacing} color="primary" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
    case 'svg':
    case 'tiff':
      return <Photo style={iconSpacing} color="primary" />;
    default:
      return <FileCopy style={iconSpacing} color="primary" />;
  }
};

export const getIcon = (c) => {
  switch (c) {
    case 'audio':
      return <Audiotrack />;
    case 'image':
    case 'framework':
      return <Photo />;
    case 'pdf':
      return <PictureAsPdf />;
    case 'video':
    case 'words':
    case 'sentences':
    case 'quiz':
      return <YouTube />;
    default:
      return <Cloud />;
  }
};

export const ERRORS = {
  USERNAME: 'Allowed characters in username are alphabets, numerics, underscores, hyphens and periods',
  NO_HEAD_TEACHER: 'We could not find any Head Teacher associated with this School',
};

export const getLicenseCount = (type) => {
  switch (type) {
    case 'School':
      return {
        teacherLicenses: 5,
        studentLicenses: 650,
        category: 'Group',
      };
    case 'Teacher':
      return {
        teacherLicenses: 1,
        studentLicenses: 0,
        category: 'Individual',
      };
    case 'Student':
      return {
        teacherLicenses: 0,
        studentLicenses: 1,
        category: 'Individual',
      };
    default:
      return {
        teacherLicenses: 5,
        studentLicenses: 650,
        category: 'Group',
      };
  }
};

export const licenseOptions = ['School', 'Teacher', 'Student'];
export const units = ['Days', 'Months', 'Years'];

export const SEARCH_TIMEOUT = 1000;

export const USER_STATUS = {
  ACTIVATING: 'Pending Activation',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  RENEWING: 'Pending Reactivation (Preserved)',
  RESTORING: 'Pending Reactivation (Reset as New)',
  EMAILUPDATE: 'Pending Email Address Change',
  PASSWORDRESET: 'Pending Password Reset',
};

export const userStatuses = Object.keys(USER_STATUS).map((key) => USER_STATUS[key]);

export const getAccountStatusLabel = (status) => {
  switch (status) {
    case USER_STATUS.ACTIVATING:
      return 'Resend Activation Email';
    case USER_STATUS.RENEWING:
    case USER_STATUS.RESTORING:
      return 'Resend Welcome Back Email';
    case USER_STATUS.ACTIVE:
      return 'Send Password Reset Email';
    case USER_STATUS.PASSWORDRESET:
      return 'Resend Password Reset Code';
    case USER_STATUS.EMAILUPDATE:
      return 'Resend Verification Email';
    default:
      return '';
  }
};
