/**
 * * Admin Users Reducer
 */

import {
  PRACTICARACTION_ATTEMPT,
  PRACTICARLIST_ATTEMPT,
  PRACTICAR_ERROR,
  FETCHALLPRACTICARS_SUCCESS,
  FETCHPRACTICAR_SUCCESS,
  ADDPRACTICAR_SUCCESS,
  UPDATEPRACTICAR_SUCCESS,
  DELETEPRACTICAR_SUCCESS,
  BULKUPDATEPRACTICAR_SUCCESS,
  BULKDELETEPRACTICAR_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  practicars: null,
  practicar: null,
};

export default function practicar(state = initialState, action) {
  switch (action.type) {
    case PRACTICARLIST_ATTEMPT:
      return { ...state, fetching: true, practicar: null };
    case PRACTICARACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case PRACTICAR_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLPRACTICARS_SUCCESS:
      return { ...state, fetching: false, practicars: action.payload.data };
    case FETCHPRACTICAR_SUCCESS:
      return { ...state, fetching: false, practicar: action.payload.practicar };
    case ADDPRACTICAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        practicars: {
          ...state.practicars,
          docs: [...state.practicars.docs, action.payload.practicar],
        },
      };
    case UPDATEPRACTICAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        practicars: {
          ...state.practicars,
          docs: state.practicars.docs.map((x) => {
            if (x._id === action.payload.practicar._id) {
              return action.payload.practicar;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEPRACTICAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        practicars: {
          ...state.practicars,
          docs: state.practicars.docs.filter(
            (x) => x._id !== action.payload.id
          ),
        },
      };
    case BULKUPDATEPRACTICAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        practicars: {
          ...state.practicars,
          docs: state.practicars.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETEPRACTICAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        practicars: {
          ...state.practicars,
          docs: state.practicars.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
