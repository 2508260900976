import axios from '../../../../redux/apiCaller';
import { BASE_S3_URL, BASE_CLOUDFRONT_URL } from 'constants';

const adminBase = 'admin';

export const uploadContentFileDirectly = async (file, onProgressUpdate) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    let result = await axios.post(`${adminBase}/upload/s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );
        if (totalLength !== null) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / totalLength
          );
          if (onProgressUpdate) {
            onProgressUpdate(progress);
          }
        }
      },
    });
    if (result.status === 200) {
      let url = result?.data?.file?.location;
      url = url.replace(BASE_S3_URL, BASE_CLOUDFRONT_URL);
      return url;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};

export const uploadContentFile = async (
  file,
  path = null,
  onProgressUpdate
) => {
  let { name, type: contentType } = file;
  try {
    if (path) {
      name = `name/${path}`;
    }
    let result = await axios.post(`${adminBase}/upload`, { name, contentType });
    const signedUrl = result?.data?.url;
    if (!signedUrl) throw new Error('Missing signed url');
    const uploadInstance = axios.create();
    delete uploadInstance.defaults.headers.common.Authorization;
    const body = await uploadInstance.put(signedUrl, file, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );
        if (totalLength !== null) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / totalLength
          );
          if (onProgressUpdate) {
            onProgressUpdate(progress);
          }
        }
      },
    });
    if (body.status === 200) {
      let url = signedUrl.split('?')[0];
      url = url.replace(BASE_S3_URL, BASE_CLOUDFRONT_URL);
      return url;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};

export const uploadContentFiles = async (
  files,
  path = null,
  onProgressUpdate
) => {
  try {
    let result = [];
    for (const [index, file] of files.entries()) {
      const url = await uploadContentFileDirectly(file, (d) => {
        if (onProgressUpdate) {
          onProgressUpdate({ index, progress: d });
        }
      });
      result.push(url);
    }
    return result;
  } catch (err) {
    console.log(err);
  }
};
