import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateConversar,
  fetchConversar,
} from '../../../redux/actions/conversar';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditConversar({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading, conversar } = useSelector(
    ({ admin }) => admin.conversar
  );

  useEffect(() => {
    dispatch(fetchConversar(id));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateConversar(id, d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {conversar && (
        <Form
          showTopicPicker
          initialData={conversar}
          content={[
            'englishVideo',
            'englishThumbnail',
            'spanishVideo',
            'spanishThumbnail',
            'video',
            'image',
            'script',
          ]}
          onSave={handleSave}
        />
      )}
    </div>
  );
}
