import { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import StandardDialogActions from 'components/StandardDialogActions';
import Typography from '@material-ui/core/Typography';

export function TextDialog({
  open,
  initial,
  editable,
  title,
  onClose,
  onResult,
}) {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(initial || '');
  }, [initial]);

  function done() {
    onResult(text);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          {editable && (
            <TextField
              fullWidth
              variant='outlined'
              multiline
              minRows={5}
              maxRows={30}
              value={text}
              onChange={(e) => setText(e.target.value)}
              inputProps={{ maxLength: 1024 }}
            />
          )}
          {!editable && (
            <Typography style={{ whiteSpace: 'break-spaces' }}>
              {text}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={!editable}
      />
    </Dialog>
  );
}
