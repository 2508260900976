import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllArchivedSchools,
  fetchAllSchools,
} from 'containers/SuperAdmin/redux/actions/school';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { sortBy } from 'utils/sort';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.0 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export default function SchoolSelector({ selected, setSelected, isArchived }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { schools, archivedSchools } = useSelector(({ admin }) => admin.school);

  useEffect(() => {
    dispatch(fetchAllSchools());
    if (isArchived) dispatch(fetchAllArchivedSchools());
  }, [dispatch, isArchived]);

  const allSchools = isArchived
    ? schools?.docs?.concat(archivedSchools?.docs)
    : schools?.docs;

  const renderValue = (selected) => {
    if (selected === '' || !selected) return 'None';
    if (selected === 'Individual') return 'Individual';
    return allSchools?.find((l) => l?._id === selected)?.name;
  };

  return (
    <Grid container direction={'row'} item sm>
      <FormControl className={classes.formControl}>
        <InputLabel shrink id='school-selectbox'>
          School
        </InputLabel>
        <Select
          labelId='school-selector'
          id='school-selector'
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          input={<Input />}
          MenuProps={MenuProps}
          displayEmpty={true}
          renderValue={renderValue}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'Individual'}>Individual</MenuItem>
          {allSchools
            ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
            ?.map((l) => (
              <MenuItem value={l?._id} key={l?._id}>
                {l?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
