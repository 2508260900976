import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { acceptAgreement } from 'redux/actions';
import {
  DialogContent,
  Dialog,
  Typography,
  makeStyles,
} from '@material-ui/core';

import StandardDialogActions from 'components/StandardDialogActions';
import BackgroundGrid from 'components/BackgroundGrid';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBottom: 20,
  },
  text: {
    lineHeight: 2,
    marginBottom: 30,
    marginTop: 30,
    textAlign: 'center',
  },
  textLayout: {
    lineHeight: 1.8,
    textAlign: 'center',
  },
}));

export default function TeacherAgreement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(({ global }) => global);

  const handleAccept = () => {
    dispatch(acceptAgreement());
  };

  const handleDecline = () => history.push('/topics');

  return (
    <BackgroundGrid>
      <Dialog open maxWidth='lg' fullWidth onClose={() => {}}>
        <DialogContent style={{ margin: 10, textAlign: 'center' }}>
          <Typography
            variant='h6'
            className={classes.textLayout}
            color='primary'
          >
            Welcome to the Vivamos Spanish Membership Site!
          </Typography>
          <Typography className={classes.textLayout} color='primary'>
            In order to proceed, please accept the Following Terms & Conditions:
          </Typography>
          <Typography className={classes.text} color='primary'>
            The training & teaching materials shared are exclusive to
            instructors within the official Vivamos Spanish program. They are
            confidential & intended for teaching purposes only. Materials are
            protected by copyright law & may not be downloaded, reproduced, or
            transmitted in any form or by any means of electronic, mechanical,
            photocopying, recording or otherwise, without prior permission from
            Vivamos Spanish LLC.
          </Typography>
          <Typography className={classes.textLayout} color='primary'>
            All rights to these materials are reserved for Vivamos Spanish LLC.
          </Typography>
          <Typography className={classes.textLayout} color='primary'>
            By utilizing the Teacher Portal on membership.vivamosspanish.com,
            you are agreeing to these terms & conditions.
          </Typography>
        </DialogContent>
        <StandardDialogActions
          doneButtonText={'Accept'}
          closeButtonText={'Decline'}
          onDone={handleAccept}
          onClose={handleDecline}
          saving={loading}
        />
      </Dialog>
    </BackgroundGrid>
  );
}
