import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'normal',
    color: COLORS.DARKBLUE,
  },
}));

export default function DeleteDialog({
  open,
  title = 'Are you sure you want to continue?',
  content = 'This action is critical in nature!',
  customContent = false,
  handleClose,
  handleConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  loading,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-dialog-title'
      maxWidth={'sm'}
    >
      { title && <DialogTitle
        id='delete-dialog-title'
        className={ classes.title }
        disableTypography
      >
        { title }
      </DialogTitle> }
      {customContent ? (
        content
      ) : (
        <DialogContent className={classes.root}>{content}</DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.cancel}
          variant='outlined'
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => handleConfirm()}
          className={classes.confirm}
          variant='contained'
          disabled={loading}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
