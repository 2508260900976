import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveTeachers } from 'containers/SuperAdmin/redux/actions/user';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@material-ui/core';
import { COLORS } from '../../../../constants';
import { ArrowDropDown } from '@material-ui/icons';
import { fetchAllGrades } from 'containers/SuperAdmin/redux/actions/grade';
import { sortBy } from 'utils/sort';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'normal',
    color: COLORS.DARKBLUE,
  },
  spacing: {
    margin: theme.spacing(1.5, 0),
  },
}));

export default function RestoreStudentDialog({
  open,
  title = 'Are you sure you want to continue?',
  handleClose,
  handleConfirm,
  confirmLabel = 'Yes',
  cancelLabel = 'No',
  loading,
  school,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { teachers, fetching: userLoading } = useSelector(
    ({ admin }) => admin.users
  );
  const { grades, fetching: gradeLoading } = useSelector(
    ({ admin }) => admin.grade
  );
  const [teacher, setTeacher] = useState('');
  const [grade, setGrade] = useState('');

  useEffect(() => {
    if (!open || !school) return;
    setTeacher('');
    setGrade('');
    dispatch(fetchActiveTeachers());
    dispatch(fetchAllGrades({ school }));
  }, [open, school, dispatch]);

  const filterTeachers = (a) => {
    return a.school === school;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-dialog-title'
      maxWidth={'sm'}
    >
      {teachers?.filter(filterTeachers)?.length > 0 && (
        <DialogTitle
          id='delete-dialog-title'
          className={classes.title}
          disableTypography
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.root}>
        {teachers?.filter(filterTeachers)?.length > 0 && (
          <Typography className={classes.spacing}>
            To restore Student(s), please assign them to an active Teacher &
            Class
          </Typography>
        )}
        {teachers?.filter(filterTeachers)?.length > 0 ? (
          <Grid container className={classes.spacing} direction='column'>
            <Grid item xs={12} sm={12} className={classes.spacing}>
              <InputLabel id='teacher-label'>Choose a Teacher</InputLabel>
              <FormControl style={{ width: 300 }}>
                <Select
                  labelId='teacher-label'
                  id='teacher-select'
                  value={teacher}
                  onChange={(e) => setTeacher(e.target.value)}
                  displayEmpty={true}
                  IconComponent={ArrowDropDown}
                >
                  {userLoading && (
                    <MenuItem value={teacher} disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {teachers
                    ?.filter(filterTeachers)
                    ?.sort((a, b) => sortBy(a, b, 'fullName', 'asc'))
                    ?.map((x) => (
                      <MenuItem value={x._id} key={x._id}>
                        {x.fullName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.spacing}>
              <InputLabel id='teacher-label'>Choose a Class</InputLabel>
              <FormControl>
                <Select
                  labelId='class-label'
                  id='class-select'
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  style={{ minWidth: 300 }}
                  displayEmpty={true}
                  disabled={!teacher}
                >
                  {gradeLoading && (
                    <MenuItem value={grade} disabled>
                      Loading...
                    </MenuItem>
                  )}
                  <MenuItem value={''}>No Class Attached</MenuItem>
                  {grades?.docs
                    ?.filter((x) => !x.isArchived)
                    ?.filter((x) => x.createdBy?._id === teacher)
                    ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
                    ?.map((g) => (
                      <MenuItem value={g._id} key={g._id}>
                        {g.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : userLoading ? (
          <Grid
            container
            className={classes.spacing}
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container className={classes.spacing} direction='column'>
            <Typography className={classes.spacing}>
              You have no active teachers to assign students to. Please add an
              active teacher to the license.
            </Typography>
          </Grid>
        )}
      </DialogContent>
      {teachers?.filter(filterTeachers)?.length > 0 && (
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.cancel}
            variant='outlined'
          >
            {cancelLabel}
          </Button>
          <Button
            onClick={() => handleConfirm({ teacher, grade })}
            className={classes.confirm}
            variant='contained'
            disabled={loading || !teacher || !grade}
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
