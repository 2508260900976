import React, { useCallback, useEffect, useState } from 'react';

import { CancelRounded, Save } from '@material-ui/icons';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Container,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import UploadField from '../UploadField';
import SelectField from '../SelectField';
import ContentEditor from '../ContentEditor';
import Dropzone from '../Dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getIcon } from 'constants';
import TopicPicker from '../TopicTree/picker';
import { aprenderColorMap } from 'constants';
import AprenderButton from 'components/Buttons/aprender';

export default function Form({
  initialData,
  content = [],
  onSave,
  html = false,
  htmlField = null,
  color = false,
  buttonColor = false,
  path = null,
  multipleField = null,
  multipleFieldTitle = 'Sounds',
  multipleFields = [],
  showMultipleToggle = false,
  dropZoneParams = {},
  sectionCategory = false,
  titleField = false,
  highlightField = false,
  orderField = true,
  spanishField = false,
  textFields = [],
  numberFields = [],
  switchFields = [],
  onSelectedData,
  maximumOrderValue = null,
  validateOrderNo,
  fieldError,
  showTopicPicker = false,
}) {
  const [data, setData] = useState(initialData);
  const [order, setOrder] = useState(0);
  const [files, setFiles] = useState([]);
  const [multipleFieldsData, setMultipleFieldsData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progresses, setProgresses] = useState([]);

  const updateItem = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const grid = 8;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    background: isDragging ? 'lightgreen' : 'inherit',
    ...draggableStyle,
  });

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      if (initialData.order) {
        setOrder(initialData.order || 0);
      }
      if (multipleField && initialData[multipleField]) {
        setFiles(initialData[multipleField]);
      }
    }
  }, [initialData, multipleField]);

  useEffect(() => {
    if (!multipleFields || multipleFields.length === 0 || !initialData) return;
    const update = {};
    multipleFields.forEach((field) => {
      if (initialData[field.field]) {
        update[field.field] = initialData[field.field];
      }
    });
    if (Object.keys(update).length > 0) {
      setMultipleFieldsData(update);
    }
  }, [initialData, multipleFields]);

  const handleOrderData = useCallback(() => {
    if (!maximumOrderValue) return;
    setOrder(maximumOrderValue);
  }, [maximumOrderValue]);

  useEffect(() => {
    handleOrderData();
  }, [handleOrderData]);

  const FileList = ({ files, setFiles, field }) => {
    const handleFileChange = (index, changedFile) => {
      setFiles(files.map((x, a) => (a === index ? changedFile : x)));
      setData({
        ...data,
        [field]: data[field].map((x, a) => (a === index ? changedFile : x)),
      });
    };

    return files.map((f, i) => (
      <Draggable draggableId={`file-${i}`} index={i} key={i}>
        {(provided, snapshot) => (
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            xs={12}
            sm={12}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              ),
            }}
          >
            <Grid item xs={6} sm={1}>
              <IconButton
                size='small'
                onClick={() => {
                  setFiles(files.filter((x, a) => a !== i));
                  setData({
                    ...data,
                    [multipleField]: data[multipleField].filter(
                      (x, a) => a !== i
                    ),
                  });
                }}
              >
                <CancelRounded color='error' />
              </IconButton>
            </Grid>
            <Grid item xs={8} sm={uploading ? 12 : 10}>
              <UploadField
                data={f}
                setData={(e) => handleFileChange(i, e)}
                label={`file-${i}`}
              />
            </Grid>
            {uploading && (
              <Grid item xs={12} sm={12}>
                <LinearProgress variant='determinate' value={progresses[i]} />
              </Grid>
            )}
          </Grid>
        )}
      </Draggable>
    ));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newResult = reorder(
      files,
      result.source.index,
      result.destination.index
    );
    setFiles(newResult);
  };

  const handleMultipleProgress = (data) => {
    const { index, progress } = data;
    let update = [...progresses];
    update[index] = progress;
    setProgresses(update);
  };

  const getNumberFieldLabel = (c) => {
    if (c === 'imagePercentage') return 'Zoom';
    if (c === 'horizontalPercentage') return 'Move Left/Right';
    if (c === 'verticalPercentage') return 'Move Up/Down';
    if (c === 'borderTopRightRadius') return 'Top Right Roundness';
    if (c === 'borderTopLeftRadius') return 'Top Left Roundness';
    if (c === 'borderBottomRightRadius') return 'Bottom Right Roundness';
    if (c === 'borderBottomLeftRadius') return 'Bottom Left Roundness';
    return c;
  };

  return (
    <Grid
      container
      direction={'column'}
      component={Paper}
      elevation={5}
      style={{ padding: 15 }}
    >
      <Grid
        item
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography>Basic Info</Typography>
        <Button
          onClick={() => onSave(data)}
          variant={'contained'}
          startIcon={<Save />}
          color={'primary'}
          disabled={fieldError}
        >
          Save
        </Button>
      </Grid>
      {showTopicPicker && (
        <TopicPicker
          selectedTopic={data?.topics}
          selectedGroup={data?.group}
          handleSelect={(e) => {
            let update = {
              topics: e.topics,
              group: e.group,
            };
            if (onSelectedData) {
              onSelectedData(update);
            }
            if (update.group) {
              update = {
                ...update,
                order: maximumOrderValue,
              };
            }
            setData({ ...data, ...update });
          }}
          enableGroups={data?.group || data?.group === ''}
        />
      )}
      <br />
      {!titleField && spanishField && (
        <TextField
          margin='dense'
          id='spanishName'
          label='Spanish Name'
          type='text'
          fullWidth
          value={data.spanishName}
          onChange={(e) => setData({ ...data, spanishName: e.target.value })}
        />
      )}
      {!titleField && (
        <TextField
          margin='dense'
          id='name'
          label='English Name'
          type='text'
          fullWidth
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
      )}
      {titleField && (
        <TextField
          margin='dense'
          id='title'
          label='Title'
          type='text'
          fullWidth
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
      )}
      {orderField && (
        <TextField
          margin='dense'
          id='order'
          label='Order'
          type='number'
          error={fieldError}
          helperText={
            fieldError ? 'Order No Already Taken' : 'Order Number Available'
          }
          InputProps={{
            inputProps: {
              min: isNaN(maximumOrderValue)
                ? maximumOrderValue?.toString()
                : maximumOrderValue,
            },
          }}
          fullWidth
          value={order}
          onChange={(e) => {
            if (validateOrderNo) {
              validateOrderNo({
                order: e.target.value,
                topics: data?.topics.toString,
                group: data?.group,
              });
            }
            setOrder(e.target.value);
            setData({ ...data, order: e.target.value });
          }}
        />
      )}
      {highlightField && (
        <TextField
          margin='dense'
          id='highlight'
          label='Highlight Word'
          type='text'
          fullWidth
          value={data.highlightWords?.toString()}
          onChange={(e) => setData({ ...data, highlightWords: e.target.value })}
        />
      )}
      {color && (
        <SelectField
          value={data.color}
          setValue={(d) => setData({ ...data, color: d })}
          label={'Text Color'}
          options={Object.keys(aprenderColorMap)}
        />
      )}
      {buttonColor && (
        <SelectField
          value={data.buttonColor}
          setValue={(d) => setData({ ...data, buttonColor: d })}
          label={'Button Color'}
          options={Object.keys(aprenderColorMap)}
        />
      )}
      {sectionCategory && (
        <SelectField
          value={data.category}
          setValue={(d) => {
            if (onSelectedData) {
              onSelectedData('category', d);
            }
            setData({
              ...data,
              category: d,
            });
          }}
          label={'Category'}
          options={['cantar', 'jugar', 'practicar', 'conversar']}
        />
      )}
      <Grid
        item
        xs={12}
        sm={12}
        container
        direction={'column'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        {textFields.map((c, i) => (
          <Grid
            item
            xs
            container
            direction={'row'}
            alignItems={'center'}
            key={i}
          >
            <TextField
              margin='dense'
              id={'text-' + i}
              label={c}
              type='text'
              fullWidth
              value={data[c] ? data[c] : ''}
              onChange={(e) => updateItem(c, e.target.value)}
            />
          </Grid>
        ))}
      </Grid>
      {numberFields?.filter((x) =>
        [
          'borderTopRightRadius',
          'borderTopLeftRadius',
          'borderBottomRightRadius',
          'borderBottomLeftRadius',
          'horizontalPercentage',
          'verticalPercentage',
          'imagePercentage',
        ]?.includes(x)
      )?.length > 0 && (
        <AprenderButton
          color={data?.buttonColor}
          image={data?.transparent}
          title={data?.name}
          width={220}
          isRound={data?.isRound}
          percentage={data?.imagePercentage}
          horizontalPercentage={data?.horizontalPercentage}
          verticalPercentage={data?.verticalPercentage}
          topRightRadius={data?.borderTopRightRadius}
          topLeftRadius={data?.borderTopLeftRadius}
          bottomRightRadius={data?.borderBottomRightRadius}
          bottomLeftRadius={data?.borderBottomLeftRadius}
        />
      )}
      <Grid
        item
        xs={12}
        sm={12}
        container
        direction={'column'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        {numberFields?.map((c, i) => (
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction={'row'}
            alignItems={'center'}
            key={i}
          >
            <TextField
              margin='dense'
              id={'number-' + i}
              label={getNumberFieldLabel(c)}
              type='number'
              fullWidth
              value={data[c]}
              onChange={(e) => updateItem(c, e.target.value)}
              disabled={
                [
                  'borderTopRightRadius',
                  'borderTopLeftRadius',
                  'borderBottomRightRadius',
                  'borderBottomLeftRadius',
                ].includes(c) && !data.isRound
              }
              {...(c === 'imagePercentage' && {
                InputProps: {
                  inputProps: {
                    min: 0,
                    max: 1,
                    step: 0.01,
                  },
                },
              })}
              {...([
                'verticalPercentage',
                'horizontalPercentage',
                'borderTopRightRadius',
                'borderTopLeftRadius',
                'borderBottomRightRadius',
                'borderBottomLeftRadius',
              ].includes(c) && {
                InputProps: {
                  inputProps: {
                    min: 0,
                    max: 100,
                    step: 1,
                  },
                },
              })}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        container
        direction={'column'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        {switchFields.map((c, i) => (
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction={'row'}
            alignItems={'center'}
            key={i}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={data[c] ? data[c] : false}
                  onChange={(e) => updateItem(c, e.target.checked)}
                  name={c}
                  color='primary'
                />
              }
              label={c === 'isRound' ? 'Roundness of the Image' : c}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        direction={'column'}
        spacing={3}
        style={{ marginTop: 15 }}
      >
        {data && (
          <Grid
            item
            container
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            style={{
              padding: 15,
            }}
          >
            {html && (
              <>
                <Typography
                  style={{ textTransform: 'capitalize' }}
                  variant={'h5'}
                >
                  {htmlField}
                </Typography>
                <ContentEditor
                  handleEditorChange={(e) =>
                    setData({ ...data, [htmlField]: e })
                  }
                  value={data[htmlField]}
                  id='html-editor'
                />
              </>
            )}
            <Divider />
            {content && content.length > 0 && (
              <Typography style={{ marginTop: 15, marginBottom: 15 }}>
                Content
              </Typography>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction={'column'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              {content.map((c, i) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  direction={'row'}
                  alignItems={'center'}
                  key={i}
                >
                  <UploadField
                    data={data[c] ? data[c] : ''}
                    setData={(e) => updateItem(c, e)}
                    label={c.toUpperCase()}
                    icon={getIcon(c)}
                    path={path}
                  />
                </Grid>
              ))}
              {multipleField && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Typography style={{ marginTop: 15, marginBottom: 15 }}>
                      {multipleFieldTitle}
                    </Typography>
                  </Grid>

                  {showMultipleToggle && (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      direction={'row'}
                      alignItems={'center'}
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={data.enableMultiple}
                            onChange={(e) =>
                              setData({
                                ...data,
                                enableMultiple: e.target.checked,
                              })
                            }
                            name='enableMultiple'
                            color='primary'
                          />
                        }
                        label='Enable Multiple Uploads'
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Dropzone
                      onFileUpload={(d) => {
                        setData({
                          ...data,
                          [multipleField]:
                            data[multipleField].length > 0
                              ? [...data[multipleField], ...d]
                              : d,
                        });
                      }}
                      onFileDrop={(d) => {
                        if (files.length === 0) {
                          setFiles(d.map((d) => d.path));
                        } else {
                          setFiles([...files, ...d.map((d) => d.path)]);
                        }
                      }}
                      onProgress={handleMultipleProgress}
                      setUploading={(u) => setUploading(u)}
                      maxFiles={data.enableMultiple ? 10 : 1}
                      multiple={data.enableMultiple}
                      {...dropZoneParams}
                    />
                  </Grid>
                  {files && files.length > 0 && (
                    <Container
                      style={{
                        padding: 15,
                        margin: '30px 0',
                        maxHeight: '50vh',
                        overflowY: 'scroll',
                      }}
                      component={Paper}
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='list'>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <FileList
                                files={files}
                                setFiles={setFiles}
                                field={multipleField}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Container>
                  )}
                </>
              )}

              {multipleFields?.map((field, index) => (
                <React.Fragment key={index}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Typography style={{ marginTop: 15, marginBottom: 15 }}>
                      {field.title}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Dropzone
                      onFileUpload={(d) => {
                        setData({
                          ...data,
                          [field.field]:
                            data[field.field].length > 0
                              ? [...data[field.field], ...d]
                              : d,
                        });
                      }}
                      onFileDrop={(d) => {
                        const f = d.map((d) => d.path);
                        const update = {
                          ...multipleFieldsData,
                          [field.field]:
                            multipleFieldsData[field.field]?.length > 0
                              ? [...multipleFieldsData[field.field], ...f]
                              : f,
                        };
                        setMultipleFieldsData(update);
                      }}
                      onProgress={handleMultipleProgress}
                      setUploading={(u) => setUploading(u)}
                      maxFiles={data.enableMultiple ? 10 : 1}
                      multiple={data.enableMultiple}
                      {...dropZoneParams}
                    />
                  </Grid>
                  {multipleFieldsData &&
                    Object.keys(multipleFieldsData)?.length > 0 && (
                      <Container
                        style={{
                          padding: 15,
                          margin: '30px 0',
                          maxHeight: '50vh',
                          overflowY: 'scroll',
                        }}
                        component={Paper}
                      >
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='list'>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <FileList
                                  files={multipleFieldsData[field.field]}
                                  setFiles={(f) => {
                                    setMultipleFieldsData({
                                      ...multipleFieldsData,
                                      [field.field]: f,
                                    });
                                  }}
                                  field={field.field}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Container>
                    )}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
