import React from 'react';
import dayJS from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: '100%',
  },
  head: {
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
  },
  spacing: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
  },
}));

export default function AcceptanceHistory({ size = 'small', data }) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} variant='outlined'>
      <Typography className={classes.spacing} align='center'>
        {data?.length > 0
          ? 'Privacy Terms Acceptance History'
          : 'No Privacy Terms Acceptance History'}
      </Typography>
      {data && (
        <TableContainer>
          <Table
            size={size}
            className={classes.table}
            aria-labelledby='licenseHistory'
            aria-label='License History Table'
          >
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell align={'center'} key={index}>
                      {dayJS(row).format('MMMM DD, YYYY')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
