import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllGrades,
  addGrade,
  deleteGrade,
  updateGrade,
  sortGrades,
  archiveGrade,
  restoreGrade,
  bulkArchiveGrade,
  bulkRestoreGrade,
  bulkDeleteGrade,
} from '../../redux/actions/grade';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import GradeDialog from '../../components/GradeDialog';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterBar from '../../components/FilterBar';
import { useParams } from 'react-router-dom';
import RestoreGradeDialog from 'containers/SuperAdmin/components/RestoreGradeDialog';
import { enqueueSnackbar } from 'redux/actions';
import { fetchLicenses } from 'containers/SuperAdmin/redux/actions/license';
import { useQuery } from 'constants';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialGrade = {
  name: '',
  createdBy: '',
  school: '',
};

export default function Grades({ name }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { teacher } = useParams();
  const isArchived = useQuery('isArchived');

  const [view, setView] = useState('normal');

  const [deleteId, setDeleteId] = useState(null);
  const [individualClass, setIndividualClass] = useState(null);
  const [deleteIds, setDeleteIds] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [archiveId, setArchiveId] = useState(null);
  const [archiveIds, setArchiveIds] = useState([]);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  const [restoreId, setRestoreId] = useState(null);
  const [restoreIds, setRestoreIds] = useState([]);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [gradeDialog, setGradeDialog] = useState(false);
  const [gradeData, setGradeData] = useState(initialGrade);

  const [editGrade, setEditGrade] = useState(false);
  const [gradeId, setGradeId] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });

  const { grades, fetching, actionLoading } = useSelector(
    ({ admin }) => admin.grade
  );

  const { licenseData } = useSelector(({ admin }) => admin.license);

  const loadPage = (page, sortOptions = {}) => {
    let update = {
      page,
      limit: grades.limit,
      ...sortOptions,
    };
    if (teacher) {
      update.createdBy = teacher;
    }
    if (searchString !== '') {
      update.search = searchString;
    }
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(
      sortGrades(sortOptions?.orderBy, sortOptions?.orderDir, grades?.docs)
    );
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'checkbox', label: 'Select' },
    { id: 'name', label: 'Name' },
    {
      id: 'schoolName',
      label: 'School',
      formatter: (x) => x.schoolName,
    },
    {
      id: `teacherName`,
      label: 'Teacher',
      formatter: (x) => x.teacherName,
    },
    {
      id: 'studentCount',
      label: 'Students',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() =>
            history.push(
              `/superadmin/grades/${row._id}/students${
                view === 'archived' ? '?isArchived=true' : ''
              }`,
              {
                from: `/superadmin/grades${
                  view === 'archived' ? '?isArchived=true' : ''
                }`,
              }
            )
          }
        >
          {view === 'archived' ? row.archivedStudentCount : row.studentCount}
        </Button>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  setGradeData({
                    ...r,
                    createdBy: r.createdBy?._id,
                    school: r.school?._id,
                  });
                  setGradeId(r._id);
                  setEditGrade(true);
                  setGradeDialog(true);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  if (!r.school) {
                    setDeleteId(r._id);
                    setIndividualClass(r._id);
                    setOpenDeleteDialog(true);
                  } else {
                    setArchiveId(r._id);
                    setIndividualClass(null);
                    setOpenArchiveDialog(true);
                  }
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  const license = licenseData?.find(
                    (x) => x.licensee === r.school?._id
                  );
                  if (
                    license?.isArchived &&
                    license?.status === 'Deactivated'
                  ) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'Class(es) cannot be restored because the License they belong to is inactive. Please reactivate their License first.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    setRestoreId(r._id);
                    setGradeData(r);
                    setOpenRestoreDialog(true);
                  }
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchLicenses());
    if (teacher !== undefined && teacher !== null) {
      dispatch(fetchAllGrades({ createdBy: teacher, isArchived }));
    } else {
      dispatch(fetchAllGrades({ isArchived }));
    }
    if (isArchived) {
      setView('archived');
    }
  }, [dispatch, teacher, isArchived]);

  const handleGrade = (data) => {
    if (!editGrade) {
      dispatch(addGrade(data));
    } else {
      dispatch(updateGrade(gradeId, data));
      setGradeId(null);
    }
    setGradeDialog(false);
  };

  const handleDelete = () => {
    if (deleteId && deleteIds?.length === 0) {
      dispatch(deleteGrade(deleteId));
      setDeleteId(null);
    } else {
      dispatch(bulkDeleteGrade(deleteIds));
      setDeleteIds([]);
    }
    setOpenDeleteDialog(false);
  };

  const onArchive = () => {
    if (view === 'normal') {
      if (isArchived) {
        if (teacher !== undefined && teacher !== null) {
          history.push('/superadmin/teachers/' + teacher + '/classes');
        } else {
          history.push('/superadmin/classes');
        }
      }
      let update = { page: grades.page, limit: grades.limit };
      if (teacher !== undefined && teacher !== null) {
        update.createdBy = teacher;
      }
      dispatch(fetchAllGrades(update));
    } else {
      let update = {
        isArchived: true,
      };
      if (teacher !== undefined && teacher !== null) {
        update.createdBy = teacher;
      }
      dispatch(fetchAllGrades(update));
    }
    setView(view === 'normal' ? 'archived' : 'normal');
  };

  return (
    <div className={classes.root}>
      <Loader fetching={fetching || actionLoading} />
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setEditGrade(false);
                setGradeData(initialGrade);
                setGradeDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
        onClearFilter={() => {
          if (teacher !== undefined && teacher !== null) {
            if (view === 'archived') {
              history.push('/superadmin/teachers?isArchived=true');
            } else {
              history.push('/superadmin/teachers');
            }
          } else {
            history.push('/superadmin/grades');
          }
        }}
      />
      <FilterBar
        enableTopics={false}
        enableGlobal
        selectedPage='Classes'
        onSearch={(d) => {
          setSearchString(d);
          let update = {
            page: grades.page,
            limit: grades.limit,
            search: d,
          };
          if (teacher) {
            update.createdBy = teacher;
          }
          if (view === 'archived') {
            update = { ...update, isArchived: true };
          }
          dispatch(fetchAllGrades(update));
        }}
        onAction={onArchive}
        toggle={view === 'archived'}
      />
      {grades && grades.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...grades,
                docs: grades.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
                totalDocs: grades.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                )?.length,
              }}
              view={view}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(grades.page, d)}
              standardPagination
              handleBulk={(data) => {
                const ids = data?.map((d) => d._id);
                setArchiveId(null);
                setRestoreId(null);
                if (view === 'normal') {
                  setArchiveIds(ids);
                  setOpenArchiveDialog(true);
                } else {
                  const restoreData = data[0];
                  const license = licenseData?.find(
                    (x) => x.licensee === restoreData.school?._id
                  );
                  if (
                    license?.isArchived &&
                    license?.status === 'Deactivated'
                  ) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'Class(es) cannot be restored because the License they belong to is inactive. Please reactivate their License first.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    setRestoreIds(ids);
                    setGradeData(data[0]);
                    setOpenRestoreDialog(true);
                  }
                }
              }}
              handleBulkDelete={(data) => {
                const ids = data?.map((d) => d._id);
                setDeleteId(null);
                setDeleteIds(ids);
                setOpenDeleteDialog(true);
              }}
            />
          </Grid>
        </Grid>
      )}
      <GradeDialog
        open={gradeDialog}
        handleClose={() => setGradeDialog(false)}
        handleConfirm={handleGrade}
        data={gradeData}
        setData={setGradeData}
        edit={editGrade}
      />
      <DeleteDialog
        title={'Archive Class'}
        content={
          'Archiving Class(es) will also archive all of their Students. Are you sure you want to archive?'
        }
        open={openArchiveDialog}
        handleClose={() => {
          setOpenArchiveDialog(false);
          setArchiveId(null);
          setArchiveIds([]);
        }}
        handleConfirm={() => {
          if (archiveId && archiveIds?.length === 0) {
            dispatch(archiveGrade(archiveId));
            setArchiveId(null);
          } else {
            dispatch(bulkArchiveGrade(archiveIds));
            setArchiveIds([]);
          }
          setOpenArchiveDialog(false);
        }}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
      />
      <DeleteDialog
        title={'Are you sure you want to delete this class? '}
        content={
          deleteId === individualClass
            ? 'Warning! This class belongs to an Individual Teacher and can only be permanently deleted. Any linked Individual Student Licenses will become unlinked, but will remain Active.'
            : 'Warning! You are attempting to delete this class. Students within the class will remain archived & accessible, but will need to be reassigned to a new Class/Teacher in order to be restored. Are you sure you want to permanently delete this class?'
        }
        open={openDeleteDialog}
        handleClose={() => {
          setDeleteId(null);
          setOpenDeleteDialog(false);
          setDeleteIds([]);
          setIndividualClass(null);
        }}
        handleConfirm={handleDelete}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
      />
      <RestoreGradeDialog
        open={openRestoreDialog}
        handleClose={() => {
          setRestoreId(null);
          setOpenRestoreDialog(false);
          setGradeData(initialGrade);
          setRestoreIds([]);
        }}
        handleConfirm={(teacher) => {
          if (restoreId && restoreIds.length === 0) {
            dispatch(restoreGrade(restoreId, { teacher }));
          } else {
            dispatch(bulkRestoreGrade(restoreIds, teacher));
          }
          setRestoreId(null);
          setRestoreIds([]);
          setOpenRestoreDialog(false);
          setGradeData(initialGrade);
        }}
        grade={gradeData}
      />
    </div>
  );
}
