/**
 * * Video Reducer
 */

import {
  VIDEOACTION_ATTEMPT,
  VIDEOLIST_ATTEMPT,
  VIDEO_ERROR,
  FETCHALLVIDEOS_SUCCESS,
  FETCHVIDEO_SUCCESS,
  ADDVIDEO_SUCCESS,
  UPDATEVIDEO_SUCCESS,
  DELETEVIDEO_SUCCESS,
  SETVIDEO,
  VIDEOVALIDATE_SUCESS,
  VIDEOMAXORDER_SUCESS,
  BULKUPDATEVIDEO_SUCCESS,
  BULKDELETEVIDEO_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  videos: null,
  video: null,
  orderNumberStatus: false,
};

export default function video(state = initialState, action) {
  switch (action.type) {
    case VIDEOLIST_ATTEMPT:
      return { ...state, fetching: true, video: null };
    case VIDEOACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case VIDEO_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLVIDEOS_SUCCESS:
      return { ...state, fetching: false, videos: action.payload.data };
    case FETCHVIDEO_SUCCESS:
      return { ...state, fetching: false, video: action.payload.video };
    case VIDEOMAXORDER_SUCESS:
      return { ...state, actionLoading: false, maxOrder: action.payload.data };
    case VIDEOVALIDATE_SUCESS:
      return {
        ...state,
        actionLoading: false,
        orderNumberStatus: action.payload.data,
      };
    case ADDVIDEO_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: [...state.videos.docs, action.payload.video],
        },
      };
    case UPDATEVIDEO_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: state.videos.docs.map((x) => {
            if (x._id === action.payload.video._id) {
              return action.payload.video;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEVIDEO_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: state.videos.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case SETVIDEO:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: action.payload,
        },
      };
    case BULKUPDATEVIDEO_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: state.videos.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETEVIDEO_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        videos: {
          ...state.videos,
          docs: state.videos.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
