import React from 'react';
import dayJS from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { formatLicenseDuration } from 'containers/SuperAdmin/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: '100%',
  },
  head: {
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
  },
  spacing: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
  },
}));

const headCells = [
  {
    id: 'issueDate',
    label: 'Issue Date',
    formatter: (r) => dayJS(r.issueDate).format('MMMM DD, YYYY'),
  },
  {
    id: 'activationDate',
    label: 'Activation Date',
    formatter: (r) =>
      r.activationDate
        ? dayJS(r.activationDate).format('MMMM DD, YYYY')
        : r.status?.includes('Pending Reactivation')
        ? 'Pending Reactivation'
        : 'Never Activated',
  },
  {
    id: 'validityPeriod',
    label: 'Period',
    formatter: (r) => formatLicenseDuration(r.activationDate, r.expiryDate),
  },
  {
    id: 'expiryDate',
    label: 'Expiry Date',
    formatter: (r) =>
      r.expiryDate
        ? `${dayJS(r.expiryDate).format('MMMM DD, YYYY')}${
            r.autoRenew ? ' (Auto-Renew)' : ''
          }${r.anticipated ? ' (Anticipated Auto-Renewal)' : ''}`
        : 'N/A',
  },
];

const AnticipatedRenewal = ({ data, isAutoRenewal, period, unit }) => {
  const current = data[data.length - 1];
  if (!isAutoRenewal) return null;
  const anticipated = {
    issueDate: current.issueDate,
    activationDate: current.expiryDate,
    expiryDate: dayJS(current.expiryDate)
      .add(period, unit)
      .format('MMMM DD, YYYY'),
    anticipated: true,
  };

  return (
    <TableRow>
      {headCells.map((headCell) => {
        return (
          <TableCell
            align={headCell.align ? headCell.align : 'left'}
            key={`anticipated-${headCell.id}`}
          >
            {headCell.formatter
              ? headCell.formatter(anticipated)
              : anticipated[headCell.id]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function LicenseHistory({
  size = 'small',
  data,
  showAnticipated,
  isAutoRenewal,
  period,
  unit,
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} variant='outlined'>
      <Typography className={classes.spacing} align='center'>
        {data ? 'License History' : 'No License History'}
      </Typography>
      {data && (
        <TableContainer>
          <Table
            size={size}
            className={classes.table}
            aria-labelledby='licenseHistory'
            aria-label='License History Table'
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={'left'}
                    padding={'normal'}
                    className={classes.head}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    {headCells.map((headCell) => {
                      return (
                        <TableCell
                          align={headCell.align ? headCell.align : 'left'}
                          key={`${row._id}-${headCell.id}`}
                        >
                          {headCell.formatter
                            ? headCell.formatter(row)
                            : row[headCell.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {showAnticipated && (
                <AnticipatedRenewal
                  data={data}
                  isAutoRenewal={isAutoRenewal}
                  period={period}
                  unit={unit}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
