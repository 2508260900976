import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { COLORS } from '../../constants';

export const PrimaryField = withStyles({
  root: {
    color: COLORS.DARKBLUE,
    '& label': {
      color: COLORS.DARKBLUE,
    },
    '& label.Mui-focused': {
      color: COLORS.DARKBLUE,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.DARKBLUE,
    },
    '& .MuiOutlinedInput-root': {
      // color: COLORS.DARKBLUE,
      '& fieldset': {
        borderColor: COLORS.DARKBLUE,
      },
      '&:hover fieldset': {
        borderColor: COLORS.DARKBLUE,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.DARKBLUE,
      },
    },
  },
})(TextField);

export default function StyledInput({ field, setField, ...props }) {
  return (
    <PrimaryField
      value={field}
      onChange={(e) => setField(e.target.value)}
      variant='outlined'
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}

export const SearchTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none !important',
        '&:hover': {
          border: '1px solid #f0f0f2',
        },
      },
    },
    '&.Mui-focused fieldset': {
      backgroundColor: '#fffff',
      boxShadow: '#a1a1a1 0px 3px 13px 1px',
      border: '1px solid #f0f0f2',
    },
  },
})(TextField);
