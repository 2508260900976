import Loader from 'components/Loader';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PasswordDialog = React.lazy(() => import('components/PasswordDialog'));

export default function ProtectedWithPassword({ children, ...rest }) {
  const isLoggedIn = localStorage && localStorage.getItem('token');
  const hasAccess = sessionStorage && sessionStorage.getItem('hasAccess');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          hasAccess ? (
            children
          ) : (
            <React.Suspense fallback={<Loader />}>
              <PasswordDialog />
            </React.Suspense>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
