import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGuide, updateGuide } from '../../../redux/actions/guide';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditGuide({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { actionLoading, guide } = useSelector(({ admin }) => admin.guide);

  useEffect(() => {
    dispatch(fetchGuide(id));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateGuide(id, d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {guide && (
        <Form
          initialData={guide}
          onSave={handleSave}
          multipleField={'content'}
          multipleFieldTitle={'Guide Pages'}
          dropZoneParams={{
            maxFiles: 250,
            multiple: true,
          }}
          titleField={'title'}
          numberFields={['startPage']}
          content={['framework']}
          orderField={false}
        />
      )}
    </div>
  );
}
