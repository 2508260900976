import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllUsers,
  addUser,
  deleteUser,
  updateUser,
} from '../../redux/actions/user';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import UserDialog from '../../components/UserDialog';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

let initialUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  access: 'admin',
  accessLevel: 'full',
  isVerified: false,
  school: '',
};

export default function Users({ name }) {
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null);
  const [userDialog, setUserDialog] = useState(false);
  const [editUser, setEditUser] = useState(false);
  // const [sending, setSending] = useState(false);

  const { users, fetching } = useSelector(({ admin }) => admin.users);

  const [sort, setSort] = useState({ orderBy: 'fullName', orderDir: 'asc' });

  const loadPage = (page, sortOptions = {}) => {
    dispatch(
      fetchAllUsers({
        page,
        limit: users.limit,
        access: 'admin',
        ...sortOptions,
      })
    );
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'email', label: 'Email' },
    {
      id: 'status',
      label: 'Account Status',
      formatter: (row) =>
        row?.isVerified ? 'Complete' : 'Pending Verification',
    },
    {
      id: 'actions',
      label: 'Actions',
      actions: [
        {
          label: 'Edit',
          action: (r) => {
            setUserData(r);
            setEditUser(true);
            setUserDialog(true);
          },
          icon: <EditIcon />,
          disabled: () => false,
        },
        {
          label: 'Permanently Delete',
          action: (r) => {
            setDeleteId(r._id);
            setOpenDeleteDialog(true);
          },
          icon: <DeleteIcon />,
          disabled: (r) => r.isSuperAdmin,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllUsers({ access: 'admin' }));
  }, [dispatch]);

  const handleUser = (data) => {
    if (editUser) {
      let {
        firstName,
        lastName,
        access,
        isVerified,
        accessLevel,
        email,
        isHeadTeacher,
      } = data;
      dispatch(
        updateUser(userData._id, {
          firstName,
          lastName,
          access,
          isVerified,
          accessLevel,
          email,
          isHeadTeacher,
        })
      );
    } else {
      dispatch(addUser(data));
    }
    setUserData(initialUser);
    setUserDialog(false);
  };

  const handleUserClose = () => {
    setUserData(initialUser);
    setUserDialog(false);
  };

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        onSearch={(search) =>
          dispatch(fetchAllUsers({ access: 'admin', search, ...sort }))
        }
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setEditUser(false);
                setUserData(initialUser);
                setUserDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      {users && users.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={users}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(users.page, d)}
            />
          </Grid>
        </Grid>
      )}
      <UserDialog
        open={userDialog}
        edit={editUser}
        data={userData}
        prefix={''}
        // sending={sending}
        showSubtitle={false}
        handleClose={handleUserClose}
        handleConfirm={handleUser}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteUser(deleteId));
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
