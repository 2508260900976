import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addCantar } from '../../../redux/actions/cantar';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialCantar = {
  name: '',
  image: '',
  video: '',
  song: '',
  karaoke: '',
  lyrics: '',
  lyricsPdf: '',
  topics: [],
  order: 0,
};

export default function NewCantar({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading } = useSelector(({ admin }) => admin.cantar);

  const handleSave = (d) => {
    dispatch(addCantar(d));
    history.push('/superadmin/cantars');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={initialCantar}
        onSave={handleSave}
        content={['image', 'video', 'song', 'karaoke', 'lyricsPdf']}
        html
        htmlField={'lyrics'}
        path={'cantar'}
        showTopicPicker
      />
    </div>
  );
}
