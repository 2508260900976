import { React } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, Paper, Button, IconButton } from '@material-ui/core';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import SearchIcon from '@material-ui/icons/Search';
import { SearchTextField } from 'components/StyledInput';
import GlobalSelector from 'containers/SuperAdmin/components/GlobalSelector';
import SectionSelector from 'containers/SuperAdmin/components/SectionSelector';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import { ArchiveFolderIcon } from 'components/Icons';
import TopicPicker from 'containers/SuperAdmin/components/TopicTree/picker';
import SchoolSelector from '../SchoolSelector';

const FilterBar = ({
  onSearch,
  onTopicSelect,
  onSectionSelect,
  selectedSection = '',
  selectedSchool,
  onSchoolSelect,
  topics = [],
  group = '',
  onAction,
  toggle = false,
  enableTopics = true,
  enableGroups = true,
  enableGlobal = false,
  enableSection = false,
  enableSchool = false,
  selectedPage = 'School',
  handleBack,
}) => {
  return (
    <Grid
      container
      alignItems='center'
      style={{
        paddingLeft: '10px',
        paddingRight: '40px',
        marginBottom: '15px',
      }}
      component={Paper}
      elevation={2}
    >
      {handleBack && (
        <IconButton onClick={handleBack}>
          <ArrowBackIosRounded />
        </IconButton>
      )}
      <Grid item sm={5}>
        <SearchTextField
          fullWidth
          variant='outlined'
          placeholder='Search here'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' style={{ border: 'none' }}>
                <SearchIcon style={{ color: 'lightgrey' }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Grid>
      <div style={{ marginLeft: 'auto' }} />
      {enableTopics && (
        <Grid item sm={3}>
          <TopicPicker
            onlyChild
            selectedTopic={topics}
            selectedGroup={group}
            handleSelect={(e) => {
              if (enableGroups) {
                onTopicSelect(e);
              } else {
                onTopicSelect(e?.topics);
              }
            }}
            enableGroups={enableGroups}
          />
        </Grid>
      )}
      {enableGlobal && (
        <Grid item sm={3}>
          <GlobalSelector selected={selectedPage} isArchived={toggle} />
        </Grid>
      )}
      {enableSection && (
        <Grid item sm={2}>
          <SectionSelector
            selected={selectedSection}
            setSelected={onSectionSelect}
          />
        </Grid>
      )}
      {enableSchool && (
        <Grid item sm={2}>
          <SchoolSelector
            selected={selectedSchool}
            setSelected={onSchoolSelect}
            isArchived={toggle}
          />
        </Grid>
      )}
      {onAction && (
        <Grid item sm={2} align='end'>
          <Button
            onClick={onAction}
            startIcon={toggle ? <FolderSpecialIcon /> : <ArchiveFolderIcon />}
            variant='contained'
            size='small'
            color='primary'
          >
            {toggle ? 'Live Folder' : 'Archive Folder'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default FilterBar;
