import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  RESOURCEACTION_ATTEMPT,
  RESOURCELIST_ATTEMPT,
  RESOURCE_ERROR,
  FETCHALLRESOURCES_SUCCESS,
  FETCHRESOURCE_SUCCESS,
  ADDRESOURCE_SUCCESS,
  UPDATERESOURCE_SUCCESS,
  DELETERESOURCE_SUCCESS,
  SETRESOURCE,
  RESOURCEMAXORDER_SUCCESS,
  RESOUCEVALIDATE_SUCCESS,
  BULKUPDATERESOURCE_SUCCESS,
  BULKDELETERESOURCE_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const resourceUrl = 'admin/resources';

export const fetchAllResources = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCELIST_ATTEMPT));
      const body = await axios.get(`${resourceUrl}`, { params });
      dispatch(successAction(FETCHALLRESOURCES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchMaxOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.get(`${resourceUrl}/maxOrder`, { params });
      dispatch(successAction(RESOURCEMAXORDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const validateOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}/validateOrder`, params);
      dispatch(successAction(RESOUCEVALIDATE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchResource = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCELIST_ATTEMPT));
      const body = await axios.get(`${resourceUrl}/${id}`);
      dispatch(successAction(FETCHRESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const addResource = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}`, data);
      dispatch(successAction(ADDRESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const updateResource = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.put(`${resourceUrl}/${id}`, data);
      dispatch(successAction(UPDATERESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const deleteResource = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.delete(`${resourceUrl}/${id}`);
      dispatch(successAction(DELETERESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const setResource = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}/order`, { data });
      dispatch(successAction(SETRESOURCE, body.data.resource));
    } catch (err) {
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const archiveResource = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.put(`${resourceUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATERESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const restoreResource = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.put(`${resourceUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATERESOURCE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveResource = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATERESOURCE_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreResource = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATERESOURCE_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteResource = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(RESOURCEACTION_ATTEMPT));
      const body = await axios.post(`${resourceUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETERESOURCE_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(RESOURCE_ERROR, err));
    }
  };
  return thunk;
};
