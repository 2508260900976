import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addGuide } from '../../../redux/actions/guide';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialGuide = {
  order: 0,
  title: 'Guide Title',
  startPage: 1,
  content: [],
};

export default function NewGuide({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading } = useSelector(({ admin }) => admin.guide);
  const [guideData, setGuideData] = useState(initialGuide);

  const handleSave = (d) => {
    setGuideData(d);
    dispatch(addGuide(d));
    history.push('/superadmin/guides');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={guideData}
        onSave={handleSave}
        multipleField={'content'}
        multipleFieldTitle={'Guide Pages'}
        dropZoneParams={{
          maxFiles: 250,
          multiple: true,
        }}
        numberFields={['startPage']}
        titleField={'title'}
        content={['framework']}
        orderField={false}
      />
    </div>
  );
}
