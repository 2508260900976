import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllSchools } from 'containers/SuperAdmin/redux/actions/school';
import { fetchAllLicenses } from '../../redux/actions/license';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Send from '@material-ui/icons/Send';
import { ArrowDropDown, Cancel } from '@material-ui/icons';
import { enqueueSnackbar } from 'redux/actions';
import EmailField from '../EmailField';
import { sortBy } from 'utils/sort';
import AcceptanceHistory from '../AcceptanceHistory';
import { getAccountStatusLabel } from 'constants';
import { userStatuses, USER_STATUS } from 'constants';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(1),
  },
  email: {
    marginBottom: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1, 0),
  },
  cancel: {
    color: theme.palette.error.main,
  },
  topSpacing: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  edit = false,
  disabledFields = [],
  suffix = '',
  prefix = 'Team',
  showSubtitle = true,
  showAcceptanceHistory = false,
  disableDone = false,
  sending,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { schools } = useSelector(({ admin }) => admin.school);
  const { licenses } = useSelector(({ admin }) => admin.license);
  const [form, setForm] = useState(data);
  const [licenseError, setLicenseError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideStatus, setHideStatus] = useState(false);
  const valid = dayjs().isBefore(data?.resetExpiryDate);

  useEffect(() => {
    if (!open) return;
    setHideStatus(false);
    dispatch(fetchAllSchools({ pagination: false }));
    dispatch(fetchAllLicenses());
  }, [open, dispatch]);

  useEffect(() => {
    if (!open) return;
    setLicenseError(false);
    setEmailError(false);
    setLoading(false);
    if (data) {
      setForm(data);
    }
  }, [open, data]);

  const validateDone = () => {
    if (
      !form?.firstName ||
      form?.firstName === '' ||
      !form?.lastName ||
      form?.lastName === '' ||
      !form?.email ||
      form?.email === '' ||
      licenseError ||
      emailError ||
      loading
    ) {
      if (disableDone && (!form?.school || form.school === '')) {
        return true;
      }
      return true;
    }
    if (!disableDone) return false;
    if (edit) {
      return false;
    } else {
      if (form?.school === '') {
        return true;
      }
      return false;
    }
  };

  const getAcceptanceHistory = () => {
    const current = data?.isAgreementAcceptedAt;
    let history =
      data?.isAgreementAccepted && data?.isAgreementAcceptedAt ? [current] : [];
    if (data?.agreementHistory) {
      const historyRecords = data?.agreementHistory;
      history = current ? [...historyRecords, current] : [...historyRecords];
    }
    return history;
  };

  const getHelperText = () => {
    switch (data?.status) {
      case USER_STATUS.ACTIVE:
        return `This user’s Account is Active. Changing this email will change the user’s login email & send the Verification email to the new address. Users must verify their new address prior to accessing the site again.`;
      case USER_STATUS.ACTIVATING:
        if ((data?.school && data?.isHeadTeacher) || !data?.school) {
          return `This user’s Account & License are Pending Activation. Changing their email address will re-send the Activation email to the new address.`;
        }
        return `This user’s Account is Pending Activation. Changing their email address will re-send the Activation email to the new address.`;
      case USER_STATUS.RESTORING:
      case USER_STATUS.RENEWING:
        if ((data?.school && data?.isHeadTeacher) || !data?.school) {
          return `This user’s Account & License are Pending Reactivation. Changing their email address will re-send the Welcome Back email to the new address. `;
        }
        return `This user’s Account is Pending Reactivation. Changing their email address will re-send the Welcome Back email to the new address`;
      case USER_STATUS.PASSWORDRESET:
        return `This user’s Account is Pending a Password Reset. Changing their email address will re-send the Password Reset email to the new address.`;
      default:
        return '';
    }
  };

  const onConfirm = () => {
    handleConfirm(form);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth={data?.status === USER_STATUS.PASSWORDRESET ? 'md' : 'sm'}
      fullWidth
    >
      <DialogTitle id='form-dialog-title' disableTypography>
        <Grid
          container
          direction={'row'}
          jsutify={'space-between'}
          alignItems={'center'}
        >
          <Grid item sm xs={9}>
            <Typography color='primary'>
              {edit
                ? `Edit ${prefix} ${
                    form?.access === 'teacher' ? 'Teacher' : 'Admin'
                  }`
                : `Add ${prefix} ${
                    form?.access === 'teacher' ? 'Teacher' : 'Admin'
                  } ${suffix}`}
            </Typography>
          </Grid>
          <Grid item sm={'auto'} xs={3}>
            <IconButton onClick={handleClose} color='primary' size='small'>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {!edit && showSubtitle && (
          <Grid container>
            <Typography variant='caption'>
              Not applicable for Head Teachers or Individual Teachers
            </Typography>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          {showAcceptanceHistory && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={classes.spacing}
            >
              <AcceptanceHistory data={getAcceptanceHistory()} />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete='fname'
              name='firstName'
              variant='outlined'
              required
              fullWidth
              id='firstName'
              label='First Name'
              autoFocus
              InputLabelProps={{ shrink: true }}
              value={form?.firstName}
              onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant='outlined'
              required
              fullWidth
              id='lastName'
              label='Last Name'
              name='lastName'
              autoComplete='lname'
              InputLabelProps={{ shrink: true }}
              value={form?.lastName}
              onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailField
              email={form?.email}
              setEmail={(d) => {
                setForm({ ...form, email: d });
                if (
                  data?.status === USER_STATUS.ACTIVE ||
                  data?.status === USER_STATUS.PASSWORDRESET
                ) {
                  setHideStatus(true);
                }
              }}
              className={classes.email}
              school={form?.school}
              setEmailError={setEmailError}
              setLoader={setLoading}
            />
          </Grid>
          {edit && (
            <Grid item xs={12} sm={12}>
              <FormHelperText>{getHelperText()}</FormHelperText>
            </Grid>
          )}
          {!disabledFields.includes('homeAddress') && (
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='homeAddress'
                label='Home Address'
                name='homeAddress'
                InputLabelProps={{ shrink: true }}
                value={form?.homeAddress}
                onChange={(e) =>
                  setForm({ ...form, homeAddress: e.target.value })
                }
              />
            </Grid>
          )}
          {!disabledFields.includes('phoneNumber') && (
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='phoneNumber'
                label='Phone Number'
                name='phoneNumber'
                InputLabelProps={{ shrink: true }}
                value={form?.phoneNumber}
                onChange={(e) =>
                  setForm({ ...form, phoneNumber: e.target.value })
                }
              />
            </Grid>
          )}
          {!disabledFields.includes('schoolName') && (
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='schoolName'
                label='School Name'
                name='schoolName'
                InputLabelProps={{ shrink: true }}
                value={form?.schoolName}
                onChange={(e) =>
                  setForm({ ...form, schoolName: e.target.value })
                }
              />
            </Grid>
          )}
          {!disabledFields.includes('schoolAddress') && (
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='schoolAddress'
                label='School Address'
                name='schoolAddress'
                InputLabelProps={{ shrink: true }}
                value={form?.schoolAddress}
                onChange={(e) =>
                  setForm({ ...form, schoolAddress: e.target.value })
                }
              />
            </Grid>
          )}
          {!edit &&
            !disabledFields.includes('password') &&
            form?.access !== 'teacher' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  InputLabelProps={{ shrink: true }}
                  value={form?.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  disabled={disabledFields.includes('password')}
                />
              </Grid>
            )}
          {form?.access === 'teacher' && (
            <Grid item xs={12} sm={12}>
              <FormControl style={{ width: 300 }}>
                <InputLabel id='access-label'>Access Type</InputLabel>
                <Select
                  labelId='access-select-label'
                  id='acces-select'
                  value={form?.accessLevel}
                  onChange={(e) =>
                    setForm({ ...form, accessLevel: e.target.value })
                  }
                  disabled={true}
                  IconComponent={() => null}
                >
                  <MenuItem value={'limited'}>Limited</MenuItem>
                  <MenuItem value={'full'}>Full</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {form?.access === 'teacher' && !disabledFields.includes('school') && (
            <Grid item xs={12} sm={12}>
              <InputLabel id='access-label'>
                {form?.school || !edit
                  ? 'Select a Group License'
                  : 'Select a License'}
              </InputLabel>
              <FormControl style={{ width: 300 }}>
                <Select
                  labelId='access-simple-select-label'
                  id='acces-simple-select'
                  value={form?.school ? form.school : ''}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      dispatch(
                        enqueueSnackbar({
                          message: 'Please select a license',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                      return;
                    }
                    const license = licenses?.docs?.find(
                      (x) => x.licensee === e.target.value
                    );
                    if (
                      license?.consumedTeacherLicenses ===
                      license?.teacherLicenses
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'There is not enough teacher seats on this license',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                      setLicenseError(true);
                    } else {
                      setLicenseError(false);
                    }
                    setForm({
                      ...form,
                      school: e.target.value,
                      licenseId: license?._id,
                    });
                  }}
                  disabled={disabledFields.includes('school') || edit}
                  displayEmpty
                  IconComponent={edit ? () => null : ArrowDropDown}
                >
                  <MenuItem value={''}>
                    {edit ? 'Individual' : 'No License Selected'}
                  </MenuItem>
                  {schools?.docs
                    ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
                    ?.map((x) => (
                      <MenuItem value={x._id} key={x._id}>
                        {x.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {form?.access === 'teacher' &&
            !edit &&
            !disabledFields.includes('sendManual') && (
              <Grid item xs={12} sm={12}>
                <FormHelperText>
                  Account setup email will be sent to the new Team Teacher & one
                  additional space from the Group License’s Teacher Pool will
                  now be occupied.
                </FormHelperText>
              </Grid>
            )}
          {form?.access === 'teacher' &&
            form?.sendManual &&
            !edit &&
            !disabledFields.includes('licenseId') && (
              <FormHelperText>
                **Please Note: If accidentally assigned to the wrong Group
                License, the teacher must be deleted/recreated in order to
                assign them to a different Group License.
              </FormHelperText>
            )}
        </Grid>
        {edit && ['Password Reset']?.includes(data?.status) && (
          <Grid container direction='column' className={classes.spacing}>
            <Grid item sm={10}>
              <Button
                onClick={() => handleConfirm({ ...form, status: 'Active' })}
                variant={'outlined'}
                endIcon={<Cancel />}
                disabled={sending}
                className={classes.cancel}
              >
                {sending ? 'Sending' : 'Cancel Password Reset Request'}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent='space-between'
          className={classes.topSpacing}
        >
          <Grid item sm={3}>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            sm={
              data?.status === USER_STATUS.PASSWORDRESET && !hideStatus ? 9 : 8
            }
            container
            justifyContent='flex-end'
            alignItems={'flex-end'}
          >
            {data?.status === USER_STATUS.PASSWORDRESET &&
              !hideStatus &&
              valid && (
                <Button
                  onClick={() =>
                    handleConfirm({ ...form, status: 'Active' }, false)
                  }
                  color='secondary'
                  variant={'outlined'}
                  startIcon={<Cancel />}
                  className={classes.link}
                >
                  Cancel Password Reset Request
                </Button>
              )}
            {edit && userStatuses?.includes(data?.status) && !hideStatus && (
              <Button
                onClick={() => handleConfirm(form, true)}
                color='primary'
                variant={'outlined'}
                endIcon={<Send />}
                disabled={sending}
                className={classes.link}
              >
                {sending ? 'Sending' : getAccountStatusLabel(data?.status)}
              </Button>
            )}
            <Button
              onClick={onConfirm}
              color='primary'
              variant='contained'
              disabled={validateDone()}
            >
              {edit ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
