import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addVideo,
  fetchMaxOrder,
  validateOrder,
} from '../../../redux/actions/video';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialVideo = {
  order: 0,
  title: 'Video Title',
  category: 'cantar',
};

export default function NewVideo({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [category, setCategory] = useState('');
  const history = useHistory();
  const { actionLoading, orderNumberStatus, maxOrder } = useSelector(
    ({ admin }) => admin.video
  );
  const [videoData, setVideoData] = useState(initialVideo);

  const handleSave = (d) => {
    setVideoData(d);
    dispatch(addVideo(d));
    history.push('/superadmin/videos');
  };

  useEffect(() => {
    dispatch(fetchMaxOrder({ status: 0 }));
    dispatch(validateOrder({ status: true }));
  }, [dispatch]);

  const getMaximumOrderNumber = (type, data) => {
    let update = { category };
    if (type === 'category') {
      setCategory(data);
      update.category = data;
    }
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  const validateOrderNumber = (order) => {
    if (category) {
      dispatch(
        validateOrder({
          category: category,
          order: order,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={videoData}
        onSave={handleSave}
        content={['image', 'video ']}
        html
        titleField
        htmlField={'content'}
        sectionCategory
        maximumOrderValue={maxOrder + 1}
        onSelectedData={(type, data) => getMaximumOrderNumber(type, data)}
        validateOrderNo={(order) => validateOrderNumber(order)}
        fieldError={orderNumberStatus}
      />
    </div>
  );
}
