import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  TOPICACTION_ATTEMPT,
  TOPICLIST_ATTEMPT,
  TOPIC_ERROR,
  FETCHALLTOPICS_SUCCESS,
  FETCHTOPIC_SUCCESS,
  ADDTOPIC_SUCCESS,
  UPDATETOPIC_SUCCESS,
  DELETETOPIC_SUCCESS,
  FETCHPARENTTOPICS_SUCCESS,
  BULKUPDATETOPIC_SUCCESS,
  BULKDELETETOPIC_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';
import { fetchAllGroups } from './group';
const topicUrl = 'admin/topics';

export const fetchParentTopics = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICLIST_ATTEMPT));
      const body = await axios.get(`${topicUrl}`, {
        params: { filters: { isChild: false } },
      });
      dispatch(successAction(FETCHPARENTTOPICS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAllTopics = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICLIST_ATTEMPT));
      const body = await axios.get(`${topicUrl}`, { params });
      dispatch(successAction(FETCHALLTOPICS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const fetchTopic = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICLIST_ATTEMPT));
      const body = await axios.get(`${topicUrl}/${id}`);
      dispatch(successAction(FETCHTOPIC_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const addTopic = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.post(`${topicUrl}`, data);
      dispatch(successAction(ADDTOPIC_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const updateTopic = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.post(`${topicUrl}/${id}`, data);
      dispatch(successAction(UPDATETOPIC_SUCCESS, body.data));
      if (body.data.status === 200 && body.data.message) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const deleteTopic = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.delete(`${topicUrl}/${id}`);
      dispatch(successAction(DELETETOPIC_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const archiveTopic = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.put(`${topicUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATETOPIC_SUCCESS, body.data));
      dispatch(fetchAllTopics({ pagination: false }));
      dispatch(fetchAllGroups({ pagination: false }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const restoreTopic = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.put(`${topicUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATETOPIC_SUCCESS, body.data));
      dispatch(fetchAllTopics({ pagination: false }));
      dispatch(fetchAllGroups({ pagination: false }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveTopic = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.post(`${topicUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATETOPIC_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreTopic = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.post(`${topicUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATETOPIC_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteTopic = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TOPICACTION_ATTEMPT));
      const body = await axios.post(`${topicUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETETOPIC_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(TOPIC_ERROR, err));
    }
  };
  return thunk;
};
