import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  SETTINGACTION_ATTEMPT,
  SETTINGLIST_ATTEMPT,
  SETTING_ERROR,
  FETCHSETTING_SUCCESS,
  UPDATESETTING_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const settingUrl = 'admin/settings';

export const fetchSetting = (name) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SETTINGLIST_ATTEMPT));
      const body = await axios.get(`${settingUrl}/${name}`);
      dispatch(successAction(FETCHSETTING_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SETTING_ERROR, err));
    }
  };
  return thunk;
};

export const updateSetting = (name, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SETTINGACTION_ATTEMPT));
      const body = await axios.post(`${settingUrl}/${name}`, data);
      dispatch(successAction(UPDATESETTING_SUCCESS, body.data));
      dispatch(
        enqueueSnackbar({
          message: 'Saved Successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(SETTING_ERROR, err));
    }
  };
  return thunk;
};
