import React from 'react';
import {
  DialogActions,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { CloseRounded, DoneRounded } from '@material-ui/icons';
import { COLORS } from 'constants';

const useStyles = makeStyles((theme) => ({
  close: {
    borderRadius: theme.spacing(1),
    color: COLORS.PINK,
  },
  done: {
    borderRadius: theme.spacing(1),
  },
  loader: {
    margin: '6px',
  },
}));

export default function StandardDialogActions({
  onClose,
  onDone,
  saving,
  hideDone,
  additionalActions,
  closeButtonText,
  doneButtonText,
  closeButtonIcon,
  doneButtonIcon,
  disableDone = false,
}) {
  const classes = useStyles();
  return (
    <DialogActions>
      {!saving && (
        <>
          {additionalActions}
          {onClose && (
            <Button
              onClick={onClose}
              className={classes.close}
              startIcon={closeButtonIcon || <CloseRounded />}
            >
              {closeButtonText || 'CLOSE'}
            </Button>
          )}

          {onDone && !hideDone && (
            <Button
              onClick={onDone}
              className={classes.done}
              variant='contained'
              color='primary'
              startIcon={doneButtonIcon || <DoneRounded />}
              disabled={disableDone}
            >
              {doneButtonText || 'DONE'}
            </Button>
          )}
        </>
      )}
      {saving && (
        <CircularProgress
          color='primary'
          size={24}
          className={classes.loader}
        />
      )}
    </DialogActions>
  );
}
