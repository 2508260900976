import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addLesson } from '../../../redux/actions/lesson';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialLesson = {
  page: 1,
  lesson: '',
  image: '',
};

export default function NewLesson({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading } = useSelector(({ admin }) => admin.lesson);
  const [lessonData, setLessonData] = useState(initialLesson);

  const handleSave = (d) => {
    setLessonData(d);
    dispatch(addLesson(d));
    history.push('/superadmin/lessons');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={lessonData}
        onSave={handleSave}
        content={['image']}
        orderField={false}
        textFields={['lesson']}
        numberFields={['page']}
        switchFields={['miniLesson', 'vocabularyTopic']}
      />
    </div>
  );
}
