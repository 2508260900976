import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMaxOrder,
  fetchVideo,
  updateVideo,
  validateOrder,
} from '../../../redux/actions/video';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditVideo({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading, video, orderNumberStatus, maxOrder } = useSelector(
    ({ admin }) => admin.video
  );

  const [category, setCategory] = React.useState();

  useEffect(() => {
    dispatch(fetchVideo(id));
    dispatch(fetchMaxOrder({ status: 0 }));
    dispatch(validateOrder({ status: true }));
  }, [dispatch, id]);

  useEffect(() => {
    if (video === null) return;
    setCategory(video?.category);
  }, [video]);

  const handleSave = (d) => {
    dispatch(updateVideo(id, d));
  };

  const validateOrderNumber = (order) => {
    if (category) {
      dispatch(
        validateOrder({
          category: category,
          order: order,
        })
      );
    }
  };

  const getMaximumOrderNumber = (type, data) => {
    let update = { category };
    if (type === 'category') {
      setCategory(data);
      update.category = data;
    }
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {video && (
        <Form
          initialData={video}
          onSave={handleSave}
          content={['image', 'video']}
          html
          titleField
          htmlField={'content'}
          sectionCategory
          maximumOrderValue={maxOrder > 0 ? maxOrder + 1 : video?.order}
          validateOrderNo={(order) => validateOrderNumber(order)}
          fieldError={orderNumberStatus}
          onSelectedData={(type, data) => getMaximumOrderNumber(type, data)}
        />
      )}
    </div>
  );
}
