/**
 * * Lesson Reducer
 */

import {
  LESSONACTION_ATTEMPT,
  LESSONLIST_ATTEMPT,
  LESSON_ERROR,
  FETCHALLLESSONS_SUCCESS,
  FETCHLESSON_SUCCESS,
  ADDLESSON_SUCCESS,
  UPDATELESSON_SUCCESS,
  DELETELESSON_SUCCESS,
  ADDBULKLESSON_SUCCESS,
  DELETEALLLESSONS_SUCCESS,
  BULKDELETELESSON_SUCCESS,
  BULKUPDATELESSON_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  lessons: null,
  lesson: null,
};

export default function lesson(state = initialState, action) {
  switch (action.type) {
    case LESSONLIST_ATTEMPT:
      return { ...state, fetching: true };
    case LESSONACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case LESSON_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLLESSONS_SUCCESS:
      return { ...state, fetching: false, lessons: action.payload.data };
    case FETCHLESSON_SUCCESS:
      return { ...state, fetching: false, lesson: action.payload.lesson };
    case ADDLESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: {
          ...state.lessons,
          docs: [...state.lessons.docs, action.payload.lesson],
        },
      };
    case ADDBULKLESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: state.lessons
          ? {
              ...state.lessons,
              docs: [...state.lessons.docs, ...action.payload.lessons],
            }
          : action.payload.lessons,
      };
    case UPDATELESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: {
          ...state.lessons,
          docs: state.lessons.docs.map((x) => {
            if (x._id === action.payload.lesson._id) {
              return action.payload.lesson;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETELESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: {
          ...state.lessons,
          docs: state.lessons.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case DELETEALLLESSONS_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: null,
      };
    case BULKUPDATELESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: {
          ...state.lessons,
          docs: state.lessons.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETELESSON_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        lessons: {
          ...state.lessons,
          docs: state.lessons.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
