import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchAllSchools, updateSchool } from '../../redux/actions/school';
import { fetchHeadTeachers } from 'containers/SuperAdmin/redux/actions/user';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';

import Button from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';

import SchoolDialog from '../../components/SchoolDialog';
import FilterBar from 'containers/SuperAdmin/components/FilterBar';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Schools({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [schoolData, setSchoolData] = useState(null);
  const [schoolDialog, setSchoolDialog] = useState(false);
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });

  const { schools, fetching, actionLoading } = useSelector(
    ({ admin }) => admin.school
  );
  const { headTeachers } = useSelector(({ admin }) => admin.users);

  const loadPage = (page, sortOptions = {}) => {
    dispatch(fetchAllSchools({ page, limit: schools.limit, ...sortOptions }));
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'address', label: 'Address' },
    {
      id: 'email',
      label: 'Head Teacher',
      formatter: (row) => {
        const teacher = headTeachers.find((x) => x.school === row._id);
        if (teacher) {
          return `${[teacher.firstName, teacher.lastName].join(' ')}`;
        } else {
          return 'N/A';
        }
      },
    },
    {
      id: 'teachers',
      label: 'Teachers',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() =>
            history.push('/superadmin/schools/' + row._id + '/teachers')
          }
        >
          {row.teacherCount}
        </Button>
      ),
    },
    {
      id: 'classes',
      label: 'Classes',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() =>
            history.push('/superadmin/schools/' + row._id + '/classes')
          }
        >
          {row.classCount}
        </Button>
      ),
    },
    {
      id: 'students',
      label: 'Students',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() =>
            history.push('/superadmin/schools/' + row._id + '/students')
          }
        >
          {row.studentCount}
        </Button>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions: [
        {
          label: 'Edit',
          action: (r) => {
            setSchoolData(r);
            setSchoolDialog(true);
          },
          icon: <EditIcon />,
          disabled: () => false,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllSchools());
    dispatch(fetchHeadTeachers());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      {(fetching || actionLoading) && (
        <Loader fetching={fetching || actionLoading} />
      )}
      <PageHeader
        onSearch={(search) => dispatch(fetchAllSchools({ search }))}
        title={name}
      />
      <FilterBar
        enableTopics={false}
        enableGlobal
        selectedPage='School'
        onSearch={(d) => dispatch(fetchAllSchools({ search: d }))}
      />
      {schools && schools.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={schools}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(schools.page, d)}
            />
          </Grid>
        </Grid>
      )}
      {schoolData && (
        <SchoolDialog
          open={schoolDialog}
          edit={true}
          data={schoolData}
          handleClose={() => {
            setSchoolDialog(false);
            setSchoolData(null);
          }}
          handleConfirm={(data) => {
            let { name, address } = data?.school;
            dispatch(updateSchool(schoolData._id, { name, address }));
            setSchoolDialog(false);
            setSchoolData(null);
          }}
          headTeacherData={headTeachers?.find(
            (x) => x.school === schoolData?._id
          )}
        />
      )}
    </div>
  );
}
