import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueAxiosError } from 'redux/actions';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';

import dayJS from 'dayjs';
import BasicDatePicker from '../DatePicker';
import LicenseHistory from '../LicenseHistory';
import Licensee from '../Licensee';
import EmailField from '../EmailField';
import ChangeHeadTeacherDialog from './ChangeHeadTeacher';
import { RESTORE_OPTIONS, formatValidityPeriodUnit } from '../../constants';

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(1, 0),
  },
  label: {
    margin: theme.spacing(0, 0),
  },
  actions: {
    justifyContent: 'space-between',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  validity: {
    root: {
      width: '75%',
    },
  },
  select: {
    margin: theme.spacing(0, 1),
  },
}));

const units = ['Days', 'Months', 'Years'];

const TIMEOUT = 500;

export default function RenewLicenseDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  currentDate = false,
  confirmText,
}) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { users, headTeachers } = useSelector(({ admin }) => admin.users);
  const [form, setForm] = useState({});
  const [headTeacher, setHeadTeacher] = useState(null);
  const [headTeacherName, setHeadTeacherName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [teacherCountError, setTeacherCountError] = useState(false);
  const [studentCountError, setStudentCountError] = useState(false);
  const [studentCountChecking, setStudentCountChecking] = useState(false);
  const [teacherCountChecking, setTeacherCountChecking] = useState(false);
  const [teacherCount, setTeacherCount] = useState(data?.teacherLicenses);
  const [studentCount, setStudentCount] = useState(data?.studentLicenses);

  const [headTeacherChange, setHeadTeacherChange] = useState(false);

  const currentHeadTeacher = headTeachers?.find(
    (x) => x.school === data?.licensee
  );

  useEffect(() => {
    if (!open) return;
    setEmailError(false);
    setTeacherCountError(false);
    setStudentCountError(false);
    setHeadTeacher(null);
    setHeadTeacherName(null);
    if (data) {
      setTeacherCount(data?.teacherLicenses);
      setStudentCount(data?.studentLicenses);
      setForm({
        ...data,
        issueDate: currentDate ? new Date() : data?.issueDate,
      });
      if (data?.licenseType === 'School' && data?._id) {
        axios(
          `/admin/licenses/${data?._id}/${
            data?.isArchived ? 'archiveCount' : 'activeCount'
          }`
        )
          .then(({ data }) => {
            const count = data?.result;
            setTeacherCount(count?.teachers);
            setStudentCount(count?.students);
          })
          .catch((error) => {
            dispatch(enqueueAxiosError(error));
          });
      }
    }
  }, [data, open, currentDate, dispatch]);

  const getLicenseHistory = () => {
    const current = {
      issueDate: data?.issueDate,
      activationDate: data?.activationDate,
      validityPeriod: data?.validityPeriod,
      validityPeriodUnit: data?.validityPeriodUnit,
      expiryDate: data?.expiryDate,
      status: data?.status,
    };
    let history = [current];
    if (data?.history) {
      const historyRecords = data?.history;
      if (current?.activationDate) {
        history = [...historyRecords, current];
      } else {
        history = [...historyRecords];
      }
    }
    return history;
  };

  const handleEmail = (d) => {
    let update = { ...form, email: d };
    if (!form?.isDomainCompliant) {
      update = {
        ...update,
        domain: d.split('@')[1],
      };
    }
    setForm(update);
  };

  const handleStudentLicenseChange = (e) => {
    if (e.target.value < 1) return;
    setForm({
      ...form,
      studentLicenses: e.target.value,
      teacherAccess: e.target.value > 0 ? 'full' : 'limited',
    });
    setStudentCountChecking(true);
    setTimeout(() => {
      if (e.target.value < studentCount && form?.settings === 'preserve') {
        if (e.target.value < 1)
          setStudentCountError('Student Pool cannot be lower than 1');
        else
          setStudentCountError(
            `Student Pool cannot be lower than the quantity of currently ${
              form?.isArchived ? 'archived' : 'active'
            } Student Accounts`
          );
      } else {
        setStudentCountError(false);
      }
      setStudentCountChecking(false);
    }, TIMEOUT);
  };

  const handleTeacherLicenseChange = (e) => {
    if (e.target.value < 0) return;
    setForm({ ...form, teacherLicenses: e.target.value });
    setTeacherCountChecking(true);
    setTimeout(() => {
      const totalTeacherCount =
        headTeacher?.updateType === 'new' ? teacherCount + 1 : teacherCount;
      if (e.target.value < totalTeacherCount && form?.settings === 'preserve') {
        if (e.target.value < 1) {
          setTeacherCountError('Teacher Pool cannot be lower than 1');
        } else {
          setTeacherCountError(
            `Teacher Pool cannot be lower than the quantity of currently ${
              form?.isArchived ? 'archived' : 'active'
            }`
          );
        }
      } else {
        setTeacherCountError(false);
      }
      setTeacherCountChecking(false);
    }, TIMEOUT);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='form-dialog-title'>
        {`Renew ${form?.category} License for ${form?.licenseType}`}
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            spacing={2}
            className={styles.spacing}
          >
            <LicenseHistory data={getLicenseHistory()} />
          </Grid>
          {form?.licenseType !== 'School' && form?.isArchived && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={styles.spacing}
            >
              <Typography>
                Taking this action will renew & activate the License. Please
                complete the necessary information to renew this License.
              </Typography>
            </Grid>
          )}
          {form?.licenseType === 'School' && (
            <>
              <Grid item xs={12}>
                <FormControl>
                  <Select
                    labelId='select-restore-settings'
                    id='select-restore-settings-select'
                    value={form?.settings || 'preserve'}
                    style={{ width: 200 }}
                    className={styles.select}
                    fullWidth
                    onChange={(e) =>
                      setForm({
                        ...form,
                        settings: e.target.value,
                      })
                    }
                  >
                    {RESTORE_OPTIONS.map((option, index) => (
                      <MenuItem
                        value={option.value}
                        key={index}
                        disabled={data?.history?.length === 0 && index === 0}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!form?.isArchived && (
                <Grid item xs={12} className={styles.spacing}>
                  {form?.settings === 'preserve' && (
                    <Typography>
                      A new Activation & Expiry Date will be set, but all
                      License configurations will remain identical to their
                      current state. Active & Archived Teachers & Students will
                      not be moved or altered, & all Active Accounts will remain
                      Activated with their existing login credentials. Only the
                      Head Teacher will need to reset their password in order
                      for all other accounts to be accessible.
                    </Typography>
                  )}
                  {form?.settings === 'reset' && (
                    <Typography>
                      A new Activation & Expiry Date will be set, & all License
                      configurations will be Reset as New. Existing Classes will
                      be Permanently Deleted. All Active Teacher & Student
                      Accounts will be Deactivated & Archived, & all Archived
                      Students will become Unassigned from their Teachers.
                    </Typography>
                  )}
                </Grid>
              )}
              {form?.isArchived && (
                <Grid item xs={12} className={styles.spacing}>
                  {form?.settings === 'preserve' && (
                    <Typography>
                      Taking this action will set a new Activation & Expiry date
                      for the License. It will also restore the Archived School,
                      Teachers, Classes, & Students belonging to this License,
                      with the exception of those that have already been deleted
                      or unassigned. Reactivated accounts will not need to reset
                      their password (with the exception of the Head Teacher) &
                      can utilize the same login credentials used prior to the
                      License being Archived.
                    </Typography>
                  )}
                  {form?.settings === 'reset' && (
                    <Typography>
                      A new Activation & Expiry Date will be set, & all License
                      configurations will be Reset as New. Archived Classes will
                      be Permanently Deleted. All Archived Teacher & Student
                      Accounts will remain Archived, & all Students will become
                      Unassigned from their Teachers.
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          )}
          {!headTeacher && !headTeacherName && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={styles.spacing}
            >
              <Licensee
                id={form?.licensee}
                type={form?.licenseType}
                isHeadTeacher={form?.licenseType === 'School'}
                textProps={{ variant: 'h6' }}
                variant='text'
              />
            </Grid>
          )}
          {headTeacherName && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={styles.spacing}
            >
              <Typography variant='h6'>
                New Head Teacher: {headTeacherName}
              </Typography>
              {headTeacher?.updateType === 'new' && (
                <Typography variant='h6'>
                  {headTeacher?.teacherData?.email}
                </Typography>
              )}
            </Grid>
          )}
          {form?.licenseType === 'School' && (
            <Grid
              item
              xs={12}
              sm={12}
              className={styles.spacing}
              container
              justifyContent='space-between'
            >
              <Button
                onClick={() => setHeadTeacherChange(true)}
                variant='contained'
                color='primary'
              >
                <Typography align='left'>Replace Head Teacher</Typography>
              </Button>
              {headTeacher && headTeacherName && (
                <Button
                  onClick={() => {
                    if (headTeacher?.updateType === 'new') {
                      setForm({
                        ...form,
                        teacherLicenses: form?.teacherLicenses - 1,
                      });
                    }
                    setHeadTeacher(null);
                    setHeadTeacherName('');
                  }}
                  variant='contained'
                  color='secondary'
                >
                  <Typography align='left'>
                    Keep current Head Teacher
                  </Typography>
                </Button>
              )}
              <ChangeHeadTeacherDialog
                open={headTeacherChange}
                handleClose={() => setHeadTeacherChange(false)}
                handleConfirm={(d) => {
                  setHeadTeacher(d);
                  if (
                    d?.teacherData?.firstName &&
                    d?.teacherData?.lastName &&
                    d?.updateType === 'new'
                  ) {
                    setHeadTeacherName(
                      `${d?.teacherData?.firstName} ${d?.teacherData?.lastName}`
                    );
                    const totalTeacherCount = teacherCount + 1;
                    setForm({ ...form, teacherLicenses: totalTeacherCount });
                  } else {
                    const user = users?.docs?.find(
                      (u) => u?._id === d?.teacherId
                    );
                    setHeadTeacherName(`${user?.firstName} ${user?.lastName}`);
                    setForm({ ...form, email: user?.email });
                  }
                  setHeadTeacherChange(false);
                }}
                school={data?.licensee}
                isArchived={data?.isArchived}
              />
            </Grid>
          )}
          {form?.licenseType === 'School' && (
            <>
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <TextField
                  name='Teacher Licenses'
                  type={'number'}
                  variant='outlined'
                  required
                  fullWidth
                  id='teacherLicenses'
                  label='Teacher Licenses'
                  value={form?.teacherLicenses}
                  onChange={handleTeacherLicenseChange}
                  inputProps={{ min: 1, max: 5 }}
                  error={Boolean(teacherCountError)}
                  helperText={teacherCountError || ''}
                  InputProps={{
                    endAdornment: teacherCountChecking && (
                      <CircularProgress size={20} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <TextField
                  name='Student Licenses'
                  type={'number'}
                  variant='outlined'
                  required
                  fullWidth
                  id='studentLicenses'
                  label='Student Licenses'
                  value={form?.studentLicenses}
                  onChange={handleStudentLicenseChange}
                  inputProps={{ max: 650, min: 1 }}
                  error={Boolean(studentCountError)}
                  helperText={studentCountError || ''}
                  InputProps={{
                    endAdornment: studentCountChecking && (
                      <CircularProgress size={20} />
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          {(form?.licenseType !== 'School' ||
            (form?.licenseType === 'School' &&
              headTeacher?.updateType !== 'new')) && (
            <Grid item xs={12} sm={12} className={styles.spacing}>
              <EmailField
                email={form?.email}
                setEmail={handleEmail}
                school={form?.licensee}
                setLoader={setLoading}
                setEmailError={setEmailError}
                forLicense
                licenseData={form}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            spacing={2}
            className={styles.spacing}
          >
            <BasicDatePicker
              value={form?.issueDate}
              setValue={(d) => setForm({ ...form, issueDate: d })}
              label={'Set New Issue/Activation Date'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            spacing={2}
            className={styles.spacing}
          >
            <Grid item sm={6}>
              <TextField
                id='validityPeriod'
                name='Validity Period'
                type={'number'}
                variant='outlined'
                required
                fullWidth
                label='Duration'
                autoFocus
                value={form?.validityPeriod}
                onChange={(e) =>
                  setForm({ ...form, validityPeriod: e.target.value })
                }
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl>
                <InputLabel
                  id='validity-period-label-demo'
                  className={styles.label}
                >
                  Validity Period Unit
                </InputLabel>
                <Select
                  labelId='select-validity-period-label'
                  id='validity-period-select'
                  value={form?.validityPeriodUnit}
                  style={{ width: 200 }}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      validityPeriodUnit: e.target.value,
                    })
                  }
                  fullWidth
                >
                  {units.map((option, index) => (
                    <MenuItem value={option} key={index}>
                      {formatValidityPeriodUnit(form?.validityPeriod, option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            spacing={2}
            className={styles.spacing}
          >
            <Grid item sm={4}>
              Estimated Expiry Date
            </Grid>
            <Grid item sm={6}>
              {dayJS(form?.issueDate)
                .add(form?.validityPeriod, form?.validityPeriodUnit)
                .format('MMMM DD, YYYY')}
            </Grid>
          </Grid>
          {form?.licenseType !== 'School' && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={styles.spacing}
            >
              <Typography variant='body2'>
                Clicking {confirmText || 'Renew Now'} will Reactive the License
                & send an account activation email to the License Holder.
              </Typography>
            </Grid>
          )}
          {form?.licenseType === 'School' && (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              spacing={2}
              className={styles.spacing}
            >
              <Typography variant='body2'>
                Clicking {confirmText || 'Renew Now'} will Reactivate the
                License & send an account activation email to the Head Teacher
                selected.
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() =>
            handleConfirm({
              issueDate: form?.issueDate,
              validityPeriod: form?.validityPeriod,
              validityPeriodUnit: form?.validityPeriodUnit,
              email: form?.email,
              teacherLicenses: form?.teacherLicenses,
              studentLicenses: form?.studentLicenses,
              settings: form?.settings,
              headTeacher: headTeacher,
            })
          }
          color='primary'
          variant={'contained'}
          endIcon={<Send />}
          disabled={
            loading ||
            emailError ||
            teacherCountError ||
            studentCountError ||
            (data?.licenseType === 'School' &&
              !headTeacher &&
              !headTeacherName &&
              !currentHeadTeacher)
          }
        >
          {confirmText || 'Renew Now'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
