import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import {
  LinearProgress,
  makeStyles,
  Button,
  Box,
  Grid,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { BASE_CLOUDFRONT_URL } from 'constants';
import Table from 'components/Table';
import TopicPicker from '../TopicTree/picker';
import SectionSelector from '../SectionSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dropZone: {
    border: '2px dashed gray',
    padding: '10px',
    margin: '5px',
    marginLeft: '0px',
    width: '90%',
  },
  spacing: {
    margin: theme.spacing(1, 0),
  },
}));

const S3FileUploader = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  // Keep track of the progress
  const [progress, setProgress] = useState(0);
  const [dir, setDir] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [group, setGroup] = useState(null);
  const [selectedSection, setSelectedSection] = useState('');

  const onDrop = async (acceptedFiles) => {
    setSelectedFiles([]);
    setDir('');
    const zip = new JSZip();
    const files = [];

    for (const file of acceptedFiles) {
      const content = await file.arrayBuffer();
      zip.file(file.name, content);
      files.push(file.name);
    }

    const zipContent = await zip.generateAsync({ type: 'blob' });
    const zipFile = new File([zipContent], 'files.zip', {
      type: 'application/zip',
    });

    const formData = new FormData();
    formData.append('file', zipFile);
    formData.append('path', '/');

    try {
      // keep track of progress
      const response = await axios.post('/admin/upload/directory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
      const directory = response.data?.directory;
      fetchFiles(directory);
      setProgress(0);
    } catch (error) {
      enqueueSnackbar('Error uploading directory', {
        variant: 'error',
      });
    }
  };

  const fetchFiles = useCallback(
    async (path) => {
      try {
        const response = await axios.get(
          `/admin/upload/files${path ? `/${path}` : ''}`
        );
        setDir(path);
        // sort by directory first, then by name
        const files = response.data.filter((f) => f.type === 'file');
        // extract the file names by splitting the key
        const fileData = separateNames(
          files.map((f) => f.key),
          path
        );
        setSelectedFiles(fileData);
      } catch (error) {
        enqueueSnackbar(error?.data?.message || '', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  function separateNames(keys, path) {
    const separatedNames = [];
    for (const key of keys) {
      const name = key.split(`${path}/`)[1].split('.')[0];
      const nameParts = name.split('-');
      const spanishName = nameParts[0];
      const englishName = name.split(`${spanishName}-`)[1];
      const url = `${BASE_CLOUDFRONT_URL}/${key}`;
      let nameObject = {
        spanishName: spanishName,
        name: englishName,
        transparent: url,
      };
      separatedNames.push(nameObject);
    }
    return separatedNames;
  }

  const handleSave = async () => {
    if (!selectedSection) {
      enqueueSnackbar('Please select a section', {
        variant: 'error',
      });
      return;
    }
    if (selectedSection === 'Aprender') {
      const update = selectedFiles
        .map((file) => {
          let fileData = {
            ...file,
            topics,
          };
          if (group || group !== '') {
            fileData = {
              ...fileData,
              group,
            };
          }
          return fileData;
        })
        .sort((a, b) => a.spanishName.localeCompare(b.spanishName));
      let data = {
        update,
        archiveOld: true,
        archiveParams: {
          topics: { $in: topics },
        },
      };
      if (group || group !== '') {
        data.archiveParams = {
          ...data.archiveParams,
          group,
        };
      }
      try {
        await axios.post('/admin/aprenders/bulkAdd', data);
        enqueueSnackbar('Saved Successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error?.data?.message || '', {
          variant: 'error',
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const headCells = [
    {
      id: 'spanishName',
      align: 'left',
      label: 'Spanish Name',
    },
    {
      id: 'name',
      align: 'left',
      label: 'English Name',
    },
    {
      id: 'image',
      align: 'left',
      label: 'Image',
      formatter: (value) => <img src={value.image} alt='img' width='60px' />,
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Box {...getRootProps()} className={classes.dropZone}>
          <input {...getInputProps()} directory='' webkitdirectory='' />
          {isDragActive ? (
            <p>Drop the directory here...</p>
          ) : (
            <p>
              Drag and drop a directory here, or click to select a directory
            </p>
          )}
          {progress > 0 && (
            <LinearProgress variant='determinate' value={progress} />
          )}
        </Box>
      </Grid>

      {dir && <h2>Directory: {dir}</h2>}
      <Grid container className={classes.spacing}>
        <Grid item sm={3}>
          <TopicPicker
            onlyRoot={false}
            selectedTopic={topics}
            selectedGroup={group}
            handleSelect={(e) => {
              setTopics(e.topics);
              // setRootTopic(e.rootTopic);
              setGroup(e.group);
            }}
            enableGroups={true}
          />
        </Grid>
        <Grid item sm={3}>
          <SectionSelector
            selected={selectedSection}
            setSelected={setSelectedSection}
            isContent
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Table
          size='small'
          tableWidth='100%'
          headCells={headCells}
          data={selectedFiles}
          selected={[]}
          setSelected={() => {}}
        />
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Button onClick={handleSave} variant='contained' color='primary'>
          Save
        </Button>
      </Grid>
    </div>
  );
};

export default S3FileUploader;
