import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TopicSelector from '../TopicSelector';

export default function GroupDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  setData,
  edit = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='data-dialog-title'
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id='data-dialog-title'>Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Name'
          type='text'
          fullWidth
          value={data?.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <TextField
          autoFocus
          margin='dense'
          id='order'
          label='Order'
          type='number'
          fullWidth
          value={data?.order}
          onChange={(e) => setData({ ...data, order: e.target.value })}
        />
        <TopicSelector
          selectedTopics={data?.topics}
          setTopics={(topics) => setData({ ...data, topics })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirm(data)}
          color='primary'
          variant='contained'
        >
          {edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
