export const ArchiveIcon = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 72 72'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='#A57939'
        d='M57.922 59.715H14.078c-.55 0-1-.45-1-1V35.182c0-.55.45-1 1-1h43.844c.55 0 1 .45 1 1v23.533c0 .55-.45 1-1 1z'
      />
      <g
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path d='M57.922 59.715H14.078c-.55 0-1-.45-1-1V35.182c0-.55.45-1 1-1h43.844c.55 0 1 .45 1 1v23.533c0 .55-.45 1-1 1z' />
        <path d='M57.718 60.002H14.364a1.145 1.145 0 0 1-1.145-1.145V35.103c0-.632.513-1.145 1.145-1.145h43.355c.633 0 1.145.513 1.145 1.145v23.754c0 .633-.512 1.145-1.145 1.145zM13.443 47.011h45.366M40.028 38.97h-8.027M40.028 51.973h-8.027M36.047 9.125v18.87M42.202 21.874l-6.14 6.14M29.872 21.874l6.14 6.14' />
      </g>
    </svg>
  );
};

export const ArchiveFolderIcon = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='white'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity={0.1}
        d='M3 9.312C3 4.938 3.938 4 8.312 4H9.93a2 2 0 0 1 1.664.89l.812 1.22A2 2 0 0 0 14.07 7h5.056c1.055 0 1.887.82 1.877 1.876L21 9.312v5.376C21 19.062 20.062 20 15.688 20H8.312C3.938 20 3 19.062 3 14.688V9.312Z'
        fill='#323232'
      />
      <path
        d='M3 9.312C3 4.938 3.938 4 8.312 4H9.93a2 2 0 0 1 1.664.89l.812 1.22A2 2 0 0 0 14.07 7h5.056c1.055 0 1.887.82 1.877 1.876L21 9.312v5.376C21 19.062 20.062 20 15.688 20H8.312C3.938 20 3 19.062 3 14.688V9.312Z'
        stroke='#323232'
        strokeWidth={2}
      />
      <path
        d='M12 16.5V11M9.5 14l2.419 2.419v0a.115.115 0 0 0 .162 0v0L14.5 14'
        stroke='#323232'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const DeleteIcon = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 1024 1024'
      className='delete_svg__icon'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M154 260h568v700H154z' fill='#FF3B30' />
      <path
        d='M624.428 261.076v485.956c0 57.379-46.737 103.894-104.391 103.894h-362.56v107.246h566.815V261.076h-99.864z'
        fill='#030504'
      />
      <path
        d='M320.5 870.07c-8.218 0-14.5-6.664-14.5-14.883V438.474c0-8.218 6.282-14.883 14.5-14.883s14.5 6.664 14.5 14.883v416.713c0 8.219-6.282 14.883-14.5 14.883zm223 0c-8.218 0-14.5-6.664-14.5-14.883V438.474c0-8.218 6.282-14.883 14.5-14.883s14.5 6.664 14.5 14.883v416.713c0 8.219-6.282 14.883-14.5 14.883z'
        fill='#152B3C'
      />
      <path d='M721.185 345.717v-84.641H164.437z' fill='#030504' />
      <path
        d='m633.596 235.166-228.054-71.773 31.55-99.3 228.055 71.773z'
        fill='#FF3B30'
      />
      <path
        d='M847.401 324.783c-2.223 0-4.475-.333-6.706-1.034L185.038 117.401c-11.765-3.703-18.298-16.239-14.592-27.996 3.706-11.766 16.241-18.288 27.993-14.595l655.656 206.346c11.766 3.703 18.298 16.239 14.592 27.996-2.995 9.531-11.795 15.631-21.286 15.631z'
        fill='#FF3B30'
      />
    </svg>
  );
};

export const RestoreIcon = (props) => (
  <svg
    fill='#7858bf'
    width='1em'
    height='1em'
    viewBox='0 0 36 36'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      className='restore_svg__clr-i-solid restore_svg__clr-i-solid-path-1'
      d='M2 24v6a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2v-6Zm28 5h-4v-2h4Z'
    />
    <path
      className='restore_svg__clr-i-solid restore_svg__clr-i-solid-path-2'
      d='M30.84 13.37A1.94 1.94 0 0 0 28.93 12H21v6a3 3 0 1 1-6 0v-6H7.07a1.92 1.92 0 0 0-1.9 1.32c-2.31 6.36-2.93 8.11-3.1 8.68h31.86c-.16-.57-.79-2.31-3.09-8.63Z'
    />
    <path
      className='restore_svg__clr-i-solid restore_svg__clr-i-solid-path-3'
      d='M13 9.92 17 6v12a1 1 0 1 0 2 0V6l4 3.95a1 1 0 1 0 1.38-1.45L18 2.16 11.61 8.5A1 1 0 0 0 13 9.92Z'
    />
    <path fill='none' d='M0 0h36v36H0z' />
  </svg>
);
