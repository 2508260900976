import 'App.css';

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import useNotifier from './hooks/useNotifier';

// !* Router Middlewares
import ProtectedRoute from 'routers/Protected';
import SuperAccess from 'routers/SuperAccess';
import TeacherAgreementAccess from 'routers/AgreementAccess';
import ProtectedWithPassword from 'routers/ProtectedWithPassword';

import NotFound from 'components/NotFound';
import SuspenseLoader from 'components/Loader/suspense';
import GlobalDialog from 'components/GlobalDialog';
import { isProd } from 'constants';

// !* Old Content
const OldTopics = lazy(() => import('containers/OldContent/Topics'));
const OldChildTopics = lazy(() => import('containers/OldContent/ChildTopics'));
const OldContentSections = lazy(() => import('containers/OldContent/ContentSections'));
const OldLesson = lazy(() => import('containers/OldContent/Textbook'));
const OldLessonView = lazy(() => import('containers/OldContent/Textbook/view'));
const OldLessonExternal = lazy(() => import('containers/OldContent/Textbook/external'));
const OldTeacherRoutes = lazy(() => import('containers/OldTeachers/routes'));

// !* Auth Routes
const Login = lazy(() => import('containers/Auth/pages/Login'));
const CleverAuth = lazy(() => import('containers/Auth/pages/Clever'));

const PasswordReset = lazy(() => import('containers/Auth/pages/PasswordReset'));
const ForgotPassword = lazy(() => import('containers/Auth/pages/ForgotPassword'));

const PasswordStudentReset = lazy(() => import('containers/Auth/pages/PasswordReset/student'));
const ForgotStudentPassword = lazy(() => import('containers/Auth/pages/ForgotPassword/student'));

const Activation = lazy(() => import('containers/Activation/LicenseActivation'));
const AdminActivation = lazy(() => import('containers/Activation/AdminActivation'));
const TeacherActivation = lazy(() => import('containers/Activation/TeacherActivation'));
const StudentActivation = lazy(() => import('containers/Activation/StudentActivation'));
const TeacherVerification = lazy(() => import('containers/Auth/pages/Verification'));
const StudentVerification = lazy(() => import('containers/Auth/pages/Verification/student'));

// !* Students
const Script = lazy(() => import('components/Conversar/external'));
const Lyrics = lazy(() => import('components/Cantar/external'));
const LessonView = lazy(() => import('containers/Students/Textbook/view'));
const Lesson = lazy(() => import('containers/Students/Textbook'));
const LessonExternal = lazy(() => import('containers/Students/Textbook/external'));
const PracticarExternal = lazy(() => import('components/Practicar/external'));

// !* Teachers
const TeacherRoutes = lazy(() => import('containers/Teachers/routes'));

// !* Global
const Demo = lazy(() => import('containers/Demo'));
const Privacy = lazy(() => import('containers/PrivacyPolicy'));

// !* Schools
const Schools = lazy(() => import('containers/Schools'));

// !* Admin
const SuperAdmin = lazy(() => import('containers/SuperAdmin'));

// !* Students
const ContentSections = lazy(() => import('containers/Students/ContentSections'));
const Home = lazy(() => import('containers/Students/Home'));
const Topics = lazy(() => import('containers/Students/Topics'));
const ChildTopics = lazy(() => import('containers/Students/ChildTopics'));
const TeacherArchive = lazy(() => import('containers/Schools/archive'));

function App() {
  useNotifier();
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Suspense fallback={<SuspenseLoader />}>
            <Login />
          </Suspense>
        </Route>
        <Route exact path="/oauth/clever">
          <Suspense fallback={<SuspenseLoader />}>
            <CleverAuth />
          </Suspense>
        </Route>

        <Route exact path="/forgot-password">
          <Suspense fallback={<SuspenseLoader />}>
            <ForgotPassword />
          </Suspense>
        </Route>
        <Route exact path="/reset-password/:id/:linkId?">
          <Suspense fallback={<SuspenseLoader />}>
            <PasswordReset />
          </Suspense>
        </Route>

        <Route exact path="/forgot-student-password">
          <Suspense fallback={<SuspenseLoader />}>
            <ForgotStudentPassword />
          </Suspense>
        </Route>
        <Route exact path="/reset-student-password/:id/:linkId?">
          <Suspense fallback={<SuspenseLoader />}>
            <PasswordStudentReset />
          </Suspense>
        </Route>

        <Route exact path="/teacher/verification/:teacherId/:verificationId">
          <Suspense fallback={<SuspenseLoader />}>
            <TeacherVerification />
          </Suspense>
        </Route>
        <Route exact path="/student/verification/:studentId/:verificationId">
          <Suspense fallback={<SuspenseLoader />}>
            <StudentVerification />
          </Suspense>
        </Route>
        <Route exact path="/admin/activation/:userId/:verificationId">
          <Suspense fallback={<SuspenseLoader />}>
            <AdminActivation />
          </Suspense>
        </Route>
        <Route exact path="/activation/:licenseId/:activationId">
          <Suspense fallback={<SuspenseLoader />}>
            <Activation />
          </Suspense>
        </Route>
        <Route exact path="/teacher/activation/:teacherId/:activationId">
          <Suspense fallback={<SuspenseLoader />}>
            <TeacherActivation />
          </Suspense>
        </Route>
        <Route exact path="/student/activation/:studentId/:activationId">
          <Suspense fallback={<SuspenseLoader />}>
            <StudentActivation />
          </Suspense>
        </Route>

        <Route exact path="/privacy-policy">
          <Suspense fallback={<SuspenseLoader />}>
            <Privacy />
          </Suspense>
        </Route>

        {!isProd && (
          <Route exact path="/demo">
            <Suspense fallback={<SuspenseLoader />}>
              <Demo />
            </Suspense>
          </Route>
        )}

        <ProtectedRoute exact path="/schools">
          <Suspense fallback={<SuspenseLoader />}>
            <Schools />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/schools/archived">
          <Suspense fallback={<SuspenseLoader />}>
            <TeacherArchive />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/home">
          <Suspense fallback={<SuspenseLoader />}>
            <Home />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/topics">
          <Suspense fallback={<SuspenseLoader />}>
            <Topics />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/topics/:id-:name">
          <Suspense fallback={<SuspenseLoader />}>
            <ChildTopics />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/topics/:id-:name/:cid-:cname">
          <Suspense fallback={<SuspenseLoader />}>
            <ContentSections />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/topics/conversar/:id/:cid">
          <Suspense fallback={<SuspenseLoader />}>
            <Script />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/topics/cantar/:id/:cid">
          <Suspense fallback={<SuspenseLoader />}>
            <Lyrics />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/practicar/:category/:id/:group?">
          <Suspense fallback={<SuspenseLoader />}>
            <PracticarExternal />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/textbook/:id">
          <Suspense fallback={<SuspenseLoader />}>
            <Lesson />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/textbook/:id/external">
          <Suspense fallback={<SuspenseLoader />}>
            <LessonExternal />
          </Suspense>
        </ProtectedRoute>

        <ProtectedRoute exact path="/textbook/:id/view/:page">
          <Suspense fallback={<SuspenseLoader />}>
            <LessonView />
          </Suspense>
        </ProtectedRoute>

        <SuperAccess path="/superadmin">
          <Suspense fallback={<SuperAccess />}>
            <SuperAdmin />
          </Suspense>
        </SuperAccess>

        <TeacherAgreementAccess path="/teachers">
          <Suspense fallback={<SuspenseLoader />}>
            <TeacherRoutes />
          </Suspense>
        </TeacherAgreementAccess>

        <ProtectedWithPassword exact path="/old/topics">
          <Suspense fallback={<SuspenseLoader />}>
            <OldTopics />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword exact path="/old/topics/:id-:name">
          <Suspense fallback={<SuspenseLoader />}>
            <OldChildTopics />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword exact path="/old/topics/:id-:name/:cid-:cname">
          <Suspense fallback={<SuspenseLoader />}>
            <OldContentSections />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword exact path="/old/textbook/:id">
          <Suspense fallback={<SuspenseLoader />}>
            <OldLesson />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword exact path="/old/textbook/:id/external">
          <Suspense fallback={<SuspenseLoader />}>
            <OldLessonExternal />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword exact path="/old/textbook/:id/view/:page">
          <Suspense fallback={<SuspenseLoader />}>
            <OldLessonView />
          </Suspense>
        </ProtectedWithPassword>

        <ProtectedWithPassword path="/old/teachers">
          <Suspense fallback={<SuspenseLoader />}>
            <OldTeacherRoutes />
          </Suspense>
        </ProtectedWithPassword>

        <NotFound />
      </Switch>
      <GlobalDialog />
    </>
  );
}

export default App;
