import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    outline: 'none',
  },
}));

function Dropzone({
  accept,
  maxFiles = 10,
  onChange,
  title = 'Drag and Drop files here or Browse',
}) {
  const classes = useStyles();

  const onDrop = async (rawFiles) => {
    if (!rawFiles) {
      return;
    }
    const files = Array.from(rawFiles);
    if (files.length === 0) {
      return;
    }

    if (onChange) {
      onChange(rawFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop,
  });

  return (
    <Grid
      container
      className={classes.root}
      alignItems='center'
      {...getRootProps()}
    >
      <Grid item sm>
        <input {...getInputProps()} />
        <Typography variant='h5' align='center'>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Dropzone;
