import React, { useEffect, useRef, useState } from 'react';
import dayJS from 'dayjs';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  FormControlLabel,
  Switch,
  Typography,
  InputAdornment,
  CircularProgress,
  colors,
} from '@material-ui/core';
import { Send, AddCircleRounded, Edit, WarningRounded, CheckCircle } from '@material-ui/icons';

import { getLicenseCount, units, licenseOptions, SEARCH_TIMEOUT, ERRORS } from 'constants';
import SchoolDialog from '../SchoolDialog';
import UserDialog from '../UserDialog';
import StudentDialog from '../StudentDialog';
import { green } from '@material-ui/core/colors';
import { validUsername } from 'utils';
import { findUsername } from 'redux/teachers/actions';
import { useDispatch, useSelector } from 'react-redux';
import PasswordField from 'components/PasswordField';

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(1, 0),
  },
  doubleSpacing: {
    margin: theme.spacing(2, 0),
  },
  label: {
    margin: theme.spacing(0, 0),
  },
  actions: {
    justifyContent: 'space-between',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  validity: {
    root: {
      width: '75%',
    },
  },
  iconSelected: {
    color: green[500],
  },
  text: {
    flexGrow: 1,
  },
}));

export default function LicenseDialog({ open, handleClose, handleConfirm, data }) {
  const styles = useStyles();
  const timer = useRef(null);
  const dispatch = useDispatch();
  const { message, status, loading } = useSelector(({ teachers }) => teachers);
  const [form, setForm] = useState(data);
  const [licenseeValue, setLicenseeValue] = useState(null);
  const [headTeacher, setHeadTeacher] = useState(null);
  const [schoolDialog, setSchoolDialog] = useState(false);
  const [teacherDialog, setTeacherDialog] = useState(false);
  const [studentDialog, setStudentDialog] = useState(false);
  const [schoolData, setSchoolData] = useState({ name: '', address: '' });
  const [teacherData, setTeacherData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    schoolName: '',
    schoolAddress: '',
    phoneNumber: '',
    homeAddress: '',
  });
  const [studentData, setStudentData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    schoolName: '',
    schoolAddress: '',
    phoneNumber: '',
    homeAddress: '',
  });
  const [genericStudentData, setGenericStudentData] = useState({
    username: '',
    password: '',
  });
  const [createGeneric, setCreateGeneric] = useState(false);
  const [licenseeMode, setLicenseeMode] = useState('add');
  const [error, setError] = useState(false);

  const activateTimer = (e) => {
    const valid = validUsername(e.target.value);
    if (!valid) {
      setError(true);
      return;
    }
    setError(false);
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      dispatch(findUsername(e.target.value));
    }, SEARCH_TIMEOUT);
  };

  const getLicenses = (type) => {
    const result = getLicenseCount(type);
    return result;
  };

  useEffect(() => {
    if (!open || !data) return;
    setSchoolData({ name: '', address: '' });
    setTeacherData({
      firstName: '',
      lastName: '',
      email: '',
      schoolName: '',
      schoolAddress: '',
      phoneNumber: '',
      homeAddress: '',
    });
    setStudentData({
      firstName: '',
      lastName: '',
      username: '',
      schoolName: '',
      schoolAddress: '',
      phoneNumber: '',
      homeAddress: '',
    });
    setHeadTeacher(null);
    setForm(data);
    setCreateGeneric(false);
    setLicenseeValue(null);
    setGenericStudentData({ username: '', password: '' });
  }, [open, data]);

  const handleLicenseeDialog = (inputValue) => {
    const licenseType = form?.licenseType;
    switch (licenseType) {
      case 'School':
        setSchoolData({
          name: inputValue,
          address: '',
        });
        setHeadTeacher(null);
        setSchoolDialog(true);
        break;
      case 'Teacher':
        setTeacherData({
          firstName: inputValue?.split(' ')[0],
          lastName: inputValue?.split(' ')[1],
          access: 'teacher',
          accessLevel: 'limited',
          schoolName: '',
          schoolAddress: '',
          phoneNumber: '',
          homeAddress: '',
        });
        setTeacherDialog(true);
        break;
      case 'Student':
        setStudentData({
          firstName: inputValue?.split(' ')[0],
          lastName: inputValue?.split(' ')[1],
          teacher: '',
          grade: '',
          school: '',
          username: '',
          schoolName: '',
          schoolAddress: '',
          phoneNumber: '',
          homeAddress: '',
        });
        setStudentDialog(true);
        break;
      default:
        setSchoolData({
          name: inputValue,
          address: '',
        });
        setSchoolDialog(true);
        break;
    }
  };

  const handleEditLicenseeDialog = () => {
    setLicenseeMode('edit');
    const licenseType = form?.licenseType;
    switch (licenseType) {
      case 'School':
        setSchoolDialog(true);
        break;
      case 'Teacher':
        setTeacherDialog(true);
        break;
      case 'Student':
        setStudentDialog(true);
        break;
      default:
        setSchoolDialog(true);
        break;
    }
  };

  const getMessage = () => {
    switch (form?.licenseType) {
      case 'School':
        return 'Clicking “Send” will activate the license & send an account setup email to the Head Teacher selected.';
      case 'Teacher':
        return 'Clicking “Send” will activate the license & send an account setup email to the Teacher selected.';
      case 'Student':
        return 'Clicking “Send” will activate the license & send an account setup email to the email provided.';
      default:
        return '';
    }
  };

  const handleLicenseeChange = (value) => {
    if (!value) {
      setForm({ ...form, licensee: null, email: '' });
      setHeadTeacher(null);
      setSchoolData({ name: '', address: '' });
      setTeacherData({
        firstName: '',
        lastName: '',
        email: '',
        schoolName: '',
        schoolAddress: '',
        phoneNumber: '',
        homeAddress: '',
      });
      setStudentData({
        firstName: '',
        lastName: '',
        username: '',
        schoolName: '',
        schoolAddress: '',
        phoneNumber: '',
        homeAddress: '',
      });
    }
    setLicenseeValue(value);
    handleLicenseeDialog(value);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">
          {`Create New ${form.category} ${form?.licenseType === 'School' ? '' : form?.licenseType} License`}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sm={12} container direction="row" spacing={2} className={styles.spacing}>
              <Grid item sm={5}>
                Issue Date: {dayJS().format('MMMM DD YYYY')}
              </Grid>
              <Grid item sm={7}>
                Estimated Expiry Date:{' '}
                {dayJS().add(form.validityPeriod, form.validityPeriodUnit).format('MMMM DD YYYY')}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} container direction="row" spacing={2} className={styles.spacing}>
              <Grid item sm={6}>
                <TextField
                  id="validityPeriod"
                  name="Validity Period"
                  type={'number'}
                  variant="outlined"
                  required
                  fullWidth
                  label="Duration"
                  autoFocus
                  value={form.validityPeriod}
                  onChange={(e) => setForm({ ...form, validityPeriod: e.target.value })}
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid item sm={6}>
                <FormControl style={styles.select}>
                  <InputLabel id="validity-period-label-demo" className={styles.label}>
                    Validity Period Unit
                  </InputLabel>
                  <Select
                    labelId="select-validity-period-label"
                    id="validity-period-select"
                    value={form.validityPeriodUnit}
                    style={{ width: 200 }}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        validityPeriodUnit: e.target.value,
                      })
                    }
                    fullWidth
                  >
                    {units.map((option, index) => (
                      <MenuItem value={option} key={index}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className={styles.spacing}>
              <FormControl style={styles.select}>
                <InputLabel id="license-label-demo" className={styles.label}>
                  License For
                </InputLabel>
                <Select
                  style={{ width: 500 }}
                  labelId="select-license-label"
                  id="license-select"
                  value={form.licenseType}
                  onChange={(e) => {
                    let access = 'limited';
                    if (e.target.value === 'School') {
                      access = 'full';
                    }
                    setForm({
                      ...form,
                      email: '',
                      licenseType: e.target.value,
                      teacherAccess: access,
                      licensee: null,
                      isDomainCompliant: e.target.value === 'School',
                      ...getLicenses(e.target.value),
                    });
                    setLicenseeValue(null);
                  }}
                  fullWidth
                >
                  {licenseOptions.map((option, index) => (
                    <MenuItem value={option} key={index}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {form.licenseType === 'Teacher' && (
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <Typography variant="caption" color="secondary">
                  This license is valid for 1 Individual Teacher only. This teacher can link to unlimited students.
                </Typography>
              </Grid>
            )}
            {form.licenseType === 'Student' && (
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <Typography variant="caption" color="secondary">
                  This license is valid for 1 Individual Student only. This student can link to 1 teacher only.
                </Typography>
              </Grid>
            )}
            {form.licenseType === 'Teacher' && (
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={createGeneric}
                      onChange={(e) => setCreateGeneric(e.target.checked)}
                      name="createGeneric"
                      color="primary"
                    />
                  }
                  label="Create Generic Student License"
                />
              </Grid>
            )}
            {form.licenseType === 'Teacher' && createGeneric && (
              <Grid item xs={12} sm={12} className={styles.spacing}>
                <Typography variant="caption" color="secondary">
                  An Individual Teacher can provide their entire class with one shared Generic Student License/Account
                  for login access. For creation, only a username is required, and the License will auto-activate. No
                  email address or email verification is required.
                </Typography>
              </Grid>
            )}
            {form.licenseType === 'Teacher' && createGeneric && (
              <>
                <Grid item xs={12} sm={12} className={styles.spacing}>
                  <TextField
                    error={status > 200 || error === true}
                    helperText={error ? ERRORS.USERNAME : status > 200 ? message : ''}
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    inputProps={{ autocomplete: 'username' }}
                    value={form?.username}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      setGenericStudentData({ ...genericStudentData, username: e.target.value.trim() });
                    }}
                    onKeyUp={activateTimer}
                    InputProps={{
                      endAdornment: error ? (
                        <InputAdornment>
                          {loading && (
                            <CircularProgress variant="indeterminate" color="primary" thickness={5} size={20} />
                          )}
                          {error && <WarningRounded />}
                        </InputAdornment>
                      ) : (
                        <InputAdornment>
                          {loading && (
                            <CircularProgress variant="indeterminate" color="primary" thickness={5} size={20} />
                          )}
                          {status === 200 && <CheckCircle style={{ color: colors.green[500] }} />}
                          {status === 400 && <WarningRounded />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={styles.spacing}>
                  <PasswordField
                    value={genericStudentData?.password}
                    onChange={(value) => setGenericStudentData({ ...genericStudentData, password: value })}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} className={styles.spacing}>
              <TextField
                name="Licensee"
                variant="outlined"
                required
                fullWidth
                id="licensee"
                label={`${form?.licenseType} Name`}
                value={licenseeValue}
                onChange={(e) => setLicenseeValue(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleLicenseeDialog(e.target.value)}
                disabled={form?.licensee === 'new'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {form?.licensee !== 'new' && (
                        <IconButton onClick={() => handleLicenseeChange(licenseeValue)}>
                          <AddCircleRounded className={styles.iconSelected} />
                        </IconButton>
                      )}
                      {form?.licensee === 'new' && (
                        <Button onClick={handleEditLicenseeDialog} color="primary" startIcon={<Edit />}>
                          Change
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {form.category === 'Group' && (
              <>
                <Grid item xs={12} sm={12} className={styles.spacing}>
                  <TextField
                    name="Teacher Licenses"
                    type={'number'}
                    variant="outlined"
                    required
                    fullWidth
                    id="teacherLicenses"
                    label="Teacher Licenses"
                    value={form.teacherLicenses}
                    onChange={(e) => setForm({ ...form, teacherLicenses: e.target.value })}
                    inputProps={form.licenseType === 'School' ? { max: 5, min: 1 } : {}}
                    disabled={form.licenseType === 'Student'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={styles.spacing}>
                  <TextField
                    name="Student Licenses"
                    type={'number'}
                    variant="outlined"
                    required
                    fullWidth
                    id="studentLicenses"
                    label="Student Licenses"
                    value={form.studentLicenses}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        studentLicenses: e.target.value,
                        teacherAccess: e.target.value > 0 ? 'full' : 'limited',
                      })
                    }
                    disabled={Boolean(form.category === 'Individual' && form.licenseType === 'Teacher')}
                    inputProps={form.licenseType === 'School' ? { max: 650, min: 1 } : {}}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {headTeacher && form.licenseType === 'School' && (
            <Grid item xs={12} sm={12} className={styles.doubleSpacing}>
              <TextField
                name="headTeacher"
                variant="outlined"
                required
                fullWidth
                type="text"
                id="headTeacher"
                label="Head Teacher Name"
                value={[headTeacher?.firstName, headTeacher?.lastName].join(' ')}
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} className={styles.spacing}>
            <TextField
              name="Email"
              variant="outlined"
              required
              fullWidth
              type="email"
              id="email"
              label="Email"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value.trim() })}
              disabled={form.licenseType !== 'Student'}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: '20px 0px' }} className={styles.spacing}>
            <FormControlLabel
              control={
                <Switch
                  checked={form.freeTrial}
                  onChange={(e) => setForm({ ...form, freeTrial: e.target.checked })}
                  name="freeTrial"
                  color="primary"
                />
              }
              label="Free Trial"
            />
          </Grid>
          {form?.licenseType === 'School' && (
            <Grid item xs={12} sm={12} style={{ margin: '20px 0px' }} className={styles.spacing}>
              <FormControlLabel
                control={
                  <Switch
                    checked={form.isDomainCompliant}
                    onChange={(e) => setForm({ ...form, isDomainCompliant: e.target.checked })}
                    name="isDomainCompliant"
                    color="primary"
                  />
                }
                label="I confirm that all email addresses under this license should be domain compliant"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} className={styles.spacing}>
            <Typography variant="caption" color="secondary">
              {getMessage()}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleConfirm({
                form,
                headTeacher,
                teacherData,
                studentData,
                schoolData,
                genericStudentData,
                createGeneric,
              })
            }
            color="primary"
            variant={'contained'}
            endIcon={<Send />}
            disabled={
              !form.email ||
              !form.licensee ||
              form.licensee === '' ||
              form.email === '' ||
              (createGeneric && (genericStudentData?.username === '' || genericStudentData?.password === ''))
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <SchoolDialog
        open={schoolDialog}
        handleClose={() => setSchoolDialog(false)}
        data={schoolData}
        headTeacherData={headTeacher}
        handleConfirm={(data) => {
          setHeadTeacher(data?.headTeacher);
          setSchoolData({ ...schoolData, ...data?.school });
          setLicenseeValue(data?.name);
          setSchoolDialog(false);
          setForm({
            ...form,
            licensee: 'new',
            email: data?.headTeacher?.email?.trim(),
          });
        }}
      />
      <UserDialog
        open={teacherDialog}
        handleClose={() => setTeacherDialog(false)}
        data={teacherData}
        edit={licenseeMode === 'edit'}
        prefix="Individual"
        showSubtitle={false}
        handleConfirm={(data) => {
          setTeacherData({ ...teacherData, ...data });
          setLicenseeValue([data?.firstName, data?.lastName].join(' '));
          setTeacherDialog(false);
          setForm({
            ...form,
            licensee: 'new',
            email: data?.email?.trim(),
          });
        }}
        disabledFields={['access', 'accessLevel', 'school', 'isHeadTeacher', 'isVerified', 'password', 'sendManual']}
      />
      <StudentDialog
        open={studentDialog}
        handleClose={() => setStudentDialog(false)}
        data={studentData}
        edit={licenseeMode === 'edit'}
        showSubtitle={false}
        handleConfirm={(data) => {
          setStudentData({ ...studentData, ...data });
          setLicenseeValue([data?.firstName, data?.lastName].join(' '));
          setStudentDialog(false);
          setForm({
            ...form,
            licensee: 'new',
            email: '',
          });
        }}
        disabledFields={['grade', 'teacher', 'school', 'password', 'username']}
        isDraft
      />
    </>
  );
}
