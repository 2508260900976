import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  STUDENTACTION_ATTEMPT,
  STUDENTLIST_ATTEMPT,
  STUDENT_ERROR,
  FETCHALLSTUDENTS_SUCCESS,
  FETCHSTUDENT_SUCCESS,
  ADDSTUDENT_SUCCESS,
  UPDATESTUDENT_SUCCESS,
  DELETESTUDENT_SUCCESS,
  IMPORTSTUDENTDATA_SUCCESS,
  SORTSTUDENTS,
  BULKUPDATESTUDENT_SUCCESS,
  BULKDELETESTUDENT_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';
import { sortBy } from 'utils/sort';
import { fetchAllLicenses } from './license';

const studentUrl = 'admin/students';

const mapStudents = (x) => ({
  ...x,
  licenseType: x.school ? x.school?.name : 'Individual',
  teacherName:
    x.isArchived && !x.teacher
      ? 'Unassigned'
      : `${x.teacher ? x.teacher?.fullName : x.school ? 'N/A' : 'Not Linked'}`,
  className:
    x.isArchived && !x.grade ? 'Unassigned' : x.grade ? x.grade?.name : 'N/A',
  linked: x.school ? 'N/A' : x.teacher ? 'Yes' : 'No',
});

export const fetchAllUsers = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTLIST_ATTEMPT));
      const body = await axios.get(`${studentUrl}`, { params });
      const data = body.data?.data;
      const students = {
        ...data,
        docs: data?.docs.map(mapStudents),
      };
      dispatch(successAction(FETCHALLSTUDENTS_SUCCESS, { data: students }));
    } catch (err) {
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const importStudents = (data, close) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/bulkImport`, data);
      if (body.data) {
        const data = body.data?.students;
        const students = {
          ...data,
          docs: data?.docs.map(mapStudents),
        };
        dispatch(successAction(IMPORTSTUDENTDATA_SUCCESS, students));
        if (body.data.message) {
          dispatch(
            enqueueSnackbar({
              message: body.data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
            })
          );
        }
        close();
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const fetchUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTLIST_ATTEMPT));
      const body = await axios.get(`${studentUrl}/${id}`);
      dispatch(successAction(FETCHSTUDENT_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const addUser = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}`, data);
      const user = mapStudents(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(ADDSTUDENT_SUCCESS, result));
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: body.data.status === 200 ? 'success' : 'error',
          },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const updateUser = (id, data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/${id}`, data);
      const user = mapStudents(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATESTUDENT_SUCCESS, result));
      dispatch(fetchAllLicenses());
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
      if (onClose) onClose();
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const archiveStudent = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/${id}/archive`);
      const user = mapStudents(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATESTUDENT_SUCCESS, result));
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const restoreStudent = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/${id}/restore`, data);
      const user = mapStudents(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATESTUDENT_SUCCESS, result));
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const confirmUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/${id}/confirm`);
      const user = mapStudents(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATESTUDENT_SUCCESS, result));
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    } catch (err) {
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const deleteUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.delete(`${studentUrl}/${id}`);
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
      dispatch(successAction(DELETESTUDENT_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const sortStudents = (field, order, data) => {
  const thunk = async function thunk(dispatch) {
    const orderedData = data.sort((a, b) => sortBy(a, b, field, order));
    dispatch(successAction(SORTSTUDENTS, orderedData));
  };
  return thunk;
};

export const bulkArchiveStudent = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATESTUDENT_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreStudent = (ids, grade, teacher) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/bulkRestore`, {
        ids,
        grade,
        teacher,
      });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATESTUDENT_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteStudent = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STUDENTACTION_ATTEMPT));
      const body = await axios.post(`${studentUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETESTUDENT_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(STUDENT_ERROR, err));
    }
  };
  return thunk;
};
