import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  fetchPracticar,
  updatePracticar,
} from '../../../redux/actions/practicar';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditPracticar({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading, practicar } = useSelector(
    ({ admin }) => admin.practicar
  );

  // const [practicarData, setPracticarData] = useState(initialPracticar);

  useEffect(() => dispatch(fetchPracticar(id)), [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updatePracticar(id, d));
    history.push('/superadmin/practicars');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {practicar && (
        <Form
          initialData={practicar}
          content={['image']}
          onSave={handleSave}
          multipleFields={[
            {
              field: 'words',
              title: 'Words',
            },
            {
              field: 'sentences',
              title: 'Sentences',
            },
            {
              field: 'quiz',
              title: 'Quiz',
            },
          ]}
          dropZoneParams={{
            maxFiles: 100,
            multiple: true,
          }}
          showTopicPicker
        />
      )}
    </div>
  );
}
