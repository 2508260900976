import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSetting, updateSetting } from '../../redux/actions/setting';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import PageHeader from '../../components/PageHeader';
import ContentEditor from '../../components/ContentEditor';
import Loader from '../../../../components/Loader';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 15,
  },
  spacing: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function Privacy({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setting, actionLoading, listLoading } = useSelector(
    ({ admin }) => admin.setting
  );

  const [form, setForm] = useState('');

  useEffect(() => dispatch(fetchSetting('general')), [dispatch]);

  useEffect(() => {
    if (setting !== null) {
      setForm(setting.privacy);
    }
  }, [setting]);

  const handleSave = () => {
    let data = form;
    dispatch(updateSetting('general', { privacy: data }));
  };

  return (
    <>
      {(actionLoading || listLoading) && (
        <Loader fetching={actionLoading || listLoading} />
      )}
      <PageHeader disableSearch title={name} />
      <Grid
        container
        direction='column'
        className={classes.grid}
        justifyContent={'space-between'}
      >
        <Grid item sm className={classes.spacing}>
          <ContentEditor
            handleEditorChange={(e) => setForm(e)}
            value={form}
            id='manual-editor'
          />
        </Grid>
        <Grid item sm>
          <Button variant='outlined' onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
