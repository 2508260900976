import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAprender,
  fetchMaxOrder,
  updateAprender,
  validateOrder,
} from '../../../redux/actions/aprender';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditAprender({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { actionLoading, aprender, orderNumberStatus, maxOrder } = useSelector(
    ({ admin }) => admin.aprender
  );

  useEffect(() => {
    dispatch(fetchAprender(id));
    dispatch(validateOrder({ status: true }));
    dispatch(fetchMaxOrder({ status: 0 }));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateAprender(id, d));
  };

  const validateOrderNumber = (data) => {
    dispatch(validateOrder(data));
  };

  const getMaximumOrderNumber = (data) => {
    let update = {
      topics: data.topics.toString(),
      group: data.group,
    };
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {aprender && (
        <Form
          initialData={aprender}
          onSave={handleSave}
          content={['audio', 'image', 'transparent']}
          color
          buttonColor
          showTopicPicker
          spanishField
          switchFields={['isRound']}
          numberFields={[
            'imagePercentage',
            'horizontalPercentage',
            'verticalPercentage',
            'borderTopRightRadius',
            'borderTopLeftRadius',
            'borderBottomRightRadius',
            'borderBottomLeftRadius',
          ]}
          maximumOrderValue={maxOrder > 0 ? maxOrder + 1 : aprender?.order}
          highlightField
          onSelectedData={getMaximumOrderNumber}
          validateOrderNo={(order) => validateOrderNumber(order)}
          fieldError={orderNumberStatus}
        />
      )}
    </div>
  );
}
