import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../../components/PageHeader';

import S3FileUploader from 'containers/SuperAdmin/components/S3FileUploader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    margin: theme.spacing(1),
  },
}));

export default function NewContent({ titleName }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageHeader disableSearch title={titleName} />
      <S3FileUploader />
    </div>
  );
}
