import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateCantar, fetchCantar } from '../../../redux/actions/cantar';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditCantar({ name }) {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading, cantar } = useSelector(({ admin }) => admin.cantar);

  const handleSave = (d) => {
    dispatch(updateCantar(id, d));
    history.push('/superadmin/cantars');
  };

  useEffect(() => dispatch(fetchCantar(id)), [dispatch, id]);

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {cantar && (
        <Form
          initialData={cantar}
          onSave={handleSave}
          content={['image', 'video', 'song', 'karaoke', 'lyricsPdf']}
          html
          htmlField={'lyrics'}
          path={'cantar'}
          showTopicPicker
        />
      )}
    </div>
  );
}
