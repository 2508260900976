import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  CONVERSARACTION_ATTEMPT,
  CONVERSARLIST_ATTEMPT,
  CONVERSAR_ERROR,
  FETCHALLCONVERSARS_SUCCESS,
  FETCHCONVERSAR_SUCCESS,
  ADDCONVERSAR_SUCCESS,
  UPDATECONVERSAR_SUCCESS,
  DELETECONVERSAR_SUCCESS,
  SETCONVERSAR,
  BULKUPDATECONVERSAR_SUCCESS,
  BULKDELETECONVERSAR_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const conversarUrl = 'admin/conversars';

export const fetchAllConversars = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARLIST_ATTEMPT));
      const body = await axios.get(`${conversarUrl}`, { params });
      dispatch(successAction(FETCHALLCONVERSARS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const fetchConversar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARLIST_ATTEMPT));
      const body = await axios.get(`${conversarUrl}/${id}`);
      dispatch(successAction(FETCHCONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const addConversar = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.post(`${conversarUrl}`, data);
      dispatch(successAction(ADDCONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const updateConversar = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.put(`${conversarUrl}/${id}`, data);
      dispatch(successAction(UPDATECONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const deleteConversar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.delete(`${conversarUrl}/${id}`);
      dispatch(successAction(DELETECONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const setConversar = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCONVERSAR, data));
  };
  return thunk;
};

export const archiveConversar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.put(`${conversarUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATECONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const restoreConversar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.put(`${conversarUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATECONVERSAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveConversar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.post(`${conversarUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATECONVERSAR_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreConversar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.post(`${conversarUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATECONVERSAR_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteConversar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CONVERSARACTION_ATTEMPT));
      const body = await axios.post(`${conversarUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETECONVERSAR_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CONVERSAR_ERROR, err));
    }
  };
  return thunk;
};
