import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import { fetchAllTopics } from '../../redux/actions/topic';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.0 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};

export default function TopicSelector({
  selectedTopics = [],
  setTopics,
  multiple = true,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { topics } = useSelector(({ admin }) => admin.topic);

  useEffect(() => {
    dispatch(fetchAllTopics({ pagination: false }));
  }, [dispatch]);

  return (
    <Grid container direction={'row'} item sm>
      {topics && topics.docs && topics.docs.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel id='topic-multi-checkbox'>Topics</InputLabel>
          <Select
            labelId='topic-multi-checkbox'
            id='topic-checkbox-multi'
            multiple={multiple}
            value={selectedTopics}
            onChange={(e) => setTopics(e.target.value)}
            input={<Input />}
            renderValue={(value) =>
              topics.docs
                .filter((x) => value.includes(x._id))
                .map((x) => x.name)
                .toString()
            }
            MenuProps={MenuProps}
          >
            {topics.docs.map((topic) => (
              <MenuItem value={topic._id} key={topic._id}>
                <Checkbox
                  checked={
                    selectedTopics.length > 0
                      ? selectedTopics.indexOf(topic._id) > -1
                      : false
                  }
                />
                <ListItemText primary={topic.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
