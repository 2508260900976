/**
 * * Resource Reducer
 */

import {
  RESOURCEACTION_ATTEMPT,
  RESOURCELIST_ATTEMPT,
  RESOURCE_ERROR,
  FETCHALLRESOURCES_SUCCESS,
  FETCHRESOURCE_SUCCESS,
  ADDRESOURCE_SUCCESS,
  UPDATERESOURCE_SUCCESS,
  DELETERESOURCE_SUCCESS,
  SETRESOURCE,
  RESOURCEMAXORDER_SUCCESS,
  RESOUCEVALIDATE_SUCCESS,
  BULKUPDATERESOURCE_SUCCESS,
  BULKDELETERESOURCE_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  resources: null,
  resource: null,
  orderNumberStatus: false,
};

export default function resource(state = initialState, action) {
  switch (action.type) {
    case RESOURCELIST_ATTEMPT:
      return { ...state, fetching: true, resource: null };
    case RESOURCEACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case RESOURCEMAXORDER_SUCCESS:
      return { ...state, actionLoading: false, maxOrder: action.payload.data };
    case RESOUCEVALIDATE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        orderNumberStatus: action.payload.data,
      };
    case RESOURCE_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLRESOURCES_SUCCESS:
      return { ...state, fetching: false, resources: action.payload.data };
    case FETCHRESOURCE_SUCCESS:
      return { ...state, fetching: false, resource: action.payload.resource };
    case ADDRESOURCE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        resources: {
          ...state.resources,
          docs: [...state.resources.docs, action.payload.resource],
        },
      };
    case UPDATERESOURCE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        resources: {
          ...state.resources,
          docs: state.resources.docs.map((x) => {
            if (x._id === action.payload.resource._id) {
              return action.payload.resource;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETERESOURCE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        resources: {
          ...state.resources,
          docs: state.resources.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case SETRESOURCE:
      return {
        ...state,
        actionLoading: false,
        resources: { ...state.resources, docs: action.payload },
      };
    case BULKUPDATERESOURCE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        resources: {
          ...state.resources,
          docs: state.resources.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETERESOURCE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        resources: {
          ...state.resources,
          docs: state.resources.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
