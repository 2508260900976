import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  WORKBOOKACTION_ATTEMPT,
  WORKBOOKLIST_ATTEMPT,
  WORKBOOK_ERROR,
  FETCHALLWORKBOOKS_SUCCESS,
  FETCHWORKBOOK_SUCCESS,
  ADDWORKBOOK_SUCCESS,
  UPDATEWORKBOOK_SUCCESS,
  DELETEWORKBOOK_SUCCESS,
  DELETEALLWORKBOOKS_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const workbookUrl = 'admin/workbooks';

export const fetchAllWorkbooks = (params) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKLIST_ATTEMPT));
      const body = await axios.get(`${workbookUrl}`, { params });
      dispatch(successAction(FETCHALLWORKBOOKS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const fetchWorkbook = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKLIST_ATTEMPT));
      const body = await axios.get(`${workbookUrl}/${id}`);
      dispatch(successAction(FETCHWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const addWorkbook = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.post(`${workbookUrl}`, data);
      dispatch(successAction(ADDWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const updateWorkbook = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.put(`${workbookUrl}/${id}`, data);
      dispatch(successAction(UPDATEWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const deleteWorkbook = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.delete(`${workbookUrl}/${id}`);
      dispatch(successAction(DELETEWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const deleteAllWorkbooks = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.post(`${workbookUrl}/deleteAll`);
      dispatch(successAction(DELETEALLWORKBOOKS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const archiveWorkbook = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.put(`${workbookUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};

export const restoreWorkbook = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(WORKBOOKACTION_ATTEMPT));
      const body = await axios.put(`${workbookUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(WORKBOOK_ERROR, err));
    }
  };
  return thunk;
};
