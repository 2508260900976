export const SETALERT = 'SETALERT';
export const SETSOUND = 'SETSOUND';
export const GLOBAL_ATTEMPT = 'GLOBAL_ATTEMPT';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const GETPROFILE_SUCCESS = 'GETPROFILE_SUCCESS';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SETLICENSEINFO = 'SETLICENSEINFO';
export const SHOWGLOBALDIALOG = 'SHOWGLOBALDIALOG';
export const HIDEGLOBALDIALOG = 'HIDEGLOBALDIALOG';
