export class Time {
  static getTimeFromSeconds(secs) {
    const totalSeconds = Math.ceil(secs);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
      seconds: `${seconds > 9 ? seconds : '0' + seconds}`,
      minutes: `${minutes > 9 ? minutes : '0' + minutes}`,
      hours,
      days,
    };
  }

  static getSecondsFromExpiry(expiry, shouldRound) {
    const now = new Date().getTime();
    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / 1000;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getSecondsFromPrevTime(prevTime, shouldRound) {
    const now = new Date().getTime();
    const milliSecondsDistance = now - prevTime;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / 1000;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getSecondsFromTimeNow() {
    const now = new Date();
    const currentTimestamp = now.getTime();
    const offset = now.getTimezoneOffset() * 60;
    return currentTimestamp / 1000 - offset;
  }

  static getFormattedTimeFromSeconds(totalSeconds, format) {
    const {
      seconds: secondsValue,
      minutes,
      hours,
    } = Time.getTimeFromSeconds(totalSeconds);
    let ampm = '';
    let hoursValue = hours;

    if (format === '12-hour') {
      ampm = hours >= 12 ? 'pm' : 'am';
      hoursValue = hours % 12;
    }

    return {
      seconds: secondsValue,
      minutes,
      hours: hoursValue,
      ampm,
    };
  }
}

export function shuffleCards(array, removeOne = false, removeId) {
  const removedArray = removeOne
    ? array.filter((d) => d._id !== removeId)
    : array;
  const length = removedArray.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

export function random(num) {
  return Math.floor(Math.random() * num);
}

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  return `${getMinutes} : ${getSeconds}`;
};

export const csvDownload = (data, name, delimiter) => {
  const items = data;
  const filename = name || `export.csv`;
  const d = delimiter || `,`;

  const header = Array.from(
    new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
  );
  let csv = items.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName] || '')).join(d)
  );
  csv.unshift(header.join(d));
  csv = csv.join('\r\n');

  const blob = new Blob([csv], {
    type: 'text/plain;charset=utf-8',
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
    return;
  }
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sorter = (a, b, sorting = false, field = 'label') => {
  if (sorting) {
    let x = a[field]?.toLowerCase();
    let y = b[field]?.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  } else {
    return true;
  }
};

export const modifyUsername = (username) => {
  // const newtxt = username.replace(/[^a-zA-Z0-9]/g, '');
  let name = username.replace(/\s+/g, '');
  if (name.includes('-')) {
    name = name.replace('-', '-\u2060');
  }
  return name;
};

export const validUsername = (username) => {
  let regexp = /^[a-zA-Z0-9-._]+$/;
  return username.match(regexp);
};

export const calculateLevels = (dataLength) => {
  let levels = [];
  let ROUNDNINE = {
    label: '9 Rounds',
    value: 9,
  };
  let ROUNDTWELEVE = {
    value: 12,
    label: '12 Rounds',
  };
  let ROUNDTWENTYFOUR = {
    label: '24 Rounds',
    value: 24,
  };
  if (dataLength < 9) {
    levels.push({
      value: dataLength,
      label: `${dataLength} Rounds`,
    });
  } else if (dataLength >= 9 && dataLength < 12) {
    levels = [ROUNDNINE];
  } else if (dataLength >= 12 && dataLength < 24) {
    levels = [ROUNDNINE, ROUNDTWELEVE];
  } else if (dataLength >= 24) {
    levels = [ROUNDNINE, ROUNDTWELEVE, ROUNDTWENTYFOUR];
  }
  return levels;
};

const checkMultiples = (dataLength, value) => {
  if (dataLength % value === 0) {
    return true;
  } else {
    return false;
  }
};

export const getGridSizes = (cardsLength) => {
  let six = checkMultiples(cardsLength, 6);
  let four = checkMultiples(cardsLength, 4);
  if (six) {
    return { md: 2, sm: 2, xs: 4 };
  }
  if (four) {
    return { md: 3, sm: 3, xs: 4 };
  }
  return {};
};

export const calculateMemoriaLevels = (dataLength) => {
  let levels = [];
  let EASY = {
    value: 3,
    label: 'Easy',
  };
  let MEDIUM = {
    value: 6,
    label: 'Medium',
  };
  let ADVANCE = {
    value: 9,
    label: 'Advanced',
  };
  let SUPERADVANCE = {
    value: 12,
    label: 'Super Advanced',
  };
  if (dataLength <= 5 && dataLength >= 3) {
    levels = [EASY];
  } else if (dataLength >= 6 && dataLength < 9) {
    levels = [EASY, MEDIUM];
  } else if (dataLength >= 9 && dataLength < 12) {
    levels = [EASY, MEDIUM, ADVANCE];
  } else if (dataLength >= 12) {
    levels = [EASY, MEDIUM, ADVANCE, SUPERADVANCE];
  }
  return levels;
};

export const validateEmail = (email) => {
  const valid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return valid;
};
