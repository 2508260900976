import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  USERACTION_ATTEMPT,
  USERLIST_ATTEMPT,
  USER_ERROR,
  FETCHALLUSERS_SUCCESS,
  FETCHHEADTEACHERS_SUCCESS,
  FETCHUSER_SUCCESS,
  ADDUSER_SUCCESS,
  UPDATEUSER_SUCCESS,
  DELETEUSER_SUCCESS,
  SORTUSERS,
  FETCHACTIVETEACHERS_SUCCESS,
  FETCHALLTEACHERS_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';
import { sortBy } from 'utils/sort';
import { fetchLicenses } from './license';
const userUrl = 'admin/users';

const mapTeachers = (x) => ({
  ...x,
  classCount:
    x.accessLevel === 'limited' && x.classCount === 0 ? 'N/A' : x.classCount,
  studentCount:
    x.accessLevel === 'limited' && x.studentCount === 0
      ? 'N/A'
      : x.studentCount,
  archivedClassCount:
    x.accessLevel === 'limited' && x.archivedClassCount === 0
      ? 'N/A'
      : x.archivedClassCount,
  archivedStudentCount:
    x.accessLevel === 'limited' && x.archivedStudentCount === 0
      ? 'N/A'
      : x.archivedStudentCount,
  licenseType: x.school ? x.school?.name : 'Individual',
  teacherType:
    x.school && x.isHeadTeacher
      ? 'Head Teacher'
      : x.school && !x.isHeadTeacher
      ? 'Team Teacher'
      : 'Individual Teacher',
});

export const fetchAllUsers = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERLIST_ATTEMPT));
      const body = await axios.get(`${userUrl}`, { params });
      const data = body.data?.data;
      const teachers = {
        ...data,
        docs: data?.docs
          .map(mapTeachers)
          .sort((a, b) => sortBy(a, b, 'fullName', 'asc')),
      };
      dispatch(successAction(FETCHALLUSERS_SUCCESS, { data: teachers }));
    } catch (err) {
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchActiveTeachers = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERLIST_ATTEMPT));
      const body = await axios.get(`${userUrl}/activeTeachers`);
      const data = body.data?.data;
      const teachers = data.sort((a, b) => sortBy(a, b, 'fullName', 'asc'));
      dispatch(successAction(FETCHACTIVETEACHERS_SUCCESS, { data: teachers }));
    } catch (err) {
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAllTeachers = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERLIST_ATTEMPT));
      const body = await axios.get(`${userUrl}/teachers`);
      const data = body.data?.data;
      const teachers = data.sort((a, b) => sortBy(a, b, 'fullName', 'asc'));
      dispatch(successAction(FETCHALLTEACHERS_SUCCESS, { data: teachers }));
    } catch (err) {
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERLIST_ATTEMPT));
      const body = await axios.get(`${userUrl}/${id}`);
      dispatch(successAction(FETCHUSER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchHeadTeachers = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERLIST_ATTEMPT));
      const body = await axios.get(`${userUrl}/headTeachers`);
      dispatch(successAction(FETCHHEADTEACHERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const addUser = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERACTION_ATTEMPT));
      const body = await axios.post(`${userUrl}`, data);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
        const user = mapTeachers(body.data.user);
        const result = { ...body.data, user };
        dispatch(successAction(ADDUSER_SUCCESS, result));
      }
      if (body.data?.status === 400) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const updateUser = (id, data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERACTION_ATTEMPT));
      const body = await axios.post(`${userUrl}/${id}`, data);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      if (onClose) {
        onClose();
      }
      const user = mapTeachers(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATEUSER_SUCCESS, result));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const archiveUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERACTION_ATTEMPT));
      const body = await axios.put(`${userUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      const user = mapTeachers(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATEUSER_SUCCESS, result));
      dispatch(fetchLicenses());
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const restoreUser = (id, data, onDone) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERACTION_ATTEMPT));
      const body = await axios.put(`${userUrl}/${id}/restore`, data);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      const user = mapTeachers(body.data.user);
      const result = { ...body.data, user };
      dispatch(successAction(UPDATEUSER_SUCCESS, result));
      dispatch(fetchLicenses());
      if (onDone) onDone();
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(USER_ERROR, err));
      if (onDone) onDone();
    }
  };
  return thunk;
};

export const resendLink = (id, email, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      const body = await axios.post(`${userUrl}/${id}/resendLink`, { email });
      if (body.status === 200 && body.data.mail) {
        dispatch(
          enqueueSnackbar({
            message: body.data.mail
              ? 'Mail Sent Successfully'
              : 'Mail Sending Failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: body.data.mail ? 'success' : 'error',
            },
          })
        );
      }
      if (setLoader) {
        setLoader(false);
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Error Sending',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      if (setLoader) {
        setLoader(false);
      }
    }
  };
  return thunk;
};

export const resendVerificationLink = (id, email, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      const body = await axios.post(`${userUrl}/${id}/resendVerificationLink`, {
        email,
      });
      if (body.status === 200 && body.data.mail) {
        dispatch(
          enqueueSnackbar({
            message: body.data.mail
              ? 'Mail Sent Successfully'
              : 'Mail Sending Failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: body.data.mail ? 'success' : 'error',
            },
          })
        );
      }
      if (setLoader) {
        setLoader(false);
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Error Sending',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      if (setLoader) {
        setLoader(false);
      }
    }
  };
  return thunk;
};

export const deleteUser = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(USERACTION_ATTEMPT));
      const body = await axios.delete(`${userUrl}/${id}`);
      dispatch(successAction(DELETEUSER_SUCCESS, body.data));
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(USER_ERROR, err));
    }
  };
  return thunk;
};

export const sortUsers = (field, order, data) => {
  const thunk = async function thunk(dispatch) {
    const orderedData = data.sort((a, b) => sortBy(a, b, field, order));
    dispatch(successAction(SORTUSERS, orderedData));
  };
  return thunk;
};
