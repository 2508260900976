import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  MANUALACTION_ATTEMPT,
  MANUALLIST_ATTEMPT,
  MANUAL_ERROR,
  FETCHALLMANUALS_SUCCESS,
  FETCHMANUAL_SUCCESS,
  ADDMANUAL_SUCCESS,
  UPDATEMANUAL_SUCCESS,
  DELETEMANUAL_SUCCESS,
  SETMANUAL,
  MANUALVALIDATE_SUCESS,
  MANUALMAXORDER_SUCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const manualUrl = 'admin/manuals';

export const fetchAllManuals = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALLIST_ATTEMPT));
      const body = await axios.get(`${manualUrl}`, { params });
      dispatch(successAction(FETCHALLMANUALS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchMaxOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.get(`${manualUrl}/maxOrder`, { params });
      dispatch(successAction(MANUALMAXORDER_SUCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const validateOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.post(`${manualUrl}/validateOrder`, params);
      dispatch(successAction(MANUALVALIDATE_SUCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchManual = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALLIST_ATTEMPT));
      const body = await axios.get(`${manualUrl}/${id}`);
      dispatch(successAction(FETCHMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const addManual = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.post(`${manualUrl}`, data);
      dispatch(successAction(ADDMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const updateManual = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.put(`${manualUrl}/${id}`, data);
      dispatch(successAction(UPDATEMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const deleteManual = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.delete(`${manualUrl}/${id}`);
      dispatch(successAction(DELETEMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const setManualData = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.post(`${manualUrl}/order`, { data });
      dispatch(successAction(SETMANUAL, body.data.resource));
    } catch (err) {
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const archiveManual = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.put(`${manualUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};

export const restoreManual = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(MANUALACTION_ATTEMPT));
      const body = await axios.put(`${manualUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEMANUAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(MANUAL_ERROR, err));
    }
  };
  return thunk;
};
