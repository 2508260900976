import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllLeers,
  deleteLeer,
  archiveLeer,
  restoreLeer,
  bulkArchiveLeer,
  bulkRestoreLeer,
  bulkDeleteLeer,
} from '../../redux/actions/leer';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterBar from '../../components/FilterBar';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Leer({ name, id }) {
  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { leers, fetching } = useSelector(({ admin }) => admin.leer);

  const history = useHistory();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });

  const loadPage = (page, sortOptions = {}) => {
    if (Object.values(sortOptions).length === 0) {
      sortOptions = sort;
    }
    let update = {
      page,
      limit: leers.limit,
      ...sortOptions,
    };
    if (searchString !== '') {
      update.search = searchString;
    }
    if (selectedTopics) {
      update.topics = selectedTopics.toString();
    }
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllLeers(update));
    setSort(sortOptions);
  };

  const headCells = [
    {
      id: 'checkbox',
      label: 'Select',
    },
    { id: 'name', label: 'Name' },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/leer/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(restoreLeer(r._id));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllLeers({ orderBy: 'name', orderDir: 'asc' }));
  }, [dispatch]);

  const handleSearch = (d) => {
    setSearchString(d);
    let update = {
      page: leers.page,
      limit: leers.limit,
      search: d,
      ...sort,
    };
    if (selectedTopics) {
      update = { ...update, topics: selectedTopics.toString() };
    }
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllLeers(update));
  };

  const handleAction = () => {
    let update = {
      page: leers.page,
      limit: leers.limit,
      topics: selectedTopics.toString(),
      ...sort,
    };
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'normal') {
      update = { ...update, isArchived: true, page: 1, limit: 25 };
    }
    dispatch(fetchAllLeers(update));
    setView(view === 'normal' ? 'archived' : 'normal');
  };

  const handleTopic = (e) => {
    setSelectedTopics(e);
    let update = {
      page: leers.page,
      limit: leers.limit,
      topics: e.toString(),
      ...sort,
    };
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(fetchAllLeers(update));
  };

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <SettingDialog id={id} name={name} enableLabel={false} />
            <IconButton
              onClick={() => {
                history.push('/superadmin/leer/new');
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        enableGroups={false}
        topics={selectedTopics}
        group={''}
        toggle={view === 'archived'}
        onSearch={handleSearch}
        onTopicSelect={handleTopic}
        onAction={handleAction}
      />
      {leers && leers.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...leers,
                docs: leers.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
              }}
              view={view}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(leers.page, d)}
              handleBulk={(ids) => {
                if (view === 'normal') {
                  dispatch(bulkArchiveLeer(ids));
                } else {
                  dispatch(bulkRestoreLeer(ids));
                }
              }}
              handleBulkDelete={(ids) => dispatch(bulkDeleteLeer(ids))}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteLeer(deleteId));
          } else {
            dispatch(archiveLeer(deleteId));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
