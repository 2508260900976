/**
 * * Workbook Reducer
 */

import {
  WORKBOOKACTION_ATTEMPT,
  WORKBOOKLIST_ATTEMPT,
  WORKBOOK_ERROR,
  FETCHALLWORKBOOKS_SUCCESS,
  FETCHWORKBOOK_SUCCESS,
  ADDWORKBOOK_SUCCESS,
  UPDATEWORKBOOK_SUCCESS,
  DELETEWORKBOOK_SUCCESS,
  DELETEALLWORKBOOKS_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  workbooks: null,
  workbook: null,
};

export default function workbook(state = initialState, action) {
  switch (action.type) {
    case WORKBOOKLIST_ATTEMPT:
      return { ...state, fetching: true };
    case WORKBOOKACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case WORKBOOK_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLWORKBOOKS_SUCCESS:
      return { ...state, fetching: false, workbooks: action.payload.data };
    case FETCHWORKBOOK_SUCCESS:
      return { ...state, fetching: false, workbook: action.payload.workbook };
    case ADDWORKBOOK_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        workbooks: {
          ...state.workbooks,
          docs: [...state.workbooks.docs, action.payload.workbook],
        },
      };
    case UPDATEWORKBOOK_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        workbooks: {
          ...state.workbooks,
          docs: state.workbooks.docs.map((x) => {
            if (x._id === action.payload.workbook._id) {
              return action.payload.workbook;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEWORKBOOK_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        workbooks: {
          ...state.workbooks,
          docs: state.workbooks.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case DELETEALLWORKBOOKS_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        workbooks: null,
      };
    default:
      return state;
  }
}
