/**
 * * Students Reducer
 */

import {
  STUDENTACTION_ATTEMPT,
  STUDENTLIST_ATTEMPT,
  STUDENT_ERROR,
  FETCHALLSTUDENTS_SUCCESS,
  FETCHSTUDENT_SUCCESS,
  ADDSTUDENT_SUCCESS,
  UPDATESTUDENT_SUCCESS,
  DELETESTUDENT_SUCCESS,
  IMPORTSTUDENTDATA_SUCCESS,
  SORTSTUDENTS,
  BULKUPDATESTUDENT_SUCCESS,
  BULKDELETESTUDENT_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  users: null,
  user: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case IMPORTSTUDENTDATA_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: action.payload,
      };
    case STUDENTLIST_ATTEMPT:
      return { ...state, fetching: true };
    case STUDENTACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case SORTSTUDENTS:
      return {
        ...state,
        users: {
          ...state.users,
          docs: action.payload,
        },
      };
    case FETCHALLSTUDENTS_SUCCESS:
      return { ...state, fetching: false, users: action.payload.data };
    case FETCHSTUDENT_SUCCESS:
      return { ...state, fetching: false, user: action.payload.user };
    case ADDSTUDENT_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: state.users
          ? {
              ...state.users,
              docs: [...state.users.docs, action.payload.user],
            }
          : {
              docs: [action.payload.user],
              total: 1,
              limit: 25,
              page: 1,
              pages: 1,
              nextPage: null,
              prevPage: null,
            },
      };
    case UPDATESTUDENT_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.map((x) => {
            if (x._id === action.payload.user._id) {
              return action.payload.user;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETESTUDENT_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case BULKUPDATESTUDENT_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETESTUDENT_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
