/**
 * * Cantar Reducer
 */

import {
  CANTARACTION_ATTEMPT,
  CANTARLIST_ATTEMPT,
  CANTAR_ERROR,
  FETCHALLCANTARS_SUCCESS,
  FETCHCANTAR_SUCCESS,
  ADDCANTAR_SUCCESS,
  UPDATECANTAR_SUCCESS,
  DELETECANTAR_SUCCESS,
  SETCANTAR,
  BULKUPDATECANTAR_SUCCESS,
  BULKDELETECANTAR_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  cantars: null,
  cantar: null,
};

export default function cantar(state = initialState, action) {
  switch (action.type) {
    case CANTARLIST_ATTEMPT:
      return { ...state, fetching: true };
    case CANTARACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case CANTAR_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLCANTARS_SUCCESS:
      return { ...state, fetching: false, cantars: action.payload.data };
    case FETCHCANTAR_SUCCESS:
      return { ...state, fetching: false, cantar: action.payload.cantar };
    case ADDCANTAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        cantars: {
          ...state.cantars,
          docs: [...state.cantars.docs, action.payload.cantar],
        },
      };
    case UPDATECANTAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        cantars: {
          ...state.cantars,
          docs: state.cantars.docs.map((x) => {
            if (x._id === action.payload.cantar._id) {
              return action.payload.cantar;
            } else {
              return x;
            }
          }),
        },
      };
    case BULKUPDATECANTAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        cantars: {
          ...state.cantars,
          docs: state.cantars.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case DELETECANTAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        cantars: {
          ...state.cantars,
          docs: state.cantars.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case BULKDELETECANTAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        cantars: {
          ...state.cantars,
          docs: state.cantars.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    case SETCANTAR:
      return {
        ...state,
        cantars: {
          ...state.cantars,
          docs: action.payload,
        },
      };
    default:
      return state;
  }
}
