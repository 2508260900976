import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'redux/actions';
import { changeHeadTeacher } from 'containers/SuperAdmin/redux/actions/license';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { COLORS } from 'constants';
import { fetchAllUsers } from 'containers/SuperAdmin/redux/actions/user';
import EmailField from '../EmailField';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'normal',
    color: COLORS.DARKBLUE,
  },
  spacing: {
    margin: theme.spacing(2, 0),
  },
}));

const TeacherForm = ({ form, setForm, confirming, handleDone, school }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete='fname'
          name='firstName'
          variant='outlined'
          required
          fullWidth
          id='firstName'
          label='First Name'
          autoFocus
          InputLabelProps={{ shrink: true }}
          value={form?.firstName}
          onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          variant='outlined'
          required
          fullWidth
          id='lastName'
          label='Last Name'
          name='lastName'
          autoComplete='lname'
          InputLabelProps={{ shrink: true }}
          value={form?.lastName}
          onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailField
          email={form?.email}
          setEmail={(d) => setForm({ ...form, email: d })}
          className={classes.email}
          school={school}
          setLoader={setLoading}
          setEmailError={setError}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Button
          onClick={handleDone}
          className={classes.confirm}
          variant={'contained'}
          disabled={
            confirming ||
            form?.firstName === '' ||
            form?.lastName === '' ||
            form?.email === '' ||
            loading ||
            error
          }
        >
          {confirming ? 'Confirming' : 'Confirm'}
        </Button>
      </Grid>
    </Grid>
  );
};

const ChooseExistingTeacher = ({
  teachers,
  selectedTeacher,
  onSelect,
  handleDone,
  confirming,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12} sm={12}>
        <InputLabel id='teacher-label'>Choose a Teacher</InputLabel>
        <FormControl style={{ width: 300 }}>
          <Select
            labelId='teacher-label'
            id='teacher-select'
            value={selectedTeacher}
            onChange={(e) => onSelect(e.target.value)}
            displayEmpty={true}
            IconComponent={ArrowDropDown}
          >
            {teachers?.map((x) => (
              <MenuItem value={x._id} key={x._id}>
                {x.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Button
          onClick={handleDone}
          className={classes.confirm}
          variant={'contained'}
          disabled={!selectedTeacher}
        >
          {confirming ? 'Confirming' : 'Confirm'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default function ConfirmHeadTeacherDialog({
  open,
  title = 'Are you sure you want to continue?',
  content = 'Would you like to Archive the current Head Teacher or keep them Active?',
  handleClose,
  handleConfirm,
  confirmLabel = 'Keep Active',
  cancelLabel = 'Archive',
  loading,
  license,
}) {
  const dispatch = useDispatch();
  const { users } = useSelector(({ admin }) => admin.users);
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);
  const [newForm, setNewForm] = useState(false);
  const [options, setOptions] = useState(false);
  const [existingTeacher, setExistingTeacher] = useState(false);
  const [teacherId, setTeacherId] = useState('');

  const [teacherData, setTeacherData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    if (!license || !license?.licensee) return;
    setCurrentOption(null);
    setOptions(false);
    setNewForm(false);
    setExistingTeacher(false);
    setTeacherId('');
    setTeacherData({
      firstName: '',
      lastName: '',
      email: '',
    });
    dispatch(
      fetchAllUsers({
        access: 'teacher',
        school: license.licensee,
      })
    );
  }, [dispatch, license]);

  const handleArchive = () => {
    setSaving(false);
    setOptions(false);
    setCurrentOption('archive');
    if (
      users?.docs?.filter((x) => !x.isHeadTeacher && !x.isArchived)?.length ===
      0
    ) {
      setNewForm(true);
    } else {
      setNewForm(false);
      setOptions(true);
    }
  };

  const handleActive = () => {
    setCurrentOption('active');
    setSaving(false);
    setNewForm(false);
    if (
      users?.docs?.filter((x) => !x.isArchived)?.length ===
        Number(license?.teacherLicenses) ||
      Number(license?.consumedTeacherLicenses) ===
        Number(license?.teacherLicenses)
    ) {
      setOptions(false);
      dispatch(
        enqueueSnackbar({
          message:
            'The Teacher Pool of this license is full. Please archive 1 active teacher or allot space for 1 additional teacher account in the License.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    } else {
      setOptions(true);
    }
  };

  const Options = () => {
    return (
      <Grid container justifyContent='space-around' className={classes.spacing}>
        <Button
          className={
            !newForm && existingTeacher ? classes.confirm : classes.cancel
          }
          variant={!newForm && existingTeacher ? 'contained' : 'outlined'}
          onClick={() => {
            setNewForm(false);
            setExistingTeacher(true);
          }}
          disabled={
            users?.docs?.filter((x) => !x.isHeadTeacher && !x.isArchived)
              ?.length === 0
          }
        >
          Select Existing
        </Button>
        <Button
          className={
            newForm && !existingTeacher ? classes.confirm : classes.cancel
          }
          variant={newForm && !existingTeacher ? 'contained' : 'outlined'}
          onClick={() => {
            if (
              Number(license?.consumedTeacherLicenses) ===
                Number(license?.teacherLicenses) &&
              currentOption === 'active'
            ) {
              setNewForm(false);
              setExistingTeacher(false);
              dispatch(
                enqueueSnackbar({
                  message:
                    'The Teacher Pool of this license is full. Please archive 1 active teacher or allot space for 1 additional teacher account in the License.',
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                  },
                })
              );
            } else {
              setNewForm(true);
              setExistingTeacher(false);
            }
          }}
        >
          Create New
        </Button>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-dialog-title'
      maxWidth={'sm'}
    >
      <DialogTitle
        id='delete-dialog-title'
        className={classes.title}
        disableTypography
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.root}>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={handleArchive}
          className={
            currentOption === 'archive' ? classes.confirm : classes.cancel
          }
          variant={currentOption === 'archive' ? 'contained' : 'outlined'}
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={handleActive}
          className={
            currentOption === 'active' ? classes.confirm : classes.cancel
          }
          variant={currentOption === 'active' ? 'contained' : 'outlined'}
          disabled={loading}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
      <DialogContent className={classes.root}>
        {options && (
          <Typography>
            Would you like to select an existing teacher from this license or
            create a new one?
          </Typography>
        )}
        {options && <Options />}
        {newForm && (
          <TeacherForm
            form={teacherData}
            setForm={setTeacherData}
            confirming={saving}
            school={license?.licensee}
            handleDone={() => {
              setSaving(true);
              // main function
              dispatch(
                changeHeadTeacher(
                  license?._id,
                  {
                    updateType: 'new',
                    teacherLicenses: license?.teacherLicenses,
                    teacherData,
                    option: currentOption,
                  },
                  () => {
                    setSaving(false);
                    handleClose();
                    handleConfirm();
                  }
                )
              );
            }}
          />
        )}
        {existingTeacher && (
          <ChooseExistingTeacher
            teachers={users?.docs?.filter(
              (x) => !x.isHeadTeacher && !x.isArchived
            )}
            selectedTeacher={teacherId}
            onSelect={setTeacherId}
            confirming={saving}
            handleDone={() => {
              setSaving(true);
              // main function
              dispatch(
                changeHeadTeacher(
                  license?._id,
                  {
                    updateType: 'existing',
                    teacherLicenses: license?.teacherLicenses,
                    teacherId,
                    option: currentOption,
                  },
                  () => {
                    setSaving(false);
                    handleClose();
                    handleConfirm();
                  }
                )
              );
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
