/**
 * * Group Reducer
 */

import {
  GROUPACTION_ATTEMPT,
  GROUPLIST_ATTEMPT,
  GROUP_ERROR,
  FETCHALLGROUPS_SUCCESS,
  FETCHGROUP_SUCCESS,
  ADDGROUP_SUCCESS,
  UPDATEGROUP_SUCCESS,
  DELETEGROUP_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  groups: null,
  group: null,
};

export default function group(state = initialState, action) {
  switch (action.type) {
    case GROUPLIST_ATTEMPT:
      return { ...state, fetching: true };
    case GROUPACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLGROUPS_SUCCESS:
      return { ...state, fetching: false, groups: action.payload.data };
    case FETCHGROUP_SUCCESS:
      return { ...state, fetching: false, group: action.payload.group };
    case ADDGROUP_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        groups: {
          ...state.groups,
          docs: [...state.groups.docs, action.payload.group],
        },
      };
    case UPDATEGROUP_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        groups: {
          ...state.groups,
          docs: state.groups.docs.map((x) => {
            if (x._id === action.payload.group._id) {
              return action.payload.group;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEGROUP_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        groups: {
          ...state.groups,
          docs: state.groups.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
