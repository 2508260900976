import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export default function Heading({
  children,
  variant = 'h3',
  sm = 12,
  xs = 12,
  padding = 15,
  color = '',
  align = 'center',
  textTransform = 'noraml',
  transform,
  gridProps = {},
}) {
  return (
    <Grid
      item
      sm={sm}
      xs={xs}
      style={{ padding: `0px ${padding}px ${padding}px ${padding}px` }}
      {...gridProps}
    >
      <Typography
        variant={variant}
        align={align}
        color='primary'
        style={{
          color: color ? color : '',
          textTransform,
          transform,
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
}
