import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllAprenders,
  deleteAprender,
  updateAprender,
  setAprender,
  archiveAprender,
  restoreAprender,
  bulkArchiveAprender,
  bulkRestoreAprender,
  bulkDeleteAprender,
} from '../../redux/actions/aprender';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import { Avatar, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputField from 'containers/SuperAdmin/components/InputField';
import FilterBar from '../../components/FilterBar';
import { InfoOutlined } from '@material-ui/icons';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';
import Archive from '@material-ui/icons/Archive';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    margin: theme.spacing(1),
  },
}));

export default function Aprender({ titleName, id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { aprenders, fetching, actionLoading } = useSelector(
    ({ admin }) => admin.aprender
  );

  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [colorId, setColorId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [color, setColor] = useState(null);
  const [order, setOrder] = useState(null);
  const [nameId, setNameId] = useState(null);
  const [spanishId, setSpanishId] = useState(null);
  const [name, setName] = useState(null);
  const [spanish, setSpanish] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({ orderBy: 'order', orderDir: 'asc' });

  const headCells = [
    {
      id: 'checkbox',
      label: 'Select',
    },
    {
      id: 'name',
      label: 'Name',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {nameId === r._id ? (
            <InputField
              initialValue={r.name}
              field={name}
              setField={setName}
              onCancel={() => {
                setName(null);
                setNameId(null);
              }}
              onSave={(d) => dispatch(updateAprender(r._id, { name: d }))}
            />
          ) : (
            <>
              <Avatar src={r.transparent || r.image} size={16} />
              <span style={{ marginLeft: 15 }}>{r.name}</span>
              <IconButton
                onClick={() => {
                  setName(r.name);
                  setNameId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'spanishName',
      label: 'Spanish',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {spanishId === r._id ? (
            <InputField
              initialValue={r.spanishName}
              field={spanish}
              setField={setSpanish}
              onCancel={() => {
                setSpanish(null);
                setSpanishId(null);
              }}
              onSave={(d) =>
                dispatch(updateAprender(r._id, { spanishName: d }))
              }
            />
          ) : (
            <>
              <span>{r.spanishName ? r.spanishName : 'N/A'}</span>
              <IconButton
                onClick={() => {
                  setSpanish(r.spanishName);
                  setSpanishId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'color',
      label: 'Color',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {colorId === r._id ? (
            <InputField
              initialValue={r.color}
              field={color}
              setField={setColor}
              onCancel={() => {
                setColor(null);
                setColorId(null);
              }}
              onSave={(d) => dispatch(updateAprender(r._id, { color: d }))}
            />
          ) : (
            <>
              <span
                style={{
                  fontWeight: 'bold',
                  color: r.color,
                }}
              >
                {r.color ? r.color : 'N/A'}
              </span>
              <IconButton
                onClick={() => {
                  setColor(r.color);
                  setColorId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'order',
      label: 'Order',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {orderId === r._id ? (
            <InputField
              initialValue={r.order}
              field={order}
              setField={setOrder}
              onCancel={() => {
                setOrder(null);
                setOrderId(null);
              }}
              onSave={(d) => dispatch(updateAprender(r._id, { order: d }))}
            />
          ) : (
            <>
              <span>{r.order}</span>
              <IconButton
                onClick={() => {
                  setOrder(r.order);
                  setOrderId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/apprender/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <Archive />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(restoreAprender(r._id));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllAprenders({ orderBy: 'order', orderDir: 'asc' }));
  }, [dispatch]);

  const loadPage = (page, sortOptions) => {
    if (Object.values(sortOptions).length === 0) {
      sortOptions = sort;
    }
    let update = { page, limit: aprenders.limit, ...sortOptions };
    if (selectedTopics) {
      update = { ...update, topics: selectedTopics.toString() };
    }
    if (selectedGroup) {
      update = { ...update, group: selectedGroup };
    }
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(fetchAllAprenders(update));
    setSort(sortOptions);
  };

  let tableProps = {
    headCells: headCells,
    orderField: sort.orderBy,
    orderDirection: sort.orderDir,
    view,
    handlePage: (d) => loadPage(d, sort),
    handleOrder: (d) => loadPage(aprenders.page, d),
    handleBulk: (ids) =>
      view === 'normal'
        ? dispatch(bulkArchiveAprender(ids))
        : dispatch(bulkRestoreAprender(ids)),
    handleBulkDelete: (ids) => dispatch(bulkDeleteAprender(ids)),
  };

  if (selectedTopics?.length > 0) {
    tableProps.handleRowOrder = (data) => {
      dispatch(setAprender(data));
    };
  }

  const handleSearch = (d) => {
    setSearchString(d);
    let update = {
      page: aprenders.page,
      limit: aprenders.limit,
      search: d,
      ...sort,
    };
    if (selectedTopics) {
      update = { ...update, topics: selectedTopics.toString() };
    }
    if (selectedGroup) {
      update = { ...update, group: selectedGroup };
    }
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(fetchAllAprenders(update));
  };

  const handleAction = () => {
    let update = {
      page: aprenders.page,
      limit: aprenders.limit,
      topics: selectedTopics.toString(),
      group: selectedGroup,
      ...sort,
    };
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'normal') {
      update = { isArchived: true };
    }
    dispatch(fetchAllAprenders(update));
    setView(view === 'normal' ? 'archived' : 'normal');
  };

  const handleTopic = (e) => {
    setSelectedTopics(e.topics);
    setSelectedGroup(e.group);
    let update = {
      page: aprenders.page,
      limit: aprenders.limit,
      topics: e.topics.toString(),
      group: e.group,
      ...sort,
    };
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    dispatch(fetchAllAprenders(update));
  };

  return (
    <div className={classes.root}>
      <Loader fetching={fetching || actionLoading} />
      <PageHeader
        disableSearch
        title={titleName}
        options={
          <>
            <SettingDialog id={id} name={titleName} enableLabel={false} />
            <IconButton
              onClick={() => {
                history.push('/superadmin/apprender/new');
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        toggle={view === 'archived'}
        topics={selectedTopics}
        group={selectedGroup}
        onSearch={handleSearch}
        onAction={handleAction}
        onTopicSelect={handleTopic}
      />
      <Grid container justifyContent='center' className={classes.alert}>
        <Alert variant='filled' color='info' icon={<InfoOutlined />}>
          The drag and drop will activate once you have selected a child
          topic/group
        </Alert>
      </Grid>
      {aprenders && aprenders?.docs?.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...aprenders,
                docs: aprenders?.docs?.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
              }}
              {...tableProps}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteAprender(deleteId));
          } else {
            dispatch(archiveAprender(deleteId));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
