import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CircularProgress,
  colors,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { validUsername } from 'utils';
import { SEARCH_TIMEOUT, ERRORS } from 'constants';
import { findUsername } from 'redux/teachers/actions';
import { CheckCircle, Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(2, 0),
  },
}));

const Licensee = ({
  id = null,
  type = 'School',
  isHeadTeacher = false,
  textProps = {},
  variant = 'text',
  handleSchoolUpdate,
  handleUserUpdate,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { headTeachers } = useSelector(({ admin }) => admin.users);
  const { licenseeByKey } = useSelector(({ admin }) => admin.license);
  const {
    message,
    status,
    loading: usernameLoading,
  } = useSelector(({ teachers }) => teachers);

  const [licensee, setLicensee] = useState(null);
  const [loading, setLoading] = useState(true);

  const [schoolName, setSchoolName] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const timer = useRef(null);

  const getPrefix = () => {
    if (type === 'School') {
      return 'Head Teacher: ';
    }
    return `${type}: `;
  };

  const activateTimer = (firstName, lastName) => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      const username = firstName.trim() + lastName[0];
      dispatch(findUsername(username));
      saveUsername(username);
    }, SEARCH_TIMEOUT);
  };

  const getLicensee = useCallback(
    (id) => {
      if (isHeadTeacher) {
        const headTeacher = headTeachers?.find((x) => x.school === id);
        setLicensee(headTeacher);
        const d = licenseeByKey[id];
        setSchoolName(d?.name);
        setSchoolAddress(d?.address);
        setFirstName(headTeacher?.firstName || '');
        setLastName(headTeacher?.lastName || '');
        setLoading(false);
      } else {
        const d = licenseeByKey[id];
        setLicensee(d);
        setSchoolName(d?.schoolName);
        setSchoolAddress(d?.schoolAddress);
        setHomeAddress(d?.homeAddress);
        setPhoneNumber(d?.phoneNumber);
        setFirstName(d?.firstName);
        setLastName(d?.lastName);
        if (d?.username) {
          setUsername(d?.username);
        }
        setLoading(false);
      }
    },
    [isHeadTeacher, headTeachers, licenseeByKey]
  );

  const saveSchoolName = (value) => {
    setSchoolName(value);
    if (type === 'School') {
      handleSchoolUpdate({ name: value, address: schoolAddress });
    } else {
      handleUserUpdate({
        lastName,
        firstName,
        phoneNumber,
        schoolAddress,
        schoolName: value,
        homeAddress,
      });
    }
  };

  const saveSchoolAddress = (value) => {
    setSchoolAddress(value);
    if (type === 'School') {
      handleSchoolUpdate({ name: schoolName, address: value });
    } else {
      handleUserUpdate({
        firstName,
        lastName,
        phoneNumber,
        schoolName,
        homeAddress,
        schoolAddress: value,
      });
    }
  };

  const saveFirstName = (value) => {
    setFirstName(value);
    if (type === 'School') {
      handleSchoolUpdate({ name: schoolName, address: schoolAddress });
      handleUserUpdate({ firstName: value, lastName });
    } else {
      if (type === 'Student') {
        activateTimer(value, lastName);
      }
      handleUserUpdate({
        firstName: value,
        lastName,
        phoneNumber,
        schoolName,
        schoolAddress,
        homeAddress,
      });
    }
  };

  const saveLastName = (value) => {
    setLastName(value);
    if (type === 'School') {
      handleSchoolUpdate({ name: schoolName, address: schoolAddress });
      handleUserUpdate({ lastName: value, firstName });
    } else {
      if (type === 'Student') {
        activateTimer(firstName, value);
      }
      handleUserUpdate({
        lastName: value,
        firstName,
        phoneNumber,
        schoolName,
        schoolAddress,
        homeAddress,
      });
    }
  };

  const savePhoneNumber = (value) => {
    setPhoneNumber(value);
    handleUserUpdate({
      lastName,
      firstName,
      phoneNumber: value,
      schoolName,
      schoolAddress,
      homeAddress,
    });
  };

  const saveHomeAddress = (value) => {
    setPhoneNumber(value);
    handleUserUpdate({
      lastName,
      firstName,
      phoneNumber,
      schoolName,
      schoolAddress,
      homeAddress: value,
    });
  };

  const saveUsername = (value) => {
    setUsername(value);
    handleUserUpdate({
      lastName,
      firstName,
      phoneNumber,
      schoolName,
      schoolAddress,
      homeAddress,
      username: value,
    });
  };

  useEffect(() => {
    if (!id) return;
    setLicensee(null);
    setSchoolName('');
    setSchoolAddress('');
    setFirstName('');
    setLastName('');
    setHomeAddress('');
    setPhoneNumber('');
    setUsername('');
    getLicensee(id);
  }, [id, dispatch, getLicensee]);

  if (loading && !licensee) {
    return (
      <Typography {...textProps}>
        <Skeleton variant='text' />
      </Typography>
    );
  }

  if (!licensee || licensee === undefined) {
    <Typography {...textProps}>
      {getPrefix()}
      {isHeadTeacher ? 'No Existing Head Teacher' : 'N/A'}
    </Typography>;
  }

  if (variant === 'button') {
    return (
      <React.Fragment>
        {type !== 'School' && (
          <Grid item xs={12} sm={12} className={classes.spacing}>
            <TextField
              name='homeAddress'
              variant='outlined'
              fullWidth
              id='homeAddress'
              label='Home Address'
              value={homeAddress}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => saveHomeAddress(e.target.value)}
            />
          </Grid>
        )}
        {type !== 'School' && (
          <Grid item xs={12} sm={12} className={classes.spacing}>
            <TextField
              name='phoneNumber'
              variant='outlined'
              fullWidth
              id='phoneNumber'
              label='Phone Number'
              value={phoneNumber}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => savePhoneNumber(e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} className={classes.spacing}>
          <TextField
            name='schoolName'
            variant='outlined'
            fullWidth
            id='schoolName'
            label='School Name'
            value={schoolName}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => saveSchoolName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.spacing}>
          <TextField
            name='schoolAddress'
            variant='outlined'
            fullWidth
            id='schoolAddress'
            label='School Address'
            value={schoolAddress}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => saveSchoolAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.spacing}>
          <TextField
            name='firstName'
            variant='outlined'
            fullWidth
            id='firstName'
            label={`${type === 'School' ? 'Head Teacher' : type} First Name`}
            value={firstName}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => saveFirstName(e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.spacing}>
          <TextField
            name='lastName'
            variant='outlined'
            fullWidth
            id='lastName'
            label={`${type === 'School' ? 'Head Teacher' : type} Last Name`}
            value={lastName}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => saveLastName(e.target.value.trim())}
          />
        </Grid>
        {type === 'Student' && (
          <Grid item xs={12} sm={12} className={classes.spacing}>
            <TextField
              error={status > 200 || error === true}
              helperText={error ? ERRORS.USERNAME : status > 200 ? message : ''}
              variant='outlined'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              inputProps={{ autocomplete: 'username' }}
              value={username}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                if (e.target.value !== username) {
                  setError(!validUsername(e.target.value));
                }
                saveUsername(e.target.value.trim());
              }}
              onKeyUp={(e) => {
                if (validUsername(e.target.value)) {
                  e.target.value.length > 3 &&
                    dispatch(findUsername(e.target.value));
                }
              }}
              InputProps={{
                endAdornment: error ? (
                  <InputAdornment>
                    {usernameLoading && (
                      <CircularProgress
                        variant='indeterminate'
                        color='primary'
                        thickness={5}
                        size={20}
                      />
                    )}
                    {error && <Warning />}
                  </InputAdornment>
                ) : (
                  <InputAdornment>
                    {usernameLoading && (
                      <CircularProgress
                        variant='indeterminate'
                        color='primary'
                        thickness={5}
                        size={20}
                      />
                    )}
                    {status === 200 && (
                      <CheckCircle style={{ color: colors.green[500] }} />
                    )}
                    {status === 400 && <Warning />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} className={classes.spacing}>
          <Typography variant='caption' color='secondary'>
            Name edits are for spelling adjustments only.
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  if (isHeadTeacher && !licensee?.name && !licensee?.fullName) {
    return (
      <Typography {...textProps}>
        {getPrefix()}
        Not Selected
      </Typography>
    );
  }

  return (
    <Typography {...textProps}>
      {getPrefix()}
      {licensee?.name || licensee?.fullName}
    </Typography>
  );
};

export default React.memo(Licensee);
