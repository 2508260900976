import React, { useEffect, useState } from 'react';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { inputStyles } from '../../containers/Activation/inputClasses';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function PasswordField({ value, onChange, label = 'Password', ...props }) {
  const inputClasses = inputStyles();
  const [password, setPassword] = useState('');
  const [passwordToggle, setPasswordToggle] = useState('password');

  const onInputChange = (e) => {
    setPassword(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setPassword(value || '');
  }, [value]);

  return (
    <TextField
      {...props}
      type={passwordToggle}
      variant="outlined"
      fullWidth
      label={label}
      value={password}
      onChange={onInputChange}
      InputProps={{
        classes: inputClasses,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setPasswordToggle(passwordToggle === 'password' ? 'text' : 'password')}>
              {passwordToggle === 'text' ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
