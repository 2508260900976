import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchLeer, updateLeer } from '../../../redux/actions/leer';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditLeer({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading, leer } = useSelector(({ admin }) => admin.leer);

  useEffect(() => {
    dispatch(fetchLeer(id));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateLeer(id, d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {leer && (
        <Form
          initialData={leer}
          onSave={handleSave}
          content={['image']}
          multipleField={'sounds'}
          multipleFieldTitle='Sounds'
          showTopicPicker
        />
      )}
    </div>
  );
}
