import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.0 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export default function GlobalSelector({ selected, isArchived }) {
  const classes = useStyles();
  const router = useHistory();

  const list = ['School', 'Teachers', 'Students', 'Classes'];

  const navigate = (item) => {
    switch (item) {
      case 'School':
        router.push('/superadmin/schools');
        break;
      case 'Teachers':
        router.push(
          `/superadmin/teachers${isArchived ? '?isArchived=true' : ''}`
        );
        break;
      case 'Students':
        router.push(
          `/superadmin/students${isArchived ? '?isArchived=true' : ''}`
        );
        break;
      case 'Classes':
        router.push(
          `/superadmin/grades${isArchived ? '?isArchived=true' : ''}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Grid container direction={'row'} item sm>
      {list.length > 0 && (
        <FormControl className={classes.formControl}>
          <Select
            labelId='global-checkbox'
            id='global-checkbox'
            value={selected}
            onChange={(e) => navigate(e.target.value)}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {list.map((l, i) => (
              <MenuItem value={l} key={i}>
                {l}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
