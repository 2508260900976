import React from 'react';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { red } from '@material-ui/core/colors';
import useSound from 'use-sound';
import ImageButton from './image';

const backgroundStyles = (url) => ({
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'transparent',
  backgroundImage: `url(${url})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
});

export function YellowReportButton({
  children,
  handleClick,
  width = 200,
  customStyles = {},
}) {
  const { sound } = useSelector(({ global }) => global);
  const [play] = useSound('/click.mp3', { soundEnabled: sound });

  return (
    <ImageButton
      onClick={() => {
        play();
        if (handleClick) {
          handleClick();
        }
      }}
      image={'/yellow_wide.png'}
      width={width}
      customStyles={customStyles}
    >
      {children}
    </ImageButton>
  );
}

export function CircularButton({ children, handleClick }) {
  const { sound } = useSelector(({ global }) => global);
  const [play] = useSound('/click.mp3', { soundEnabled: sound });

  return (
    <Avatar
      style={{
        width: 52,
        height: 52,
        color: 'rgb(120,88,191)',
        fontSize: 26,
        fontWeight: 'bold',
        cursor: 'pointer',
        ...backgroundStyles('/BlueRoundButton.png'),
      }}
      onClick={() => {
        play();
        if (handleClick) {
          handleClick();
        }
      }}
    >
      {children}
    </Avatar>
  );
}

export function BlueButton({
  children,
  height = 100,
  width = 200,
  customStyles = {},
  handleClick,
  ...props
}) {
  const { sound } = useSelector(({ global }) => global);
  const [play] = useSound('/click.mp3', { soundEnabled: sound });

  return (
    <Button
      style={{
        ...backgroundStyles('/BlueButton.png'),
        textAlign: 'center',
        textTransform: 'none',
        fontSize: 22,
        verticalAlign: 'middle',
        display: 'block',
        height,
        minWidth: width,
        ...customStyles,
      }}
      disableRipple
      disableElevation
      disableFocusRipple
      onClick={() => {
        play();
        if (handleClick) {
          handleClick();
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function PinkButton({
  children,
  customStyles,
  version = 1,
  handleClick,
  ...props
}) {
  const { sound } = useSelector(({ global }) => global);
  const [play] = useSound('/click.mp3', { soundEnabled: sound });

  const variant = (v) => {
    switch (v) {
      case 1:
        return backgroundStyles('/PinkButton.png');
      case 2:
        return backgroundStyles('/Long-Button.png');
      case 3:
        return backgroundStyles('/Long-Button2.png');
      default:
        return backgroundStyles('/PinkButton.png');
    }
  };
  return (
    <Button
      style={{
        ...variant(version),
        minWidth: 200,
        height: 90,
        margin: '0 auto',
        textTransform: 'none',
        color: 'rgb(34,83,179)',
        ...customStyles,
      }}
      disableRipple
      disableElevation
      disableFocusRipple
      onClick={() => {
        play();
        if (handleClick) {
          handleClick();
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function YellowButton({
  children,
  customStyles,
  handleClick,
  ...props
}) {
  const { sound } = useSelector(({ global }) => global);
  const [play] = useSound('/click.mp3', { soundEnabled: sound });

  return (
    <Button
      style={{
        ...backgroundStyles('/YellowButton.png'),
        minWidth: 200,
        height: 90,
        margin: '0 auto',
        textTransform: 'none',
        color: 'rgb(34,83,179)',
        ...customStyles,
      }}
      disableRipple
      disableElevation
      disableFocusRipple
      onClick={() => {
        play();
        if (handleClick) {
          handleClick();
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function ContentButton({ option, setContentType }) {
  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      md={5}
      onClick={() => setContentType(option.name)}
      direction={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      style={{ margin: 20 }}
    >
      <Grid
        item
        sm={6}
        md={4}
        container
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'stretch'}
        style={{
          cursor: 'pointer',
          ...backgroundStyles('/BlueButton.png'),
          textAlign: 'center',
          minWidth: 200,
          height: 95,
          margin: 10,
        }}
      >
        <Typography
          align='center'
          style={{
            color: red[700],
            textShadow: '1px 0.5px #fff',
            marginTop: 15,
          }}
        >
          {option.name}
        </Typography>
        <Typography align='center' color='primary' style={{ marginBottom: 15 }}>
          {option.englishName}
        </Typography>
      </Grid>
      <Typography
        align='center'
        style={{
          fontStyle: 'italic',
          fontFamily: 'Open Sans',
          marginBottom: 10,
        }}
        variant={'caption'}
      >
        {option.description}
      </Typography>
    </Grid>
  );
}
