import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  GUIDEACTION_ATTEMPT,
  GUIDELIST_ATTEMPT,
  GUIDE_ERROR,
  FETCHALLGUIDES_SUCCESS,
  FETCHGUIDE_SUCCESS,
  ADDGUIDE_SUCCESS,
  UPDATEGUIDE_SUCCESS,
  DELETEGUIDE_SUCCESS,
  DELETEALLGUIDES_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const guideUrl = 'admin/guides';

export const fetchAllGuides = (params) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDELIST_ATTEMPT));
      const body = await axios.get(`${guideUrl}`, { params });
      dispatch(successAction(FETCHALLGUIDES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchGuide = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDELIST_ATTEMPT));
      const body = await axios.get(`${guideUrl}/${id}`);
      dispatch(successAction(FETCHGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const addGuide = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.post(`${guideUrl}`, data);
      dispatch(successAction(ADDGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const updateGuide = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.put(`${guideUrl}/${id}`, data);
      dispatch(successAction(UPDATEGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const deleteGuide = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.delete(`${guideUrl}/${id}`);
      dispatch(successAction(DELETEGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const deleteAllGuides = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.post(`${guideUrl}/deleteAll`);
      dispatch(successAction(DELETEALLGUIDES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const archiveGuide = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.put(`${guideUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};

export const restoreGuide = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GUIDEACTION_ATTEMPT));
      const body = await axios.put(`${guideUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GUIDE_ERROR, err));
    }
  };
  return thunk;
};
