import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  addTopic,
  deleteTopic,
  updateTopic,
  archiveTopic,
  restoreTopic,
} from '../../redux/actions/topic';

import {
  addGroup,
  deleteGroup,
  updateGroup,
  archiveGroup,
  restoreGroup,
} from '../../redux/actions/group';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../components/Loader';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import TopicDialog from '../../components/TopicDialog';
import GroupDialog from 'containers/SuperAdmin/components/GroupDialog';
import TopicTree from 'containers/SuperAdmin/components/TopicTree';
import TopicFilters from 'containers/SuperAdmin/components/TopicFilters';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialTopic = {
  name: '',
  spanishName: '',
  description: '',
  heading: '',
  image: '',
  isChild: false,
  buttonType: 'square',
  order: 0,
  enableContentOnly: false,
  hasGroup: false,
  columns: 0,
  backgroundLeft: '',
  backgroundRight: '',
  backgroundVariant: '',
};

const initialGroup = {
  name: '',
  topics: [],
};

export default function Topics({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGroupDeleteDialog, setOpenGroupDeleteDialog] = useState(false);

  const [archiveId, setArchiveId] = useState(null);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openGroupArchiveDialog, setOpenGroupArchiveDialog] = useState(false);

  const [restoreId, setRestoreId] = useState(null);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [openGroupRestoreDialog, setOpenGroupRestoreDialog] = useState(false);

  const [topicDialog, setTopicDialog] = useState(false);
  const [groupDialog, setGroupDialog] = useState(false);

  const [topicData, setTopicData] = useState(initialTopic);
  const [editTopic, setEditTopic] = useState(false);
  const [topicId, setTopicId] = useState(null);

  const [groupData, setGroupData] = useState(null);
  const [editGroup, setEditGroup] = useState(false);

  const [filter, setFilter] = useState('all');

  const { fetching, actionLoading } = useSelector(({ admin }) => admin.topic);

  const handleTopic = (data) => {
    if (!editTopic) {
      dispatch(addTopic(data));
    } else {
      dispatch(updateTopic(topicId, topicData));
      setTopicId(null);
    }
    setTopicDialog(false);
  };

  const handleFilter = (x) => {
    if (!x.isChild) {
      return x;
    }
    if (x.hasGroup) {
      return x;
    }
    if (filter === 'all') {
      return x;
    }
    if (filter === 'live') {
      return !x.isArchived;
    }
    if (filter === 'archived') {
      return x.isArchived;
    }
  };

  const handleFilterGroup = (x) => {
    if (filter === 'all') {
      return x;
    }
    if (filter === 'live') {
      return !x.isArchived;
    }
    if (filter === 'archived') {
      return x.isArchived;
    }
  };

  return (
    <div className={classes.root}>
      {(fetching || actionLoading) && <Loader fetching={true} />}
      <PageHeader
        disableSearch
        title={name}
        moreOptions
        options={
          <>
            <TopicFilters selected={filter} onChange={setFilter} />
          </>
        }
      />

      <TopicTree
        handleTopicAdd={() => {
          setEditTopic(false);
          setTopicData(initialTopic);
          setTopicDialog(true);
        }}
        handleChildTopicAdd={(r) => {
          setEditTopic(false);
          setTopicData({ ...initialTopic, isChild: true, parent: r });
          setTopicDialog(true);
        }}
        handleTopicEdit={(r) => {
          setEditTopic(true);
          setTopicId(r._id);
          setTopicData(r);
          setTopicDialog(true);
        }}
        handleTopicDelete={(r) => {
          setDeleteId(r._id);
          setOpenDeleteDialog(true);
        }}
        handleGroupAdd={(r) => {
          setEditGroup(false);
          setGroupData({ ...initialGroup, topics: [r] });
          setGroupDialog(true);
        }}
        handleGroupEdit={(r) => {
          setGroupData(r);
          setEditGroup(true);
          setGroupDialog(true);
        }}
        handleGroupDelete={(r) => {
          setDeleteId(r._id);
          setOpenGroupDeleteDialog(true);
        }}
        handleTopicArchive={(r) => {
          setArchiveId(r._id);
          setOpenArchiveDialog(true);
        }}
        handleTopicRestore={(r) => {
          setRestoreId(r._id);
          setOpenRestoreDialog(true);
        }}
        handleGroupArchive={(r) => {
          setArchiveId(r._id);
          setOpenGroupArchiveDialog(true);
        }}
        handleGroupRestore={(r) => {
          setRestoreId(r._id);
          setOpenGroupRestoreDialog(true);
        }}
        filter={handleFilter}
        filterGroup={handleFilterGroup}
      />
      <TopicDialog
        data={topicData}
        setData={setTopicData}
        edit={editTopic}
        open={topicDialog}
        handleClose={() => setTopicDialog(false)}
        handleConfirm={handleTopic}
      />
      <GroupDialog
        open={groupDialog}
        edit={editGroup}
        data={groupData}
        setData={setGroupData}
        handleClose={() => {
          setGroupData(initialGroup);
          setGroupDialog(false);
        }}
        handleConfirm={(data) => {
          if (editGroup) {
            dispatch(updateGroup(groupData._id, data));
          } else {
            dispatch(addGroup(data));
          }
          setGroupData(initialGroup);
          setGroupDialog(false);
        }}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteTopic(deleteId));
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={openGroupDeleteDialog}
        handleClose={() => setOpenGroupDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteGroup(deleteId));
          setDeleteId(null);
          setOpenGroupDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={openArchiveDialog}
        content={
          'Archiving Topic will also archive all of their Child Topics and Groups. Are you sure you want to archive?'
        }
        handleClose={() => {
          setOpenArchiveDialog(false);
          setArchiveId(null);
        }}
        handleConfirm={() => {
          dispatch(archiveTopic(archiveId));
          setArchiveId(null);
          setOpenArchiveDialog(false);
        }}
      />
      <DeleteDialog
        open={openRestoreDialog}
        content={
          'Restoring Topic will also restore all of their Child Topics and Groups, with the exception of those that have been deleted. Are you sure you want to restore?'
        }
        handleClose={() => {
          setOpenRestoreDialog(false);
          setRestoreId(null);
        }}
        handleConfirm={() => {
          dispatch(restoreTopic(restoreId));
          setRestoreId(null);
          setOpenRestoreDialog(false);
        }}
      />
      <DeleteDialog
        open={openGroupArchiveDialog}
        handleClose={() => {
          setOpenGroupArchiveDialog(false);
          setArchiveId(null);
        }}
        handleConfirm={() => {
          dispatch(archiveGroup(archiveId));
          setArchiveId(null);
          setOpenGroupArchiveDialog(false);
        }}
      />
      <DeleteDialog
        open={openGroupRestoreDialog}
        handleClose={() => {
          setOpenGroupRestoreDialog(false);
          setRestoreId(null);
        }}
        handleConfirm={() => {
          dispatch(restoreGroup(restoreId));
          setRestoreId(null);
          setOpenGroupRestoreDialog(false);
        }}
      />
    </div>
  );
}
