import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import ConfirmHeadTeacherDialog from './confirm';

const ChangeHeadTeacher = ({ license, handleConfirm }) => {
  const [confirm, setConfirm] = useState(false);
  return (
    <React.Fragment>
      <Button
        onClick={() => setConfirm(true)}
        variant='contained'
        color='primary'
      >
        <Typography align='left'>Replace Head Teacher</Typography>
      </Button>
      <ConfirmHeadTeacherDialog
        open={confirm}
        handleClose={() => setConfirm(false)}
        content={
          'Would you like to Archive current Head Teacher or keep them Active?'
        }
        cancelLabel='Archive'
        confirmLabel='Keep Active'
        license={license}
        handleConfirm={handleConfirm}
      />
    </React.Fragment>
  );
};

export default ChangeHeadTeacher;
