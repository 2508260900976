export const STUDENTLIST_ATTEMPT = 'STUDENTLIST_ATTEMPT';
export const STUDENTLIST_ERROR = 'STUDENTLIST_ERROR';

export const FETCHCHILDTOPICS_SUCCESS = 'FETCHCHILDOTOPICS_SUCCESS';
export const FETCHALLCHILDTOPICS_SUCCESS = 'FETCHALLCHILDOTOPICS_SUCCESS';
export const FETCHROOTTOPICS_SUCCESS = 'FETCHROOTOTOPICS_SUCCESS';

export const GETAPRENDER_SUCCESS = 'GETAPRENDER_SUCCESS';
export const GETPRACTICAR_SUCCESS = 'GETPRACTICAR_SUCCESS';
export const GETLEER_SUCCESS = 'GETLEER_SUCCESS';
export const GETCONVERSAR_SUCCESS = 'GETCONVERSAR_SUCCESS';
export const GETSINGLECANTAR_SUCCESS = 'GETSINGLECANTAR_SUCCESS';
export const GETSINGLECONVERSAR_SUCCESS = 'GETSINGLECONVERSAR_SUCCESS';
export const GETCANTAR_SUCCESS = 'GETCANTAR_SUCCESS';
export const GETMINILESSON_SUCCESS = 'GETMINILESSON_SUCCESS';
export const GETJUGAR_SUCCESS = 'GETJUGAR_SUCCESS';
export const SETAPRENDER = 'SETAPRENDER';
export const GETGROUPS_SUCCESS = 'GETGROUPS_SUCCESS';
export const GETALLGROUPS_SUCCESS = 'GETALLGROUPS_SUCCESS';
export const ADDSCORE_SUCCESS = 'ADDSCORE_SUCCESS';
export const GETSETTING_SUCCESS = 'GETSETTING_SUCCESS';
export const GETPRIVACY_SUCCESS = 'GETPRIVACY_SUCCESS';
