import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkbook, updateWorkbook } from '../../../redux/actions/workbook';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditWorkbook({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { actionLoading, workbook } = useSelector(
    ({ admin }) => admin.workbook
  );

  useEffect(() => {
    dispatch(fetchWorkbook(id));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateWorkbook(id, d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {workbook && (
        <Form
          initialData={workbook}
          onSave={handleSave}
          multipleField={'content'}
          multipleFieldTitle={'Workbook Pages'}
          dropZoneParams={{
            maxFiles: 250,
            multiple: true,
          }}
          titleField={'title'}
          orderField={false}
        />
      )}
    </div>
  );
}
