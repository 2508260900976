import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import { COLORS, theme } from '../../constants';
import NotFoundNotice from 'components/Notices/NotFoundNotice';

const useStyles = makeStyles((theme) => ({
  bodyRoot: {
    backgroundImage: `url('/bg.jpeg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  gridRoot: {
    padding: 10,
    width: '75vw',
    height: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      width: '100vw',
    },
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  box: {
    backgroundColor: COLORS.TRANSPARENT,
    borderRadius: 22,
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '65vh',
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} className={classes.bodyRoot}>
        <Box boxShadow={12} borderRadius={10} className={classes.box}>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.gridRoot}
            wrap={'wrap'}
            style={{ padding: 100 }}
          >
            <NotFoundNotice />
          </Grid>
        </Box>
      </Grid>
    </MuiThemeProvider>
  );
};

export default NotFound;
