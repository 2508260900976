/**
 * * Conversar Reducer
 */

import {
  CONVERSARACTION_ATTEMPT,
  CONVERSARLIST_ATTEMPT,
  CONVERSAR_ERROR,
  FETCHALLCONVERSARS_SUCCESS,
  FETCHCONVERSAR_SUCCESS,
  ADDCONVERSAR_SUCCESS,
  UPDATECONVERSAR_SUCCESS,
  DELETECONVERSAR_SUCCESS,
  BULKUPDATECONVERSAR_SUCCESS,
  BULKDELETECONVERSAR_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  conversars: null,
  conversar: null,
};

export default function conversar(state = initialState, action) {
  switch (action.type) {
    case CONVERSARLIST_ATTEMPT:
      return { ...state, fetching: true, conversar: null };
    case CONVERSARACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case CONVERSAR_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLCONVERSARS_SUCCESS:
      return { ...state, fetching: false, conversars: action.payload.data };
    case FETCHCONVERSAR_SUCCESS:
      return { ...state, fetching: false, conversar: action.payload.conversar };
    case ADDCONVERSAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        conversars: {
          ...state.conversars,
          docs: [...state.conversars.docs, action.payload.conversar],
        },
      };
    case UPDATECONVERSAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        conversars: {
          ...state.conversars,
          docs: state.conversars.docs.map((x) => {
            if (x._id === action.payload.conversar._id) {
              return action.payload.conversar;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETECONVERSAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        conversars: {
          ...state.conversars,
          docs: state.conversars.docs.filter(
            (x) => x._id !== action.payload.id
          ),
        },
      };
    case BULKUPDATECONVERSAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        conversars: {
          ...state.conversars,
          docs: state.conversars.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETECONVERSAR_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        conversars: {
          ...state.conversars,
          docs: state.conversars.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
