import React from 'react';
import NotFound from 'components/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.isDev = process.env.NODE_ENV === 'development';
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error, errorInfo: info });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      if (this.isDev) {
        console.log(this.state.error, this.state.errorInfo);
      }
      return <NotFound />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
