import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from '../../redux/actions';

import NotFound from '../../components/NotFound';
import LayoutSkeleton from './skeleton';

export default function SuperAccess({ children, ...rest }) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ global }) => global);

  useEffect(() => {
    if (user) return;
    dispatch(getProfile());
  }, [dispatch, user]);

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          ['admin'].includes(user.access) ? (
            children
          ) : (
            <NotFound />
          )
        ) : (
          <LayoutSkeleton />
        )
      }
    />
  );
}
