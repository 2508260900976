/**
 * * Setting Reducer
 */

import {
  SETTINGACTION_ATTEMPT,
  SETTINGLIST_ATTEMPT,
  SETTING_ERROR,
  FETCHSETTING_SUCCESS,
  UPDATESETTING_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  setting: null,
};

export default function manual(state = initialState, action) {
  switch (action.type) {
    case SETTINGLIST_ATTEMPT:
      return { ...state, fetching: true };
    case SETTINGACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case SETTING_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHSETTING_SUCCESS:
      return { ...state, fetching: false, setting: action.payload.setting };
    case UPDATESETTING_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        setting: action.payload.setting,
      };
    default:
      return state;
  }
}
