import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLesson, updateLesson } from '../../../redux/actions/lesson';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EditLesson({ name }) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { actionLoading, lesson } = useSelector(({ admin }) => admin.lesson);

  useEffect(() => {
    dispatch(fetchLesson(id));
  }, [dispatch, id]);

  const handleSave = (d) => {
    dispatch(updateLesson(id, d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      {lesson && (
        <Form
          initialData={lesson}
          onSave={handleSave}
          content={['image']}
          orderField={false}
          textFields={['lesson']}
          numberFields={['page']}
          switchFields={['miniLesson', 'vocabularyTopic']}
        />
      )}
    </div>
  );
}
