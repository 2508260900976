import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 150,
  },
  image: {
    position: 'relative',
    height: 75,
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
}));

export default function ImageButton({
  image,
  width = 180,
  children,
  color = 'rgb(34,83,179)',
  customStyles = {},
  ...props
}) {
  const classes = useStyles();
  return (
    <ButtonBase
      focusRipple
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{
        width,
        ...customStyles,
      }}
      {...props}
    >
      <span
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <span className={classes.imageButton} style={{ color }}>
        <Typography
          component='span'
          variant='subtitle1'
          color='inherit'
          className={classes.imageTitle}
        >
          {children}
        </Typography>
      </span>
    </ButtonBase>
  );
}
