import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grades from './pages/Grades';
import Users from './pages/Users';

import Topics from './pages/Topics';

import NewAprender from './pages/Aprender/New';
import EditAprender from './pages/Aprender/Edit';
import Aprender from './pages/Aprender';

import NewConversar from './pages/Conversar/New';
import EditConversar from './pages/Conversar/Edit';
import Conversar from './pages/Conversar';

import Cantar from './pages/Cantar';
import NewCantar from './pages/Cantar/New';
import EditCantar from './pages/Cantar/Edit';

import Leer from './pages/Leer';
import NewLeer from './pages/Leer/New';
import EditLeer from './pages/Leer/Edit';

import Practicars from './pages/Practicars';
import NewPracticar from './pages/Practicars/New';
import EditPracticar from './pages/Practicars/Edit';

import Resource from './pages/Trivia';
import NewResource from './pages/Trivia/New';
import EditResource from './pages/Trivia/Edit';

import Video from './pages/Video';
import NewVideo from './pages/Video/New';
import EditVideo from './pages/Video/Edit';

import Guide from './pages/Guides';
import NewGuide from './pages/Guides/New';
import EditGuide from './pages/Guides/Edit';

import Workbook from './pages/Workbooks';
import NewWorkbook from './pages/Workbooks/New';
import EditWorkbook from './pages/Workbooks/Edit';

import Manual from './pages/Manual';
import NewManual from './pages/Manual/New';
import EditManual from './pages/Manual/Edit';

import Students from './pages/Students';
import StudentsBySchool from './pages/Students/school';
import StudentsByGrade from './pages/Students/grade';

import PeopleIcon from '@material-ui/icons/People';
import ClassIcon from '@material-ui/icons/Class';
import BookIcon from '@material-ui/icons/Book';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SchoolIcon from '@material-ui/icons/School';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import PolicyIcon from '@material-ui/icons/Policy';

import Lesson from './pages/Lessons';
import NewLesson from './pages/Lessons/New';
import EditLesson from './pages/Lessons/Edit';

import Groups from './pages/Group';
import Privacy from './pages/Privacy';
import Teachers from './pages/Teachers';
import Schools from './pages/Schools';
import License from './pages/License';
import GradesBySchool from './pages/Grades/school';
import PageSetting from './pages/PageSetting';
import NewContent from './pages/NewContent';

// Old Content
import {
  OldAprender,
  OldCantar,
  OldConversar,
  OldPracticar,
  OldLeer,
  OldGuide,
  OldTrivia,
  OldLesson,
} from './pages/OldContent';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const PAGES = {
  MAINMENU: {
    text: 'Main Menu',
    link: '/superadmin/topics',
    icon: <AccountTreeIcon />,
    exact: true,
    component: <Topics name="Main Menu" />,
  },
  SUBMENUS: {
    text: 'Sub-Menus',
    link: '/superadmin/groups',
    icon: <AccountTreeIcon />,
    exact: true,
    component: <Groups name="Sub Menus" />,
  },
  GRADES: {
    text: 'Classes',
    link: '/superadmin/grades',
    icon: <GroupWorkIcon />,
    exact: true,
    component: <Grades name="Classes" />,
  },
  USERS: {
    text: 'Admins',
    link: '/superadmin/users',
    icon: <SupervisedUserCircleIcon />,
    exact: true,
    component: <Users name="Admins" />,
  },
  LICENSE: {
    text: 'Licenses',
    link: '/superadmin/licenses',
    icon: <VpnKeyOutlinedIcon />,
    exact: true,
    component: <License name="Licenses" />,
  },
  TEACHERS: {
    text: 'Teachers',
    link: '/superadmin/teachers',
    icon: <PeopleIcon />,
    exact: true,
    component: <Teachers name="Teachers" />,
  },
  STUDENTS: {
    text: 'Students',
    link: '/superadmin/students',
    icon: <ChildCareIcon />,
    exact: true,
    component: <Students name="Students" />,
  },
  SCHOOLS: {
    text: 'Schools',
    link: '/superadmin/schools',
    icon: <SchoolIcon />,
    exact: true,
    component: <Schools name="Schools" />,
  },
  STUDENTBYSCHOOL: {
    link: '/superadmin/schools/:schoolId/students',
    exact: true,
    component: <StudentsBySchool />,
  },
  GRADESBYSCHOOL: {
    link: '/superadmin/schools/:schoolId/classes',
    exact: true,
    component: <GradesBySchool />,
  },
  STUDENTSBYGRADE: {
    text: 'Students',
    link: '/superadmin/grades/:grade/students',
    exact: true,
    component: <StudentsByGrade />,
  },
  STUDENTSBYTEACHER: {
    text: 'Students',
    link: '/superadmin/teachers/:teacher/students',
    exact: true,
    component: <Students />,
  },
  TEACHERBYGRADES: {
    text: 'Students',
    link: '/superadmin/teachers/:teacher/classes',
    exact: true,
    component: <Grades />,
  },
  TEACHERSBYSCHOOL: {
    text: 'Schools',
    link: '/superadmin/schools/:schoolId/teachers',
    exact: true,
    component: <Teachers name="Schools" />,
  },
  TEXTBOOK: {
    text: 'Textbook',
    link: '/superadmin/lessons',
    icon: <ClassIcon />,
    exact: true,
    component: <Lesson name="Textbook" id="lesson" />,
  },
  TEACHERMANUAL: {
    text: 'Teacher Manual',
    link: '/superadmin/manuals',
    icon: <BookIcon />,
    exact: true,
    component: <Manual name="Teacher Manual" id="manual" />,
  },
  TRIVIA: {
    text: 'Trivia',
    link: '/superadmin/resources',
    icon: <BookIcon />,
    exact: true,
    component: <Resource titleName="Trivia" id="trivia" />,
  },
  TRAININGVIDEOS: {
    text: 'Training Videos',
    link: '/superadmin/videos',
    icon: <MovieCreationIcon />,
    exact: true,
    component: <Video titleName="Training Videos" id="video" />,
  },
  PRIVACYPOLICY: {
    text: 'Privacy Policy',
    link: '/superadmin/privacy',
    icon: <PolicyIcon />,
    exact: true,
    component: <Privacy name="Privacy Policy" />,
  },
  TEACHERGUIDES: {
    text: 'Teacher Guides',
    link: '/superadmin/guides',
    icon: <BookIcon />,
    exact: true,
    component: <Guide titleName="Teacher Guides" id="guide" />,
  },
  NEWGUIDE: {
    link: '/superadmin/guides/new',
    exact: true,
    component: <NewGuide name="New Guide" />,
  },
  EDITGUIDE: {
    link: '/superadmin/guides/edit/:id',
    exact: true,
    component: <EditGuide name="Edit Guide" />,
  },
  STUDENTWORKBOOKS: {
    text: 'Student Workbooks',
    link: '/superadmin/workbooks',
    icon: <BookIcon />,
    exact: true,
    component: <Workbook titleName="Student Workbooks" id="workbook" />,
  },
  NEWWORKBOOK: {
    link: '/superadmin/workbooks/new',
    exact: true,
    component: <NewWorkbook name="New Workbook" />,
  },
  EDITWORKBOOK: {
    link: '/superadmin/workbooks/edit/:id',
    exact: true,
    component: <EditWorkbook name="Edit Workbook" />,
  },
  LESSON: {
    link: '/superadmin/lessons',
    exact: true,
    component: <Lesson name="Textbook" id="lesson" />,
  },
  NEWLESSON: {
    link: '/superadmin/lessons/new',
    exact: true,
    component: <NewLesson name="New Textbook Page" />,
  },
  EDITLESSON: {
    link: '/superadmin/lessons/edit/:id',
    exact: true,
    component: <EditLesson name="Edit Textbook Page" />,
  },
  NEWVIDEO: {
    link: '/superadmin/videos/new',
    exact: true,
    component: <NewVideo name="New Video" />,
  },
  EDITVIDEO: {
    link: '/superadmin/videos/edit/:id',
    exact: true,
    component: <EditVideo name="Edit Video" />,
  },
  NEWRESOURCE: {
    link: '/superadmin/resources/new',
    exact: true,
    component: <NewResource name="New Resource" />,
  },
  EDITRESOURCE: {
    link: '/superadmin/resources/edit/:id',
    exact: true,
    component: <EditResource name="Edit Resource" />,
  },
  NEWMANUAL: {
    link: '/superadmin/manuals/new',
    exact: true,
    component: <NewManual name="New Manual" />,
  },
  EDITMANUAL: {
    link: '/superadmin/manuals/edit/:id',
    exact: true,
    component: <EditManual name="Edit Manual" />,
  },
  LEER: {
    text: 'Leer',
    link: '/superadmin/leer',
    icon: <ImageOutlinedIcon />,
    exact: true,
    component: <Leer name="Leer" id="leer" />,
  },
  NEWLEER: {
    link: '/superadmin/leer/new',
    exact: true,
    component: <NewLeer name="New Leer" />,
  },
  EDITLEER: {
    link: '/superadmin/leer/edit/:id',
    exact: true,
    component: <EditLeer name="Edit Leer" />,
  },
  CANTAR: {
    text: 'Cantar',
    link: '/superadmin/cantars',
    icon: <LibraryMusicIcon />,
    exact: true,
    component: <Cantar name="Cantar" id="cantar" />,
  },
  CANTARNEW: {
    link: '/superadmin/cantars/new',
    exact: true,
    component: <NewCantar name="New Cantar" />,
  },
  CANTAREDIT: {
    link: '/superadmin/cantars/edit/:id',
    exact: true,
    component: <EditCantar name="Edit Cantar" />,
  },
  CONVERSAR: {
    text: 'Conversar',
    link: '/superadmin/conversars',
    icon: <MovieCreationIcon />,
    exact: true,
    component: <Conversar name="Conversar" id="conversar" />,
  },
  NEWCONVERSAR: {
    link: '/superadmin/conversars/new',
    exact: true,
    component: <NewConversar name="New Conversar" />,
  },
  EDITCONVERSAR: {
    link: '/superadmin/conversars/edit/:id',
    exact: true,
    component: <EditConversar name="Edit Conversar" />,
  },
  APRENDER: {
    text: 'Aprender',
    link: '/superadmin/apprender',
    icon: <ClassIcon />,
    exact: true,
    component: <Aprender titleName="Aprender" id="aprender" />,
  },
  NEWAPRENDER: {
    link: '/superadmin/apprender/new',
    exact: true,
    component: <NewAprender name="New Aprender" />,
  },
  EDITAPRENDER: {
    link: '/superadmin/apprender/edit/:id',
    exact: true,
    component: <EditAprender name="Edit Aprender" />,
  },
  PRACTICAR: {
    text: 'Practicar',
    link: '/superadmin/practicars',
    icon: <MovieCreationIcon />,
    exact: true,
    component: <Practicars name="Practicar" id="practicar" />,
  },
  NEWPRACTICAR: {
    link: '/superadmin/practicars/new',
    exact: true,
    component: <NewPracticar name="New Practicar" />,
  },
  EDITPRACTICAR: {
    link: '/superadmin/practicars/edit/:id',
    exact: true,
    component: <EditPracticar name="Edit Practicar" />,
  },
  PAGESETTING: {
    text: 'Menu Settings',
    link: '/superadmin/pageSetting',
    exact: true,
    icon: <SettingsIcon />,
    component: <PageSetting name="Menu Settings" />,
  },
  NEWCONTENT: {
    text: 'New Content',
    link: '/superadmin/newContent',
    exact: true,
    icon: <SettingsIcon />,
    component: <NewContent name="New Content" />,
  },
  // Old Content
  OLDAPRENDER: {
    text: 'Aprender',
    link: '/superadmin/oldContent/aprenders',
    icon: <ClassIcon />,
    exact: true,
    component: <OldAprender titleName="Aprender" id="aprender" />,
  },
  OLDCANTAR: {
    text: 'Cantar',
    link: '/superadmin/oldContent/cantars',
    icon: <LibraryMusicIcon />,
    exact: true,
    component: <OldCantar name="Cantar" id="cantar" />,
  },
  OLDCONVERSAR: {
    text: 'Conversar',
    link: '/superadmin/oldContent/conversars',
    icon: <MovieCreationIcon />,
    exact: true,
    component: <OldConversar name="Conversar" id="conversar" />,
  },
  OLDLEER: {
    text: 'Leer',
    link: '/superadmin/oldContent/leers',
    icon: <ImageOutlinedIcon />,
    exact: true,
    component: <OldLeer name="Leer" id="leer" />,
  },
  OLDPRACTICAR: {
    text: 'Practicar',
    link: '/superadmin/oldContent/practicars',
    icon: <MovieCreationIcon />,
    exact: true,
    component: <OldPracticar name="Practicar" id="practicar" />,
  },
  OLDGUIDE: {
    text: 'Teacher Guides',
    link: '/superadmin/oldContent/guides',
    icon: <BookIcon />,
    exact: true,
    component: <OldGuide titleName="Teacher Guides" id="guide" />,
  },
  OLDTRIVIA: {
    text: 'Trivia',
    link: '/superadmin/oldContent/trivias',
    icon: <BookIcon />,
    exact: true,
    component: <OldTrivia titleName="Trivia" id="trivia" />,
  },
  OLDLESSON: {
    text: 'Textbook',
    link: '/superadmin/oldContent/lessons',
    icon: <ClassIcon />,
    exact: true,
    component: <OldLesson name="Textbook" id="lesson" />,
  },
};

export const routes = [
  PAGES.MAINMENU,
  PAGES.SUBMENUS,
  PAGES.GRADES,
  PAGES.USERS,
  PAGES.LICENSE,
  PAGES.TEACHERS,
  PAGES.STUDENTS,
  PAGES.SCHOOLS,
  PAGES.STUDENTBYSCHOOL,
  PAGES.GRADESBYSCHOOL,
  PAGES.STUDENTSBYGRADE,
  PAGES.STUDENTSBYTEACHER,
  PAGES.TEACHERBYGRADES,
  PAGES.TEACHERSBYSCHOOL,
  PAGES.TEXTBOOK,
  PAGES.TEACHERMANUAL,
  PAGES.EDITMANUAL,
  PAGES.NEWMANUAL,
  PAGES.TRIVIA,
  PAGES.NEWRESOURCE,
  PAGES.EDITRESOURCE,
  PAGES.TRAININGVIDEOS,
  PAGES.NEWVIDEO,
  PAGES.EDITVIDEO,
  PAGES.PRIVACYPOLICY,
  PAGES.TEACHERGUIDES,
  PAGES.NEWGUIDE,
  PAGES.EDITGUIDE,
  PAGES.LESSON,
  PAGES.NEWLESSON,
  PAGES.EDITLESSON,
  PAGES.EDITGUIDE,
  PAGES.LEER,
  PAGES.NEWLEER,
  PAGES.EDITLEER,
  PAGES.CANTAR,
  PAGES.CANTAREDIT,
  PAGES.CANTARNEW,
  PAGES.CONVERSAR,
  PAGES.NEWCONVERSAR,
  PAGES.EDITCONVERSAR,
  PAGES.APRENDER,
  PAGES.NEWAPRENDER,
  PAGES.EDITAPRENDER,
  PAGES.PRACTICAR,
  PAGES.NEWPRACTICAR,
  PAGES.EDITPRACTICAR,
  PAGES.PAGESETTING,
  PAGES.NEWCONTENT,
  PAGES.OLDAPRENDER,
  PAGES.OLDCANTAR,
  PAGES.OLDCONVERSAR,
  PAGES.OLDLEER,
  PAGES.OLDPRACTICAR,
  PAGES.OLDGUIDE,
  PAGES.OLDTRIVIA,
  PAGES.OLDLESSON,
  PAGES.NEWWORKBOOK,
  PAGES.EDITWORKBOOK,
  PAGES.STUDENTWORKBOOKS,
];

const SECTIONS = {
  MANAGEMENT: {
    title: 'Management',
    forceRefresh: true,
    sections: [PAGES.LICENSE, PAGES.SCHOOLS, PAGES.TEACHERS, PAGES.GRADES, PAGES.STUDENTS],
  },
  MENUS: {
    title: 'Menus',
    sections: [PAGES.MAINMENU],
  },
  CONTENT: {
    title: 'Student Content',
    forceRefresh: true,
    sections: [PAGES.APRENDER, PAGES.LEER, PAGES.PRACTICAR, PAGES.CONVERSAR, PAGES.CANTAR, PAGES.TEXTBOOK],
  },
  TEACHERS: {
    forceRefresh: true,
    title: 'Teacher Portal',
    sections: [PAGES.TEACHERGUIDES, PAGES.STUDENTWORKBOOKS, PAGES.TEACHERMANUAL, PAGES.TRIVIA, PAGES.TRAININGVIDEOS],
  },
  SETTINGS: {
    title: 'Settings',
    sections: [PAGES.USERS, PAGES.PAGESETTING, PAGES.PRIVACYPOLICY],
  },
  OLDCONTENT: {
    title: 'Old Content',
    forceRefresh: true,
    sections: [
      PAGES.OLDAPRENDER,
      PAGES.OLDLEER,
      PAGES.OLDPRACTICAR,
      PAGES.OLDCONVERSAR,
      PAGES.OLDCANTAR,
      PAGES.OLDLESSON,
      PAGES.OLDGUIDE,
      PAGES.OLDTRIVIA,
    ],
  },
};

export const content = [
  SECTIONS.MANAGEMENT,
  SECTIONS.MENUS,
  SECTIONS.CONTENT,
  SECTIONS.TEACHERS,
  SECTIONS.OLDCONTENT,
  SECTIONS.SETTINGS,
];

export const disabledSchoolFields = ['homeAddress', 'schoolAddress', 'phoneNumber', 'schoolName'];

export const RESTORE_OPTIONS = [
  { label: 'Preserve Settings', value: 'preserve' },
  { label: 'Reset as New', value: 'reset' },
];

export const formatValidityPeriodUnit = (period, unit) => {
  return period <= 1 ? unit?.substring(0, unit?.length - 1) : unit;
};

export const formatDuration = (period, unit) => {
  if (period === undefined || unit === undefined) return 'N/A';
  if (!period) return `Same ${formatValidityPeriodUnit(period, unit)}`;
  return `${period} ${formatValidityPeriodUnit(period, unit)}`;
};

export const formatLicenseDuration = (startDate, expiryDate) => {
  const activationDate = dayjs(startDate);
  const endDate = dayjs(expiryDate);

  const diff = endDate.diff(activationDate);
  const duration = dayjs.duration(diff);

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let durationString = '';

  if (years > 0) {
    durationString += `${years} year${years > 1 ? 's' : ''}`;
  }

  if (months > 0) {
    durationString += `${years > 0 ? ', ' : ''}${months} month${months > 1 ? 's' : ''}`;
  }

  if (days > 0) {
    durationString += `${years > 0 || months > 0 ? ', ' : ''}${days} day${days > 1 ? 's' : ''}`;
  }

  if (days === 0 && months === 0 && years === 0) {
    durationString = 'Same Day';
  }

  if (!durationString) {
    durationString = 'N/A';
  }

  return durationString;
};
