import { useState, useRef } from 'react';
import {
  CircularProgress,
  colors,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CheckCircle, Warning } from '@material-ui/icons';
import axios from 'axios';
import { SEARCH_TIMEOUT } from 'constants';

const EmailField = ({
  email,
  setEmail,
  school,
  className,
  setEmailError,
  setLoader,
  isStudent = false,
  forLicense = false,
  licenseData = null,
  disabled,
}) => {
  const timer = useRef(null);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { licenses } = useSelector(({ admin }) => admin.license);

  const handleEmailClick = () => {
    setLoading(true);
    if (school !== '') {
      const license = forLicense
        ? licenseData
        : licenses?.docs?.find((x) => x.licensee === school);
      if (license?.isDomainCompliant) {
        const schoolDomain = license?.domain;
        let regex = new RegExp(`[a-z0-9]+@${schoolDomain}`);
        let valid = regex.test(email);
        setTouched(true);
        setError(!valid || error);
        setEmailError(!valid || error);
        if (!valid) {
          setMessage(`Email must be from ${schoolDomain}`);
        } else {
          setMessage('');
        }
        setLoading(false);
      }
      setLoading(false);
      let regex = new RegExp(`[a-z0-9]+@`);
      let valid = regex.test(email);
      setTouched(true);
      setError(!valid || error);
      setEmailError(!valid || error);
      if (!valid) {
        setMessage('Email is not valid');
      }
    } else {
      setLoading(false);
      let regex = new RegExp(`[a-z0-9]+@`);
      let valid = regex.test(email);
      setTouched(true);
      setError(!valid || error);
      setEmailError(!valid || error);
      if (!valid) {
        setMessage('Email is not valid');
      }
    }
  };

  const activateTimer = (email) => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(validateEmail, SEARCH_TIMEOUT, email);
  };

  const validateEmail = (value) => {
    setLoading(true);
    if (setLoader) {
      setLoader(true);
    }
    axios
      .post(
        isStudent
          ? '/admin/licenses/validateEmail'
          : '/admin/users/validateEmail',
        { email: value }
      )
      .then((response) => {
        if (response?.data?.status === 200) {
          setError(false);
          setEmailError(false);
          setMessage(response?.data?.message);
          setLoading(false);
          if (setLoader) {
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        setError(true);
        setEmailError(true);
        setLoading(false);
        if (setLoader) {
          setLoader(false);
        }
        setMessage(error?.data?.message);
      });
  };

  const handleEmail = (value) => {
    setMessage('');
    setEmail(value);
    if (school !== '') {
      const license = forLicense
        ? licenseData
        : licenses?.docs?.find((x) => x.licensee === school);
      if (license?.isDomainCompliant) {
        const schoolDomain = license?.domain;
        let regex = new RegExp(`[a-z0-9]+@${schoolDomain}`);
        let valid = regex.test(value);
        setError(!valid);
        setEmailError(!valid);
        if (!valid) {
          setMessage(`Email must be from ${schoolDomain}`);
        } else {
          activateTimer(value);
        }
      } else {
        activateTimer(value);
      }
    } else {
      activateTimer(value);
    }
  };

  return (
    <TextField
      helperText={message}
      variant='outlined'
      required
      fullWidth
      id='email'
      label='Email'
      name='email'
      autoComplete='email'
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      className={className}
      value={email}
      onClick={handleEmailClick}
      onChange={(e) => handleEmail(e.target.value.trim())}
      error={error && touched}
      InputProps={{
        endAdornment: error ? (
          <InputAdornment>
            {loading && (
              <CircularProgress
                variant='indeterminate'
                color='primary'
                thickness={5}
                size={20}
              />
            )}
            {error && !loading && <Warning />}
          </InputAdornment>
        ) : (
          <InputAdornment>
            {loading && (
              <CircularProgress
                variant='indeterminate'
                color='primary'
                thickness={5}
                size={20}
              />
            )}
            {!error && !loading && touched && (
              <CheckCircle style={{ color: colors.green[500] }} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailField;
