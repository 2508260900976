export const UPLOAD_ATTEMPT = 'UPLOAD_ATTEMPT';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';

export const USERLIST_ATTEMPT = 'USERLIST_ATTEMPT';
export const USERACTION_ATTEMPT = 'USERACTION_ATTEMPT';
export const USER_ERROR = 'USER_ERROR';

export const FETCHALLUSERS_SUCCESS = 'FETCHALLUSERS_SUCCESS';
export const FETCHHEADTEACHERS_SUCCESS = 'FETCHHEADTEACHERS_SUCCESS';
export const FETCHACTIVETEACHERS_SUCCESS = 'FETCHACTIVETEACHERS_SUCCESS';
export const FETCHALLTEACHERS_SUCCESS = 'FETCHALLTEACHERS_SUCCESS';
export const FETCHUSER_SUCCESS = 'FETCHUSER_SUCCESS';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDHEADTEACHER_SUCCESS = 'ADDHEADTEACHER_SUCCESS';
export const UPDATEUSER_SUCCESS = 'UPDATEUSER_SUCCESS';
export const DELETEUSER_SUCCESS = 'DELETEUSER_SUCCESS';
export const SORTUSERS = 'SORTUSERS';

export const STUDENTLIST_ATTEMPT = 'STUDENTLIST_ATTEMPT';
export const STUDENTACTION_ATTEMPT = 'STUDENTACTION_ATTEMPT';
export const STUDENT_ERROR = 'STUDENT_ERROR';

export const FETCHALLSTUDENTS_SUCCESS = 'FETCHALLSTUDENTS_SUCCESS';
export const FETCHSTUDENT_SUCCESS = 'FETCHSTUDENT_SUCCESS';
export const ADDSTUDENT_SUCCESS = 'ADDSTUDENT_SUCCESS';
export const UPDATESTUDENT_SUCCESS = 'UPDATESTUDENT_SUCCESS';
export const DELETESTUDENT_SUCCESS = 'DELETESTUDENT_SUCCESS';
export const BULKUPDATESTUDENT_SUCCESS = 'BULKUPDATESTUDENT_SUCCESS';
export const BULKDELETESTUDENT_SUCCESS = 'BULKDELETESTUDENT_SUCCESS';
export const SORTSTUDENTS = 'SORTSTUDENTS';

export const GRADELIST_ATTEMPT = 'GRADELIST_ATTEMPT';
export const GRADEACTION_ATTEMPT = 'GRADEACTION_ATTEMPT';
export const GRADE_ERROR = 'GRADE_ERROR';

export const FETCHALLGRADES_SUCCESS = 'FETCHALLGRADES_SUCCESS';
export const FETCHGRADE_SUCCESS = 'FETCHGRADE_SUCCESS';
export const ADDGRADE_SUCCESS = 'ADDGRADE_SUCCESS';
export const UPDATEGRADE_SUCCESS = 'UPDATEGRADE_SUCCESS';
export const DELETEGRADE_SUCCESS = 'DELETEGRADE_SUCCESS';
export const BULKUPDATEGRADE_SUCCESS = 'BULKUPDATEGRADE_SUCCESS';
export const BULKDELETEGRADE_SUCCESS = 'BULKDELETEGRADE_SUCCESS';
export const SORTGRADES = 'SORTGRADES';

export const APRENDERLIST_ATTEMPT = 'APRENDERLIST_ATTEMPT';
export const APRENDERACTION_ATTEMPT = 'APRENDERACTION_ATTEMPT';
export const APRENDER_ERROR = 'APRENDER_ERROR';

export const FETCHALLAPRENDERS_SUCCESS = 'FETCHALLAPRENDERS_SUCCESS';
export const FETCHAPRENDER_SUCCESS = 'FETCHAPRENDER_SUCCESS';
export const ADDAPRENDER_SUCCESS = 'ADDAPRENDER_SUCCESS';
export const UPDATEAPRENDER_SUCCESS = 'UPDATEAPRENDER_SUCCESS';
export const BULKUPDATEAPRENDER_SUCCESS = 'BULKUPDATEAPRENDER_SUCCESS';
export const BULKDELETEAPRENDER_SUCCESS = 'BULKDELETEAPRENDER_SUCCESS';
export const DELETEAPRENDER_SUCCESS = 'DELETEAPRENDER_SUCCESS';
export const SETAPRENDER = 'SETAPRENDERDATA';

export const LEERLIST_ATTEMPT = 'LEERLIST_ATTEMPT';
export const LEERACTION_ATTEMPT = 'LEERACTION_ATTEMPT';
export const LEER_ERROR = 'LEER_ERROR';

export const FETCHALLLEERS_SUCCESS = 'FETCHALLLEERS_SUCCESS';
export const FETCHLEER_SUCCESS = 'FETCHLEER_SUCCESS';
export const ADDLEER_SUCCESS = 'ADDLEER_SUCCESS';
export const UPDATELEER_SUCCESS = 'UPDATELEER_SUCCESS';
export const BULKUPDATELEER_SUCCESS = 'BULKUPDATELEER_SUCCESS';
export const DELETELEER_SUCCESS = 'DELETELEER_SUCCESS';
export const BULKDELETELEER_SUCCESS = 'BULKDELETELEER_SUCCESS';
export const SETLEER = 'SETLEERDATA';

export const PRACTICARLIST_ATTEMPT = 'PRACTICARLIST_ATTEMPT';
export const PRACTICARACTION_ATTEMPT = 'PRACTICARACTION_ATTEMPT';
export const PRACTICAR_ERROR = 'PRACTICAR_ERROR';

export const FETCHALLPRACTICARS_SUCCESS = 'FETCHALLPRACTICARS_SUCCESS';
export const FETCHPRACTICAR_SUCCESS = 'FETCHPRACTICAR_SUCCESS';
export const ADDPRACTICAR_SUCCESS = 'ADDPRACTICAR_SUCCESS';
export const UPDATEPRACTICAR_SUCCESS = 'UPDATEPRACTICAR_SUCCESS';
export const BULKUPDATEPRACTICAR_SUCCESS = 'BULKUPDATEPRACTICAR_SUCCESS';
export const BULKDELETEPRACTICAR_SUCCESS = 'BULKDELETEPRACTICAR_SUCCESS';
export const DELETEPRACTICAR_SUCCESS = 'DELETEPRACTICAR_SUCCESS';
export const SETPRACTICAR = 'SETPRACTICARDATA';

export const CANTARLIST_ATTEMPT = 'CANTARLIST_ATTEMPT';
export const CANTARACTION_ATTEMPT = 'CANTARACTION_ATTEMPT';
export const CANTAR_ERROR = 'CANTAR_ERROR';

export const FETCHALLCANTARS_SUCCESS = 'FETCHALLCANTARS_SUCCESS';
export const FETCHCANTAR_SUCCESS = 'FETCHCANTAR_SUCCESS';
export const ADDCANTAR_SUCCESS = 'ADDCANTAR_SUCCESS';
export const UPDATECANTAR_SUCCESS = 'UPDATECANTAR_SUCCESS';
export const DELETECANTAR_SUCCESS = 'DELETECANTAR_SUCCESS';
export const BULKUPDATECANTAR_SUCCESS = 'BULKUPDATECANTAR_SUCCESS';
export const BULKDELETECANTAR_SUCCESS = 'BULKDELETECANTAR_SUCCESS';
export const SETCANTAR = 'SETCANTARDATA';

export const CONVERSARLIST_ATTEMPT = 'CONVERSARLIST_ATTEMPT';
export const CONVERSARACTION_ATTEMPT = 'CONVERSARACTION_ATTEMPT';
export const CONVERSAR_ERROR = 'CONVERSAR_ERROR';

export const FETCHALLCONVERSARS_SUCCESS = 'FETCHALLCONVERSARS_SUCCESS';
export const FETCHCONVERSAR_SUCCESS = 'FETCHCONVERSAR_SUCCESS';
export const ADDCONVERSAR_SUCCESS = 'ADDCONVERSAR_SUCCESS';
export const UPDATECONVERSAR_SUCCESS = 'UPDATECONVERSAR_SUCCESS';
export const DELETECONVERSAR_SUCCESS = 'DELETECONVERSAR_SUCCESS';
export const BULKUPDATECONVERSAR_SUCCESS = 'BULKUPDATECONVERSAR_SUCCESS';
export const BULKDELETECONVERSAR_SUCCESS = 'BULKDELETECONVERSAR_SUCCESS';
export const SETCONVERSAR = 'SETCONVERSARDATA';

export const TOPICLIST_ATTEMPT = 'TOPICLIST_ATTEMPT';
export const TOPICACTION_ATTEMPT = 'TOPICACTION_ATTEMPT';
export const TOPIC_ERROR = 'TOPIC_ERROR';

export const FETCHPARENTTOPICS_SUCCESS = 'FETCHPARENTTOPICS_SUCCESS';
export const FETCHALLTOPICS_SUCCESS = 'FETCHALLTOPICS_SUCCESS';
export const FETCHTOPIC_SUCCESS = 'FETCHTOPIC_SUCCESS';
export const ADDTOPIC_SUCCESS = 'ADDTOPIC_SUCCESS';
export const UPDATETOPIC_SUCCESS = 'UPDATETOPIC_SUCCESS';
export const DELETETOPIC_SUCCESS = 'DELETETOPIC_SUCCESS';
export const BULKUPDATETOPIC_SUCCESS = 'BULKUPDATETOPIC_SUCCESS';
export const BULKDELETETOPIC_SUCCESS = 'BULKDELETETOPIC_SUCCESS';

export const LESSONLIST_ATTEMPT = 'LESSONLIST_ATTEMPT';
export const LESSONACTION_ATTEMPT = 'LESSONACTION_ATTEMPT';
export const LESSON_ERROR = 'LESSON_ERROR';

export const FETCHALLLESSONS_SUCCESS = 'FETCHALLLESSONS_SUCCESS';
export const FETCHLESSON_SUCCESS = 'FETCHLESSON_SUCCESS';
export const ADDLESSON_SUCCESS = 'ADDLESSON_SUCCESS';
export const ADDBULKLESSON_SUCCESS = 'ADDBULKLESSON_SUCCESS';
export const UPDATELESSON_SUCCESS = 'UPDATELESSON_SUCCESS';
export const DELETELESSON_SUCCESS = 'DELETELESSON_SUCCESS';
export const BULKUPDATELESSON_SUCCESS = 'BULKUPDATELESSON_SUCCESS';
export const BULKDELETELESSON_SUCCESS = 'BULKDELETELESSON_SUCCESS';
export const DELETEALLLESSONS_SUCCESS = 'DELETEALLLESSONS_SUCCESS';

export const MANUALLIST_ATTEMPT = 'MANUALLIST_ATTEMPT';
export const MANUALACTION_ATTEMPT = 'MANUALACTION_ATTEMPT';
export const MANUAL_ERROR = 'MANUAL_ERROR';

export const FETCHALLMANUALS_SUCCESS = 'FETCHALLMANUALS_SUCCESS';
export const FETCHMANUAL_SUCCESS = 'FETCHMANUAL_SUCCESS';
export const ADDMANUAL_SUCCESS = 'ADDMANUAL_SUCCESS';
export const UPDATEMANUAL_SUCCESS = 'UPDATEMANUAL_SUCCESS';
export const DELETEMANUAL_SUCCESS = 'DELETEMANUAL_SUCCESS';
export const SETMANUAL = 'SETMANUAL';

export const GUIDELIST_ATTEMPT = 'GUIDELIST_ATTEMPT';
export const GUIDEACTION_ATTEMPT = 'GUIDEACTION_ATTEMPT';
export const GUIDE_ERROR = 'GUIDE_ERROR';

export const FETCHALLGUIDES_SUCCESS = 'FETCHALLGUIDES_SUCCESS';
export const FETCHGUIDE_SUCCESS = 'FETCHGUIDE_SUCCESS';
export const ADDGUIDE_SUCCESS = 'ADDGUIDE_SUCCESS';
export const UPDATEGUIDE_SUCCESS = 'UPDATEGUIDE_SUCCESS';
export const DELETEGUIDE_SUCCESS = 'DELETEGUIDE_SUCCESS';
export const DELETEALLGUIDES_SUCCESS = 'DELETEALLGUIDES_SUCCESS';

export const WORKBOOKLIST_ATTEMPT = 'WORKBOOKLIST_ATTEMPT';
export const WORKBOOKACTION_ATTEMPT = 'WORKBOOKACTION_ATTEMPT';
export const WORKBOOK_ERROR = 'WORKBOOK_ERROR';

export const FETCHALLWORKBOOKS_SUCCESS = 'FETCHALLWORKBOOKS_SUCCESS';
export const FETCHWORKBOOK_SUCCESS = 'FETCHWORKBOOK_SUCCESS';
export const ADDWORKBOOK_SUCCESS = 'ADDWORKBOOK_SUCCESS';
export const UPDATEWORKBOOK_SUCCESS = 'UPDATEWORKBOOK_SUCCESS';
export const DELETEWORKBOOK_SUCCESS = 'DELETEWORKBOOK_SUCCESS';
export const DELETEALLWORKBOOKS_SUCCESS = 'DELETEALLWORKBOOKS_SUCCESS';

export const RESOURCELIST_ATTEMPT = 'RESOURCELIST_ATTEMPT';
export const RESOURCEACTION_ATTEMPT = 'RESOURCEACTION_ATTEMPT';
export const RESOURCE_ERROR = 'RESOURCE_ERROR';

export const FETCHALLRESOURCES_SUCCESS = 'FETCHALLRESOURCES_SUCCESS';
export const FETCHRESOURCE_SUCCESS = 'FETCHRESOURCE_SUCCESS';
export const ADDRESOURCE_SUCCESS = 'ADDRESOURCE_SUCCESS';
export const UPDATERESOURCE_SUCCESS = 'UPDATERESOURCE_SUCCESS';
export const DELETERESOURCE_SUCCESS = 'DELETERESOURCE_SUCCESS';
export const BULKUPDATERESOURCE_SUCCESS = 'BULKUPDATERESOURCE_SUCCESS';
export const BULKDELETERESOURCE_SUCCESS = 'BULKDELETERESOURCE_SUCCESS';
export const SETRESOURCE = 'SETRESOURCEDATA';

export const VIDEOLIST_ATTEMPT = 'VIDEOLIST_ATTEMPT';
export const VIDEOACTION_ATTEMPT = 'VIDEOACTION_ATTEMPT';
export const VIDEO_ERROR = 'VIDEO_ERROR';

export const FETCHALLVIDEOS_SUCCESS = 'FETCHALLVIDEOS_SUCCESS';
export const FETCHVIDEO_SUCCESS = 'FETCHVIDEO_SUCCESS';
export const ADDVIDEO_SUCCESS = 'ADDVIDEO_SUCCESS';
export const UPDATEVIDEO_SUCCESS = 'UPDATEVIDEO_SUCCESS';
export const DELETEVIDEO_SUCCESS = 'DELETEVIDEO_SUCCESS';
export const BULKUPDATEVIDEO_SUCCESS = 'BULKUPDATEVIDEO_SUCCESS';
export const BULKDELETEVIDEO_SUCCESS = 'BULKDELETEVIDEO_SUCCESS';
export const SETVIDEO = 'SETVIDEODATA';

export const GROUPLIST_ATTEMPT = 'GROUPLIST_ATTEMPT';
export const GROUPACTION_ATTEMPT = 'GROUPACTION_ATTEMPT';
export const GROUP_ERROR = 'GROUP_ERROR';

export const FETCHALLGROUPS_SUCCESS = 'FETCHALLGROUPS_SUCCESS';
export const FETCHGROUP_SUCCESS = 'FETCHGROUP_SUCCESS';
export const ADDGROUP_SUCCESS = 'ADDGROUP_SUCCESS';
export const UPDATEGROUP_SUCCESS = 'UPDATEGROUP_SUCCESS';
export const DELETEGROUP_SUCCESS = 'DELETEGROUP_SUCCESS';
export const BULKUPDATEGROUP_SUCCESS = 'BULKUPDATEGROUP_SUCCESS';
export const BULKDELETEGROUP_SUCCESS = 'BULKDELETEGROUP_SUCCESS';

export const SETTINGACTION_ATTEMPT = 'SETTINGACTION_ATTEMPT';
export const SETTINGLIST_ATTEMPT = 'SETTINGLIST_ATTEMPT';
export const SETTING_ERROR = 'SETTING_ERROR';
export const FETCHSETTING_SUCCESS = 'FETCHSETTING_SUCCESS';
export const UPDATESETTING_SUCCESS = 'UPDATESETTING_SUCCESS';

export const SCHOOLACTION_ATTEMPT = 'SCHOOLACTION_ATTEMPT';
export const SCHOOLLIST_ATTEMPT = 'SCHOOLLIST_ATTEMPT';
export const SCHOOL_ERROR = 'SCHOOL_ERROR';

export const FETCHALLSCHOOLS_SUCCESS = 'FETCHALLSCHOOLS_SUCCESS';
export const FETCHALLARCHIVEDSCHOOLS_SUCCESS =
  'FETCHALLARCHIVEDSCHOOLS_SUCCESS';
export const FETCHSCHOOL_SUCCESS = 'FETCHSCHOOL_SUCCESS';
export const ADDSCHOOL_SUCCESS = 'ADDSCHOOL_SUCCESS';
export const UPDATESCHOOL_SUCCESS = 'UPDATESCHOOL_SUCCESS';
export const DELETESCHOOL_SUCCESS = 'DELETESCHOOL_SUCCESS';

export const LICENSELIST_ATTEMPT = 'LICENSELIST_ATTEMPT';
export const LICENSEELIST_ATTEMPT = 'LICENSEELIST_ATTEMPT';
export const LICENSEACTION_ATTEMPT = 'LICENSEACTION_ATTEMPT';
export const LICENSE_ERROR = 'LICENSE_ERROR';

export const FETCHALLLICENSES_SUCCESS = 'FETCHALLLICENSES_SUCCESS';
export const FETCHLICENSEE_SUCCESS = 'FETCHLICENSEE_SUCCESS';
export const FETCHLICENSEES_SUCCESS = 'FETCHLICENSEES_SUCCESS';
export const FETCHLICENSE_SUCCESS = 'FETCHLICENSE_SUCCESS';
export const FETCHLICENSES_SUCCESS = 'FETCHLICENSES_SUCCESS';
export const ADDLICENSE_SUCCESS = 'ADDLICENSE_SUCCESS';
export const UPDATELICENSE_SUCCESS = 'UPDATELICENSE_SUCCESS';
export const DELETELICENSE_SUCCESS = 'DELETELICENSE_SUCCESS';

export const IMPORTSTUDENTDATA_ATTEMPT = 'IMPORTSTUDENTDATA_ATTEMPT';
export const IMPORTSTUDENTDATA_SUCCESS = 'IMPORTSTUDENTDATA_SUCCESS';
export const IMPORTSTUDENTDATA_ERROR = 'IMPORTSTUDENTDATA_ERROR';

export const FETCHORDERNUMBER_SUCCESS = 'FETCHORDERNUMBER_SUCCESS';
export const VALIDATEORDER_SUCCESS = 'VALIDATEORDER_SUCCESS';
export const RESOUCEVALIDATE_SUCCESS = 'RESOUCEVALIDATE_SUCCESS';
export const RESOURCEMAXORDER_SUCCESS = 'RESOURCEMAXORDER_SUCCESS';
export const VIDEOVALIDATE_SUCESS = 'VIDEOVALIDATE_SUCESS';
export const VIDEOMAXORDER_SUCESS = 'VIDEOMAXORDER_SUCESS';
export const MANUALVALIDATE_SUCESS = 'MANUALVALIDATE_SUCESS';
export const MANUALMAXORDER_SUCESS = 'MANUALMAXORDER_SUCCESS';
