/**
 * * Students Reducer
 */

import {
  STUDENTLIST_ATTEMPT,
  STUDENTLIST_ERROR,
  FETCHALLCHILDTOPICS_SUCCESS,
  FETCHCHILDTOPICS_SUCCESS,
  FETCHROOTTOPICS_SUCCESS,
  GETPRACTICAR_SUCCESS,
  GETAPRENDER_SUCCESS,
  GETLEER_SUCCESS,
  GETCANTAR_SUCCESS,
  GETSINGLECANTAR_SUCCESS,
  GETCONVERSAR_SUCCESS,
  GETSINGLECONVERSAR_SUCCESS,
  GETMINILESSON_SUCCESS,
  GETGROUPS_SUCCESS,
  ADDSCORE_SUCCESS,
  GETPRIVACY_SUCCESS,
  SETAPRENDER,
  GETALLGROUPS_SUCCESS,
  GETSETTING_SUCCESS,
} from '../constants';

const initialState = {
  loading: false,
  error: null,
  topics: [],
  childTopics: [],
  groups: [],
  aprenders: null,
  practicars: null,
  leers: null,
  conversars: null,
  cantars: null,
  lessons: null,
  score: null,
  privacy: null,
  gameData: null,
  rootTopic: null,
  groupTopic: null,
  cantar: null,
  conversar: null,
};

export default function students(state = initialState, action) {
  switch (action.type) {
    case STUDENTLIST_ATTEMPT:
      return { ...state, loading: true };
    case STUDENTLIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ADDSCORE_SUCCESS:
      return { ...state, loading: false, score: action.payload.savedScore };
    case FETCHALLCHILDTOPICS_SUCCESS:
      return { ...state, childTopics: action.payload.topics, loading: false };
    case FETCHCHILDTOPICS_SUCCESS:
      return {
        ...state,
        childTopics: action.payload.topics,
        rootTopic: action.payload.topic,
        groupTopic: action.payload.groupTopic,
        groups: action.payload.groups,
        loading: false,
      };
    case GETGROUPS_SUCCESS:
      return { ...state, groups: action.payload.groups, loading: false };
    case GETALLGROUPS_SUCCESS:
      return { ...state, groups: action.payload.groups, loading: false };
    case FETCHROOTTOPICS_SUCCESS:
      return { ...state, topics: action.payload.topics, loading: false };
    case GETPRACTICAR_SUCCESS:
      return {
        ...state,
        loading: false,
        practicars: action.payload.practicars,
      };
    case GETCANTAR_SUCCESS:
      return { ...state, loading: false, cantars: action.payload.cantars };
    case GETSINGLECANTAR_SUCCESS:
      return { ...state, loading: false, cantar: action.payload.cantar };
    case GETSINGLECONVERSAR_SUCCESS:
      return { ...state, loading: false, conversar: action.payload.conversar };
    case GETCONVERSAR_SUCCESS:
      return {
        ...state,
        loading: false,
        conversars: action.payload.conversars,
      };
    case GETLEER_SUCCESS:
      return { ...state, loading: false, leers: action.payload.leers };
    case GETAPRENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        aprenders: action.payload.aprenders,
        gameData: action.payload.aprenders,
      };
    case SETAPRENDER:
      return {
        ...state,
        gameData: action.payload,
      };
    case GETMINILESSON_SUCCESS:
      return { ...state, loading: false, lessons: action.payload.lessons };
    case GETPRIVACY_SUCCESS:
      return { ...state, loading: false, privacy: action.payload.setting };
    case GETSETTING_SUCCESS:
      return { ...state, loading: false, setting: action.payload.setting };
    default:
      return state;
  }
}
