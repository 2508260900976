/**
 * * Grade Reducer
 */

import {
  GRADEACTION_ATTEMPT,
  GRADELIST_ATTEMPT,
  GRADE_ERROR,
  FETCHALLGRADES_SUCCESS,
  FETCHGRADE_SUCCESS,
  ADDGRADE_SUCCESS,
  UPDATEGRADE_SUCCESS,
  DELETEGRADE_SUCCESS,
  SORTGRADES,
  BULKUPDATEGRADE_SUCCESS,
  BULKDELETEGRADE_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  grades: null,
  grade: null,
};

export default function grade(state = initialState, action) {
  switch (action.type) {
    case GRADELIST_ATTEMPT:
      return { ...state, fetching: true };
    case GRADEACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case GRADE_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLGRADES_SUCCESS:
      return { ...state, fetching: false, grades: action.payload.data };
    case SORTGRADES:
      return {
        ...state,
        grades: {
          ...state.grades,
          docs: action.payload,
        },
      };
    case FETCHGRADE_SUCCESS:
      return { ...state, fetching: false, grade: action.payload.grade };
    case ADDGRADE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        grades: {
          ...state.grades,
          docs: [...state.grades.docs, action.payload.grade],
        },
      };
    case UPDATEGRADE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        grades: {
          ...state.grades,
          docs: state.grades.docs.map((x) => {
            if (x._id === action.payload.grade._id) {
              return action.payload.grade;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEGRADE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        grades: {
          ...state.grades,
          docs: state.grades.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case BULKUPDATEGRADE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        grades: {
          ...state.grades,
          docs: state.grades.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETEGRADE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        grades: {
          ...state.grades,
          docs: state.grades.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
