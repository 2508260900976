import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllLicenses,
  addLicense,
  deleteLicense,
  updateLicense,
  archiveLicense,
  restoreLicense,
} from '../../redux/actions/license';
import { fetchHeadTeachers } from 'containers/SuperAdmin/redux/actions/user';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';

import dayjs from 'dayjs';
import LicenseDialog from '../../components/LicenseDialog';
import ChangeLicenseDialog from 'containers/SuperAdmin/components/ChangeLicenseDialog';
import RenewLicenseDialog from 'containers/SuperAdmin/components/RenewLicenseDialog';
import FilterBar from 'containers/SuperAdmin/components/FilterBar';
import { enqueueSnackbar } from 'redux/actions';
import { formatDuration } from 'containers/SuperAdmin/constants';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

let initialLicense = {
  category: 'Group',
  licenseType: 'School',
  studentLicenses: 650,
  teacherLicenses: 5,
  status: 'Approved',
  email: '',
  isDomainCompliant: true,
  licensee: '',
  validityPeriod: 1,
  validityPeriodUnit: 'Years',
  privateStudentLicenses: 0,
  freeTrial: false,
  teacherAccess: 'full',
};

export default function Licenses({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [archiveId, setArchiveId] = useState(null);
  const [archiveType, setArchiveType] = useState(null);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  const [restoreId, setRestoreId] = useState(null);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [newAccountDeleteId, setNewAccountDeleteId] = useState(null);
  const [openNewAccountDeleteDialog, setOpenNewAccountDeleteDialog] = useState(false);

  const [archiveExistingId, setArchiveExistingId] = useState(null);
  const [openArchiveExistingDialog, setOpenArchiveExistingDialog] = useState(false);

  const [view, setView] = useState('normal');
  const [searchString, setSearchString] = useState('');
  const [licenseData, setLicenseData] = useState(null);
  const [licenseDialog, setLicenseDialog] = useState(false);
  const [changeLicenseDialog, setChangeLicenseDialog] = useState(false);

  const [sort, setSort] = useState({ orderBy: 'issueDate', orderDir: 'desc' });

  const { licenses, fetching, actionLoading } = useSelector(({ admin }) => admin.license);

  const loadPage = (page, sortOptions = {}) => {
    let options = {
      page,
      limit: licenses.limit,
      ...sortOptions,
    };
    if (view === 'archived') {
      options = {
        ...options,
        isArchived: true,
      };
    }
    dispatch(fetchAllLicenses(options));
    setSort(sortOptions);
  };

  const standardActions = [
    {
      id: 'change',
      label: 'Change',
      action: (r) => {
        setLicenseData(r);
        setChangeLicenseDialog(true);
      },
      icon: <EditIcon />,
      disabled: () => false,
    },
    {
      id: 'archive',
      label: 'Archive',
      action: (r) => {
        if (r?.isGeneric) {
          dispatch(
            enqueueSnackbar({
              message: 'Cannot archive Generic Student License directly. Please delete it from the linked IT License',
              options: {
                variant: 'error',
              },
            }),
          );
          return;
        }
        if (!r?.activationDate && r?.history?.length === 0 && r?.archiveHistory?.length === 0) {
          setNewAccountDeleteId(r._id);
          setOpenNewAccountDeleteDialog(true);
        } else if (!r?.activationDate && r?.history?.length > 0 && r?.archiveHistory?.length > 0) {
          setArchiveExistingId(r._id);
          setOpenArchiveExistingDialog(true);
        } else {
          setArchiveId(r._id);
          setArchiveType(r.licenseType);
          setOpenArchiveDialog(true);
        }
      },
      icon: <ArchiveIcon />,
      disabled: () => false,
    },
  ];

  const archiveActions = [
    {
      id: 'restore',
      label: 'Restore',
      action: (r) => {
        if (
          r['archivedTeacherLicenses'] > r['teacherLicenses'] ||
          r['archivedStudentLicenses'] > r['studentLicenses']
        ) {
          dispatch(
            enqueueSnackbar({
              message: 'Cannot restore license with more teachers or students than the original license',
              options: {
                variant: 'error',
              },
            }),
          );
          return;
        } else {
          setRestoreId(r._id);
          setLicenseData(r);
          setOpenRestoreDialog(true);
        }
      },
      icon: <RestoreFromTrash />,
      disabled: () => false,
    },
    {
      id: 'delete',
      label: 'Permanently Delete',
      action: (r) => {
        setDeleteId(r._id);
        setDeleteType(r.licenseType);
        setOpenDeleteDialog(true);
      },
      icon: <DeleteIcon />,
      disabled: () => false,
    },
  ];

  const headCells = [
    {
      id: 'licensee',
      label: 'Name',
      formatter: (row) => row?.name,
    },
    {
      id: 'licenseType',
      label: 'License',
      formatter: (row) => row['licenseType'][0].toUpperCase() + row['licenseType'].slice(1),
    },
    {
      id: 'teacherLicenses',
      label: 'Teacher Qty',
      formatter: (row) => {
        if (row.isArchived && view === 'archived' && row.licenseType === 'School') {
          return `${row['archivedTeacherLicenses']}/${row['teacherLicenses']}`;
        } else {
          return `${row['consumedTeacherLicenses']}/${row['teacherLicenses']}`;
        }
      },
    },
    {
      id: 'studentLicenses',
      label: 'Student Qty',
      formatter: (row) => {
        if (row.isArchived && view === 'archived' && row.licenseType === 'School') {
          return `${row['archivedStudentLicenses']}/${row['studentLicenses']}`;
        } else {
          return `${row['consumedStudentLicenses']}/${row['studentLicenses']}`;
        }
      },
    },
    {
      id: 'validityPeriod',
      label: 'Duration',
      formatter: (r) => formatDuration(r.validityPeriod, r.validityPeriodUnit),
    },
    {
      id: 'activationDate',
      label: 'Activation Date',
      formatter: (r) =>
        r?.status === 'Deactivated'
          ? !r.activationDate && r?.history?.length === 0 && r?.archiveHistory?.length === 0
            ? 'Never Activated'
            : dayjs(r.activationDate).format('MMMM DD, YYYY')
          : r.activationDate
          ? dayjs(r.activationDate).format('MMMM DD, YYYY')
          : ['Pending', 'Approved'].includes(r.status)
          ? 'Pending Activation'
          : r.status,
    },
    {
      id: 'expiryDate',
      label: 'Expiry Date',
      formatter: (r) => {
        if (r.status === 'Deactivated') {
          if (!r.activationDate && r?.history?.length === 0 && r?.archiveHistory?.length === 0) {
            return 'N/A';
          }
          if (r.expiryDate) {
            return dayjs(r.expiryDate).format('MMMM DD, YYYY');
          }
          return 'N/A';
        }
        if (['Pending', 'Approved'].includes(r.status)) {
          return 'Pending Activation';
        }
        if (r.status === 'Activated') {
          return r.expiryDate ? dayjs(r.expiryDate).format('MMMM DD, YYYY') : 'N/A';
        }
        return r.status;
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      actions: view === 'normal' ? standardActions : archiveActions,
    },
  ];

  useEffect(() => {
    dispatch(fetchAllLicenses());
    dispatch(fetchHeadTeachers());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Loader fetching={fetching || actionLoading} />
      <PageHeader
        onSearch={(search) => dispatch(fetchAllLicenses({ search }))}
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setLicenseData(initialLicense);
                setLicenseDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        enableTopics={false}
        onSearch={setSearchString}
        toggle={view === 'archived'}
        onAction={() => {
          let params = {
            page: licenses?.page,
            limit: licenses?.limit,
            ...sort,
          };
          if (view === 'normal') {
            params = {
              ...params,
              isArchived: true,
            };
          }
          if (searchString !== '') {
            params = { ...params, search: searchString };
          }
          dispatch(fetchAllLicenses(params));
          setView(view === 'normal' ? 'archived' : 'normal');
        }}
      />
      {licenses && licenses.docs.length > 0 && (
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...licenses,
                docs: licenses?.docs
                  ?.filter((x) =>
                    searchString !== ''
                      ? x.name.toLowerCase().includes(searchString.toLowerCase()) ||
                        x.licenseType.toLowerCase().includes(searchString.toLowerCase())
                      : x,
                  )
                  .filter((x) => {
                    if (view === 'normal') {
                      return !x.isArchived;
                    } else {
                      return x.isArchived;
                    }
                  }),
                totalDocs: licenses?.docs
                  ?.filter((x) =>
                    searchString !== ''
                      ? x.name.toLowerCase().includes(searchString.toLowerCase()) ||
                        x.licenseType.toLowerCase().includes(searchString.toLowerCase())
                      : x,
                  )
                  .filter((x) => {
                    if (view === 'normal') {
                      return !x.isArchived;
                    } else {
                      return x.isArchived;
                    }
                  }).length,
              }}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(licenses.page, d)}
            />
          </Grid>
        </Grid>
      )}
      <LicenseDialog
        open={licenseDialog}
        data={initialLicense}
        handleClose={() => {
          setLicenseData(initialLicense);
          setLicenseDialog(false);
        }}
        handleConfirm={(data) => {
          dispatch(addLicense(data));
          setLicenseData(initialLicense);
          setLicenseDialog(false);
        }}
      />
      <ChangeLicenseDialog
        open={changeLicenseDialog}
        data={{
          ...licenseData,
          domain: licenseData?.email?.substring(licenseData?.email?.lastIndexOf('@') + 1),
        }}
        handleClose={() => {
          setLicenseData(initialLicense);
          setChangeLicenseDialog(false);
          dispatch(fetchHeadTeachers());
        }}
        handleConfirm={(data) => {
          dispatch(updateLicense(licenseData?._id, data));
          setLicenseData(initialLicense);
          setChangeLicenseDialog(false);
        }}
      />
      <RenewLicenseDialog
        open={openRestoreDialog}
        data={{
          ...licenseData,
          settings:
            licenseData?.history?.length === 0 ? 'reset' : licenseData?.licenseType === 'School' ? 'preserve' : 'reset',
        }}
        confirmText={'Restore Now'}
        currentDate={true}
        handleClose={() => {
          setOpenRestoreDialog(false);
          setLicenseData(initialLicense);
          setRestoreId(null);
        }}
        handleConfirm={(data) => {
          dispatch(restoreLicense(restoreId, data));
          setRestoreId(null);
          setOpenRestoreDialog(false);
          setLicenseData(initialLicense);
        }}
      />
      <DeleteDialog
        open={openArchiveDialog}
        content={
          archiveType === 'School'
            ? 'Archiving this active License will cause the License to expire & deactivate. It will also archive the School, Teachers, Classes, & Students belonging to this License. Are you sure you want to archive?'
            : archiveType === 'Teacher'
            ? 'Archiving this active License will delete its classes & cause the License to expire & deactivate. It will also unlink from any active Licenses it was linked to. (The linked Licenses will not be affected & will remain active.) Are you sure you want to archive?'
            : 'Archiving this active License will cause the License to expire & deactivate. It will also unlink from any active Licenses it was linked to. (The linked Licenses will not be affected & will remain active.) Are you sure you want to archive?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => {
          setOpenArchiveDialog(false);
          setLicenseData(initialLicense);
        }}
        handleConfirm={() => {
          dispatch(archiveLicense(archiveId));
          setArchiveId(null);
          setOpenArchiveDialog(false);
          setArchiveType(null);
        }}
      />
      <DeleteDialog
        open={openDeleteDialog}
        content={
          deleteType === 'School'
            ? 'Warning! Deleting this License will permanently delete its school & ALL Teachers, Classes, & Students belonging to this License. Are you sure you want to permanently delete? This action can’t be undone!'
            : deleteType === 'Teacher'
            ? 'Warning! Are you sure you want to permanently delete this License? No previously linked Students will be deleted; only this Teacher License & its direct records.'
            : 'Warning! Are you sure you want to permanently delete this License? No previously linked Teacher will be deleted; only this Student License & its direct records.'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setLicenseData(initialLicense);
          setDeleteId(null);
          setDeleteType(null);
        }}
        handleConfirm={() => {
          dispatch(deleteLicense(deleteId));
          setDeleteId(null);
          setOpenDeleteDialog(false);
          setDeleteType(null);
        }}
      />
      <DeleteDialog
        open={openNewAccountDeleteDialog}
        title=""
        content={
          'Warning! You are attempting to Archive a License that has not been fully activated. This cannot be archived & will instead be Permanently Deleted. Are you sure you want to Permanently Delete this License?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => {
          setOpenNewAccountDeleteDialog(false);
          setNewAccountDeleteId(null);
        }}
        handleConfirm={() => {
          dispatch(deleteLicense(newAccountDeleteId));
          setNewAccountDeleteId(null);
          setOpenNewAccountDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={openArchiveExistingDialog}
        title=""
        content={
          'You are attempting to Archive a renewed License that has not been fully reactivated. This License will return to the Archive & revert to its former state prior to being renewed. Are you sure you want to Archive this License?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => {
          setOpenArchiveExistingDialog(false);
          setArchiveExistingId(null);
        }}
        handleConfirm={() => {
          dispatch(archiveLicense(archiveExistingId));
          setOpenArchiveExistingDialog(false);
          setArchiveExistingId(null);
        }}
      />
    </div>
  );
}
