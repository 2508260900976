import { combineReducers } from 'redux';

import global from './global';
import { auth } from '../../containers/Auth/redux/reducers';
import { admin } from '../../containers/SuperAdmin/redux/reducers';
import students from '../students/reducers';
import teachers from '../teachers/reducers';
import schools from '../schools/reducers';

const rootReducer = combineReducers({
  global,
  auth,
  admin,
  students,
  teachers,
  schools,
});

export default rootReducer;
