import React, { useState } from 'react';
import { uploadContentFileDirectly } from '../../redux/actions/upload';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Photo } from '@material-ui/icons';

export default function UploadField({
  data,
  setData,
  label = 'Photo',
  icon = <Photo />,
  path = null,
}) {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const uploadFile = async (file) => {
    setUploading(true);
    const data = await uploadContentFileDirectly(file, setProgress);
    if (data) {
      const location = data;
      setData(location);
      setUploading(false);
    }
  };

  return (
    <Grid item xs={12} container direction={'row'} alignItems={'center'}>
      <input
        style={{ display: 'none' }}
        id={`image-${label}-file`}
        type='file'
        onChange={(e) => uploadFile(e.target.files[0])}
      />
      <TextField
        fullWidth
        margin='dense'
        id={`link${label}field`}
        label={label}
        type='text'
        variant={'outlined'}
        value={data}
        onChange={(e) => setData(e.target.value)}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <>
              {uploading && (
                <CircularProgress
                  size={24}
                  color='primary'
                  value={progress}
                  variant='determinate'
                />
              )}
              <InputAdornment position='end'>
                <label htmlFor={`image-${label}-file`}>
                  <IconButton
                    color='primary'
                    aria-label={`upload ${label}`}
                    size='small'
                    component='span'
                  >
                    {icon}
                  </IconButton>
                </label>
              </InputAdornment>
            </>
          ),
        }}
      />
    </Grid>
  );
}
