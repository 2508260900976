/**
 * * Topic Reducer
 */

import {
  TOPICACTION_ATTEMPT,
  TOPICLIST_ATTEMPT,
  TOPIC_ERROR,
  FETCHALLTOPICS_SUCCESS,
  FETCHPARENTTOPICS_SUCCESS,
  FETCHTOPIC_SUCCESS,
  ADDTOPIC_SUCCESS,
  UPDATETOPIC_SUCCESS,
  DELETETOPIC_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  topics: null,
  topic: null,
  parentTopics: null,
};

export default function topic(state = initialState, action) {
  switch (action.type) {
    case TOPICLIST_ATTEMPT:
      return { ...state, fetching: true };
    case TOPICACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case TOPIC_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLTOPICS_SUCCESS:
      return { ...state, fetching: false, topics: action.payload.data };
    case FETCHPARENTTOPICS_SUCCESS:
      return { ...state, fetching: false, parentTopics: action.payload.data };
    case FETCHTOPIC_SUCCESS:
      return { ...state, fetching: false, topic: action.payload.topic };
    case ADDTOPIC_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        topics: {
          ...state.topics,
          docs: [...state.topics.docs, action.payload.topic],
        },
      };
    case UPDATETOPIC_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        topics: {
          ...state.topics,
          docs: state.topics.docs.map((x) => {
            if (x._id === action.payload.topic._id) {
              return action.payload.topic;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETETOPIC_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        topics: {
          ...state.topics,
          docs: state.topics.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
