import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addManual,
  fetchMaxOrder,
  validateOrder,
} from '../../../redux/actions/manual';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Switch,
  Checkbox,
  Input,
  InputLabel,
} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';

import PageHeader from '../../../components/PageHeader';
import ContentEditor from '../../../components/ContentEditor';
import Loader from '../../../../../components/Loader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 650,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 15,
  },
  spacing: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function NewManual({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { manuals, actionLoading, maxOrder, orderNumberStatus } = useSelector(
    ({ admin }) => admin.manual
  );

  const [order, setOrder] = useState();

  useEffect(() => {
    dispatch(fetchMaxOrder({}));
    dispatch(
      validateOrder({
        status: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!maxOrder) return;
    if (maxOrder) {
      setOrder(maxOrder + 1);
    }
  }, [maxOrder]);

  const [form, setForm] = useState({
    title: '',
    content: '',
    haveChildren: false,
    parent: '',
    order: 0,
  });

  const handleSave = () => {
    let data = form;
    dispatch(addManual(data));
  };

  const validateOrderNumber = (order) => {
    dispatch(
      validateOrder({
        haveChildren: form.haveChildren,
        order: order,
      })
    );
  };

  return (
    <>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Grid
        container
        direction='column'
        className={classes.grid}
        justifyContent={'space-between'}
      >
        <Grid item sm className={classes.spacing}>
          <TextField
            autoFocus
            margin='dense'
            id='title'
            label='Name'
            type='text'
            fullWidth
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </Grid>
        <Grid item sm className={classes.spacing}>
          <TextField
            autoFocus
            margin='dense'
            id='order'
            label='Order'
            type='number'
            fullWidth
            error={orderNumberStatus}
            InputProps={{
              inputProps: { min: maxOrder + 1 },
            }}
            helperText={
              orderNumberStatus
                ? 'Order No Already Taken'
                : 'Order Number Available'
            }
            value={order}
            onChange={(e) => {
              validateOrderNumber(e.target.value);
              setOrder(e.target.value);
              setForm({ ...form, order: e.target.value });
            }}
          />
        </Grid>
        <Grid item sm className={classes.spacing}>
          <FormControlLabel
            control={
              <Switch
                checked={form.haveChildren}
                onChange={(e) => {
                  setForm({
                    ...form,
                    haveChildren: e.target.checked,
                    parent: '',
                  });
                  dispatch(fetchMaxOrder({ haveChildren: e.target.checked }));
                }}
                name='enableChild'
                color='primary'
              />
            }
            label='Have Sub Category'
          />
        </Grid>
        {form.haveChildren && (
          <Grid item sm className={classes.spacing}>
            {manuals && manuals.docs.length > 0 && (
              <FormControl className={classes.formControl}>
                <InputLabel id='parent-select'>Parent Manual</InputLabel>
                <Select
                  labelId='parent-select'
                  id='parent-checkbox'
                  value={form.parent}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      parent: e.target.value,
                    });
                  }}
                  input={<Input />}
                  renderValue={(selected) =>
                    manuals.docs.find((x) => selected === x._id).title
                  }
                  MenuProps={MenuProps}
                >
                  {manuals.docs
                    .filter((x) => x.haveChildren)
                    .map((manual) => (
                      <MenuItem key={manual._id} value={manual._id}>
                        <Checkbox checked={form.parent === manual._id} />
                        <ListItemText primary={manual.title} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        )}
        <Grid item sm className={classes.spacing}>
          <ContentEditor
            handleEditorChange={(e) => setForm({ ...form, content: e })}
            value={form.content}
            id='manual-editor'
          />
        </Grid>
        <Grid item sm>
          <Button
            variant='outlined'
            onClick={handleSave}
            disabled={orderNumberStatus}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
