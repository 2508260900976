export const VERIFYCODE_ATTEMPT = 'VERIFYCODE_ATTEMPT';
export const VERIFYCODE_SUCCESS = 'VERIFYCODE_SUCCESS';
export const VERIFYCODE_FAIL = 'VERIFYCODE_FAIL';

export const SETPWD_ATTEMPT = 'SETPWD_ATTEMPT';
export const SETPWD_SUCCESS = 'SETPWD_SUCCESS';
export const SETPWD_FAIL = 'SETPWD_FAIL';

export const FORGOTPWD_ATTEMPT = 'FORGOTPWD_ATTEMPT';
export const FORGOTPWD_SUCCESS = 'FORGOTPWD_SUCCESS';
export const FORGOTPWD_FAIL = 'FORGOTPWD_FAIL';

export const SIGNIN_ATTEMPT = 'SIGNIN_ATTEMPT';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const REGISTER_ATTEMPT = 'REGISTER_ATTEMPT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const RESETLINK_ATTEMPT = 'RESETLINK_ATTEMPT';
export const RESETLINK_SUCCESS = 'RESETLINK_SUCCESS';
export const RESETLINK_FAIL = 'RESETLINK_FAIL';
