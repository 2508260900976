import React from 'react';
import Grid from '@material-ui/core/Grid';
import { PinkButton } from 'components/Buttons';
import Heading from 'components/Heading';

export default function NotFoundNotice() {
  const contactUs = () => {
    window.open(
      'mailto:info@vivamosspanish.com?subject=Vivamos%20Spanish%20Account%20Reactivation&body=Hi%20Vivamos%20Spanish%20Support,%0A%0A'
    );
  };

  return (
    <Grid container justifyContent='center' direction='column'>
      <Heading variant='h3'>Sorry, this page is not valid. 😔</Heading>
      <Heading variant='h6' align='center'>
        If you believe that you have received this notice in error, <br />
        please contact our team to assist you.
      </Heading>
      <Grid
        item
        container
        justifyContent='center'
        sm={12}
        alignItems='center'
        style={{ marginTop: 10 }}
      >
        <Grid item sm={2} xs={6}>
          <PinkButton
            onClick={contactUs}
            version={2}
            customStyles={{ fontSize: 20, height: 60 }}
          >
            Contact
          </PinkButton>
        </Grid>
        <Grid item sm={2} xs={6}>
          <PinkButton
            onClick={() => window.open('/', '_self')}
            version={2}
            customStyles={{ fontSize: 20, height: 60 }}
          >
            Back to Home
          </PinkButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
