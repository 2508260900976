import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllGuides,
  deleteGuide,
  updateGuide,
  restoreGuide,
  archiveGuide,
  deleteAllGuides,
} from 'containers/SuperAdmin/redux/actions/guide';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputField from 'containers/SuperAdmin/components/InputField';
import FilterBar from '../../components/FilterBar';
import { ImageDialog } from 'containers/SuperAdmin/components/ImageDialog';
import { ImagesDialog } from 'containers/SuperAdmin/components/ImagesDialog';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Guides({ titleName, id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { guides, fetching, actionLoading } = useSelector(
    ({ admin }) => admin.guide
  );

  const [view, setView] = useState('normal');
  const [searchString, setSearchString] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [nameId, setNameId] = useState(null);
  const [name, setName] = useState(null);
  const [guideId, setGuideId] = useState(null);
  const [guideIdImages, setGuideIdImages] = useState(null);
  const [framework, setFramework] = useState('');
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [content, setContent] = useState([]);

  const [sort, setSort] = useState({ orderBy: 'createdAt', orderDir: 'desc' });

  const loadPage = (page, sortOptions) => {
    let update = {
      page,
      limit: guides.limit,
      ...sortOptions,
    };
    if (searchString !== '') {
      update.search = searchString;
    }
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(fetchAllGuides(update));
    setSort(sortOptions);
  };

  const headCells = [
    {
      id: 'title',
      label: 'Title',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {nameId === r._id ? (
            <InputField
              initialValue={r.title}
              field={name}
              setField={setName}
              onCancel={() => {
                setName(null);
                setNameId(null);
              }}
              onSave={(d) => dispatch(updateGuide(r._id, { title: d }))}
            />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: 15 }}>{r.title}</span>
              <IconButton
                onClick={() => {
                  setName(r.title);
                  setNameId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </div>
          )}
        </div>
      ),
    },
    {
      id: 'framework',
      label: 'Framework',
      formatter: (r) => (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setFramework(r.framework);
            setGuideId(r._id);
          }}
        >
          {r.framework && (
            <img
              src={r.framework}
              style={{ width: 'auto', height: 32, objectFit: 'contain' }}
              alt={r._id + 'lesson'}
            />
          )}
        </div>
      ),
    },
    {
      id: 'content',
      label: 'Guide Pages',
      formatter: (r) => (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setContent(r.content);
            setGuideIdImages(r._id);
          }}
        >
          {r.content[0] && (
            <img
              src={r.content[0]}
              style={{ width: 'auto', height: 32, objectFit: 'contain' }}
              alt={r._id + 'content'}
            />
          )}
        </div>
      ),
    },
    {
      id: 'contentLength',
      label: 'Total Pages',
      formatter: (r) => r.content.length,
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/guides/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(restoreGuide(r._id));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllGuides());
  }, [dispatch]);

  const handleSearch = (d) => {
    setSearchString(d);
    let update = {
      page: guides.page,
      limit: guides.limit,
      search: d,
    };
    if (view === 'archived') {
      update = { ...update, isArchived: true };
    }
    dispatch(fetchAllGuides(update));
  };

  const handleAction = () => {
    if (view === 'normal') {
      dispatch(fetchAllGuides({ isArchived: true }));
    } else {
      dispatch(
        fetchAllGuides({
          page: guides.page,
          limit: guides.limit,
        })
      );
    }
    setView(view === 'normal' ? 'archived' : 'normal');
  };

  return (
    <div className={classes.root}>
      <Loader fetching={fetching || actionLoading} />
      <PageHeader
        disableSearch
        title={titleName}
        options={
          <>
            <SettingDialog id={id} name={titleName} />
            <Button
              onClick={() => setDeleteAllDialog(true)}
              variant='contained'
              color='secondary'
            >
              Delete All
            </Button>
            <IconButton
              onClick={() => {
                history.push('/superadmin/guides/new');
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        toggle={view === 'archived'}
        onSearch={handleSearch}
        enableTopics={false}
        onAction={handleAction}
      />
      {guides && guides.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...guides,
                docs: guides.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
                totalDocs: guides.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ).length,
              }}
              headCells={headCells}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handlePage={loadPage}
              handleOrder={(d) => loadPage(guides.page, d)}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        content={
          view === 'normal'
            ? 'This action will archive this guide?'
            : 'This action will permanently delete this guide?'
        }
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteGuide(deleteId));
          } else {
            dispatch(archiveGuide(deleteId));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={deleteAllDialog}
        handleClose={() => setDeleteAllDialog(false)}
        handleConfirm={() => {
          dispatch(deleteAllGuides());
          setDeleteAllDialog(false);
        }}
      />
      <ImageDialog
        title={'Upload Framework'}
        editable
        open={Boolean(guideId)}
        initial={framework}
        onClose={() => setGuideId(null)}
        onResult={(d) => {
          dispatch(updateGuide(guideId, { framework: d }));
          setGuideId(null);
          setFramework('');
        }}
      />
      <ImagesDialog
        title={'Upload Guide Images'}
        editable
        open={Boolean(guideIdImages)}
        initial={content}
        onClose={() => setGuideIdImages(null)}
        onResult={(d) => {
          dispatch(updateGuide(guideIdImages, { content: d }));
          setGuideIdImages(null);
          setContent([]);
        }}
      />
    </div>
  );
}
