/**
 * * School Reducer
 */

import {
  SCHOOLACTION_ATTEMPT,
  SCHOOLLIST_ATTEMPT,
  SCHOOL_ERROR,
  GETSCHOOLTEACHERS,
  GETSCHOOLARCHIVEDTEACHERS,
  BULKSENDACTIVATION_TEACHER,
  DELETESCHOOLTEACHER,
  UPDATESCHOOLTEACHER,
  RESTORESCHOOLTEACHER,
  UPDATETEACHERSTATUS,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  message: null,
  status: null,
  teachers: [],
  archivedTeachers: [],
};

export default function schools(state = initialState, action) {
  switch (action.type) {
    case SCHOOLLIST_ATTEMPT:
      return {
        ...state,
        listLoading: true,
        status: null,
        message: null,
        error: null,
      };
    case SCHOOLACTION_ATTEMPT:
      return {
        ...state,
        actionLoading: true,
        status: null,
        message: null,
        error: null,
      };
    case SCHOOL_ERROR:
      return {
        ...state,
        listLoading: false,
        actionLoading: false,
        error: action.payload,
      };
    case GETSCHOOLTEACHERS:
      return { ...state, listLoading: false, teachers: action.payload };
    case GETSCHOOLARCHIVEDTEACHERS:
      return { ...state, listLoading: false, archivedTeachers: action.payload };
    case BULKSENDACTIVATION_TEACHER:
      return {
        ...state,
        actionLoading: false,
        teachers: [...state.teachers, ...action.payload.users],
      };
    case UPDATESCHOOLTEACHER:
      return {
        ...state,
        actionLoading: false,
        teachers: state.teachers?.map((x) =>
          x._id === action.payload.id ? action.payload.teacher : x
        ),
        archivedTeachers: state.archivedTeachers?.map((x) =>
          x._id === action.payload.id ? action.payload.teacher : x
        ),
      };
    case UPDATETEACHERSTATUS:
      return {
        ...state,
        actionLoading: false,
        teachers: state.teachers?.map((x) =>
          x._id === action.payload.id
            ? {
                ...x,
                ...action.payload.update,
              }
            : x
        ),
      };
    case RESTORESCHOOLTEACHER:
      return {
        ...state,
        actionLoading: false,
        teachers: [...state.teachers, action.payload.teacher],
        archivedTeachers: state.archivedTeachers?.filter(
          (x) => x._id !== action.payload.id
        ),
      };
    case DELETESCHOOLTEACHER:
      return {
        ...state,
        actionLoading: false,
        teachers: state.teachers.filter((x) => x._id !== action.payload.id),
        archivedTeachers: state.archivedTeachers?.filter(
          (x) => x._id !== action.payload.id
        ),
      };
    default:
      return state;
  }
}
