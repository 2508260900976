export const TEACHERS_ATTEMPT = 'TEACHERS_ATTEMPT';
export const TEACHERS_ERROR = 'TEACHERS_ERROR';

export const GETARCHIVEDSTUDENTS = 'GETARCHIVEDSTUDENTS';
export const GETSTUDENTSBYGRADE_SUCCESS = 'GETSTUDENTSBYGRADE_SUCCESS';
export const GETREPORT = 'GETREPORT';
export const GENERATEREPORT = 'GENERATEREPORT';
export const ADDSTUDENTBYGRADE_SUCCESS = 'ADDSTUDENTBYGRADE_SUCCESS';
export const UPDATESTUDENTBYGRADE_SUCCESS = 'UPDATESTUDENTBYGRADE_SUCCESS';
export const DELETESTUDENTBYGRADE_SUCCESS = 'DELETESTUDENTBYGRADE_SUCCESS';
export const ARCHIVETEACHERSTUDENTS_SUCCESS = 'ARCHIVETEACHERSTUDENTS_SUCCESS';
export const RESTOREGROUPSTUDENT = 'RESTOREGROUPSTUDENT';
export const RESTOREGROUPSTUDENTS = 'RESTOREGROUPSTUDENTS';
export const DELETEGROUPSTUDENT = 'DELETEGROUPSTUDENT';
export const DELETEGROUPSTUDENTS = 'DELETEGROUPSTUDENTS';
export const GETTEACHERGRADES_SUCCESS = 'GETTEACHERGRADE_SUCCESS';
export const GETTEACHERGRADEORDER_SUCCESS = 'GETTEACHERGRADEORDER_SUCCESS';
export const CREATETEACHERGRADE_SUCCESS = 'CREATETEACHERGRADE_SUCCESS';
export const UPDATETEACHERGRADE_SUCCESS = 'UPDATETEACHERGRADE_SUCCESS';
export const DELETETEACHERGRADE_SUCCESS = 'DELETETEACHERGRADE_SUCCESS';
export const GETGUIDES_SUCCESS = 'GETGUIDES_SUCCESS';
export const GETGUIDE_SUCCESS = 'GETGUIDE_SUCCESS';
export const GETGUIDEPAGE_SUCCESS = 'GETGUIDEPAGE_SUCCESS';
export const GETWORKBOOKS_SUCCESS = 'GETWORKBOOKS_SUCCESS';
export const GETWORKBOOK_SUCCESS = 'GETWORKBOOK_SUCCESS';
export const GETWORKBOOKPAGE_SUCCESS = 'GETWORKBOOKPAGE_SUCCESS';
export const GETMANUALCONTENT_SUCCESS = 'GETMANUALCONTENT_SUCCESS';
export const GETMANUALS_SUCCESS = 'GETMANUALS_SUCCESS';
export const FINDUSERNAME_SUCCESS = 'FINDUSERNAME_SUCCESS';
export const RESETUSERNAME_SUCCESS = 'RESETUSERNAME_SUCCESS';
export const FETCHRESOURCESBYTOPIC = 'FETCHRESOURCESBYTOPIC';
export const FETCHVIDEOSBYCATEGORY = 'FETCHVIDEOSBYCATEGORY';
export const GETACTIVETEACHERS = 'GETACTIVETEACHERS';
export const GETALLGRADES = 'GETALLGRADES';
export const GETUSERNAMES = 'GETUSERNAMES';
export const IMPORTSTUDENTS = 'IMPORTSTUDENTS';
export const GETUSERNAMES_ATTEMPT = 'GETUSERNAMES_ATTEMPT';
