import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllUsers,
  addUser,
  deleteUser,
  updateUser,
  sortUsers,
  archiveUser,
  restoreUser,
} from '../../redux/actions/user';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';

import UserDialog from '../../components/UserDialog';
import FilterBar from 'containers/SuperAdmin/components/FilterBar';
import { Button, Chip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { green, yellow } from '@mui/material/colors';
import { enqueueSnackbar } from 'redux/actions';
import { fetchLicenses } from 'containers/SuperAdmin/redux/actions/license';
import { disabledSchoolFields } from 'containers/SuperAdmin/constants';
import { useQuery } from 'constants';
import RestoreTeacherDialog from 'containers/SuperAdmin/components/RestoreTeacherDialog';
import AccountStatus from 'components/AccountStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullChip: {
    textTransform: 'capitalize',
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  limitedChip: {
    textTransform: 'capitalize',
    backgroundColor: yellow[500],
    color: theme.palette.getContrastText(yellow[500]),
  },
}));

let initialUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  school: '',
  access: 'teacher',
  accessLevel: 'full',
  status: 'Activating',
};

export default function Teachers({ name }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isArchived = useQuery('isArchived') === 'true';
  const { schoolId } = useParams();

  const [archiveId, setArchiveId] = useState(null);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  const [restoreId, setRestoreId] = useState(null);
  const [restoreEmail, setRestoreEmail] = useState(null);
  const [restoreName, setRestoreName] = useState(null);
  const [restoreSchool, setRestoreSchool] = useState(null);
  const [restoring, setRestoring] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [newAccountDeleteId, setNewAccountDeleteId] = useState(null);
  const [openNewAccountDeleteDialog, setOpenNewAccountDeleteDialog] =
    useState(false);

  const [existingArchiveId, setExistingArchiveId] = useState(null);
  const [openExistingArchiveDialog, setOpenExistingArchiveDialog] =
    useState(false);

  const [userData, setUserData] = useState(null);
  const [userDialog, setUserDialog] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [view, setView] = useState(isArchived ? 'archived' : 'normal');
  const [updating, setUpdating] = useState(false);
  const [school, setSchool] = useState(null);

  const [sort, setSort] = useState({ orderBy: 'fullName', orderDir: 'asc' });

  const { users, fetching } = useSelector(({ admin }) => admin.users);
  const { licenseData } = useSelector(({ admin }) => admin.license);

  const loadPage = (page, sortOptions = {}) => {
    let options = {
      page,
      limit: users.limit,
      access: 'teacher',
      ...sortOptions,
    };
    if (schoolId) {
      options.school = schoolId;
    }
    if (view === 'archived') {
      options.isArchived = true;
    }
    dispatch(
      sortUsers(sortOptions?.orderBy, sortOptions?.orderDir, users?.docs)
    );
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'fullName', label: 'Name' },
    {
      id: 'accessLevel',
      label: 'Teacher Access',
      formatter: (row) => (
        <Chip
          label={row.accessLevel}
          className={classes[`${row.accessLevel}Chip`]}
        />
      ),
    },
    {
      id: 'classCount',
      label: 'Classes',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() => {
            if (view === 'archived') {
              history.push(
                `/superadmin/teachers/${row._id}/classes?isArchived=true`,
                { from: '/superadmin/teachers?isArchived=true' }
              );
            } else {
              history.push('/superadmin/teachers/' + row._id + '/classes');
            }
          }}
        >
          {view === 'archived' ? row.archivedClassCount : row.classCount}
        </Button>
      ),
    },
    {
      id: 'studentCount',
      label: 'Students',
      formatter: (row) => (
        <Button
          color='primary'
          size='small'
          style={{ textTransform: 'none' }}
          onClick={() => {
            if (view === 'archived') {
              history.push(
                `/superadmin/teachers/${row._id}/students?isArchived=true`,
                { from: '/superadmin/teachers?isArchived=true' }
              );
            } else {
              history.push('/superadmin/teachers/' + row._id + '/students');
            }
          }}
        >
          {view === 'archived' ? row.archivedStudentCount : row.studentCount}
        </Button>
      ),
    },
    {
      id: 'licenseType',
      label: 'School',
    },
    {
      id: 'status',
      label: 'Account Status',
      align: 'center',
      formatter: (row) => <AccountStatus status={row.status} />,
    },
    {
      id: 'teacherType',
      label: 'Teacher Type',
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  setUserData({ ...r, school: r.school?._id });
                  setEditUser(true);
                  setUserDialog(true);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  if (r.isHeadTeacher) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'You are attempting to archive a Head Teacher. You must first assign a new Head Teacher in the Group License before you can archive this individual.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else if (!r.school) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'You are attempting to archive an Individual Teacher. You can perform this action only from Licenses Page.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    if (!r.isVerified) {
                      setNewAccountDeleteId(r._id);
                      setOpenNewAccountDeleteDialog(true);
                    } else if (
                      r?.resetId &&
                      r?.resetExpiryDate &&
                      r?.isLogOutRequired
                    ) {
                      setExistingArchiveId(r._id);
                      setOpenExistingArchiveDialog(true);
                    } else {
                      setArchiveId(r._id);
                      setOpenArchiveDialog(true);
                    }
                  }
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  if (r.isHeadTeacher) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'You are attempting to restore a Head Teacher. You must restore Head Teacher only by restoring the License first.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else if (!r.school) {
                    dispatch(
                      enqueueSnackbar({
                        message:
                          'You are attempting to restore an Individual Teacher. You can perform this action only from Licenses Page.',
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'error',
                        },
                      })
                    );
                  } else {
                    const license = licenseData?.find(
                      (x) => x.licensee === r.school?._id
                    );
                    if (
                      license?.isArchived &&
                      license?.status === 'Deactivated'
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'This Team Teacher cannot be restored because the License they belong to is inactive. Please reactivate their License first.',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                    } else if (
                      license?.consumedTeacherLicenses ===
                      license?.teacherLicenses
                    ) {
                      dispatch(
                        enqueueSnackbar({
                          message:
                            'The active Teacher Pool of this License is full. Please Archive active Teachers or allot space for additional Teacher Accounts within the License',
                          options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                          },
                        })
                      );
                    } else {
                      setRestoreId(r._id);
                      setRestoreEmail(r.email);
                      setRestoreName(r.fullName);
                      setRestoreSchool(r.school);
                      setOpenRestoreDialog(true);
                    }
                  }
                },
                icon: <RestoreFromTrash />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: (r) => r.isSuperAdmin,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchLicenses());
    if (schoolId !== undefined || schoolId !== null) {
      dispatch(
        fetchAllUsers({ access: 'teacher', school: schoolId, isArchived })
      );
    } else {
      dispatch(fetchAllUsers({ access: 'teacher', isArchived }));
      if (isArchived) {
        setView('archived');
      }
    }
  }, [dispatch, schoolId, isArchived]);

  const onCloseUserDialog = () => {
    setUserData(initialUser);
    setUserDialog(false);
  };

  const onConfirmUser = (data, emailTrigger = false) => {
    if (editUser) {
      const id = userData?._id;
      let update = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        accessLevel: data?.accessLevel,
        email: data?.email,
        school: data?.school,
        status: data?.status,
        homeAddress: data?.homeAddress,
        phoneNumber: data?.phoneNumber,
        schoolName: data?.schoolName,
        schoolAddress: data?.schoolAddress,
        emailTrigger,
      };
      setUpdating(true);
      dispatch(
        updateUser(id, update, () => {
          setUserData(initialUser);
          setUserDialog(false);
          setUpdating(false);
        })
      );
    } else {
      dispatch(addUser(data));
      setUserData(initialUser);
      setUserDialog(false);
    }
  };

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setEditUser(false);
                setUserData(initialUser);
                setUserDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
        onClearFilter={() => history.push('/superadmin/schools')}
      />
      <FilterBar
        enableTopics={false}
        enableGlobal
        selectedPage='Teachers'
        onSearch={(d) => {
          let update = {
            access: 'teacher',
            search: d,
          };
          if (schoolId) {
            update.school = schoolId;
          }
          if (view === 'archived') {
            update.isArchived = true;
          }
          dispatch(fetchAllUsers(update));
        }}
        enableSchool
        selectedSchool={school}
        onSchoolSelect={(d) => {
          setSchool(d);
          let options = {
            page: users.page,
            limit: users.limit,
            access: 'teacher',
            isArchived: view !== 'normal',
          };
          if (d) {
            options.school = d;
          }
          dispatch(fetchAllUsers(options));
        }}
        onAction={() => {
          let options = {
            page: users.page,
            limit: users.limit,
            access: 'teacher',
          };
          if (schoolId) {
            options.school = schoolId;
          }
          if (view === 'normal') {
            dispatch(
              fetchAllUsers({
                ...options,
                isArchived: true,
                page: 1,
                limit: 25,
              })
            );
          } else {
            dispatch(fetchAllUsers(options));
          }
          setView(view === 'normal' ? 'archived' : 'normal');
        }}
        toggle={view === 'archived'}
      />
      {users && users.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...users,
                docs: users.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
                totalDocs: users.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ).length,
              }}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(users.page, d)}
              standardPagination
            />
          </Grid>
        </Grid>
      )}
      {userData && (
        <UserDialog
          open={userDialog}
          edit={editUser}
          data={userData}
          prefix={
            editUser
              ? userData?.isHeadTeacher
                ? 'Head'
                : userData?.school
                ? 'Team'
                : 'Individual'
              : 'Team'
          }
          suffix={'to an Active Group License'}
          disableDone={true}
          showAcceptanceHistory={editUser}
          disabledFields={
            !editUser
              ? disabledSchoolFields
              : userData?.school
              ? disabledSchoolFields
              : []
          }
          handleClose={onCloseUserDialog}
          handleConfirm={onConfirmUser}
          sending={updating}
        />
      )}
      <DeleteDialog
        open={openArchiveDialog}
        content={
          'Archiving this Teacher will also archive all of their Classes & Students. Are you sure you want to archive?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => setOpenArchiveDialog(false)}
        handleConfirm={() => {
          dispatch(archiveUser(archiveId));
          setArchiveId(null);
          setOpenArchiveDialog(false);
        }}
      />
      <RestoreTeacherDialog
        open={openRestoreDialog}
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => setOpenRestoreDialog(false)}
        handleConfirm={(data) => {
          setRestoring(true);
          dispatch(
            restoreUser(restoreId, data, () => {
              setRestoreId(null);
              setRestoreEmail(null);
              setRestoreName(null);
              setRestoring(false);
              setOpenRestoreDialog(false);
            })
          );
        }}
        initial={restoreEmail}
        school={restoreSchool}
        name={restoreName}
        saving={restoring}
      />
      <DeleteDialog
        open={openDeleteDialog}
        content={
          'Warning! Deleting this Teacher will also permanently delete the classes that belong to them. Students within those classes will remain archived & accessible, but will need to be reassigned to a new Class/Teacher in order to be restored. Are you sure you want to permanently delete this Teacher & their Classes?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteUser(deleteId));
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={openNewAccountDeleteDialog}
        title=''
        content={
          'Warning! You are attempting to Archive an Account that has not been fully activated. This cannot be archived & will instead be Permanently Deleted. Are you sure you want to Permanently Delete this Account?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => setOpenNewAccountDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteUser(newAccountDeleteId));
          setNewAccountDeleteId(null);
          setOpenNewAccountDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={openExistingArchiveDialog}
        title=''
        content={
          'You are attempting to Archive a restored Account that has not been fully reactivated. This Account will return to the Archive & revert to its former state prior to being restored. Are you sure you want to Archive this Account?'
        }
        cancelLabel={'No'}
        confirmLabel={'Yes'}
        handleClose={() => setOpenExistingArchiveDialog(false)}
        handleConfirm={() => {
          dispatch(archiveUser(existingArchiveId));
          setExistingArchiveId(null);
          setOpenExistingArchiveDialog(false);
        }}
      />
    </div>
  );
}
