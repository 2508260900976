import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
}));

export default function TablePaginationActions(props) {
  const classes = useStyles1();
  const {
    count,
    page,
    totalPages,
    limit,
    hasNext,
    hasPrev,
    nextPage,
    prevPage,
    onChangePage,
  } = props;

  const lastPage = Math.ceil(count / limit);

  const handleFirstPageButtonClick = () => {
    onChangePage(1);
  };

  const handleBackButtonClick = () => {
    onChangePage(prevPage);
  };

  const handleNextButtonClick = () => {
    onChangePage(nextPage);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(lastPage);
  };

  return (
    <Grid
      className={classes.root}
      container
      direction='row'
      justifyContent='flex-end'
      alignItems='center'
    >
      <Typography>
        {page} of {totalPages}
      </Typography>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!hasPrev}
        aria-label='first page'
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={!hasPrev}
        aria-label='previous page'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!hasNext}
        aria-label='next page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!hasNext}
        aria-label='last page'
      >
        <LastPageIcon />
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
