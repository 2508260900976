import axios from 'axios';
import { BASE_URL } from '../constants';

const errorHandler = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error.response);
};

const successHandler = (response) => {
  return response;
};

const configHandler = (config) => {
  config.baseURL = BASE_URL;
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = '';
  }
  return config;
};

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  (config) => configHandler(config),
  (error) => errorHandler(error)
);

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axios;
