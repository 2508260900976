import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParentTopics } from '../../redux/actions/topic';
import { Input, InputLabel, makeStyles } from '@material-ui/core';
import { MusicNote } from '@material-ui/icons';

import UploadField from '../UploadField';
import GameRounds from '../GameRounds';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  spacer: {
    marginTop: 20,
  },
  spacing: {
    margin: theme.spacing(2, 0),
  },
  switchSpacing: {
    margin: theme.spacing(1, 0),
  },
}));

export default function TopicDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  setData,
  edit = false,
}) {
  const dispatch = useDispatch();
  const { parentTopics } = useSelector(({ admin }) => admin.topic);
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchParentTopics());
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='topic-dialog'
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id='topic-dialog'>Topic</DialogTitle>
      <DialogContent>
        {data.buttonType === 'picture' && (
          <UploadField
            data={data.image}
            setData={(e) => setData({ ...data, image: e })}
            label={'Button Icon'}
          />
        )}
        <UploadField
          data={data.sound}
          setData={(e) => setData({ ...data, sound: e })}
          label={'Topic Sound'}
          icon={<MusicNote />}
        />
        <TextField
          margin='dense'
          id='name'
          label='Name'
          type='text'
          fullWidth
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <TextField
          margin='dense'
          id='spanishName'
          label='Spanish Name'
          type='text'
          fullWidth
          value={data.spanishName}
          onChange={(e) => setData({ ...data, spanishName: e.target.value })}
        />
        <TextField
          margin='dense'
          id='order'
          label='Order'
          type='order'
          fullWidth
          value={data.order}
          onChange={(e) =>
            setData({
              ...data,
              order: e.target.value,
            })
          }
        />
        {!data.isChild && (
          <TextField
            margin='dense'
            id='desc'
            label='Button Subtitle'
            type='text'
            fullWidth
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={data.isChild}
              onChange={(e) => setData({ ...data, isChild: e.target.checked })}
              name='isChild'
              color='primary'
            />
          }
          label='Child Topic'
          className={classes.switchSpacing}
        />
        {data.isChild && (
          <TextField
            margin='dense'
            id='subTitle'
            label='Subtitle for the topic'
            type='text'
            fullWidth
            multiline
            rows={2}
            value={data.subTitle}
            onChange={(e) => setData({ ...data, subTitle: e.target.value })}
          />
        )}
        {data.isChild && (
          <TextField
            margin='dense'
            id='heading'
            label='Category Title Above Button'
            type='text'
            fullWidth
            value={data.heading}
            onChange={(e) => setData({ ...data, heading: e.target.value })}
          />
        )}
        {parentTopics && parentTopics.docs.length > 0 && data.isChild && (
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-mutiple-checkbox-label'>
              Parent Topic
            </InputLabel>
            <Select
              labelId='demo-mutiple-checkbox-label'
              id='demo-mutiple-checkbox'
              value={data.parent}
              onChange={(e) => setData({ ...data, parent: e.target.value })}
              input={<Input />}
              renderValue={(selected) =>
                parentTopics.docs
                  .filter((x) => selected === x._id)
                  .map((x) => x.name)
                  .toString()
              }
              MenuProps={MenuProps}
            >
              {parentTopics.docs.map((topic) => (
                <MenuItem key={topic._id} value={topic._id}>
                  <Checkbox checked={data.parent === topic._id} />
                  <ListItemText primary={topic.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!data.isChild && data.enableContentOnly && (
          <Grid item sm>
            <TextField
              margin='dense'
              id='textbookStartpage'
              label='Lesson Start Page for Slider'
              type='number'
              fullWidth
              value={data.textbookStartPage}
              onChange={(e) =>
                setData({
                  ...data,
                  textbookStartPage: e.target.value,
                })
              }
            />
          </Grid>
        )}
        {!data.isChild && (
          <Grid item sm>
            <FormControlLabel
              control={
                <Switch
                  checked={data.enableContentOnly}
                  onChange={(e) =>
                    setData({
                      ...data,
                      enableContentOnly: e.target.checked,
                    })
                  }
                  name='enableContentOnly'
                  color='primary'
                />
              }
              label='Enable Content Only'
              className={classes.switchSpacing}
            />
          </Grid>
        )}
        {data.isChild && (
          <Grid item sm>
            <FormControlLabel
              control={
                <Switch
                  checked={data.hasGroup}
                  onChange={(e) =>
                    setData({
                      ...data,
                      hasGroup: e.target.checked,
                    })
                  }
                  name='hasGroup'
                  color='primary'
                />
              }
              label='Has Groups?'
            />
          </Grid>
        )}
        <Grid item container xs direction={'column'} style={{ marginTop: 15 }}>
          <Typography>Page Options</Typography>
          <TextField
            margin='dense'
            id='pages'
            label='Columns (Total 12)'
            type='number'
            min={1}
            fullWidth
            value={data.columns}
            onChange={(e) =>
              setData({
                ...data,
                columns: e.target.value,
              })
            }
          />
          <TextField
            margin='dense'
            id='pages'
            label='Old Content Columns (Total 12)'
            type='number'
            min={1}
            fullWidth
            value={data.oldColumns}
            onChange={(e) =>
              setData({
                ...data,
                oldColumns: e.target.value,
              })
            }
          />
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-mutiple-checkbox-label'>
              Button Type
            </InputLabel>
            <Select
              labelId='checkbox-label'
              id='button-checkbox'
              value={data.buttonType}
              onChange={(e) => setData({ ...data, buttonType: e.target.value })}
              input={<Input />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
            >
              {['square', 'round', 'picture'].map((t) => (
                <MenuItem key={t} value={t}>
                  <Checkbox checked={data.buttonType === t} />
                  <ListItemText
                    primary={t}
                    style={{ textTransform: 'capitalize' }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data.isChild && (
            <FormControl className={classes.formControl}>
              <InputLabel id='checkbox-label'>Background Variant</InputLabel>
              <Select
                labelId='checkbox-label'
                id='bg-checkbox'
                value={data.backgroundVariant}
                onChange={(e) =>
                  setData({ ...data, backgroundVariant: e.target.value })
                }
                input={<Input />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
              >
                {['v1', 'v2', 'v3', 'v4', 'v5', 'v6'].map((t) => (
                  <MenuItem key={t} value={t}>
                    <Checkbox checked={data.backgroundVariant === t} />
                    <ListItemText
                      primary={t}
                      style={{ textTransform: 'capitalize' }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <UploadField
            data={data.leftBackground}
            setData={(e) => setData({ ...data, leftBackground: e })}
            label={'Left Background'}
          />
          <UploadField
            data={data.rightBackground}
            setData={(e) => setData({ ...data, rightBackground: e })}
            label={'Right Background'}
          />
        </Grid>
        {!data.enableContentOnly && (
          <Grid item>
            <GameRounds
              data={data}
              handleUpdate={(type, value) =>
                setData({ ...data, [type]: value })
              }
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirm(data)}
          color='primary'
          variant='contained'
        >
          {edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
