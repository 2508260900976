import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllVideos,
  deleteVideo,
  updateVideo,
  setVideo,
  archiveVideo,
  restoreVideo,
  bulkArchiveVideo,
  bulkRestoreVideo,
  bulkDeleteVideo,
} from '../../redux/actions/video';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import InputField from 'containers/SuperAdmin/components/InputField';
import FilterBar from '../../components/FilterBar';

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Archive from '@material-ui/icons/Archive';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    margin: theme.spacing(1),
  },
}));

export default function Video({ titleName }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { videos, fetching, actionLoading } = useSelector(
    ({ admin }) => admin.video
  );

  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [order, setOrder] = useState(null);
  const [nameId, setNameId] = useState(null);
  const [name, setName] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [category, setCategory] = useState('');
  const [sort, setSort] = useState({ orderBy: 'title', order: 'desc' });

  const loadPage = (page, sortOptions = {}) => {
    let update = { page, limit: videos.limit, category, ...sortOptions };
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllVideos(update));
    setSort(sortOptions);
  };

  const headCells = [
    {
      id: 'checkbox',
      label: 'Select',
    },
    {
      id: 'title',
      label: 'Title',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {nameId === r._id ? (
            <InputField
              initialValue={r.title}
              field={name}
              setField={setName}
              onCancel={() => {
                setName(null);
                setNameId(null);
              }}
              onSave={(d) => dispatch(updateVideo(r._id, { title: d }))}
            />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: 15 }}>{r.title}</span>
              <IconButton
                onClick={() => {
                  setName(r.title);
                  setNameId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </div>
          )}
        </div>
      ),
    },
    {
      id: 'category',
      label: 'Category',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontWeight: 'bold' }}>{r.category.toUpperCase()}</span>
        </div>
      ),
    },
    {
      id: 'order',
      label: 'Order',
      formatter: (r) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {orderId === r._id ? (
            <InputField
              initialValue={r.order}
              field={order}
              setField={setOrder}
              onCancel={() => {
                setOrder(null);
                setOrderId(null);
              }}
              onSave={(d) => dispatch(updateVideo(r._id, { order: d }))}
            />
          ) : (
            <>
              <span>{r.order}</span>
              <IconButton
                onClick={() => {
                  setOrder(r.order);
                  setOrderId(r._id);
                }}
                size='small'
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/videos/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <Archive />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(restoreVideo(r._id));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  let tableProps = {
    headCells: headCells,
    orderField: sort.orderBy,
    orderDirection: sort.orderDir,
    view,
    handlePage: loadPage,
    handleOrder: (d) => loadPage(videos.page, d),
    handleBulk: (ids) =>
      view === 'normal'
        ? dispatch(bulkArchiveVideo(ids))
        : dispatch(bulkRestoreVideo(ids)),
    handleBulkDelete: (ids) => dispatch(bulkDeleteVideo(ids)),
  };

  if (category) {
    tableProps.handleRowOrder = (data) => {
      dispatch(setVideo(data));
    };
  }

  useEffect(() => {
    dispatch(fetchAllVideos());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Loader fetching={fetching || actionLoading} />
      <PageHeader
        disableSearch
        title={titleName}
        options={
          <>
            <IconButton
              onClick={() => {
                history.push('/superadmin/videos/new');
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        enableTopics={false}
        enableSection={true}
        selectedSection={category}
        onSectionSelect={(d) => {
          setCategory(d);
          let selectionQuery = {
            page: videos.page,
            limit: videos.limit,
            search: searchString,
          };
          if (d !== '') {
            selectionQuery = { ...selectionQuery, category: d };
          }
          if (view === 'archived') {
            selectionQuery.isArchived = true;
          }
          dispatch(fetchAllVideos(selectionQuery));
        }}
        onSearch={(d) => {
          setSearchString(d);
          let searchOptions = {
            page: videos.page,
            limit: videos.limit,
            search: d,
          };
          if (category !== '') {
            searchOptions = {
              ...searchOptions,
              category,
            };
          }
          if (view === 'archived') {
            searchOptions.isArchived = true;
          }
          dispatch(fetchAllVideos(searchOptions));
        }}
        onAction={() => {
          if (view === 'normal') {
            dispatch(
              fetchAllVideos({
                isArchived: true,
                search: searchString,
              })
            );
          } else {
            dispatch(
              fetchAllVideos({
                page: videos.page,
                limit: videos.limit,
                search: searchString,
              })
            );
          }
          setView(view === 'normal' ? 'archived' : 'normal');
        }}
        toggle={view === 'archived'}
      />
      <Grid container justifyContent='center' className={classes.alert}>
        <Alert variant='filled' color='info' icon={<InfoOutlined />}>
          The drag and drop will activate once you have selected a category
        </Alert>
      </Grid>
      {videos && videos.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...videos,
                docs: videos.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
              }}
              {...tableProps}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteVideo(deleteId));
          } else {
            dispatch(archiveVideo(deleteId));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
