import axios from '../../../../redux/apiCaller';
import { successAction, attemptAction, errorAction } from '../../../../constants';
import {
  LICENSEACTION_ATTEMPT,
  LICENSEELIST_ATTEMPT,
  LICENSELIST_ATTEMPT,
  LICENSE_ERROR,
  FETCHALLLICENSES_SUCCESS,
  FETCHLICENSE_SUCCESS,
  FETCHLICENSEES_SUCCESS,
  FETCHLICENSEE_SUCCESS,
  ADDLICENSE_SUCCESS,
  UPDATELICENSE_SUCCESS,
  DELETELICENSE_SUCCESS,
  FETCHLICENSES_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';
import dayJS from 'dayjs';
import { fetchHeadTeachers } from './user';

const licenseUrl = 'admin/licenses';
const licenseeUrl = 'admin/licensees';

export const fetchLicensees = (type) => {
  const thunk = async (dispatch) => {
    dispatch(attemptAction(LICENSEELIST_ATTEMPT));
    try {
      const { data } = await axios.get(`${licenseeUrl}`, { params: { type } });
      dispatch(successAction(FETCHLICENSEES_SUCCESS, data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchLicensee = (id, type) => {
  const thunk = async (dispatch) => {
    dispatch(attemptAction(LICENSEELIST_ATTEMPT));
    try {
      const { data } = await axios.get(`${licenseeUrl}/${id}`, {
        params: { type },
      });
      const result = { id, data: data?.data };
      dispatch(successAction(FETCHLICENSEE_SUCCESS, result));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchAllLicenses = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSELIST_ATTEMPT));
      const body = await axios.get(`${licenseUrl}`, { params });
      dispatch(successAction(FETCHALLLICENSES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchLicenses = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSELIST_ATTEMPT));
      const body = await axios.get(`${licenseUrl}/all`);
      dispatch(successAction(FETCHLICENSES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchLicense = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSELIST_ATTEMPT));
      const body = await axios.get(`${licenseUrl}/${id}`);
      dispatch(successAction(FETCHLICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const addLicense = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}`, data);
      dispatch(successAction(ADDLICENSE_SUCCESS, body.data));
      if (body.data.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.mailResult ? 'Mail Sent Successfully' : 'Mail Sending Failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: body.data.mailResult ? 'success' : 'error',
            },
          }),
        );
      }
      dispatch(fetchHeadTeachers());
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const updateLicense = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}`, data);
      dispatch(fetchAllLicenses());
      dispatch(fetchHeadTeachers());
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      dispatch(successAction(UPDATELICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
    }
  };
  return thunk;
};

export const archiveLicense = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/archive`);
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      dispatch(successAction(UPDATELICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
    }
  };
  return thunk;
};

export const restoreLicense = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/restore`, data);
      let updatedLicense = body.data;
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      dispatch(successAction(UPDATELICENSE_SUCCESS, updatedLicense));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
    }
  };
  return thunk;
};

export const renewLicense = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/renew`, data);
      let updatedLicense = body.data;
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      dispatch(successAction(UPDATELICENSE_SUCCESS, updatedLicense));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const changeHeadTeacher = (id, data, onEnd) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/changeHeadTeacher`, data);
      let updatedLicense = body.data?.license;
      if (updatedLicense?.activationDate) {
        updatedLicense.expiryDate = dayJS(updatedLicense.activationDate).add(
          updatedLicense.validityPeriod,
          updatedLicense.validityPeriodUnit,
        );
      }
      dispatch(fetchHeadTeachers());
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      dispatch(
        successAction(UPDATELICENSE_SUCCESS, {
          ...body.data,
          license: updatedLicense,
        }),
      );
      if (onEnd) {
        onEnd();
      }
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      if (onEnd) {
        onEnd();
      }
    }
  };
  return thunk;
};

export const resendLicenseLink = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/resend`, data);
      const result = body.data;
      if (result.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: result.mail ? 'Mail Sent Successfully' : 'Mail Sending Failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: result.mail ? 'success' : 'error',
            },
          }),
        );
      }
      dispatch(successAction(UPDATELICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
    }
  };
  return thunk;
};

export const extendLicense = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/extend`, data);
      dispatch(successAction(UPDATELICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const approveLicense = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.post(`${licenseUrl}/${id}/approval`);
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: body.data.status === 200 ? 'success' : 'error',
          },
        }),
      );
      dispatch(successAction(UPDATELICENSE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const deleteLicense = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LICENSEACTION_ATTEMPT));
      const body = await axios.delete(`${licenseUrl}/${id}`);
      dispatch(successAction(DELETELICENSE_SUCCESS, body.data));
      dispatch(
        enqueueSnackbar({
          message: body.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(errorAction(LICENSE_ERROR, err));
    }
  };
  return thunk;
};

export const removeLicenseFromTable = (id) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(DELETELICENSE_SUCCESS, { id }));
  };
  return thunk;
};
