import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, makeStyles } from '@material-ui/core';
import { USER_STATUS } from 'constants';

const useStyles = makeStyles((theme) => ({
  spacing: {
    margin: theme.spacing(2, 0),
  },
}));

const Licensee = ({
  id = null,
  license,
  isHeadTeacher = false,
  textProps = {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { headTeachers } = useSelector(({ admin }) => admin.users);
  const { licenseeByKey } = useSelector(({ admin }) => admin.license);

  const [licensee, setLicensee] = useState(null);
  const [loading, setLoading] = useState(true);

  const type = license?.licenseType;
  const status = license?.status;

  const getLicensee = useCallback(
    (id) => {
      if (isHeadTeacher && headTeachers) {
        const headTeacher = headTeachers?.find((x) => x.school === id);
        setLicensee(headTeacher);
        setLoading(false);
      } else {
        const d = licenseeByKey[id];
        if (
          (status === USER_STATUS.RESTORING ||
            status === USER_STATUS.RENEWING) &&
          !d?.school &&
          type === 'Student'
        ) {
          d.status = status;
        }
        setLicensee(d);
        setLoading(false);
      }
    },
    [isHeadTeacher, headTeachers, licenseeByKey, type, status]
  );

  useEffect(() => {
    if (!id) return;
    setLicensee(null);
    getLicensee(id);
  }, [id, dispatch, getLicensee]);

  const getStatus = (status) => {
    switch (status) {
      case USER_STATUS.ACTIVATING:
        if (type === 'Student') {
          return 'This is a brand new License that is pending activation. The student will receive the “Activation” Email.';
        }
        return 'This is a brand new License that is pending activation. The teacher will receive the “Activation” Email.';
      case USER_STATUS.RENEWING:
      case USER_STATUS.RESTORING:
        return 'This is a Restored License that is pending Reactivation. The License holder will receive the “Welcome Back” Email.';
      default:
        return '';
    }
  };

  if (loading && !licensee) {
    return (
      <Typography {...textProps}>
        <Skeleton variant='text' />
      </Typography>
    );
  }

  if (!licensee) {
    return (
      <Typography {...textProps}>
        {isHeadTeacher ? 'No Existing Head Teacher' : 'N/A'}
      </Typography>
    );
  }

  return (
    <Grid container>
      <Typography
        classNmae={classes.spacing}
        variant='caption'
        color='error'
        {...textProps}
      >
        {getStatus(licensee?.status)}
      </Typography>
    </Grid>
  );
};

export default React.memo(Licensee);
