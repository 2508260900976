import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { COLORS } from 'constants';

import Skeleton from '@material-ui/lab/Skeleton';
import { content } from 'containers/SuperAdmin/constants';
import PageHeader from 'containers/SuperAdmin/components/PageHeader';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  list: {
    overflowY: 'scroll',
    maxHeight: '90vh',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footer: {
    width: '100%',
    padding: theme.spacing(1.5),
    bottom: 0,
    position: 'fixed',
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  spacing: {
    margin: theme.spacing(2, 0),
  },
  searchbar: {
    padding: theme.spacing(2, 1),
  },
}));

const SearchBarLoader = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.searchbar}>
      <Skeleton variant='text' />
    </Paper>
  );
};

const TableRowsLoader = ({ rowsNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      <TableCell component='th' scope='row'>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
    </TableRow>
  ));
};

const TableHeadLoader = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
    </TableRow>
  );
};

const TableLoader = ({ rowsNum }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableHeadLoader />
        </TableHead>
        <TableBody>
          <TableRowsLoader rowsNum={rowsNum} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
      dark: COLORS.PRIMARY,
      light: '#000',
    },
    secondary: {
      main: COLORS.SECONDARY,
      dark: COLORS.SECONDARY,
    },
  },
  overrides: {
    MuiButton: {
      label: { textTransform: 'none' },
    },
  },
});

export default function LayoutSkeleton() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position='absolute'
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <Skeleton variant='circle' width={24} height={24} />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}
            >
              <Skeleton variant='text' />
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='logout'
              onClick={() => {
                window.location.href = '/home';
              }}
            >
              <Skeleton variant='circle' width={24} height={24} />
            </IconButton>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='logout'
              onClick={() => {
                localStorage.clear();
                history.push('/');
              }}
            >
              <Skeleton variant='circle' width={24} height={24} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {content?.length > 0 && (
          <Drawer
            variant='permanent'
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <img
                src='/logo.png'
                alt='logo'
                style={{
                  height: 50,
                  width: 'auto',
                  verticalAlign: 'middle',
                  padding: 5,
                  textAlign: 'start',
                }}
              />
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div className={classes.list}>
              {content?.map((item, itemIndex) => (
                <List
                  dense
                  key={itemIndex}
                  subheader={
                    open && (
                      <ListSubheader component='div'>
                        <Skeleton variant='text' />
                      </ListSubheader>
                    )
                  }
                >
                  <Divider />
                  {item.sections &&
                    item.sections.length > 0 &&
                    item.sections.map((section, sectionIndex) => (
                      <ListItem button key={sectionIndex}>
                        <ListItemIcon>
                          <Skeleton variant='circle' width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText>
                          <Skeleton variant='text' />
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              ))}
            </div>
          </Drawer>
        )}
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth='xl' className={classes.container}>
            <Grid container spacing={1}>
              <PageHeader title={<Skeleton variant='text' width={250} />} />
              <Grid item xs={12} className={classes.spacing}>
                <SearchBarLoader />
              </Grid>
              <Grid item xs={12} className={classes.spacing}>
                <TableLoader rowsNum={5} />
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    </MuiThemeProvider>
  );
}
