import React from 'react';
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  spacer: {
    marginTop: 20,
  },
}));

export default function SelectField({ value, setValue, label, options = [] }) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${label}-checkbox`}>{label}</InputLabel>
      <Select
        labelId='checkbox-label'
        id={`${label}-checkbox`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        input={
          label === 'Color' ||
          label === 'Button Color' ||
          label === 'Text Color' ? (
            <Input style={{ backgroundColor: value, color: 'white' }} />
          ) : (
            <Input style={{ textTransform: 'capitalize' }} />
          )
        }
        renderValue={(selected) => selected}
        MenuProps={MenuProps}
      >
        <MenuItem value='' disabled>
          Select
        </MenuItem>
        {options.map((t) => (
          <MenuItem key={t} value={t}>
            <Checkbox checked={value === t} />
            <ListItemText primary={t} style={{ textTransform: 'capitalize' }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
