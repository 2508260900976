import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.0 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export default function TopicFilters({ selected, onChange }) {
  const classes = useStyles();

  const list = [
    { label: 'Show all Items', value: 'all' },
    { label: 'Show only live items', value: 'live' },
    { label: 'Show only archived items', value: 'archived' },
  ];

  return (
    <Grid container direction={'row'} item sm>
      {list.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel shrink id='topic-multi-checkbox'>
            Topic Filters
          </InputLabel>
          <Select
            labelId='global-checkbox'
            id='global-checkbox'
            value={selected}
            onChange={(e) => onChange(e.target.value)}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {list.map((l, i) => (
              <MenuItem value={l.value} key={i}>
                {l.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
