import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsers } from 'containers/SuperAdmin/redux/actions/user';
import { fetchAllSchools } from 'containers/SuperAdmin/redux/actions/school';
import { importStudents } from 'containers/SuperAdmin/redux/actions/student';
import { fetchAllLicenses } from 'containers/SuperAdmin/redux/actions/license';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import * as XLSX from 'xlsx/xlsx.mjs';
import DropzoneFilePicker from '../DropzoneFilePicker';
import Loader from 'components/Loader';

const ImportStudentDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { actionLoading } = useSelector(({ admin }) => admin.student);
  const { users } = useSelector(({ admin }) => admin.users);
  const { licenses } = useSelector(({ admin }) => admin.license);
  const { schools } = useSelector(({ admin }) => admin.school);

  const [sheetData, setSheetData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState('');

  const [selectedLicense, setSelectedLicense] = useState(null);
  const [consumedStudents, setConsumedStudents] = useState(0);
  const [availableStudents, setAvailableStudents] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  const [password, setPassword] = useState('spanish');

  let array = ['First Name', 'Last Name', 'Username', 'Grade', 'Clever ID'];

  useEffect(() => {
    if (!open) return;
    dispatch(fetchAllUsers({ access: 'teacher' }));
    dispatch(fetchAllSchools());
    dispatch(fetchAllLicenses());
  }, [open, dispatch]);

  const handleSchool = (schoolId) => {
    setSelectedSchool(schoolId);
    const license = licenses?.docs.find(
      (x) =>
        x.licenseType === 'School' &&
        schoolId.toString() === x.licensee.toString()
    );
    if (license) {
      setSelectedLicense(license?._id);
      let totalLimit = license?.studentLicenses;
      let consumedStudents = license?.consumedStudentLicenses;
      let available = totalLimit - consumedStudents;
      setConsumedStudents(consumedStudents);
      setAvailableStudents(available);
    } else {
      setSelectedLicense(null);
      setConsumedStudents(0);
      setAvailableStudents(0);
    }
  };

  const formatStudent = (value, gradeIndex) => {
    const students = value.slice(1).filter((x) => x.length > 0);
    return students.map((y) => {
      let username = y[1] ? y[0] + y[1][0] : y[1];
      return {
        firstName: y[0],
        lastName: y[1],
        grade: y[2],
        username,
        gradeIndex,
        cleverStudentSISId: y[3],
        cleverId: y[4],
        cleverSchoolId: y[5],
      };
    });
  };

  const createUsernames = (allData) => {
    const allStudents = allData.map((x) => x.students).flat();
    const data = allData.map((x) => {
      return {
        ...x,
        students: x.students.map((y) => {
          let username = y.username;
          let remainingStudents = allStudents
            .filter((x) => x.cleverId !== y.cleverId)
            .filter((x) => x.username === username);
          if (remainingStudents.length > 0) {
            username = username + Math.floor(Math.random() * (100 - 1) + 1);
          }
          return {
            ...y,
            username,
          };
        }),
      };
    });
    return data;
  };

  const readDataFromExcel = (data) => {
    const wb = XLSX.read(data);
    var mySheetData = {};
    for (var i = 0; i < wb.SheetNames.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        blankrow: '',
        header: 1,
      });
      mySheetData[sheetName] = jsonData;
    }
    const formattedData = Object.keys(mySheetData).map((key, index) => {
      return {
        index,
        grade: key,
        students: formatStudent(mySheetData[key], index),
      };
    });
    const dataWithUsernames = createUsernames(formattedData);
    return dataWithUsernames;
  };

  const calculateStudentLength = (value) => {
    const sum = value.reduce((accumulator, object) => {
      return accumulator + object.students.length;
    }, 0);
    setTotalStudents(sum);
  };

  const onChange = async (e) => {
    const file = e[0];

    if (!file) return;
    const data = await file.arrayBuffer();

    const formattedOutput = readDataFromExcel(data);
    calculateStudentLength(formattedOutput);
    setSheetData(formattedOutput);
  };

  const changeTab = (value, index) => {
    setActiveTab(index);
  };

  const onSubmit = () => {
    let data = {
      school: selectedSchool,
      teacher: selectedTeacher,
      license: selectedLicense,
      data: sheetData,
      password,
    };
    dispatch(importStudents(data, handleClose));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='xl'
      fullWidth
      fullScreen
    >
      <DialogTitle id='form-dialog-title'>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item sm xs={9}>
            <Typography color='primary' style={{ fontSize: 'x-large' }}>
              Import Students
            </Typography>
          </Grid>
          <Grid item sm={'auto'} xs={3}>
            <IconButton onClick={handleClose} color='primary' size='small'>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {actionLoading ? (
        <DialogContent>
          <Loader fetching={actionLoading} />
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            xs={12}
            sm={12}
            style={{ marginBottom: '30px', padding: '10px' }}
          >
            <FormControl style={{ width: 250 }}>
              <InputLabel htmlFor='school-select'>Choose School</InputLabel>
              <Select
                labelId='school-label'
                id='school-label'
                displayEmpty={true}
                value={selectedSchool}
                onChange={(e) => handleSchool(e.target.value)}
              >
                {schools?.docs?.map((x) => (
                  <MenuItem value={x._id} key={x._id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedSchool && (
              <FormControl style={{ width: 250 }}>
                <InputLabel htmlFor='teacher-select'>Choose Teacher</InputLabel>
                <Select
                  labelId='teacher-label'
                  id='teacher-label'
                  value={selectedTeacher}
                  onChange={(e) => setSelectedTeacher(e.target.value)}
                  displayEmpty={true}
                >
                  {users?.docs
                    ?.filter(
                      (x) => x.school && x.school?._id === selectedSchool
                    )
                    ?.map((x) => (
                      <MenuItem value={x._id} key={x._id}>
                        {x.fullName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            <FormControl style={{ width: 300 }}>
              <TextField
                required
                variant='outlined'
                label='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography color='primary'>
              Licenses Used:
              <span style={{ color: 'black', fontWeight: 500 }}>
                {' '}
                {consumedStudents}
              </span>
            </Typography>
            <Typography color='primary'>
              Licenses Available:
              <span style={{ color: 'black', fontWeight: 500 }}>
                {' '}
                {availableStudents}
              </span>
            </Typography>
            <Typography color='primary'>
              Ready for Import:
              <span style={{ color: 'green', fontWeight: 500 }}>
                {' '}
                {totalStudents}
              </span>
            </Typography>
            {availableStudents - totalStudents > 0 && (
              <Typography color='primary'>
                Available after Import:
                <span style={{ color: 'green', fontWeight: 500 }}>
                  {' '}
                  {availableStudents - totalStudents}
                </span>
              </Typography>
            )}
          </Grid>

          {availableStudents > 0 ? (
            <Grid
              item
              xs={12}
              container
              justifyContent='flex-end'
              style={{ marginTop: '10px', marginBottom: '20px' }}
            >
              <DropzoneFilePicker
                onChange={(fil) => onChange(fil)}
                maxFiles={1}
              />
            </Grid>
          ) : (
            <Typography
              variant='h6'
              color='error'
              style={{ margin: '10px 0px' }}
            >
              You have no available licenses to import students 😔
            </Typography>
          )}

          {availableStudents < totalStudents && (
            <Grid item xs={12} sm={12} style={{ padding: '5px' }}>
              <Typography color='error'>
                Sorry you do not have enough licenses to import this many
                students, kindly upload file with less students or contact your
                IT
              </Typography>
            </Grid>
          )}

          {sheetData.length > 0 && (
            <Grid container>
              <Tabs
                value={activeTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={changeTab}
                aria-label='class tabs'
                variant='scrollable'
                scrollButtons='auto'
              >
                {sheetData?.map((x) => (
                  <Tab key={x.index} label={x.grade} />
                ))}
              </Tabs>
            </Grid>
          )}
          {sheetData.length > 0 && (
            <Grid>
              <TableContainer>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow style={{ padding: '15px' }}>
                      {array?.map((x) => (
                        <TableCell key={x}>{x}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sheetData
                      .filter((z) => z.index === activeTab)
                      .map((x) => {
                        return x.students.map((y) => (
                          <TableRow>
                            <TableCell component='th' scope='row'>
                              {y.firstName}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {y.lastName}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {y.username}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {y.grade}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {y.cleverId}
                            </TableCell>
                          </TableRow>
                        ));
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </DialogContent>
      )}
      <DialogActions style={{ justifyContent: 'center', marginTop: 15 }}>
        <Grid container>
          <Button
            onClick={onSubmit}
            color='primary'
            fullWidth
            variant={'contained'}
            disabled={
              totalStudents > availableStudents ||
              !totalStudents ||
              !availableStudents ||
              actionLoading
            }
          >
            {actionLoading ? 'Processing' : 'Import Students'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default ImportStudentDialog;
