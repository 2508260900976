import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  VIDEOACTION_ATTEMPT,
  VIDEOLIST_ATTEMPT,
  VIDEO_ERROR,
  FETCHALLVIDEOS_SUCCESS,
  FETCHVIDEO_SUCCESS,
  ADDVIDEO_SUCCESS,
  UPDATEVIDEO_SUCCESS,
  DELETEVIDEO_SUCCESS,
  SETVIDEO,
  VIDEOVALIDATE_SUCESS,
  VIDEOMAXORDER_SUCESS,
  BULKUPDATEVIDEO_SUCCESS,
  BULKDELETEVIDEO_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const videoUrl = 'admin/videos';

export const fetchAllVideos = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOLIST_ATTEMPT));
      const body = await axios.get(`${videoUrl}`, { params });
      dispatch(successAction(FETCHALLVIDEOS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const fetchMaxOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.get(`${videoUrl}/maxOrder`, { params });
      dispatch(successAction(VIDEOMAXORDER_SUCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const validateOrder = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}/validateOrder`, params);
      dispatch(successAction(VIDEOVALIDATE_SUCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const fetchVideo = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOLIST_ATTEMPT));
      const body = await axios.get(`${videoUrl}/${id}`);
      dispatch(successAction(FETCHVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const addVideo = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}`, data);
      dispatch(successAction(ADDVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const updateVideo = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.put(`${videoUrl}/${id}`, data);
      dispatch(successAction(UPDATEVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const deleteVideo = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.delete(`${videoUrl}/${id}`);
      dispatch(successAction(DELETEVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const setVideo = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}/order`, { data });
      dispatch(successAction(SETVIDEO, body.data.video));
    } catch (err) {
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const archiveVideo = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.put(`${videoUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const restoreVideo = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.put(`${videoUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEVIDEO_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveVideo = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEVIDEO_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreVideo = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEVIDEO_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteVideo = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VIDEOACTION_ATTEMPT));
      const body = await axios.post(`${videoUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETEVIDEO_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(VIDEO_ERROR, err));
    }
  };
  return thunk;
};
