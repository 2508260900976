import { Grid } from '@material-ui/core';
import Loader from 'components/Loader';
import TeacherAgreement from 'containers/Teachers/components/TeacherAgreement';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getProfile } from 'redux/actions';

export default function TeacherAgreementAccess({ children, ...rest }) {
  const isLoggedIn = localStorage && localStorage.getItem('token');

  const dispatch = useDispatch();
  const { user } = useSelector(({ global }) => global);

  useEffect(() => {
    if (user) return;
    dispatch(getProfile());
  }, [dispatch, user]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          user ? (
            user?.isAgreementAccepted ? (
              children
            ) : (
              <TeacherAgreement />
            )
          ) : (
            <Grid
              container
              style={{
                backgroundImage: `url('/bg.jpeg')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <Loader fetching={true} />
            </Grid>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
