import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalDialog } from 'redux/actions';

import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
    padding: theme.spacing(4, 1, 0, 1),
    margin: theme.spacing(2),
    lineHeight: '2.5rem',
    textAlign: 'justify',
  },
  cancel: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: COLORS.PINK,
  },
}));

export default function GlobalDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialogState, dialogContent } = useSelector((state) => state.global);

  const handleClose = () => {
    dispatch(hideGlobalDialog());
  };

  return (
    <Dialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby='global-dialog-title'
      maxWidth={'sm'}
    >
      <IconButton className={classes.cancel} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      {dialogContent && (
        <DialogContent className={classes.root}>{dialogContent}</DialogContent>
      )}
    </Dialog>
  );
}
