/**
 * * Aprender Reducer
 */

import {
  APRENDERACTION_ATTEMPT,
  APRENDERLIST_ATTEMPT,
  APRENDER_ERROR,
  FETCHALLAPRENDERS_SUCCESS,
  FETCHAPRENDER_SUCCESS,
  ADDAPRENDER_SUCCESS,
  UPDATEAPRENDER_SUCCESS,
  BULKUPDATEAPRENDER_SUCCESS,
  DELETEAPRENDER_SUCCESS,
  SETAPRENDER,
  FETCHORDERNUMBER_SUCCESS,
  VALIDATEORDER_SUCCESS,
  BULKDELETEAPRENDER_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  aprenders: null,
  aprender: null,
  orderNumberStatus: false,
};

export default function aprender(state = initialState, action) {
  switch (action.type) {
    case APRENDERLIST_ATTEMPT:
      return { ...state, fetching: true, aprender: null };
    case APRENDERACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case APRENDER_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLAPRENDERS_SUCCESS:
      return { ...state, fetching: false, aprenders: action.payload.data };
    case FETCHAPRENDER_SUCCESS:
      return { ...state, fetching: false, aprender: action.payload.apprender };
    case FETCHORDERNUMBER_SUCCESS:
      return { ...state, actionLoading: false, maxOrder: action.payload.data };
    case VALIDATEORDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        orderNumberStatus: action.payload.data,
      };
    case ADDAPRENDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        aprenders: state.aprenders
          ? {
              ...state.aprenders,
              docs: state.aprenders?.docs
                ? [...state.aprenders.docs, action.payload.aprender]
                : [action.payload.aprender],
            }
          : { docs: [action.payload.aprender] },
      };
    case UPDATEAPRENDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        aprenders: {
          ...state.aprenders,
          docs: state.aprenders?.docs?.map((x) => {
            if (x._id === action.payload.aprender._id) {
              return action.payload.aprender;
            } else {
              return x;
            }
          }),
        },
      };
    case BULKUPDATEAPRENDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        aprenders: {
          ...state.aprenders,
          docs: state.aprenders.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEAPRENDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        aprenders: {
          ...state.aprenders,
          docs: state.aprenders.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case BULKDELETEAPRENDER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        aprenders: {
          ...state.aprenders,
          docs: state.aprenders.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    case SETAPRENDER:
      return {
        ...state,
        actionLoading: false,
        aprenders: { ...state.aprenders, docs: action.payload },
      };
    default:
      return state;
  }
}
