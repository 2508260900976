import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  GRADEACTION_ATTEMPT,
  GRADELIST_ATTEMPT,
  GRADE_ERROR,
  FETCHALLGRADES_SUCCESS,
  FETCHGRADE_SUCCESS,
  ADDGRADE_SUCCESS,
  UPDATEGRADE_SUCCESS,
  DELETEGRADE_SUCCESS,
  SORTGRADES,
  BULKDELETEGRADE_SUCCESS,
  BULKUPDATEGRADE_SUCCESS,
} from '../constants.js';
import { sortBy } from 'utils/sort';
import { enqueueSnackbar } from 'redux/actions';

const gradeUrl = 'admin/grades';

const mapGrades = (x) => ({
  ...x,
  schoolName: x.school ? x.school?.name : 'Individual',
  teacherName: `${x.createdBy ? x.createdBy?.fullName : 'N/A'}`,
});

export const fetchAllGrades = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADELIST_ATTEMPT));
      const body = await axios.get(`${gradeUrl}`, { params });
      const data = body.data?.data;
      const grades = {
        ...data,
        docs: data?.docs.map(mapGrades),
      };
      dispatch(successAction(FETCHALLGRADES_SUCCESS, { data: grades }));
    } catch (err) {
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const fetchGrade = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADELIST_ATTEMPT));
      const body = await axios.get(`${gradeUrl}/${id}`);
      dispatch(successAction(FETCHGRADE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const addGrade = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.post(`${gradeUrl}`, data);
      const grade = mapGrades(body.data.grade);
      const result = { ...body.data, grade };
      dispatch(successAction(ADDGRADE_SUCCESS, result));
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const updateGrade = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.post(`${gradeUrl}/${id}`, data);
      const grade = mapGrades(body.data.grade);
      const result = { ...body.data, grade };
      dispatch(successAction(UPDATEGRADE_SUCCESS, result));
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const deleteGrade = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.delete(`${gradeUrl}/${id}`);
      dispatch(successAction(DELETEGRADE_SUCCESS, body.data));
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const sortGrades = (field, order, data) => {
  const thunk = async function thunk(dispatch) {
    const orderedData = data.sort((a, b) => sortBy(a, b, field, order));
    dispatch(successAction(SORTGRADES, orderedData));
  };
  return thunk;
};

export const archiveGrade = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.put(`${gradeUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGRADE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const restoreGrade = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.put(`${gradeUrl}/${id}/restore`, data);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGRADE_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveGrade = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.post(`${gradeUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEGRADE_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreGrade = (ids, teacher) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.post(`${gradeUrl}/bulkRestore`, {
        ids,
        teacher,
      });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEGRADE_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteGrade = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GRADEACTION_ATTEMPT));
      const body = await axios.post(`${gradeUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETEGRADE_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GRADE_ERROR, err));
    }
  };
  return thunk;
};
