import React, { useEffect, useState } from 'react';

import './styles.css';
import { lighten, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import { DragHandle } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EnhancedTableHead from './head';
import TablePaginationActions from './pagination';
import StandardTablePaginationActions from './standardPagination';
import { ArchiveIcon, DeleteIcon, RestoreIcon } from 'components/Icons';
import {
  Checkbox,
  IconButton,
  TableFooter,
  TablePagination,
  Toolbar,
  Typography,
  Button,
  Collapse,
} from '@material-ui/core';
import { reorder, getItemStyle } from './drag-drop';
import { enqueueSnackbar } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

const PAGES_PER_ROW = [5, 10, 25, 50, 100, 250, 500, 1000];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    minWidth: 750,
  },
  head: {
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: ({ numSelected }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    ...(numSelected > 0 && {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }),
  }),
  title: {
    flex: '1 1 60%',
  },
  button: {
    margin: theme.spacing(0, 1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, view, onClick, onDelete, onDeselectAll } = props;
  const classes = useToolbarStyles({ numSelected });

  return (
    <Toolbar className={classes.root}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
          {onDeselectAll && (
            <Tooltip title={'Deselect All'}>
              <Button
                aria-label='deselectall'
                onClick={onDeselectAll}
                variant='contained'
                color='primary'
                className={classes.button}
              >
                Deselect All
              </Button>
            </Tooltip>
          )}
          {onClick && (
            <Tooltip title={view === 'normal' ? 'Archive' : 'Restore'}>
              <Button
                aria-label='delete'
                onClick={onClick}
                endIcon={
                  view === 'normal' ? (
                    <ArchiveIcon />
                  ) : (
                    <RestoreIcon fill='white' />
                  )
                }
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {view === 'normal' ? 'Bulk Archive' : 'Bulk Restore'}
              </Button>
            </Tooltip>
          )}
          {view !== 'normal' && onDelete && (
            <Tooltip title={'Delete'}>
              <Button
                aria-label='delete'
                onClick={onDelete}
                endIcon={<DeleteIcon />}
                variant='contained'
                color='primary'
                className={classes.button}
              >
                Bulk Delete
              </Button>
            </Tooltip>
          )}
        </React.Fragment>
      ) : null}
    </Toolbar>
  );
};

const studentRoutes = [
  '/superadmin/teachers/:teacher/students',
  '/superadmin/grades/:grade/students',
  '/superadmin/schools/:schoolId/students',
  '/superadmin/students',
];

export default function EnhancedTable({
  size = 'small',
  headCells,
  data,
  orderField,
  orderDirection,
  handleClick,
  handlePage,
  handleOrder,
  standardPagination = false,
  handleRowOrder,
  handleBulk,
  handleBulkDelete,
  view,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [orderDir, setOrderDir] = useState(orderDirection);
  const [orderBy, setOrderBy] = useState(orderField);
  const [selected, setSelected] = useState([]);

  const managementPages = useRouteMatch([
    '/superadmin/teachers/:teacher/classes',
    '/superadmin/schools/:schoolId/classes',
    '/superadmin/grades',
    ...studentRoutes,
  ]);

  const studentPages = useRouteMatch(studentRoutes);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const allSelected = data?.docs?.map((n) => n.school?._id)?.map((x) => x);
  // check if all values are same
  const isDifferentLicense = !allSelected.every(
    (val, i, arr) => val === arr[0]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    event.preventDefault();
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (handleOrder) {
      handleOrder({ orderBy: property, orderDir: isAsc ? 'desc' : 'asc' });
    }
  };

  const handleSelectAllClick = (event) => {
    const allIndividuals = data?.docs?.every((x) => !x.school);
    if (
      (Boolean(managementPages?.isExact) && isDifferentLicense) ||
      (allIndividuals && Boolean(studentPages?.isExact))
    ) {
      dispatch(
        enqueueSnackbar({
          message: 'Bulk actions cannot be performed across multiple licenses',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      return;
    }
    if (event.target.checked) {
      const newSelecteds = data?.docs;
      if (Boolean(managementPages?.isExact)) {
        setSelected(newSelecteds);
      } else {
        setSelected(newSelecteds.map((n) => n._id));
      }
      return;
    }
    setSelected([]);
  };

  const handleSelected = (event, data) => {
    const selectedIndex = Boolean(managementPages?.isExact)
      ? selected.findIndex((x) => x._id === data?._id)
      : selected.indexOf(data);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (!handleRowOrder) return;

    const items = reorder(
      data?.docs,
      result.source.index,
      result.destination.index
    );

    handleRowOrder(items.map((x, index) => ({ id: x._id, order: index })));
  };

  const handleRowClick = (r, row) => {
    if (Boolean(managementPages?.isExact)) {
      if (row.licenseType === 'Individual') {
        dispatch(
          enqueueSnackbar({
            message: 'Selected row must belong to a group license',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
        return;
      } else if (selected.length === 0) {
        handleSelected(r, row);
      } else {
        const schools = selected?.map((x) => x.school?._id)?.map((x) => x);
        if (schools?.length > 0) {
          if (schools.includes(row.school?._id)) {
            handleSelected(r, row);
          } else {
            // error
            dispatch(
              enqueueSnackbar({
                message:
                  'Bulk actions cannot be performed across multiple licenses',
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'error',
                },
              })
            );
            return;
          }
        } else {
          handleSelected(r, row);
        }
      }
    } else {
      handleSelected(r, row._id);
    }
  };

  const TableStandardRow = React.forwardRef(({ row, style, ...props }, ref) => {
    return (
      <TableRow
        hover
        selected={
          isDifferentLicense
            ? selected.findIndex((x) => x._id === row._id) !== -1
            : selected.indexOf(row._id) !== -1
        }
        style={{ cursor: 'pointer', ...style }}
        ref={ref}
        key={row._id}
        id={`table-row-${row._id}`}
        {...props}
      >
        {headCells.map((headCell) => {
          if (headCell.id === 'actions') {
            return (
              <TableCell align='left' key={`${row._id}-${headCell.id}`}>
                {headCell.actions.map((option, index) =>
                  option.disabled(row) ? (
                    <IconButton
                      aria-label={option.label + row._id}
                      aria-controls='action-button'
                      onClick={() => option.action(row)}
                      disabled={option.disabled(row)}
                    >
                      {option.icon}
                    </IconButton>
                  ) : (
                    <Tooltip title={option.label} key={index}>
                      <IconButton
                        aria-label={option.label + row._id}
                        aria-controls='action-button'
                        onClick={() => option.action(row)}
                        disabled={option.disabled(row)}
                        id={`action-btn-${row._id}-${index}`}
                      >
                        {option.label === 'Archive' && <ArchiveIcon />}
                        {option.label === 'Restore' && <RestoreIcon />}
                        {option.label === 'Permanently Delete' && (
                          <DeleteIcon />
                        )}
                        {!['Archive', 'Permanently Delete', 'Restore'].includes(
                          option.label
                        ) && option.icon}
                      </IconButton>
                    </Tooltip>
                  )
                )}
                {handleRowOrder && (
                  <Tooltip title='Drag to use'>
                    <IconButton
                      aria-label={'sort-handle' + row._id}
                      aria-controls='sort-button'
                    >
                      <DragHandle />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            );
          }
          if (headCell.id === 'checkbox') {
            return (
              <TableCell
                align={headCell.align ? headCell.align : 'left'}
                key={`${row._id}-${headCell.id}`}
                padding='checkbox'
                onClick={(r) => handleRowClick(r, row)}
              >
                <Checkbox
                  checked={
                    Boolean(managementPages?.isExact)
                      ? selected.findIndex((x) => x._id === row?._id) !== -1
                      : selected.indexOf(row._id) !== -1
                  }
                  inputProps={{
                    'aria-labelledby': `${row._id}-${headCell.id}`,
                  }}
                  color='primary'
                />
              </TableCell>
            );
          }
          return (
            <TableCell
              align={headCell.align ? headCell.align : 'left'}
              key={`${row._id}-${headCell.id}`}
              onClick={(r) => handleClick && handleClick(row)}
            >
              {headCell.formatter ? headCell.formatter(row) : row[headCell.id]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });

  useEffect(() => {
    setSelected([]);
  }, [view]);

  useEffect(() => {
    if (!orderDirection || orderField) return;
    if (orderDirection) {
      setOrderDir(orderDirection);
    }
    if (orderField) {
      setOrderBy(orderField);
    }
  }, [orderDirection, orderField]);

  return (
    <Paper className={classes.paper}>
      <Collapse in={selected.length > 0}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onClick={() => {
            handleBulk(selected);
            setSelected([]);
          }}
          onDelete={() => {
            handleBulkDelete(selected);
            setSelected([]);
          }}
          view={view}
          onDeselectAll={isDifferentLicense ? () => setSelected([]) : null}
        />
      </Collapse>
      <TableContainer>
        <Table
          size={size}
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            order={orderDir}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={data?.docs?.length}
          />
          {handleRowOrder ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='list'>
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {standardPagination &&
                      (rowsPerPage > 0
                        ? data?.docs?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : data?.docs
                      ).map((row, index) => (
                        <TableStandardRow
                          key={row._id}
                          row={row}
                          index={index}
                        />
                      ))}
                    {!standardPagination &&
                      data?.docs?.length > 0 &&
                      data?.docs?.map((row, index) => (
                        <Draggable
                          key={row._id}
                          draggableId={'list-' + row._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableStandardRow
                              row={row}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            />
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <TableBody>
              {standardPagination &&
                (rowsPerPage > 0
                  ? data?.docs?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data?.docs
                ).map((row, index) => (
                  <TableStandardRow key={row._id} row={row} index={index} />
                ))}
              {!standardPagination &&
                data?.docs?.length > 0 &&
                data.docs?.map((row) => (
                  <TableStandardRow row={row} key={row._id} />
                ))}
            </TableBody>
          )}
          {standardPagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={PAGES_PER_ROW.filter(
                    (x) => x <= data?.totalDocs
                  )}
                  colSpan={headCells.length}
                  count={data?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={StandardTablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {!standardPagination && (
          <TablePaginationActions
            count={data.totalDocs}
            limit={data.limit}
            totalPages={data.totalPages}
            page={data.page}
            hasNext={data.hasNextPage}
            hasPrev={data.hasPrevPage}
            nextPage={data.nextPage}
            prevPage={data.prevPage}
            onChangePage={handlePage}
          />
        )}
      </TableContainer>
    </Paper>
  );
}
