import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addLeer } from '../../../redux/actions/leer';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialLeer = {
  name: '',
  image: '',
  content: {},
  topics: [],
  sounds: [],
  enableMultiple: false,
};

export default function NewLeer({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading } = useSelector(({ admin }) => admin.leer);

  const handleSave = (d) => {
    dispatch(addLeer(d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={initialLeer}
        onSave={handleSave}
        content={['image']}
        multipleField={'sounds'}
        multipleFieldTitle='Sounds'
        showTopicPicker
      />
    </div>
  );
}
