/**
 * * Leer Reducer
 */

import {
  LEERACTION_ATTEMPT,
  LEERLIST_ATTEMPT,
  LEER_ERROR,
  FETCHALLLEERS_SUCCESS,
  FETCHLEER_SUCCESS,
  ADDLEER_SUCCESS,
  UPDATELEER_SUCCESS,
  DELETELEER_SUCCESS,
  BULKDELETELEER_SUCCESS,
  BULKUPDATELEER_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  leers: null,
  leer: null,
};

export default function leer(state = initialState, action) {
  switch (action.type) {
    case LEERLIST_ATTEMPT:
      return { ...state, fetching: true };
    case LEERACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case LEER_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case FETCHALLLEERS_SUCCESS:
      return { ...state, fetching: false, leers: action.payload.data };
    case FETCHLEER_SUCCESS:
      return { ...state, fetching: false, leer: action.payload.leer };
    case ADDLEER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        leers: state.leers
          ? {
              ...state.leers,
              docs: [...state.leers.docs, action.payload.leer],
            }
          : {
              ...state.leers,
              docs: [action.payload.leer],
            },
      };
    case UPDATELEER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        leers: {
          ...state.leers,
          docs: state.leers.docs.map((x) => {
            if (x._id === action.payload.leer._id) {
              return action.payload.leer;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETELEER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        leers: {
          ...state.leers,
          docs: state.leers.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    case BULKUPDATELEER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        leers: {
          ...state.leers,
          docs: state.leers.docs.map((x) => {
            if (action.payload.ids.includes(x._id)) {
              return {
                ...x,
                ...action.payload.update,
              };
            } else {
              return x;
            }
          }),
        },
      };
    case BULKDELETELEER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        leers: {
          ...state.leers,
          docs: state.leers.docs.filter(
            (x) => !action.payload.ids.includes(x._id)
          ),
        },
      };
    default:
      return state;
  }
}
