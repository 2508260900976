import { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import StandardDialogActions from 'components/StandardDialogActions';
import UploadField from '../UploadField';
import SwipeableViews from 'react-swipeable-views';

import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    alignItems: 'center',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '65vh',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  buttons: {
    marginLeft: 'auto',
  },
}));
export function ImagesDialog({
  open,
  initial,
  editable,
  title,
  onClose,
  onResult,
}) {
  const [images, setImages] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const classes = useStyles();

  useEffect(() => {
    if (!open) return;
    setImages(initial || '');
    setActiveStep(0);
  }, [initial, open]);

  function done() {
    onResult(images);
    onClose();
  }
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const updateImage = (location, index) => {
    let updatedImages = [...images];
    updatedImages = updatedImages.map((x, imageIndex) => {
      if (index === imageIndex) {
        return location;
      } else {
        return x;
      }
    });
    setImages(updatedImages);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {images && (
          <Grid
            item
            container
            justifyContent='center'
            direction='row'
            alignItems='center'
          >
            <Grid item>
              <IconButton onClick={handleBack} disabled={activeStep === 0}>
                <img src='/left.png' alt='left' height='64' />
              </IconButton>
            </Grid>
            <Grid item style={{ width: '60%' }}>
              <AutoPlaySwipeableViews
                className={classes.main}
                autoplay={false}
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images?.map((image, index) => (
                  <div key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className={classes.img}
                        src={image}
                        alt={`img-${index}`}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleNext}
                disabled={activeStep === images.length - 1}
              >
                <img src='/right.png' alt='left' height='64' />
              </IconButton>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <UploadField
                data={images[activeStep]}
                setData={(location) => updateImage(location, activeStep)}
                label='Image'
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={!editable}
      />
    </Dialog>
  );
}
