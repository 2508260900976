import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import { green, blue, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: ({ status }) => ({
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...(status === 'Pending Activation' && {
      backgroundColor: blue[500],
      color: theme.palette.getContrastText(blue[500]),
    }),
    ...(status === 'Pending Password Reset' && {
      backgroundColor: yellow[500],
      color: theme.palette.getContrastText(yellow[500]),
    }),
    ...(status === 'Active' && {
      backgroundColor: green[500],
      color: theme.palette.getContrastText(green[500]),
    }),
    ...(status === 'Deactivated' && {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
    }),
    ...(status === 'Pending Email Address Change' && {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.getContrastText(theme.palette.warning.main),
    }),
  }),
}));

const AccountStatus = ({ status, forHeadTeacher }) => {
  const classes = useStyles({ status });
  if (forHeadTeacher) {
    if (status?.includes('Pending Reactivation')) {
      return <Chip label='Pending Reactivation' className={classes.root} />;
    }
    return <Chip label={status} className={classes.root} />;
  }

  return <Chip label={status} className={classes.root} />;
};

export default AccountStatus;
