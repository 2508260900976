/**
 * * Users Reducer
 */

import {
  LICENSEACTION_ATTEMPT,
  LICENSELIST_ATTEMPT,
  LICENSEELIST_ATTEMPT,
  LICENSE_ERROR,
  FETCHALLLICENSES_SUCCESS,
  FETCHLICENSE_SUCCESS,
  ADDLICENSE_SUCCESS,
  UPDATELICENSE_SUCCESS,
  DELETELICENSE_SUCCESS,
  FETCHLICENSEE_SUCCESS,
  FETCHLICENSEES_SUCCESS,
  FETCHLICENSES_SUCCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  licenseesFetching: false,
  licenses: null,
  license: null,
  licensees: [],
  licenseeByKey: {},
  licenseData: [],
};

export default function license(state = initialState, action) {
  switch (action.type) {
    case LICENSELIST_ATTEMPT:
      return { ...state, fetching: true, licensees: [] };
    case LICENSEELIST_ATTEMPT:
      return { ...state, licenseesFetching: true, licensees: [] };
    case LICENSEACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case LICENSE_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
        licenseesFetching: false,
      };
    case FETCHALLLICENSES_SUCCESS:
      return {
        ...state,
        fetching: false,
        licenses: action.payload.data,
        licenseeByKey: action.payload.licensees,
      };
    case FETCHLICENSES_SUCCESS:
      return {
        ...state,
        fetching: false,
        licenseData: action.payload.data,
      };
    case FETCHLICENSE_SUCCESS:
      return {
        ...state,
        fetching: false,
        license: action.payload.license,
      };
    case FETCHLICENSEES_SUCCESS:
      return {
        ...state,
        fetching: false,
        licensees: action.payload.data,
        licenseesFetching: false,
      };
    case FETCHLICENSEE_SUCCESS:
      return {
        ...state,
        licenseesFetching: false,
        licenseeByKey: {
          ...state.licenseeByKey,
          [action.payload.id]: action.payload.data,
        },
      };
    case ADDLICENSE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        licenses: state.licenses
          ? {
              ...state.licenses,
              docs: [...state.licenses.docs, action.payload.license],
            }
          : { docs: [action.payload.license] },
        licenseeByKey: {
          ...state.licenseeByKey,
          ...action.payload.licensees,
        },
      };
    case UPDATELICENSE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        licenses: {
          ...state.licenses,
          docs: state.licenses.docs.map((x) => {
            if (x._id === action.payload.license?._id) {
              return action.payload.license;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETELICENSE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        licenses: {
          ...state.licenses,
          docs: state.licenses.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
