import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.0 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export default function SectionSelector({ selected, setSelected, isContent }) {
  const classes = useStyles();

  const list = isContent
    ? ['Aprender', 'Leer', 'Practicar', 'Conversar', 'Cantar']
    : ['Conversar', 'Cantar', 'Practicar', 'Jugar'];

  const navigate = (item) => {
    setSelected(item);
  };

  return (
    <Grid container direction={'row'} item sm>
      {list.length > 0 && (
        <FormControl className={classes.formControl}>
          {!isContent && (
            <InputLabel shrink id='topic-multi-checkbox'>
              Category
            </InputLabel>
          )}
          <Select
            labelId='global-checkbox'
            id='global-checkbox'
            value={selected}
            onChange={(e) => navigate(e.target.value)}
            input={<Input />}
            MenuProps={MenuProps}
            displayEmpty={true}
            renderValue={(selected) => (selected ? selected : 'None')}
          >
            <MenuItem value={''}>None</MenuItem>
            {list.map((l, i) => (
              <MenuItem value={l} key={i}>
                {l}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
