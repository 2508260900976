import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTeachers } from 'containers/SuperAdmin/redux/actions/user';
import { findUsername } from 'redux/teachers/actions';
import { fetchAllGrades } from 'containers/SuperAdmin/redux/actions/grade';
import { fetchAllSchools } from 'containers/SuperAdmin/redux/actions/school';

import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import {
  ArrowDropDown,
  CheckCircle,
  Close,
  Visibility,
  VisibilityOff,
  Warning,
  Cancel,
  Send,
} from '@material-ui/icons';
import { colors } from '@material-ui/core';
import { validUsername } from 'utils';
import EmailField from '../EmailField';
import { sortBy } from 'utils/sort';
import { getAccountStatusLabel } from 'constants';
import { SEARCH_TIMEOUT, USER_STATUS, ERRORS, userStatuses } from 'constants';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  email: {
    marginBottom: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1, 0),
  },
  cancel: {
    color: theme.palette.error.main,
  },
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'center',
    marginTop: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  genericText: {
    margin: theme.spacing(0.5, 2.5),
  },
  capitalize: {
    textTransform: 'uppercase',
  },
}));

export default function StudentDialog({
  open,
  handleClose,
  handleConfirm,
  data,
  edit = false,
  suffix = '',
  disabledFields = [],
  disableDone = false,
  showSubtitle = true,
  isDraft = false,
  sending,
}) {
  const classRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { message, status, loading } = useSelector(({ teachers }) => teachers);
  const { grades } = useSelector(({ admin }) => admin.grade);
  const { allTeachers } = useSelector(({ admin }) => admin.users);
  const { schools } = useSelector(({ admin }) => admin.school);
  const timer = useRef(null);
  const [form, setForm] = useState();
  const [passwordToggle, setPasswordToggle] = useState('password');
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailFetching, setEmailFetching] = useState(false);

  const [hideStatus, setHideStatus] = useState(false);
  const valid = dayjs().isBefore(data?.resetExpiryDate);

  const generateUsername = (first, last) => {
    let user = last ? first.trim() + last[0] : first.trim();
    setError(!validUsername(user));
    setForm({
      ...form,
      username: last ? first.trim() + last[0] : first.trim(),
    });
  };

  const activateTimer = (firstName, lastName) => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      const username = firstName.trim() + lastName[0];
      dispatch(findUsername(username));
    }, SEARCH_TIMEOUT);
  };

  useEffect(() => {
    if (!open) return;
    setError(false);
    setHideStatus(false);
    setEmailError(false);
    setEmailLoader(false);
    dispatch(fetchAllSchools({ pagination: false }));
    dispatch(fetchAllGrades({ pagination: false }));
    dispatch(fetchAllTeachers());
  }, [open, dispatch]);

  useEffect(() => {
    if (!open || !data) return;
    setEmailError(false);
    setEmailLoader(false);
    const userId = data?._id;
    setForm(data);
    if (!data.school && edit && userId) {
      setEmailFetching(true);
      axios
        .get(`/admin/licenses/${userId}/license`)
        .then((res) => {
          const email = res.data?.email;
          let update = {
            ...data,
            email,
          };
          setForm(update);
          setEmailFetching(false);
        })
        .catch((_err) => {
          setEmailFetching(false);
        });
    } else {
    }
  }, [open, data, edit]);

  const validateDone = () => {
    if (
      !form?.firstName ||
      form?.firstName === '' ||
      !form?.lastName ||
      form?.lastName === '' ||
      !form?.username ||
      form?.username === '' ||
      (isDraft ? false : form?.school && form?.grade === '') ||
      emailError ||
      emailLoader ||
      loading ||
      sending
    ) {
      if (disableDone && (!form?.school || form?.school === '')) {
        return true;
      }
      return true;
    }
    if (!disableDone) return false;
    if (edit) {
      return false;
    } else {
      if (form?.school === '') {
        return true;
      }
      if (form?.teacher === '') {
        return true;
      }
      if (form?.grade === '') {
        return true;
      }
      if (form?.password === '' || !form?.password) {
        return true;
      }
      return false;
    }
  };

  const getHelperText = () => {
    switch (data?.status) {
      case USER_STATUS.ACTIVE:
        return `This user’s Account is Active. Changing this email will update the user’s Account records & send the Verification email to the new address. The user must verify their new address prior to accessing the site again.`;
      case USER_STATUS.ACTIVATING:
        return `This user’s Account & License are Pending Activation. Changing their email address will resend the Activation email to the new address.`;
      case USER_STATUS.RESTORING:
      case USER_STATUS.RENEWING:
        return `This user’s Account & License are Pending Reactivation. Changing their email address will resend the Welcome Back email to the new address.`;
      case USER_STATUS.PASSWORDRESET:
        return `This user’s Account is Pending a Password Reset. Changing their email address will re-send the Password Reset email to the new address.`;
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title" disableTypography>
        <Grid container direction={'row'} jsutify={'space-between'} alignItems={'center'}>
          <Grid item sm xs={9}>
            <Typography color="primary">{edit ? 'Edit Student' : `Add Student ${suffix}`}</Typography>
          </Grid>
          <Grid item sm={'auto'} xs={3}>
            <IconButton onClick={handleClose} color="primary" size="small">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {!edit && showSubtitle && (
          <Grid container>
            <Typography variant="caption">Not Applicable for Individual Licenses</Typography>
          </Grid>
        )}
      </DialogTitle>
      {form?.isGeneric && (
        <Grid container className={classes.genericText}>
          <Typography color="error" className={classes.capitalize}>
            Generic Student License
          </Typography>
        </Grid>
      )}
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={form?.firstName}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setForm({ ...form, firstName: e.target.value.trim() });
                if (form?.lastName !== '' && e.target.value !== '' && !isDraft) {
                  activateTimer(e.target.value.trim(), form?.lastName);
                }
              }}
              onKeyUp={(e) => generateUsername(e.target.value, form?.lastName)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              value={form?.lastName}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setForm({ ...form, lastName: e.target.value.trim() });
                if (form?.firstName !== '' && e.target.value !== '' && !isDraft) {
                  activateTimer(form?.firstName, e.target.value.trim());
                }
              }}
              onKeyUp={(e) => generateUsername(form?.firstName, e.target.value)}
            />
          </Grid>
          {!form?.school && edit && !form?.isGeneric && (
            <Grid item xs={12} sm={12}>
              {emailFetching ? (
                <CircularProgress size={20} />
              ) : (
                <EmailField
                  email={form?.email}
                  setEmail={(e) => {
                    setForm({ ...form, email: e });
                    if (data?.status === USER_STATUS.ACTIVE || data?.status === USER_STATUS.PASSWORDRESET) {
                      setHideStatus(true);
                    }
                  }}
                  isStudent
                  setEmailError={setEmailError}
                  setLoader={setEmailLoader}
                />
              )}
            </Grid>
          )}
          {edit && !form?.school && !form?.isGeneric && (
            <Grid item xs={12} sm={12} className={classes.spacing}>
              <FormHelperText>{getHelperText()}</FormHelperText>
            </Grid>
          )}
          {!disabledFields.includes('username') && (
            <Grid item xs={12}>
              <TextField
                error={status > 200 || error === true}
                helperText={error ? ERRORS.USERNAME : status > 200 ? message : ''}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                inputProps={{
                  autocomplete: 'username',
                }}
                value={form?.username}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (e.target.value !== form?.username) {
                    setError(!validUsername(e.target.value));
                  }
                  setForm({ ...form, username: e.target.value.trim() });
                }}
                onKeyUp={(e) => {
                  if (e.target.value === data?.username) return;
                  if (validUsername(e.target.value)) {
                    e.target.value.length > 3 && dispatch(findUsername(e.target.value));
                  }
                }}
                InputProps={{
                  endAdornment: error ? (
                    <InputAdornment>
                      {loading && <CircularProgress variant="indeterminate" color="primary" thickness={5} size={20} />}
                      {error && <Warning />}
                    </InputAdornment>
                  ) : (
                    <InputAdornment>
                      {loading && <CircularProgress variant="indeterminate" color="primary" thickness={5} size={20} />}
                      {status === 200 && <CheckCircle style={{ color: colors.green[500] }} />}
                      {status === 400 && <Warning />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!disabledFields.includes('homeAddress') && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="homeAddress"
                label="Home Address"
                name="homeAddress"
                InputLabelProps={{ shrink: true }}
                value={form?.homeAddress}
                onChange={(e) => setForm({ ...form, homeAddress: e.target.value })}
              />
            </Grid>
          )}
          {!disabledFields.includes('phoneNumber') && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                InputLabelProps={{ shrink: true }}
                value={form?.phoneNumber}
                onChange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
              />
            </Grid>
          )}
          {!disabledFields.includes('schoolName') && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="schoolName"
                label="School Name"
                name="schoolName"
                InputLabelProps={{ shrink: true }}
                value={form?.schoolName}
                onChange={(e) => setForm({ ...form, schoolName: e.target.value })}
              />
            </Grid>
          )}
          {!disabledFields.includes('schoolAddress') && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="schoolAdress"
                label="School Address"
                name="schoolAddress"
                InputLabelProps={{ shrink: true }}
                value={form?.schoolAddress}
                onChange={(e) => setForm({ ...form, schoolAddress: e.target.value })}
              />
            </Grid>
          )}
          {!disabledFields.includes('password') && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                autoComplete="new-password"
                inputProps={{
                  autocomplete: 'new-password',
                }}
                type={passwordToggle}
                value={form?.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPasswordToggle(passwordToggle === 'password' ? 'text' : 'password')}
                      >
                        {passwordToggle === 'text' ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!disabledFields.includes('school') && (
            <Grid item xs={12} sm={12}>
              <InputLabel id="school-label">Choose a School</InputLabel>
              <FormControl style={{ width: 300 }}>
                <Select
                  labelId="school-label"
                  id="school-select"
                  value={form?.school ? form?.school : ''}
                  onChange={(e) => setForm({ ...form, school: e.target.value, teacher: '' })}
                  disabled={edit}
                  IconComponent={edit ? () => null : ArrowDropDown}
                  displayEmpty
                >
                  {edit && <MenuItem value={''}>Individual</MenuItem>}
                  {schools?.docs
                    ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
                    ?.map((x) => (
                      <MenuItem value={x._id} key={x._id}>
                        {x.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!disabledFields.includes('teacher') && (
            <Grid item xs={12} sm={12}>
              <InputLabel id="teacher-label">Choose a Teacher</InputLabel>
              <FormControl style={{ width: 300 }}>
                <Select
                  labelId="teacher-label"
                  id="teacher-select"
                  value={form?.teacher ? form?.teacher : ''}
                  disabled={form?.isGeneric}
                  onChange={(e) => {
                    setForm({ ...form, teacher: e.target.value, grade: '' });
                    // scroll to class
                    setTimeout(() => {
                      if (classRef.current) {
                        classRef.current.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        });
                      }
                    }, 1000);
                  }}
                  displayEmpty={true}
                >
                  <MenuItem value={''}></MenuItem>
                  {allTeachers
                    ?.filter((x) =>
                      [USER_STATUS.ACTIVE, USER_STATUS.EMAILUPDATE, USER_STATUS.PASSWORDRESET].includes(x.status),
                    )
                    ?.filter((x) => (form?.school ? form?.school === x.school : !x.school))
                    ?.sort((a, b) => sortBy(a, b, 'fullName', 'asc'))
                    ?.map((x) => (
                      <MenuItem value={x._id} key={x._id}>
                        {x.fullName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {form?.teacher !== '' && grades && !disabledFields.includes('grade') && (
            <Grid item xs={12} sm={12} ref={classRef}>
              <InputLabel id="teacher-label">Choose a Class</InputLabel>
              <FormControl>
                <Select
                  labelId="class-label"
                  id="class-select"
                  value={form?.grade}
                  onChange={(e) => setForm({ ...form, grade: e.target.value })}
                  style={{ minWidth: 300 }}
                  displayEmpty={true}
                >
                  <MenuItem value={''}>No Class Attached</MenuItem>
                  {grades.docs
                    ?.filter((x) => (form?.teacher ? x.createdBy?._id === form?.teacher : x))
                    ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
                    ?.map((g) => (
                      <MenuItem value={g._id} key={g._id}>
                        {g.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {edit && ['Password Reset']?.includes(data?.status) && (
          <Grid container direction="column" className={classes.spacing}>
            <Grid item sm={10}>
              <Button
                onClick={() => handleConfirm({ ...form, status: 'Active' })}
                variant={'outlined'}
                endIcon={<Cancel />}
                disabled={sending}
                className={classes.cancel}
              >
                {sending ? 'Sending' : 'Cancel Password Reset Request'}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="space-between" className={classes.topSpacing}>
          {data?.status === USER_STATUS.PASSWORDRESET && !hideStatus && valid && (
            <Button
              onClick={() => handleConfirm({ ...form, status: 'Active' }, false)}
              color="secondary"
              variant={'outlined'}
              startIcon={<Cancel />}
              className={classes.link}
            >
              Cancel Password Reset Request
            </Button>
          )}
          {edit && userStatuses?.includes(data?.status) && !hideStatus && !data?.school && !form?.isGeneric && (
            <Button
              onClick={() => handleConfirm(form, true)}
              color="primary"
              variant={'outlined'}
              endIcon={<Send />}
              disabled={sending}
              className={classes.link}
            >
              {sending ? 'Sending' : getAccountStatusLabel(data?.status)}
            </Button>
          )}
          <Button
            onClick={() => handleConfirm(form)}
            color="primary"
            variant={'contained'}
            disabled={validateDone()}
            fullWidth={Boolean(!edit || data?.school || hideStatus)}
          >
            {edit ? 'Update' : 'Create Student'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
