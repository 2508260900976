import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllManuals,
  deleteManual,
  updateManual,
  setManualData,
} from '../../redux/actions/manual';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { IconButton } from '@material-ui/core';
import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterBar from '../../components/FilterBar';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';

export default function Manual({ name, id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { manuals, fetching } = useSelector(({ admin }) => admin.manual);
  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [children, setChildren] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    orderBy: 'title',
    orderDir: 'desc',
  });

  const loadPage = (page, sortOptions = {}) => {
    let update = { page, limit: manuals.limit, ...sortOptions };
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllManuals(update));
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'title', label: 'Title' },
    { id: 'order', label: 'Order' },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/manuals/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(updateManual(r._id, { isArchived: false }));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllManuals({ haveChildren: true }));
  }, [dispatch]);

  return (
    <>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <SettingDialog id={id} name={name} />
            <IconButton onClick={() => history.push('/superadmin/manuals/new')}>
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        enableTopics={false}
        onSearch={(d) => {
          setSearchString(d);
          let update = {
            page: manuals.page,
            limit: manuals.limit,
            search: d,
          };
          if (view === 'archived') {
            update.isArchived = true;
          }
          dispatch(fetchAllManuals(update));
        }}
        onAction={() => {
          if (view === 'normal') {
            dispatch(
              fetchAllManuals({
                isArchived: true,
                search: searchString,
              })
            );
          } else {
            dispatch(
              fetchAllManuals({
                page: manuals.page,
                limit: manuals.limit,
                search: searchString,
              })
            );
          }
          setView(view === 'normal' ? 'archived' : 'normal');
        }}
        toggle={view === 'archived'}
        handleBack={
          children
            ? () => {
                setChildren(false);
                dispatch(fetchAllManuals({ haveChildren: true }));
              }
            : null
        }
      />
      {manuals && manuals.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...manuals,
                docs: manuals.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
              }}
              handleClick={(r) => {
                if (r.haveChildren) {
                  dispatch(
                    fetchAllManuals({
                      page: manuals.page,
                      limit: manuals.limit,
                      search: searchString,
                      parent: r._id,
                    })
                  );
                  setChildren(true);
                }
              }}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(manuals.page, d)}
              handleRowOrder={(data) => dispatch(setManualData(data))}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        content={
          view === 'normal'
            ? 'This action will archive this manual?'
            : 'This action will permanently delete this manual?'
        }
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteManual(deleteId));
          } else {
            dispatch(updateManual(deleteId, { isArchived: true }));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );
}
