import React from 'react';

import { CancelRounded } from '@material-ui/icons';
import {
  TextField,
  Grid,
  LinearProgress,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import UploadField from '../UploadField';
import { Draggable } from 'react-beautiful-dnd';

export default function Item({
  f,
  i,
  setF,
  handleDelete,
  uploading,
  progress,
}) {
  const textFields = ['name', 'lesson'];
  const numberFields = ['page'];
  const switchFields = ['miniLesson', 'vocabularyTopic'];

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    background: isDragging ? 'lightgreen' : 'inherit',
    ...draggableStyle,
  });

  return (
    <Draggable draggableId={`file-${i}`} index={i}>
      {(provided, snapshot) => (
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          xs={12}
          sm={12}
          spacing={2}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
          }}
        >
          <Grid item xs={6} sm={6} align='start'>
            Order: {i}
          </Grid>
          <Grid item xs={6} sm={6} align='end'>
            <IconButton size='small' onClick={() => handleDelete(i)}>
              <CancelRounded color='error' />
            </IconButton>
          </Grid>
          {uploading && (
            <Grid item xs={6} sm={12}>
              <LinearProgress variant='determinate' value={progress} />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <UploadField
              data={f.image}
              setData={(e) => setF(i, 'image', e)}
              label={`file-${i}`}
            />
          </Grid>
          <Grid
            item
            sm={12}
            container
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            spacing={1}
          >
            {textFields.map((c, j) => (
              <Grid
                item
                xs
                container
                direction={'row'}
                alignItems={'center'}
                key={j}
              >
                <TextField
                  margin='dense'
                  id={'text-' + j + i}
                  label={c}
                  type='text'
                  fullWidth
                  value={f[c] ? f[c] : ''}
                  onChange={(e) => setF(i, c, e.target.value)}
                />
              </Grid>
            ))}
            {numberFields.map((c, k) => (
              <Grid
                item
                xs
                container
                direction={'row'}
                alignItems={'center'}
                key={k}
              >
                <TextField
                  margin='dense'
                  id={'number-' + i}
                  label={c}
                  type='number'
                  fullWidth
                  value={f[c] ? f[c] : ''}
                  onChange={(e) => setF(i, c, e.target.value)}
                />
              </Grid>
            ))}
            {switchFields.map((c, l) => (
              <Grid
                item
                xs
                container
                direction={'row'}
                alignItems={'center'}
                key={l}
              >
                <FormControlLabel
                  control={
                    <Switch
                      name={c}
                      color='primary'
                      checked={f[c] ? f[c] : false}
                      onChange={(e) => setF(i, c, e.target.checked)}
                    />
                  }
                  label={c}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
}
