import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSetting,
  updateSetting,
} from 'containers/SuperAdmin/redux/actions/setting';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Tooltip } from '@material-ui/core';

import StandardDialogActions from 'components/StandardDialogActions';

import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import ContentEditor from '../ContentEditor';

export default function SettingDialog({
  id,
  name,
  enableLabel = true,
  enableContentEditor = false,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const { setting } = useSelector(({ admin }) => admin.setting);

  useEffect(() => dispatch(fetchSetting('general')), [dispatch]);

  useEffect(() => {
    if (setting === null || !id) return;
    setTitle(setting[id]?.title || '');
    setDescription(setting[id]?.description || '');
  }, [setting, id]);

  const handleSave = () => {
    let data = { title, description };
    dispatch(updateSetting('general', { [id]: data }));
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Tooltip title={`${name} Setting`} aria-label='add'>
          <DescriptionIcon />
        </Tooltip>
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='data-dialog-title'
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id='data-dialog-title'>{name} Page Setting</DialogTitle>
        <DialogContent>
          {enableLabel && (
            <TextField
              autoFocus
              margin='dense'
              id='title'
              label='Title'
              type='text'
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )}
          {enableContentEditor ? (
            <ContentEditor
              handleEditorChange={(e) => {
                setDescription(e);
              }}
              value={description}
              id='trvia-editor'
            />
          ) : (
            <TextField
              autoFocus
              margin='dense'
              id='description'
              label='Description'
              type='text'
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
        </DialogContent>

        <DialogActions>
          <StandardDialogActions
            onClose={handleClose}
            onDone={handleSave}
            doneButtonText={'Save'}
            doneButtonIcon={<SaveIcon />}
            closeButtonText={'Cancel'}
          ></StandardDialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
}
