import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  GROUPACTION_ATTEMPT,
  GROUPLIST_ATTEMPT,
  GROUP_ERROR,
  FETCHALLGROUPS_SUCCESS,
  FETCHGROUP_SUCCESS,
  ADDGROUP_SUCCESS,
  UPDATEGROUP_SUCCESS,
  DELETEGROUP_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const groupUrl = 'admin/groups';

export const fetchAllGroups = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPLIST_ATTEMPT));
      const body = await axios.get(`${groupUrl}`, { params });
      dispatch(successAction(FETCHALLGROUPS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const fetchGroup = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPLIST_ATTEMPT));
      const body = await axios.get(`${groupUrl}/${id}`);
      dispatch(successAction(FETCHGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const addGroup = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPACTION_ATTEMPT));
      const body = await axios.post(`${groupUrl}`, data);
      dispatch(successAction(ADDGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const updateGroup = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPACTION_ATTEMPT));
      const body = await axios.post(`${groupUrl}/${id}`, data);
      dispatch(successAction(UPDATEGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const deleteGroup = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPACTION_ATTEMPT));
      const body = await axios.delete(`${groupUrl}/${id}`);
      dispatch(successAction(DELETEGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const archiveGroup = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPACTION_ATTEMPT));
      const body = await axios.put(`${groupUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};

export const restoreGroup = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GROUPACTION_ATTEMPT));
      const body = await axios.put(`${groupUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(GROUP_ERROR, err));
    }
  };
  return thunk;
};
