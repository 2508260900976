import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllLessons,
  deleteLesson,
  updateLesson,
  addBulkLessons,
  deleteAllLessons,
  bulkArchiveLesson,
  bulkRestoreLesson,
  bulkDeleteLesson,
} from '../../redux/actions/lesson';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { IconButton, Switch, Button } from '@material-ui/core';
import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterBar from '../../components/FilterBar';
import BulkLessonImport from 'containers/SuperAdmin/components/BulkLessonImport';
import { TextDialog } from 'containers/SuperAdmin/components/TextDialog';
import { ImageDialog } from 'containers/SuperAdmin/components/ImageDialog';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';

export default function Lesson({ name, id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const [lesson, setLesson] = useState('');
  const [imageId, setImageId] = useState(null);
  const [image, setImage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openBulkDialog, setOpenBulkDialog] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);

  const { lessons, fetching } = useSelector(({ admin }) => admin.lesson);
  const [sort, setSort] = useState({ orderBy: 'title', orderDir: 'asc' });

  const loadPage = (page, sortOptions = {}) => {
    let params = {
      page,
      limit: lessons.limit,
      search: searchString,
      version: 1,
      ...sortOptions,
    };
    if (view === 'archived') {
      params.isArchived = true;
    }
    dispatch(fetchAllLessons(params));
    setSort(sortOptions);
  };

  const headCells = [
    {
      id: 'checkbox',
      label: 'Select',
    },
    { id: 'page', label: 'Page' },
    {
      id: 'lesson',
      label: 'Lesson',
      formatter: (r) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 15 }}>{r.lesson}</span>
            <IconButton
              onClick={() => {
                setLesson(r.lesson);
                setLessonId(r._id);
              }}
              size='small'
            >
              <EditIcon fontSize={'small'} />
            </IconButton>
          </div>
        );
      },
    },
    {
      id: 'image',
      label: 'Path',
      formatter: (r) => (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setImage(r.image);
            setImageId(r._id);
          }}
        >
          {r.image && (
            <img
              src={r.image}
              style={{ width: 'auto', height: 32, objectFit: 'contain' }}
              alt={r.lesson + 'lesson'}
            />
          )}
        </div>
      ),
    },
    {
      id: 'miniLesson',
      label: 'Mini Lesson',
      formatter: (r) => (
        <Switch
          checked={r.miniLesson}
          onChange={(e) =>
            dispatch(updateLesson(r._id, { miniLesson: e.target.checked }))
          }
          name='miniLesson'
          color='primary'
        />
      ),
    },
    {
      id: 'voacbularyTopic',
      label: 'Vocabulary Topic',
      formatter: (r) => (
        <Switch
          checked={r.vocabularyTopic}
          onChange={(e) =>
            dispatch(updateLesson(r._id, { vocabularyTopic: e.target.checked }))
          }
          name='miniLesson'
          color='primary'
        />
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/lessons/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(updateLesson(r._id, { isArchived: false }));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllLessons({ version: 1 }));
  }, [dispatch]);

  return (
    <>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={
          <>
            {name} <span style={{ fontSize: 14 }}>Old Content</span>
          </>
        }
        options={
          <>
            <SettingDialog id={id} name={name} />
            <Button
              onClick={() => setDeleteAllDialog(true)}
              variant='contained'
              color='secondary'
              style={{ marginRight: 7 }}
            >
              Delete All
            </Button>
            <Button
              onClick={() => setOpenBulkDialog(true)}
              variant='contained'
              color='primary'
            >
              Bulk Import
            </Button>
            <IconButton
              onClick={() => {
                history.push('/superadmin/lessons/new');
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        onSearch={(d) => {
          setSearchString(d);
          let update = {
            page: lessons.page,
            limit: lessons.limit,
            search: d,
            version: 1,
          };
          if (view === 'archived') {
            update.isArchived = true;
          }
          dispatch(fetchAllLessons(update));
        }}
        enableTopics={false}
        onAction={() => {
          if (view === 'normal') {
            dispatch(
              fetchAllLessons({
                isArchived: true,
                version: 1,
              })
            );
          } else {
            dispatch(
              fetchAllLessons({
                version: 1,
                page: lessons.page,
                limit: lessons.limit,
              })
            );
          }
          setView(view === 'normal' ? 'archived' : 'normal');
        }}
        toggle={view === 'archived'}
      />
      {lessons && lessons.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...lessons,
                docs: lessons.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
                totalDocs: lessons.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ).length,
              }}
              view={view}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(lessons.page, d)}
              handleBulk={(ids) => {
                if (view === 'normal') {
                  dispatch(bulkArchiveLesson(ids));
                } else {
                  dispatch(bulkRestoreLesson(ids));
                }
              }}
              handleBulkDelete={(ids) => dispatch(bulkDeleteLesson(ids))}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deleteLesson(deleteId));
          } else {
            dispatch(updateLesson(deleteId, { isArchived: true }));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
      <DeleteDialog
        open={deleteAllDialog}
        handleClose={() => setDeleteAllDialog(false)}
        handleConfirm={() => {
          dispatch(deleteAllLessons());
          setDeleteAllDialog(false);
        }}
      />
      <BulkLessonImport
        open={openBulkDialog}
        handleClose={() => setOpenBulkDialog(false)}
        handleConfirm={(d) => dispatch(addBulkLessons(d))}
      />
      <TextDialog
        title={'Edit Textbook Page Name'}
        editable
        open={Boolean(lessonId)}
        initial={lesson}
        onClose={() => setLessonId(null)}
        onResult={(d) => {
          dispatch(updateLesson(lessonId, { lesson: d }));
          setLessonId(null);
          setLesson('');
        }}
      />
      <ImageDialog
        title={'Upload Image'}
        editable
        open={Boolean(imageId)}
        initial={image}
        onClose={() => setImageId(null)}
        onResult={(d) => {
          dispatch(updateLesson(imageId, { image: d }));
          setImageId(null);
          setImage('');
        }}
      />
    </>
  );
}
