import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import ContentEditor from 'containers/SuperAdmin/components/ContentEditor';
import PageHeader from 'containers/SuperAdmin/components/PageHeader';
import ImageIcon from '@material-ui/icons/Image';
import { useDispatch, useSelector } from 'react-redux';
import UploadField from 'containers/SuperAdmin/components/UploadField';
import {
  fetchSetting,
  updateSetting,
} from 'containers/SuperAdmin/redux/actions/setting';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 15,
  },
  dividerSpacing: {
    marginTop: 15,
    marginBottom: 15,
  },
  spacing: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  paperPadding: {
    padding: '15px',
  },
}));

const PageSetting = ({ name }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setting } = useSelector(({ admin }) => admin.setting);
  const [youtubeData, setYoutubeData] = useState({
    title: '',
    description: '',
    buttonText: '',
    channelUrl: '',
    image: '',
  });

  const [reportData, setReportData] = useState({
    title: '',
    description: '',
  });
  const [globPopData, setGlobPopData] = useState({
    image: '',
    title: '',
    description: '',
    descriptionImage: '',
  });

  const [memoriaData, setMemoriaData] = useState({
    image: '',
    title: '',
    description: '',
    descriptionImage: '',
  });

  const [adivinanzaData, setAdivinanzaData] = useState({
    image: '',
    title: '',
    description: '',
    descriptionImage: '',
  });
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');

  const [videoData, setVideoData] = useState({
    title: '',
    description: '',
    practicar: '',
    conversar: '',
    cantar: '',
    jugar: '',
    note: '',
  });
  const [resource, setResource] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    if (setting === null) return;
    setYoutubeData(setting?.youtube);
    setReportData(setting?.report);
    setMemoriaData(setting?.memoria);
    setAdivinanzaData(setting?.adivinanza);
    setVideoData(setting?.video);
    setGlobPopData(setting?.globoPop);
    setResource(setting?.resource);
    setMaintenanceMode(setting?.maintenanceMode);
    setMaintenanceMessage(setting?.maintenanceMessage);
  }, [setting]);

  useEffect(() => dispatch(fetchSetting('general')), [dispatch]);

  const handleSave = (value, data) => {
    dispatch(updateSetting('general', { [value]: data }));
  };

  const handleMaintenace = () => {
    dispatch(
      updateSetting('general', {
        maintenanceMode,
        maintenanceMessage,
      })
    );
  };

  return (
    <>
      <PageHeader disableSearch title={name} />
      <Grid
        container
        direction='column'
        className={classes.grid}
        justifyContent={'space-between'}
      >
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item>
            <h2>Maintenance Mode</h2>
          </Grid>
          <Grid item className={classes.spacing}>
            <FormControlLabel
              control={
                <Switch
                  checked={maintenanceMode}
                  onChange={(e) => setMaintenanceMode(e.target.checked)}
                  name={'maintenanceMode'}
                  color='primary'
                />
              }
              label={maintenanceMode ? 'On' : 'Off'}
            />
          </Grid>
          <Grid item sm className={classes.spacing}>
            <ContentEditor
              handleEditorChange={(e) => setMaintenanceMessage(e)}
              value={maintenanceMessage}
              id='maintenance-editor'
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={handleMaintenace}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
        <Grid item className={classes.dividerSpacing}>
          <Divider />
        </Grid>
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item>
            <h2>Youtube Page</h2>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='title'
              type='text'
              fullWidth
              label='Title'
              placeholder='Enter Youtube Page Title'
              value={youtubeData?.title}
              onChange={(e) =>
                setYoutubeData({ ...youtubeData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item sm className={classes.spacing}>
            <ContentEditor
              handleEditorChange={(e) => {
                setYoutubeData({ ...youtubeData, description: e });
              }}
              value={youtubeData?.description}
              id='youtube-editor'
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='buttonText'
              type='text'
              label='Button Text'
              fullWidth
              placeholder='Enter Button Text'
              value={youtubeData?.buttonText}
              onChange={(e) =>
                setYoutubeData({ ...youtubeData, buttonText: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='buttonUrl'
              type='text'
              fullWidth
              label='Channel Url'
              placeholder='Enter Channel Url'
              value={youtubeData?.channelUrl}
              onChange={(e) =>
                setYoutubeData({ ...youtubeData, channelUrl: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={youtubeData?.image}
              setData={(e) => setYoutubeData({ ...youtubeData, image: e })}
              label={'Upload Youtube Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('youtube', youtubeData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
        <Grid item className={classes.dividerSpacing}>
          <Divider />
        </Grid>
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item className={classes.spacing}>
            <h2>Student Reports Page</h2>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='titleReport'
              type='text'
              fullWidth
              label='Report Title'
              placeholder='Enter Title'
              className={classes.spacing}
              value={reportData?.title}
              onChange={(e) =>
                setReportData({ ...reportData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='description'
              type='text'
              fullWidth
              label='Report Description'
              placeholder='Enter Description'
              className={classes.spacing}
              value={reportData?.description}
              onChange={(e) =>
                setReportData({ ...reportData, description: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('report', reportData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
        <Grid item className={classes.dividerSpacing}>
          <Divider />
        </Grid>
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item className={classes.spacing}>
            <h2>Resources Page</h2>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='titleTrivia'
              type='text'
              fullWidth
              label='Resource Title'
              placeholder='Enter Title'
              className={classes.spacing}
              value={resource?.title}
              onChange={(e) =>
                setResource({
                  ...resource,
                  title: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='resourcescription'
              type='text'
              fullWidth
              label='Resources Description'
              placeholder='Enter Description'
              className={classes.spacing}
              value={resource?.description}
              onChange={(e) =>
                setResource({ ...resource, description: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('resource', resource)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
        <Grid item className={classes.dividerSpacing}>
          <Divider />
        </Grid>
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item className={classes.spacing}>
            <h2>Jugar</h2>
          </Grid>
          <Grid item>
            <h4>Globo Pop</h4>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='globtitle'
              type='text'
              fullWidth
              label='Button Text'
              placeholder='Enter Button text'
              value={globPopData?.title}
              onChange={(e) =>
                setGlobPopData({ ...globPopData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='globdescription'
              type='text'
              fullWidth
              label='Page Description'
              placeholder='Enter Description'
              className={classes.spacing}
              value={globPopData?.description}
              onChange={(e) =>
                setGlobPopData({ ...globPopData, description: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={globPopData?.image}
              setData={(e) => {
                setGlobPopData({ ...globPopData, image: e });
              }}
              label={'Upload Globo Pop Top Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={globPopData?.descriptionImage}
              setData={(e) => {
                setGlobPopData({ ...globPopData, descriptionImage: e });
              }}
              label={'Upload  Globo Pop Bottom  Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('globoPop', globPopData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
          <Grid item className={classes.dividerSpacing}>
            <Divider />
          </Grid>
          <Grid item>
            <h4>Memoria</h4>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='title'
              type='text'
              fullWidth
              label='Button Text'
              placeholder='Enter Button text'
              value={memoriaData?.title}
              onChange={(e) =>
                setMemoriaData({ ...memoriaData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='description'
              type='text'
              fullWidth
              label='Page Description'
              placeholder='Enter Description'
              className={classes.spacing}
              value={memoriaData?.description}
              onChange={(e) =>
                setMemoriaData({ ...memoriaData, description: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={memoriaData?.image}
              setData={(e) => setMemoriaData({ ...memoriaData, image: e })}
              label={'Upload Memoria Top Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={memoriaData?.descriptionImage}
              setData={(e) =>
                setMemoriaData({ ...memoriaData, descriptionImage: e })
              }
              label={'Upload Memoria Bottom Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('memoria', memoriaData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
          <Grid item className={classes.dividerSpacing}>
            <Divider />
          </Grid>
          <Grid item>
            <h4>Adivinanza</h4>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='title'
              type='text'
              fullWidth
              label='Button Text'
              placeholder='Enter Button Text'
              value={adivinanzaData?.title}
              onChange={(e) =>
                setAdivinanzaData({ ...adivinanzaData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='description'
              type='text'
              fullWidth
              label='Page Description'
              placeholder='Enter Description'
              className={classes.spacing}
              value={adivinanzaData?.description}
              onChange={(e) =>
                setAdivinanzaData({
                  ...adivinanzaData,
                  description: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={adivinanzaData?.image}
              setData={(e) =>
                setAdivinanzaData({ ...adivinanzaData, image: e })
              }
              label={'Upload Adivinanza Top Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <UploadField
              data={adivinanzaData?.descriptionImage}
              setData={(e) =>
                setAdivinanzaData({ ...adivinanzaData, descriptionImage: e })
              }
              label={'Upload Adivinanza  Bottom Image'}
              icon={<ImageIcon />}
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('adivinanza', adivinanzaData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
        <Grid item className={classes.dividerSpacing}>
          <Divider />
        </Grid>
        <Paper elevation={3} className={classes.paperPadding}>
          <Grid item className={classes.spacing}>
            <h2>Training Videos Page</h2>
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='video-title'
              type='text'
              fullWidth
              label='Title'
              placeholder='Enter Title'
              value={videoData?.title}
              onChange={(e) =>
                setVideoData({ ...videoData, title: e.target.value })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <ContentEditor
              handleEditorChange={(e) => {
                setVideoData({ ...videoData, description: e });
              }}
              value={videoData?.description}
              id='video-editor'
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='practicar-description'
              type='text'
              fullWidth
              label='Practicar Description'
              placeholder='Enter Practicar Description'
              className={classes.spacing}
              value={videoData?.practicar}
              onChange={(e) =>
                setVideoData({
                  ...videoData,
                  practicar: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='cantar-description'
              type='text'
              fullWidth
              label='Cantar Description'
              placeholder='Enter Cantar Description'
              className={classes.spacing}
              value={videoData?.cantar}
              onChange={(e) =>
                setVideoData({
                  ...videoData,
                  cantar: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='conversar-description'
              type='text'
              fullWidth
              label='Conversar Description'
              placeholder='Enter Conversar Description'
              className={classes.spacing}
              value={videoData?.conversar}
              onChange={(e) =>
                setVideoData({
                  ...videoData,
                  conversar: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='jugar-description'
              type='text'
              fullWidth
              label='Jugar Description'
              placeholder='Enter Jugar Description'
              className={classes.spacing}
              value={videoData?.jugar}
              onChange={(e) =>
                setVideoData({
                  ...videoData,
                  jugar: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <TextField
              margin='dense'
              id='video-note'
              type='text'
              fullWidth
              label='Notes'
              placeholder='Enter Notes'
              className={classes.spacing}
              value={videoData?.note}
              onChange={(e) =>
                setVideoData({
                  ...videoData,
                  note: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item className={classes.spacing}>
            <Button
              onClick={() => handleSave('video', videoData)}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default PageSetting;
