import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core';
import { COLORS, fontFamily } from '../../constants';

const useStyles = makeStyles((theme) => ({
  bodyRoot: {
    backgroundImage: `url('/bg.jpeg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
      dark: COLORS.PRIMARY,
      light: '#000',
    },
    secondary: {
      main: COLORS.SECONDARY,
      dark: COLORS.SECONDARY,
    },
  },
  typography: {
    fontFamily,
  },
  overrides: {
    MuiButton: {
      label: { textTransform: 'none' },
    },
  },
});

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        className={classes.bodyRoot}
      >
        {children}
      </Grid>
    </MuiThemeProvider>
  );
};

export default Layout;
