import React from 'react';
// import { upload_handler } from './functions';

import 'tinymce/tinymce';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';

// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/save';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullpage';

import { Editor } from '@tinymce/tinymce-react';
import { TINY_API_KEY } from 'constants';

const initialConfig = {
  height: '100%',
  width: '100%',
  toolbar:
    'table image undo redo | forecolor backcolor bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | code',
  quickbars_selection_toolbar:
    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
};

export default function ContentEditor({
  handleEditorChange,
  id,
  config = initialConfig,
  value,
}) {
  return (
    <Editor
      apiKey={TINY_API_KEY}
      init={{
        selector: `textarea#builder${id}`,
        // images_upload_handler: upload_handler,
        branding: false,
        statusbar: true,
        menubar: false,
        toolbar_sticky: true,
        resize: 'both',
        autoresize: true,
        autoresize_overflow_padding: 1,
        object_resizing: true,
        plugins:
          'fullpage code autoresize paste importcss searchreplace autolink image link table imagetools lists advlist save',
        importcss_append: true,
        imagetools_cors_hosts: [
          'app.vivamosspanish.com',
          'dev.vivamossapnish.com',
        ],
        imagetools_toolbar:
          'rotateleft rotateright | flipv fliph | editimage imageoptions',
        image_advtab: true,
        image_caption: true,
        resize_img_proportional: true,
        contextmenu: 'link image imagetools table paste',
        ...config,
      }}
      value={value}
      onEditorChange={handleEditorChange}
    />
  );
}
