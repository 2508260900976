/**
 * * Global Reducer
 */

import {
  GETPROFILE_SUCCESS,
  GLOBAL_ATTEMPT,
  GLOBAL_ERROR,
  SETALERT,
  SETSOUND,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SETLICENSEINFO,
  SHOWGLOBALDIALOG,
  HIDEGLOBALDIALOG,
} from '../constants';

const initialState = {
  message: null,
  status: null,
  open: false,
  loading: false,
  error: null,
  user: null,
  domain: '',
  sound: localStorage.getItem('sound')
    ? Boolean(localStorage.getItem('sound') === 'true')
    : true,
  notifications: [],
  licenseInfo: null,
  dialogState: false,
  dialogContent: null,
};

export default function global(state = initialState, action) {
  switch (action.type) {
    case SETALERT:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        open: action.payload.alert,
      };
    case SETSOUND:
      return {
        ...state,
        sound: action.payload,
      };
    case GLOBAL_ATTEMPT:
      return { ...state, loading: true, error: null };
    case GLOBAL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case GETPROFILE_SUCCESS:
      if (action.payload.type === 'teacher') {
        return {
          ...state,
          loading: false,
          error: null,
          user: action.payload.user,
          domain: action.payload?.user?.email?.substring(
            action.payload?.user?.email?.lastIndexOf('@') + 1
          ),
          licenseInfo: action.payload.licenseInfo,
        };
      } else {
        return {
          ...state,
          loading: false,
          error: null,
          user: action.payload.user,
          licenseInfo: action.payload.licenseInfo,
        };
      }
    case SETLICENSEINFO:
      return {
        ...state,
        licenseInfo: { ...state.licenseInfo, ...action.payload },
      };
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };
    case SHOWGLOBALDIALOG:
      return {
        ...state,
        dialogState: true,
        dialogContent: action.content,
      };
    case HIDEGLOBALDIALOG:
      return {
        ...state,
        dialogState: false,
        dialogContent: null,
      };
    default:
      return state;
  }
}
