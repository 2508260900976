export const SCHOOLLIST_ATTEMPT = 'SCHOOLLIST_ATTEMPT';
export const SCHOOLACTION_ATTEMPT = 'SCHOOLACTION_ATTEMPT';
export const SCHOOL_ERROR = 'SCHOOL_ERROR';

export const ACTIVATELICENSE = 'ACTIVATELICENSE';
export const FETCHSCHOOLLICENSEINFO = 'FETCHSCHOOLLICENSEINFO';
export const SENDACTIVATION_TEACHER = 'SENDACTIVATION_TEACHER';
export const BULKSENDACTIVATION_TEACHER = 'BULKSENDACTIVATION_TEACHER';
export const GETSCHOOLTEACHERS = 'GETSCHOOLTEACHERS';
export const GETSCHOOLARCHIVEDTEACHERS = 'GETSCHOOLARCHIVEDTEACHERS';
export const UPDATESCHOOLTEACHER = 'UPDATESCHOOLTEACHER';
export const RESTORESCHOOLTEACHER = 'RESTORESCHOOLTEACHER';
export const DELETESCHOOLTEACHER = 'DELETESCHOOLTEACHER';
export const UPDATETEACHERSTATUS = 'UPDATETEACHERSTATUS';