import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  CANTARACTION_ATTEMPT,
  CANTARLIST_ATTEMPT,
  CANTAR_ERROR,
  FETCHALLCANTARS_SUCCESS,
  FETCHCANTAR_SUCCESS,
  ADDCANTAR_SUCCESS,
  UPDATECANTAR_SUCCESS,
  DELETECANTAR_SUCCESS,
  SETCANTAR,
  BULKUPDATECANTAR_SUCCESS,
  BULKDELETECANTAR_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const cantarUrl = 'admin/cantars';

export const fetchAllCantars = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARLIST_ATTEMPT));
      const body = await axios.get(`${cantarUrl}`, { params });
      dispatch(successAction(FETCHALLCANTARS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const fetchCantar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARLIST_ATTEMPT));
      const body = await axios.get(`${cantarUrl}/${id}`);
      dispatch(successAction(FETCHCANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const addCantar = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.post(`${cantarUrl}`, data);
      dispatch(successAction(ADDCANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const updateCantar = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.put(`${cantarUrl}/${id}`, data);
      dispatch(successAction(UPDATECANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const archiveCantar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.put(`${cantarUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATECANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const restoreCantar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.put(`${cantarUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATECANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const deleteCantar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.delete(`${cantarUrl}/${id}`);
      dispatch(successAction(DELETECANTAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const setCantar = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCANTAR, data));
  };
  return thunk;
};

export const bulkArchiveCantar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.post(`${cantarUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATECANTAR_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreCantar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.post(`${cantarUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATECANTAR_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteCantar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANTARACTION_ATTEMPT));
      const body = await axios.post(`${cantarUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETECANTAR_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(CANTAR_ERROR, err));
    }
  };
  return thunk;
};
