import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { COLORS } from '../../../../constants';

export const PrimaryField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.PRIMARY,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.PRIMARY,
      },
      '&:hover fieldset': {
        borderColor: COLORS.PRIMARY,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.PRIMARY,
      },
    },
  },
})(TextField);

export default function InputField({
  onSave,
  onCancel,
  initialValue,
  field,
  setField,
  showIcons = true,
  ...props
}) {
  const done = () => {
    if (onSave) {
      onSave(field);
      onCancel();
    }
  };

  const cancel = () => {
    setField(initialValue);
    onCancel();
  };

  return (
    <PrimaryField
      id={`textfield-${Math.random(15).toString()}`}
      value={field}
      onChange={(e) => setField(e.target.value)}
      variant='outlined'
      inputRef={(input) => {
        if (input != null) {
          input.focus();
        }
      }}
      InputProps={{
        endAdornment: showIcons ? (
          <InputAdornment position='end'>
            <IconButton size='small' color='primary' onClick={done}>
              <CheckIcon style={{ color: 'green' }} />
            </IconButton>
            <IconButton size='small' onClick={cancel} style={{ color: 'red' }}>
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}
