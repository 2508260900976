/**
 * * Users Reducer
 */

import {
  USERACTION_ATTEMPT,
  USERLIST_ATTEMPT,
  USER_ERROR,
  FETCHALLUSERS_SUCCESS,
  FETCHHEADTEACHERS_SUCCESS,
  FETCHACTIVETEACHERS_SUCCESS,
  FETCHALLTEACHERS_SUCCESS,
  FETCHUSER_SUCCESS,
  ADDUSER_SUCCESS,
  ADDHEADTEACHER_SUCCESS,
  UPDATEUSER_SUCCESS,
  DELETEUSER_SUCCESS,
  SORTUSERS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  users: null,
  user: null,
  headTeachers: [],
  teachers: [],
  allTeachers: [], // all teachers, including those without a school
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case USERLIST_ATTEMPT:
      return { ...state, fetching: true };
    case USERACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case USER_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case SORTUSERS:
      return {
        ...state,
        users: {
          ...state.users,
          docs: action.payload,
        },
      };
    case FETCHALLUSERS_SUCCESS:
      return { ...state, fetching: false, users: action.payload.data };
    case FETCHACTIVETEACHERS_SUCCESS:
      return { ...state, fetching: false, teachers: action.payload.data };
    case FETCHALLTEACHERS_SUCCESS:
      return { ...state, fetching: false, allTeachers: action.payload.data };
    case FETCHHEADTEACHERS_SUCCESS:
      return { ...state, fetching: false, headTeachers: action.payload.data };
    case FETCHUSER_SUCCESS:
      return { ...state, fetching: false, user: action.payload.user };
    case ADDUSER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: [...state.users.docs, action.payload.user],
        },
      };
    case ADDHEADTEACHER_SUCCESS:
      return {
        ...state,
        headTeachers: [...state.headTeachers, action.payload.user],
      };
    case UPDATEUSER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.map((x) => {
            if (x._id === action.payload.user._id) {
              return action.payload.user;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEUSER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        users: {
          ...state.users,
          docs: state.users.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
