import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

const RoundsBox = ({
  open,
  handleClose,
  dialogtitle,
  handleUpdate,
  type,
  initial,
}) => {
  const [roundsData, setRoundsData] = useState();

  useEffect(() => {
    if (!initial) return;
    setRoundsData(initial);
  }, [initial]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='rounds-dialog'
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id='topic-dialog'>{dialogtitle}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='title'
          label='Title'
          type='text'
          fullWidth
          value={roundsData?.label}
          onChange={(e) => {
            setRoundsData({ ...roundsData, label: e.target.value });
          }}
        />
        <TextField
          margin='dense'
          id='round'
          label={type === 'memoria' ? 'Pairs' : 'Rounds'}
          type='number'
          fullWidth
          value={roundsData?.value}
          onChange={(e) => {
            setRoundsData({ ...roundsData, value: e.target.value });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => handleUpdate(type, roundsData)}
          color='primary'
          variant='contained'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoundsBox;
