import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addResource,
  fetchMaxOrder,
  validateOrder,
} from '../../../redux/actions/resource';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialResource = {
  order: 0,
  title: 'Resource Title',
  category: 'class-trivia',
  topics: [],
  group: '',
  content: [],
};

export default function NewResource({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading, orderNumberStatus, maxOrder } = useSelector(
    ({ admin }) => admin.resource
  );

  const handleSave = (d) => {
    let update = { ...d };
    if (update.group === '') {
      update.group = null;
    }
    dispatch(addResource(update));
    history.push('/superadmin/resources');
  };

  useEffect(() => {
    dispatch(fetchMaxOrder({ status: 0 }));
    dispatch(
      validateOrder({
        status: true,
      })
    );
  }, [dispatch]);

  const getMaximumOrderNumber = (data) => {
    let update = {
      topics: data.topics.toString(),
      group: data.group,
    };
    dispatch(fetchMaxOrder(update));
    dispatch(validateOrder({ status: true }));
  };

  const validateOrderNumber = (data) => {
    dispatch(validateOrder(data));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={initialResource}
        onSave={handleSave}
        titleField={true}
        multipleField={'content'}
        multipleFieldTitle={'Images'}
        dropZoneParams={{
          maxFiles: 100,
          multiple: true,
        }}
        showTopicPicker
        maximumOrderValue={maxOrder + 1}
        onSelectedData={getMaximumOrderNumber}
        validateOrderNo={(order) => validateOrderNumber(order)}
        fieldError={orderNumberStatus}
      />
    </div>
  );
}
