/**
 * * Manual Reducer
 */

import {
  MANUALACTION_ATTEMPT,
  MANUALLIST_ATTEMPT,
  MANUAL_ERROR,
  FETCHALLMANUALS_SUCCESS,
  FETCHMANUAL_SUCCESS,
  ADDMANUAL_SUCCESS,
  UPDATEMANUAL_SUCCESS,
  DELETEMANUAL_SUCCESS,
  SETMANUAL,
  MANUALMAXORDER_SUCESS,
  MANUALVALIDATE_SUCESS,
} from '../constants';

const initialState = {
  fetching: false,
  actionLoading: false,
  manuals: null,
  manual: null,
};

export default function manual(state = initialState, action) {
  switch (action.type) {
    case MANUALLIST_ATTEMPT:
      return { ...state, fetching: true };
    case MANUALACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case MANUAL_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        actionLoading: false,
      };
    case MANUALMAXORDER_SUCESS:
      return { ...state, actionLoading: false, maxOrder: action.payload.data };
    case MANUALVALIDATE_SUCESS:
      return {
        ...state,
        actionLoading: false,
        orderNumberStatus: action.payload.data,
      };
    case SETMANUAL:
      return {
        ...state,
        actionLoading: false,
        manuals: { ...state.manuals, docs: action.payload },
      };
    case FETCHALLMANUALS_SUCCESS:
      return { ...state, fetching: false, manuals: action.payload.data };
    case FETCHMANUAL_SUCCESS:
      return { ...state, fetching: false, manual: action.payload.manual };
    case ADDMANUAL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        manuals: {
          ...state.manuals,
          docs: [...state.manuals.docs, action.payload.manual],
        },
      };
    case UPDATEMANUAL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        manuals: {
          ...state.manuals,
          docs: state.manuals.docs.map((x) => {
            if (x._id === action.payload.manual._id) {
              return action.payload.manual;
            } else {
              return x;
            }
          }),
        },
      };
    case DELETEMANUAL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        manuals: {
          ...state.manuals,
          docs: state.manuals.docs.filter((x) => x._id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
