import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../constants';

const useStyles = (color) =>
  makeStyles(() => ({
    root: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '9999',
      
    },
    top: {
      color: color,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }))();

export default function Loader({
  fetching,
  size = 50,
  color = COLORS.PRIMARY,
  ...props
}) {
  const classes = useStyles(color);
  if (fetching) {
    return (
      <div className={classes.root}>
        <CircularProgress
          variant='indeterminate'
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={size}
          thickness={6}
          {...props}
        />
      </div>
    );
  } else {
    return null;
  }
}
