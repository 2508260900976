import React, { useEffect, useState } from 'react';
import RoundsBox from './dialog';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Delete, Edit, InfoOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '8px',
  },
  spacing: {
    margin: theme.spacing(1),
  },
  buttonSpacing: {
    marginLeft: '10vh',
  },
  mainHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alert: {
    margin: theme.spacing(1),
  },
}));

const initialData = {
  startAutomatically: false,
  rounds: [],
};

const GameRounds = ({ handleUpdate, data }) => {
  const classes = useStyles();
  const [globoPopData, setGloboPopData] = useState(initialData);
  const [memoriaData, setMemoriaData] = useState(initialData);
  const [advinanzaData, setAdvinanzaData] = useState(initialData);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDataIdentifier, setDialogDataIdentifier] = useState('');
  const [initialRoundsData, setInitialRoundsData] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [operationCount, setOperationCount] = useState(0);

  useEffect(() => {
    if (!data) return;
    if (data) {
      if (data?.globoPop) {
        setGloboPopData(data?.globoPop);
      }
      if (data?.memoria) {
        setMemoriaData(data?.memoria);
      }
      if (data?.adivinanza) {
        setAdvinanzaData(data?.adivinanza);
      }
    }
  }, [data]);

  const addRoundsData = (type, data) => {
    if (type === 'globoPop') {
      handleUpdate(type, {
        ...globoPopData,
        rounds: [...globoPopData?.rounds, data],
      });
      setGloboPopData({
        ...globoPopData,
        rounds: [...globoPopData?.rounds, data],
      });
    } else if (type === 'memoria') {
      handleUpdate(type, {
        ...memoriaData,
        rounds: [...memoriaData?.rounds, data],
      });
      setMemoriaData({
        ...memoriaData,
        rounds: [...memoriaData?.rounds, data],
      });
    } else if (type === 'adivinanza') {
      setAdvinanzaData({
        ...advinanzaData,
        rounds: [...advinanzaData?.rounds, data],
      });
      handleUpdate(type, {
        ...advinanzaData,
        rounds: [...advinanzaData?.rounds, data],
      });
    }

    setOpen(false);
    setOperationCount(operationCount + 1);
  };

  const updateRoundsData = (type, data) => {
    if (type === 'globoPop') {
      handleUpdate(type, {
        ...globoPopData,
        rounds: globoPopData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
      setGloboPopData({
        ...globoPopData,
        rounds: globoPopData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
    } else if (type === 'memoria') {
      setMemoriaData({
        ...memoriaData,
        rounds: memoriaData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
      handleUpdate(type, {
        ...memoriaData,
        rounds: memoriaData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
    } else if (type === 'adivinanza') {
      setAdvinanzaData({
        ...advinanzaData,
        rounds: advinanzaData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
      handleUpdate(type, {
        ...advinanzaData,
        rounds: advinanzaData?.rounds.map((x, index) => {
          if (data.index === index) {
            return { ...x, label: data.label, value: data.value };
          }
          return x;
        }),
      });
    }

    setEditOpen(false);
    setOperationCount(operationCount + 1);
  };

  const deleteRounds = (type, index) => {
    if (type === 'globoPop') {
      setGloboPopData({
        ...globoPopData,
        rounds: globoPopData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });

      handleUpdate(type, {
        ...globoPopData,
        rounds: globoPopData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });
    } else if (type === 'memoria') {
      setMemoriaData({
        ...memoriaData,
        rounds: memoriaData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });

      handleUpdate(type, {
        ...memoriaData,
        rounds: memoriaData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });
    } else if (type === 'adivinanza') {
      setAdvinanzaData({
        ...advinanzaData,
        rounds: advinanzaData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });

      handleUpdate(type, {
        ...advinanzaData,
        rounds: advinanzaData?.rounds.filter((obj, roundIndex) => {
          return roundIndex !== index;
        }),
      });
    }

    setOpen(false);
    setOperationCount(operationCount + 1);
  };

  return (
    <>
      <Grid container direction='column' className={classes.container}>
        <Grid item className={classes.mainHeading}>
          <Typography variant='h4'>Jugar </Typography>
        </Grid>
        {operationCount > 0 && (
          <Grid container className={classes.alert}>
            <Alert variant='filled' color='info' icon={<InfoOutlined />}>
              Please Click On Update Button To Save Your Changes.
            </Alert>
          </Grid>
        )}

        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.spacing}>
          <Typography variant='h6'>Globo Pop</Typography>
        </Grid>
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          className={classes.spacing}
        >
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={globoPopData?.startAutomatically}
                  onChange={(e) => {
                    setGloboPopData({
                      ...globoPopData,
                      startAutomatically: e.target.checked,
                    });
                    handleUpdate('globoPop', {
                      ...globoPopData,
                      startAutomatically: e.target.checked,
                    });
                  }}
                />
              }
              label='Start Automatically'
              labelPlacement='end'
            />
          </Grid>
          <Grid item align='center' className={classes.buttonSpacing}>
            <Button
              variant='contained'
              color='primary'
              disabled={globoPopData?.startAutomatically}
              onClick={() => {
                setOpen(true);
                setDialogTitle('Add Globo Pop Rounds');
                setDialogDataIdentifier('globoPop');
              }}
            >
              Add Rounds
            </Button>
          </Grid>
        </Grid>
        {globoPopData?.rounds?.length > 0 && !globoPopData?.startAutomatically && (
          <Grid item style={{ width: '60%', marginBottom: '30px' }}>
            <List>
              {globoPopData?.rounds.map((x, index) => (
                <>
                  <ListItem>
                    <ListItemText primary={index + 1} />
                    <ListItemText primary={x.label} />
                    <ListItemText secondary={x.value} />
                    <ListItemText
                      primary={
                        <>
                          <IconButton
                            onClick={() => {
                              setEditOpen(true);
                              setDialogTitle('Edit Globo Pop Rounds');
                              setDialogDataIdentifier('globoPop');

                              setInitialRoundsData({
                                ...initialRoundsData,
                                label: x.label,
                                value: x.value,
                                index: index,
                              });
                            }}
                          >
                            <Edit color='primary' />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteRounds('globoPop', index)}
                          >
                            <Delete color='error' />
                          </IconButton>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        )}
        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.spacing}>
          <Typography variant='h6'>Juego De Memoria</Typography>
        </Grid>
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          className={classes.spacing}
        >
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={memoriaData?.startAutomatically}
              onClick={() => {
                setOpen(true);
                setDialogTitle('Add Memoria Rounds');
                setDialogDataIdentifier('memoria');
              }}
            >
              Add Rounds
            </Button>
          </Grid>
        </Grid>
        {memoriaData?.rounds?.length > 0 && !memoriaData?.startAutomatically && (
          <Grid item style={{ width: '60%', marginBottom: '30px' }}>
            <List>
              {memoriaData?.rounds.map((x, index) => (
                <>
                  <ListItem>
                    <ListItemText primary={index + 1} />
                    <ListItemText primary={x.label} />
                    <ListItemText secondary={x.value} />
                    <ListItemText
                      primary={
                        <>
                          <IconButton
                            onClick={() => {
                              setEditOpen(true);
                              setDialogTitle('Edit Memoria Rounds');
                              setDialogDataIdentifier('memoria');

                              setInitialRoundsData({
                                ...initialRoundsData,
                                label: x.label,
                                value: x.value,
                                index: index,
                              });
                            }}
                          >
                            <Edit color='primary' />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteRounds('memoria', index)}
                          >
                            <Delete color='error' />
                          </IconButton>
                        </>
                      }
                    />
                  </ListItem>

                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        )}
        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.spacing}>
          <Typography variant='h6'>Adivinanza</Typography>
        </Grid>
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          className={classes.spacing}
        >
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={advinanzaData?.startAutomatically}
                  onChange={(e) => {
                    setAdvinanzaData({
                      ...advinanzaData,
                      startAutomatically: e.target.checked,
                    });
                    handleUpdate('adivinanza', {
                      ...advinanzaData,
                      startAutomatically: e.target.checked,
                    });
                  }}
                />
              }
              label='Start Automatically'
              labelPlacement='end'
            />
          </Grid>
          <Grid item className={classes.buttonSpacing}>
            <Button
              variant='contained'
              color='primary'
              disabled={advinanzaData?.startAutomatically}
              onClick={() => {
                setOpen(true);
                setDialogTitle('Add Advinanza Rounds');
                setDialogDataIdentifier('adivinanza');
              }}
            >
              Add Rounds
            </Button>
          </Grid>
        </Grid>
        {advinanzaData?.rounds?.length > 0 &&
          !advinanzaData?.startAutomatically && (
            <Grid item style={{ width: '60%', marginBottom: '30px' }}>
              <List>
                {advinanzaData?.rounds.map((x, index) => (
                  <>
                    <ListItem>
                      <ListItemText primary={index + 1} />
                      <ListItemText primary={x.label} />
                      <ListItemText secondary={x.value} />
                      <ListItemText
                        primary={
                          <>
                            <IconButton
                              onClick={() => {
                                setEditOpen(true);
                                setDialogTitle('Edit Advinanza Rounds');
                                setDialogDataIdentifier('adivinanza');

                                setInitialRoundsData({
                                  ...initialRoundsData,
                                  label: x.label,
                                  value: x.value,
                                  index: index,
                                });
                              }}
                            >
                              <Edit color='primary' />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteRounds('adivinanza', index)}
                            >
                              <Delete color='error' />
                            </IconButton>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </Grid>
          )}
      </Grid>
      {editOpen && (
        <RoundsBox
          open={editOpen}
          dialogtitle={dialogTitle}
          initial={initialRoundsData}
          handleClose={() => setEditOpen(false)}
          type={dialogDataIdentifier}
          handleUpdate={updateRoundsData}
        />
      )}
      {open && (
        <RoundsBox
          open={open}
          handleClose={() => setOpen(false)}
          handleUpdate={addRoundsData}
          dialogtitle={dialogTitle}
          type={dialogDataIdentifier}
          initial={initialData}
        />
      )}
    </>
  );
};

export default GameRounds;
