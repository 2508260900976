import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { uploadContentFiles } from '../../redux/actions/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    outline: 'none',
  },
}));

function Dropzone({
  accept,
  onFileUpload,
  onFileDrop,
  setUploading,
  maxFiles = 10,
  onProgress,
  title = 'Drag and Drop files here or Browse',
}) {
  const classes = useStyles();

  const onDrop = async (rawFiles) => {
    if (!rawFiles) {
      return;
    }
    const files = Array.from(rawFiles);
    if (files.length === 0) {
      return;
    }
    setUploading(true);
    if (onProgress) {
      for (const index of files.keys()) {
        onProgress({ index, progress: 0 });
      }
    }
    if (onFileDrop) {
      onFileDrop(rawFiles);
    }
    let data = await uploadContentFiles(files, null, (d) => {
      if (onProgress) {
        onProgress(d);
      }
    });
    if (data) {
      onFileUpload(data);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop,
  });

  return (
    <Grid
      container
      className={classes.root}
      alignItems='center'
      {...getRootProps()}
    >
      <Grid item sm>
        <input {...getInputProps()} />
        <Typography variant='h5' align='center'>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Dropzone;
