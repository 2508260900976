/**
 * * Teacher Actions
 */
import axios from '../../apiCaller';
import { successAction, errorAction, attemptAction } from '../../../constants';
import {
  TEACHERS_ATTEMPT,
  TEACHERS_ERROR,
  GETSTUDENTSBYGRADE_SUCCESS,
  GETTEACHERGRADES_SUCCESS,
  CREATETEACHERGRADE_SUCCESS,
  UPDATETEACHERGRADE_SUCCESS,
  DELETETEACHERGRADE_SUCCESS,
  ADDSTUDENTBYGRADE_SUCCESS,
  GETGUIDES_SUCCESS,
  GETGUIDE_SUCCESS,
  GETGUIDEPAGE_SUCCESS,
  GETWORKBOOKS_SUCCESS,
  GETWORKBOOK_SUCCESS,
  GETWORKBOOKPAGE_SUCCESS,
  GETMANUALS_SUCCESS,
  GETMANUALCONTENT_SUCCESS,
  FINDUSERNAME_SUCCESS,
  GETREPORT,
  GENERATEREPORT,
  UPDATESTUDENTBYGRADE_SUCCESS,
  DELETESTUDENTBYGRADE_SUCCESS,
  ARCHIVETEACHERSTUDENTS_SUCCESS,
  DELETEGROUPSTUDENT,
  DELETEGROUPSTUDENTS,
  RESTOREGROUPSTUDENT,
  RESTOREGROUPSTUDENTS,
  FETCHRESOURCESBYTOPIC,
  FETCHVIDEOSBYCATEGORY,
  RESETUSERNAME_SUCCESS,
  GETARCHIVEDSTUDENTS,
  GETALLGRADES,
  GETACTIVETEACHERS,
  GETUSERNAMES,
  IMPORTSTUDENTS,
  GETUSERNAMES_ATTEMPT,
} from '../constants';
import { csvDownload } from 'utils';
import { getProfile, enqueueSnackbar } from 'redux/actions';

export const getStudentsByGrade = (grade) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/grades/${grade}/students`);
      dispatch(successAction(GETSTUDENTSBYGRADE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getTeacherStudents = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/grades/students`);
      dispatch(successAction(GETSTUDENTSBYGRADE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getArchivedStudents = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/studentArchives`);
      dispatch(successAction(GETARCHIVEDSTUDENTS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getUsernames = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETUSERNAMES_ATTEMPT));
      const body = await axios.get(`/teacher/students`);
      dispatch(successAction(GETUSERNAMES, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const importStudents = (data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/students`, data);
      dispatch(successAction(IMPORTSTUDENTS, body.data));
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Students Imported Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        onClose();
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getReport = (grade, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/grades/${grade}/scores`, {
        params,
      });
      dispatch(successAction(GETREPORT, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const generateReport = (data, fileName) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/report`, data);
      dispatch(successAction(GENERATEREPORT, body.data));
      if (body.data && body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Report Generated Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        csvDownload(body.data, `${fileName} Game Report.csv`);
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const generateStudentReport = (id, data, fileName) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/report/${id}`, data);
      dispatch(successAction(GENERATEREPORT, body.data));
      if (body.data && body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Report Generated Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        csvDownload(body.data, `${fileName} Game Report.csv`);
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const createTeacherStudent = (data, linking = false, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades/students`, data);
      dispatch(successAction(ADDSTUDENTBYGRADE_SUCCESS, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: `Student ${linking ? 'Linked' : 'Created'} Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const updateTeacherStudent = (id, data, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades/students/${id}`, data);
      dispatch(successAction(UPDATESTUDENTBYGRADE_SUCCESS, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: `Student:${data?.firstName} Updated Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err?.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      if (setLoader) {
        setLoader(false);
      }
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const archiveTeacherStudent = (id, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades/students/${id}/archive`);
      dispatch(successAction(DELETESTUDENTBYGRADE_SUCCESS, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || 'Student Archived Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const bulkMoveTeacherStudents = (ids, grade, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.put('/teacher/grades/students/bulkMove', {
        ids,
        grade,
      });
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `${ids?.length} Students Moved Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
      dispatch(getTeacherGrades());
      dispatch(getTeacherStudents());
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveTeacherStudents = (ids, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.put('/teacher/grades/students/bulkArchive', {
        ids,
      });
      dispatch(successAction(ARCHIVETEACHERSTUDENTS_SUCCESS, { ids }));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `${ids?.length} Students Archived Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const deleteTeacherStudent = (id, linking = false, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.delete(`/teacher/grades/students/${id}`);
      dispatch(successAction(DELETESTUDENTBYGRADE_SUCCESS, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: `Student ${linking ? 'Unlinked' : 'Deleted'} Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
      if (body.data?.redirect) {
        window.location.href = '/teachers';
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const restoreGroupTeacherStudent = (id, data, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/studentArchives/${id}/restore`, data);
      dispatch(successAction(RESTOREGROUPSTUDENT, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `Student Restored Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
      dispatch(getProfile());
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err?.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      if (setLoader) {
        setLoader(true);
      }
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const deleteGroupTeacherStudent = (id, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.delete(`/teacher/studentArchives/${id}`);
      dispatch(successAction(DELETEGROUPSTUDENT, body.data));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `Student Deleted Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteGroupTeacherStudents = (ids, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/studentArchives/bulkDelete`, {
        ids,
      });
      dispatch(successAction(RESTOREGROUPSTUDENTS, { ids }));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `${ids?.length} Students Deleted Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err?.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      if (setLoader) {
        setLoader(true);
      }
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreGroupTeacherStudents = (ids, grade, teacher, passwords, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/studentArchives/bulkRestore`, {
        ids,
        grade,
        teacher,
        passwords,
      });
      dispatch(successAction(DELETEGROUPSTUDENTS, { ids }));
      if (setLoader) {
        setLoader(false);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body?.data?.message || `${ids?.length} Students Unarchived Successfully`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
        dispatch(getProfile());
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err?.response?.message || err?.data?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const findUsername = (username) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades/username`, { username });
      dispatch(successAction(FINDUSERNAME_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const resetUsername = () => {
  return successAction(RESETUSERNAME_SUCCESS, {});
};

export const getTeacherGrades = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/grades`, params);
      dispatch(successAction(GETTEACHERGRADES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const saveGradeOrder = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      await axios.post(`/teacher/grades/order`, { order: data });
    } catch (err) {
      console.log(err);
    }
  };
  return thunk;
};

export const getAllGrades = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/grades/all`);
      dispatch(successAction(GETALLGRADES, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getActiveTeachers = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/activeTeachers`);
      dispatch(successAction(GETACTIVETEACHERS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const createTeacherGrade = (data, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades`, data);
      dispatch(successAction(CREATETEACHERGRADE_SUCCESS, body.data));
      if (setLoader) {
        setLoader(body?.data?.grade?._id);
      }
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Class Created Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const updateTeacherGrade = (id, data, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.post(`/teacher/grades/${id}`, data);
      if (setLoader) {
        setLoader(false);
      }
      dispatch(successAction(UPDATETEACHERGRADE_SUCCESS, body.data));
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Class Updated Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const deleteTeacherGrade = (id, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.delete(`/teacher/grades/${id}`);
      dispatch(successAction(DELETETEACHERGRADE_SUCCESS, body.data));
      if (body.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Class Deleted Successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      }
      if (setLoader) {
        setLoader(false);
      }
      if (body.data?.redirect) {
        window.location.href = '/teachers';
      }
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getGuides = (old) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/${old ? 'old-content/' : ''}guides`);
      dispatch(successAction(GETGUIDES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getGuide = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/guides/${id}`);
      dispatch(successAction(GETGUIDE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getGuidePage = (id, page) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/guides/${id}/${page}`);
      dispatch(successAction(GETGUIDEPAGE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getWorkbooks = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get('/teacher/workbooks');
      dispatch(successAction(GETWORKBOOKS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getWorkbook = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/workbooks/${id}`);
      dispatch(successAction(GETWORKBOOK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getWorkbookPage = (id, page) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/workbooks/${id}/${page}`);
      dispatch(successAction(GETWORKBOOKPAGE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getManuals = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/manuals`, { params });
      dispatch(successAction(GETMANUALS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getManual = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/manuals/${id}`);
      dispatch(successAction(GETMANUALCONTENT_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getResourceByTopic = (category, topic, gid = null, old) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/${old ? 'old-content/' : ''}resources/${category}/${topic}`, {
        params: { gid },
      });
      dispatch(successAction(FETCHRESOURCESBYTOPIC, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};

export const getVideosByCategory = (category) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(TEACHERS_ATTEMPT));
      const body = await axios.get(`/teacher/videos/${category}`);
      dispatch(successAction(FETCHVIDEOSBYCATEGORY, body.data));
    } catch (err) {
      dispatch(errorAction(TEACHERS_ERROR, err));
    }
  };
  return thunk;
};
