import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Grid } from '@material-ui/core';

export default function Copyright({ isDark = false }) {
  return (
    <Grid style={{ justifyContent: 'space-between' }} container>
      <Typography
        variant="body2"
        color="textPrimary"
        align="center"
        style={{ color: 'white', letterSpacing: '0.04em', transform: 'scaleX(1.1)', transformOrigin: 'left' }}
      >
        <RouterLink to="/privacy-policy" style={{ color: isDark ? 'black' : 'white', textDecoration: 'none' }}>
          Privacy Policy
        </RouterLink>
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        align="center"
        style={{ color: isDark ? 'black' : 'white', letterSpacing: '0.04em', transform: 'scaleX(1.1)' }}
      >
        {`©${new Date().getFullYear()} `}
        <Link color="inherit" href="https://vivamosspanish.com/">
          Vivamos Spanish LLC
        </Link>
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        align="center"
        style={{
          color: isDark ? 'black' : 'white',
          letterSpacing: '0.04em',
          transform: 'scaleX(1.1)',
          transformOrigin: 'right',
        }}
      >
        <Link color="inherit" href="https://vivamosspanish.com/" target="_blank">
          vivamosspanish.com
        </Link>
      </Typography>
    </Grid>
  );
}
