import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import Copyright from '../Copyright';
import { COLORS, theme } from '../../constants';
import Loader from '.';

const useStyles = makeStyles((theme) => ({
  bodyRoot: {
    backgroundImage: `url('/bg.jpeg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  gridRoot: {
    padding: 10,
    width: '75vw',
    maxHeight: '100%',
    minHeight: '60vh',
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      width: '100vw',
    },
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  footer: {
    marginTop: 50,
    bottom: 0,
    width: '75vw',
    marginBottom: 20,
  },
  box: {
    backgroundColor: COLORS.TRANSPARENT,
    borderRadius: 22,
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
  },
}));

const SuspenseLoader = () => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        className={classes.bodyRoot}
      >
        <Box boxShadow={12} borderRadius={10} className={classes.box}>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.gridRoot}
            wrap={'wrap'}
          >
            <Loader />
          </Grid>
        </Box>
        <Grid item sm={12} xs={12} className={classes.footer}>
          <Copyright />
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default SuspenseLoader;
