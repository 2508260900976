import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveTeachers } from 'containers/SuperAdmin/redux/actions/user';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { COLORS } from '../../../../constants';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'normal',
    color: COLORS.DARKBLUE,
  },
  spacing: {
    margin: theme.spacing(1.5, 0),
  },
}));

export default function RestoreGradeDialog({
  open,
  title = 'Are you sure you want to continue?',
  handleClose,
  handleConfirm,
  confirmLabel = 'Yes',
  cancelLabel = 'No',
  loading,
  grade,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { teachers, fetching: userLoading } = useSelector(
    ({ admin }) => admin.users
  );
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    if (!open) return;
    setTeacher('');
    dispatch(fetchActiveTeachers());
  }, [open, dispatch]);

  const filterTeachers = (a) => {
    if (grade?.school) {
      return a.school === grade?.school?._id;
    } else {
      return !a.school;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-dialog-title'
      maxWidth={'sm'}
    >
      <DialogTitle
        id='delete-dialog-title'
        className={classes.title}
        disableTypography
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Typography className={classes.spacing}>
          Restoring Class(es) will also restore all of their Students, with the
          exception of those that have been reassigned or deleted. To restore,
          please select an active Teacher to assign the class(es) to.
        </Typography>
        <Grid container className={classes.spacing}>
          <Grid item xs={12} sm={12}>
            <InputLabel id='teacher-label'>Choose a Teacher</InputLabel>
            <FormControl style={{ width: 300 }}>
              <Select
                labelId='teacher-label'
                id='teacher-select'
                value={teacher}
                onChange={(e) => setTeacher(e.target.value)}
                displayEmpty={true}
                IconComponent={ArrowDropDown}
              >
                {userLoading && (
                  <MenuItem value={teacher} disabled>
                    Loading...
                  </MenuItem>
                )}
                {teachers?.filter(filterTeachers)?.map((x) => (
                  <MenuItem value={x._id} key={x._id}>
                    {x.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.cancel}
          variant='outlined'
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => handleConfirm(teacher)}
          className={classes.confirm}
          variant='contained'
          disabled={loading || !grade || !teacher}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
