import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { COLORS } from 'constants';
import EmailField from '../EmailField';
import { RESTORE_OPTIONS } from '../../constants';

const useRestoreStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.PINK,
    fontWeight: 'normal',
  },
  cancel: {
    color: COLORS.PINK,
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  confirm: {
    color: '#fff',
    backgroundColor: COLORS.DARKBLUE,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: COLORS.DARKBLUE,
    },
  },
  title: {
    fontWeight: 'bold',
    color: COLORS.DARKBLUE,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
  },
  warning: {
    color: COLORS.PINK,
    fontWeight: 'normal',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(2, 0),
    lineHeight: '2.0rem',
    letterSpacing: '0.01rem',
  },
  email: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
  },
  select: {
    margin: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  spacing: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0, 1),
  },
}));

const RestoreTeacherDialog = ({
  open,
  handleClose,
  handleConfirm,
  confirmLabel = 'YES',
  cancelLabel = 'NO',
  saving,
  initial,
  name,
}) => {
  const classes = useRestoreStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) return;
    setForm({
      email: initial || '',
      settings: 'preserve',
    });
  }, [dispatch, open, initial]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='restore-dialog-title'
      maxWidth={'sm'}
      scroll='body'
    >
      <DialogTitle>Restore Team Teacher</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              color={'textSecondary'}
              className={classes.title}
            >
              Name: {name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EmailField
              email={form?.email}
              setEmail={(e) => setForm({ ...form, email: e })}
              setEmailError={setError}
              setLoader={setLoading}
              className={classes.email}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Select
                labelId='select-restore-settings'
                id='select-restore-settings-select'
                value={form?.settings}
                className={classes.select}
                fullWidth
                onChange={(e) =>
                  setForm({
                    ...form,
                    settings: e.target.value,
                  })
                }
              >
                {RESTORE_OPTIONS.map((option, index) => (
                  <MenuItem value={option.value} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.spacing}>
            {form?.settings === 'preserve' && (
              <FormHelperText>
                This Teacher’s Classes & Students will be restored, with the
                exception of those that have been reassigned, unassigned, or
                deleted.
              </FormHelperText>
            )}
            {form?.settings === 'reset' && (
              <FormHelperText>
                This Teacher’s Classes will be permanently deleted. Their
                Students will become “Unassigned” from this Teacher & will
                remain Archived.
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Typography
            variant='body1'
            color={'textSecondary'}
            className={classes.warning}
          >
            Restoring this Team Teacher with “Preserved Settings” will instantly
            reactivate their Account. They will not need to reset their password
            & can utilize the same login credentials used prior to the Account
            being Archived. Are you sure you want to Restore this Teacher?
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.cancel}
          variant='outlined'
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => handleConfirm(form)}
          className={classes.confirm}
          variant='contained'
          disabled={
            saving ||
            !form ||
            !form?.email ||
            !form?.settings ||
            error ||
            loading
          }
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreTeacherDialog;
