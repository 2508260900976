/**
 * * Upload Reducer
 */

import { UPLOAD_ATTEMPT, UPLOAD_SUCCESS, UPLOAD_FAIL } from '../constants';

const initialState = {
  fetching: false,
  uploadLoading: false,
  upload: null,
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_ATTEMPT:
      return { ...state, uploadLoading: true, upload: null };
    case UPLOAD_SUCCESS:
      return { ...state, uploadLoading: false, upload: action.payload };
    case UPLOAD_FAIL:
      return { ...state, uploadLoading: false, error: action.payload };
    default:
      return state;
  }
}
