import React, { useEffect, useState } from 'react';

import { CancelRounded } from '@material-ui/icons';
import {
  Grid,
  Typography,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import Dropzone from '../Dropzone';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Item from './item';

export default function BulkLessonImport({
  open,
  handleClose,
  handleConfirm,
  dropZoneParams = {},
}) {
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!open) return;
    if (open) setData([]);
  }, [open]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newResult = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    setData(newResult);
  };

  const handleUpdate = (index, field, value) => {
    let newData = [...data];
    let update = newData.map((x, a) =>
      a === index ? { ...x, [field]: value } : x
    );
    setData(update);
  };

  const handleDone = () => {
    handleConfirm(data);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='xl'
      fullWidth
      fullScreen
    >
      <DialogTitle id='bulk-import-title'>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5'>Bulk Import</Typography>
          </Grid>
          <Grid item container sm={6} justifyContent='flex-end'>
            {data && data.length > 0 && (
              <Button
                onClick={handleDone}
                variant='contained'
                color='primary'
                style={{ marginRight: 15 }}
              >
                Save
              </Button>
            )}
            <IconButton onClick={handleClose}>
              <CancelRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 15 }}>
        <Grid container direction={'column'} style={{ padding: 15 }}>
          <Grid item container xs={12} direction={'column'}>
            <Grid
              item
              container
              direction={'column'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              style={{ padding: 15 }}
            >
              <Grid
                item
                xs
                container
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
              >
                <Grid container item xs direction={'row'} alignItems={'center'}>
                  <Dropzone
                    onFileUpload={(d) => {
                      setData([
                        ...data,
                        ...d.map((a) => ({
                          image: a,
                          page: '',
                          lesson: '',
                          name: '',
                          miniLesson: false,
                          vocabularyTopic: false,
                        })),
                      ]);
                    }}
                    onProgress={(p) => setProgress(p)}
                    setUploading={(u) => setUploading(u)}
                    maxFiles={250}
                    multiple={true}
                    {...dropZoneParams}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {data && data.length > 0 && (
            <Grid item sm={12}>
              <Container maxWidth='xl'>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='list'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {data.map((f, i) => (
                          <Item
                            f={f}
                            i={i}
                            setF={handleUpdate}
                            key={i}
                            handleDelete={(i) =>
                              setData(data.filter((x, a) => a !== i))
                            }
                            uploading={uploading}
                            progress={progress}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Container>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
