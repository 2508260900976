import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  PRACTICARACTION_ATTEMPT,
  PRACTICARLIST_ATTEMPT,
  PRACTICAR_ERROR,
  FETCHALLPRACTICARS_SUCCESS,
  FETCHPRACTICAR_SUCCESS,
  ADDPRACTICAR_SUCCESS,
  UPDATEPRACTICAR_SUCCESS,
  DELETEPRACTICAR_SUCCESS,
  SETPRACTICAR,
  BULKUPDATEPRACTICAR_SUCCESS,
  BULKDELETEPRACTICAR_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const practicarUrl = 'admin/practicars';

export const fetchAllPracticars = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARLIST_ATTEMPT));
      const body = await axios.get(`${practicarUrl}`, { params });
      dispatch(successAction(FETCHALLPRACTICARS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const fetchPracticar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARLIST_ATTEMPT));
      const body = await axios.get(`${practicarUrl}/${id}`);
      dispatch(successAction(FETCHPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const addPracticar = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.post(`${practicarUrl}`, data);
      dispatch(successAction(ADDPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const updatePracticar = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.put(`${practicarUrl}/${id}`, data);
      dispatch(successAction(UPDATEPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const deletePracticar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.delete(`${practicarUrl}/${id}`);
      dispatch(successAction(DELETEPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const setPracticar = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETPRACTICAR, data));
  };
  return thunk;
};

export const archivePracticar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.put(`${practicarUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const restorePracticar = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.put(`${practicarUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATEPRACTICAR_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchivePracticar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.post(`${practicarUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEPRACTICAR_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestorePracticar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.post(`${practicarUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATEPRACTICAR_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeletePracticar = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(PRACTICARACTION_ATTEMPT));
      const body = await axios.post(`${practicarUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETEPRACTICAR_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(PRACTICAR_ERROR, err));
    }
  };
  return thunk;
};
