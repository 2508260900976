import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchAllPracticars,
  deletePracticar,
  archivePracticar,
  restorePracticar,
  bulkArchivePracticar,
  bulkRestorePracticar,
  bulkDeletePracticar,
} from '../../redux/actions/practicar';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterBar from '../../components/FilterBar';
import SettingDialog from 'containers/SuperAdmin/components/SettingDialog';
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Practicars({ name, id }) {
  const [view, setView] = useState('normal');
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({ orderBy: 'createdAt', orderDir: 'desc' });
  const { practicars, fetching } = useSelector(({ admin }) => admin.practicar);

  const loadPage = (page, sortOptions = {}) => {
    if (Object.values(sortOptions).length === 0) {
      sortOptions = sort;
    }
    let update = { page, limit: practicars.limit, ...sortOptions };
    if (selectedTopics) {
      update = { ...update, topics: selectedTopics.toString() };
    }
    if (selectedGroup) {
      update = { ...update, group: selectedGroup };
    }
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllPracticars(update));
    setSort(sortOptions);
  };

  const headCells = [
    {
      id: 'checkbox',
      label: 'Select',
    },
    { id: 'name', label: 'Name' },
    {
      id: 'actions',
      label: 'Actions',
      actions:
        view === 'normal'
          ? [
              {
                label: 'Edit',
                action: (r) => {
                  history.push(`/superadmin/practicars/edit/${r._id}`);
                },
                icon: <EditIcon />,
                disabled: () => false,
              },
              {
                label: 'Archive',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <ArchiveIcon />,
                disabled: () => false,
              },
            ]
          : [
              {
                label: 'Restore',
                action: (r) => {
                  dispatch(restorePracticar(r._id));
                },
                icon: <RestoreFromTrashIcon />,
                disabled: () => false,
              },
              {
                label: 'Permanently Delete',
                action: (r) => {
                  setDeleteId(r._id);
                  setOpenDeleteDialog(true);
                },
                icon: <DeleteForeverIcon />,
                disabled: () => false,
              },
            ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllPracticars());
  }, [dispatch]);

  const handleSearch = (d) => {
    setSearchString(d);
    let update = {
      page: practicars.page,
      limit: practicars.limit,
      topics: selectedTopics.toString(),
      search: d,
    };
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllPracticars(update));
  };

  const handleTopic = (data) => {
    setSelectedTopics(data.topics);
    setSelectedGroup(data.group);
    let update = {
      page: practicars.page,
      limit: practicars.limit,
      topics: data.topics.toString(),
      group: data.group,
      ...sort,
    };
    if (searchString !== '') {
      update = { ...update, search: searchString };
    }
    if (view === 'archived') {
      update.isArchived = true;
    }
    dispatch(fetchAllPracticars(update));
  };

  const handleAction = () => {
    if (view === 'normal') {
      dispatch(
        fetchAllPracticars({
          isArchived: true,
        })
      );
    } else {
      dispatch(
        fetchAllPracticars({
          page: practicars.page,
          limit: practicars.limit,
        })
      );
    }
    setView(view === 'normal' ? 'archived' : 'normal');
  };

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <SettingDialog id={id} name={name} enableLabel={false} />
            <IconButton
              onClick={() => history.push('/superadmin/practicars/new')}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      <FilterBar
        onSearch={handleSearch}
        topics={selectedTopics}
        group={selectedGroup}
        toggle={view === 'archived'}
        onTopicSelect={handleTopic}
        onAction={handleAction}
      />
      {practicars && practicars.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={{
                ...practicars,
                docs: practicars.docs.filter((x) =>
                  view === 'normal' ? !x.isArchived : x.isArchived
                ),
              }}
              view={view}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(practicars.page, d)}
              handleBulk={(ids) => {
                if (view === 'normal') {
                  dispatch(bulkArchivePracticar(ids));
                } else {
                  dispatch(bulkRestorePracticar(ids));
                }
              }}
              handleBulkDelete={(ids) => dispatch(bulkDeletePracticar(ids))}
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          if (view === 'archived') {
            dispatch(deletePracticar(deleteId));
          } else {
            dispatch(archivePracticar(deleteId));
          }
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
