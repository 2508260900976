import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  LEERACTION_ATTEMPT,
  LEERLIST_ATTEMPT,
  LEER_ERROR,
  FETCHALLLEERS_SUCCESS,
  FETCHLEER_SUCCESS,
  ADDLEER_SUCCESS,
  UPDATELEER_SUCCESS,
  DELETELEER_SUCCESS,
  SETLEER,
  BULKDELETELEER_SUCCESS,
  BULKUPDATELEER_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const leerUrl = 'admin/leers';

export const fetchAllLeers = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERLIST_ATTEMPT));
      const body = await axios.get(`${leerUrl}`, { params });
      dispatch(successAction(FETCHALLLEERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const fetchLeer = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERLIST_ATTEMPT));
      const body = await axios.get(`${leerUrl}/${id}`);
      dispatch(successAction(FETCHLEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const addLeer = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.post(`${leerUrl}`, data);
      dispatch(successAction(ADDLEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const updateLeer = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.put(`${leerUrl}/${id}`, data);
      dispatch(successAction(UPDATELEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const deleteLeer = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.delete(`${leerUrl}/${id}`);
      dispatch(successAction(DELETELEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const setLeer = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETLEER, data));
  };
  return thunk;
};

export const archiveLeer = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.put(`${leerUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATELEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const restoreLeer = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.put(`${leerUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATELEER_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveLeer = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.post(`${leerUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATELEER_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreLeer = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.post(`${leerUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATELEER_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteLeer = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LEERACTION_ATTEMPT));
      const body = await axios.post(`${leerUrl}/bulkDelete`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETELEER_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LEER_ERROR, err));
    }
  };
  return thunk;
};
