import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveTeachers } from 'containers/SuperAdmin/redux/actions/user';
import { fetchAllLicenses } from '../../redux/actions/license';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';
import { sortBy } from 'utils/sort';
import { USER_STATUS } from 'constants';

export default function GradeDialog({
  open,
  handleClose,
  handleConfirm,
  handleDelete,
  data,
  setData,
  edit = false,
}) {
  const dispatch = useDispatch();
  const { teachers, fetching: userLoading } = useSelector(
    ({ admin }) => admin.users
  );
  const { licenses, licenseeByKey } = useSelector(({ admin }) => admin.license);

  useEffect(() => {
    if (!open) return;
    dispatch(fetchActiveTeachers());
    dispatch(fetchAllLicenses());
  }, [open, dispatch]);

  const filterTeachers = (x) => {
    if (data?.school) {
      return (
        data.school === x.school &&
        x.access === 'teacher' &&
        x.accessLevel === 'full' &&
        [
          USER_STATUS.ACTIVE,
          USER_STATUS.EMAILUPDATE,
          USER_STATUS.PASSWORDRESET,
        ].includes(x.status)
      );
    } else {
      if (edit) return x;
      return (
        !x.school &&
        x.access === 'teacher' &&
        x.accessLevel === 'full' &&
        [
          USER_STATUS.ACTIVE,
          USER_STATUS.EMAILUPDATE,
          USER_STATUS.PASSWORDRESET,
        ].includes(x.status)
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='data-dialog-title'
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id='data-dialog-title'>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item sm={3} xs={9}>
            <Typography color='primary'>
              {edit ? 'Edit Class' : 'Add Class'}
            </Typography>
          </Grid>
          <Grid item sm xs={3} align='end'>
            <IconButton onClick={handleClose} color='primary' size='small'>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoFocus
              id='name'
              label={edit ? 'Edit Class Name' : 'Class Name'}
              type='text'
              fullWidth
              required
              variant='outlined'
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel id='school-label'>Choose a School</InputLabel>
            <FormControl style={{ width: 300 }}>
              <Select
                labelId='school-label'
                id='school-select'
                value={data.school ? data.school : ''}
                onChange={(e) => setData({ ...data, school: e.target.value })}
                displayEmpty={true}
                disabled={edit}
                IconComponent={edit ? () => null : ArrowDropDown}
              >
                <MenuItem value={''}>Individual</MenuItem>
                {licenses?.docs
                  ?.filter(
                    (x) =>
                      x.licenseType === 'School' && x.teacherAccess === 'full'
                  )
                  ?.map((x) => ({
                    ...x,
                    name: licenseeByKey[x.licensee]?.name,
                  }))
                  ?.sort((a, b) => sortBy(a, b, 'name', 'asc'))
                  ?.map((x) => (
                    <MenuItem value={x.licensee} key={x.licensee}>
                      {x?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel id='teacher-label'>Choose a Teacher</InputLabel>
            <FormControl style={{ width: 300 }}>
              <Select
                labelId='teacher-label'
                id='teacher-select'
                value={data.createdBy ? data.createdBy : ''}
                onChange={(e) =>
                  setData({ ...data, createdBy: e.target.value })
                }
                displayEmpty={true}
                disabled={(data?.school === '' || !data?.school) && edit}
                IconComponent={
                  (data?.school === '' || !data?.school) && edit
                    ? () => null
                    : ArrowDropDown
                }
              >
                {userLoading && (
                  <MenuItem value={data?.createdBy} disabled>
                    Loading...
                  </MenuItem>
                )}
                {teachers
                  ?.filter(filterTeachers)
                  ?.sort((a, b) => sortBy(a, b, 'fullName', 'asc'))
                  ?.map((x) => (
                    <MenuItem value={x._id} key={x._id}>
                      {x.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'space-between', margin: '5px 15px' }}
      >
        {(!edit || !handleDelete) && (
          <Button onClick={handleClose} color='primary' variant='outlined'>
            Cancel
          </Button>
        )}
        {edit && handleDelete && (
          <Button
            onClick={() => handleDelete(data)}
            color='secondary'
            variant={'contained'}
          >
            Delete Class
          </Button>
        )}
        <Button
          onClick={() => handleConfirm(data)}
          color='primary'
          variant={'contained'}
        >
          {edit ? 'Update Class' : 'Create Class'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
