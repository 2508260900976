import axios from '../../../../redux/apiCaller';
import {
  successAction,
  attemptAction,
  errorAction,
} from '../../../../constants';
import {
  LESSONACTION_ATTEMPT,
  LESSONLIST_ATTEMPT,
  LESSON_ERROR,
  FETCHALLLESSONS_SUCCESS,
  FETCHLESSON_SUCCESS,
  ADDLESSON_SUCCESS,
  UPDATELESSON_SUCCESS,
  DELETELESSON_SUCCESS,
  ADDBULKLESSON_SUCCESS,
  DELETEALLLESSONS_SUCCESS,
  BULKDELETELESSON_SUCCESS,
  BULKUPDATELESSON_SUCCESS,
} from '../constants.js';
import { enqueueSnackbar } from 'redux/actions';

const lessonUrl = 'admin/lessons';

export const fetchAllLessons = (params) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONLIST_ATTEMPT));
      const body = await axios.get(`${lessonUrl}`, { params });
      dispatch(successAction(FETCHALLLESSONS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const fetchLesson = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONLIST_ATTEMPT));
      const body = await axios.get(`${lessonUrl}/${id}`);
      dispatch(successAction(FETCHLESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const addLesson = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}`, data);
      dispatch(successAction(ADDLESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const addBulkLessons = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}/bulkImport`, data);
      dispatch(successAction(ADDBULKLESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const updateLesson = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.put(`${lessonUrl}/${id}`, data);
      dispatch(successAction(UPDATELESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const deleteLesson = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.delete(`${lessonUrl}/${id}`);
      dispatch(successAction(DELETELESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const deleteAllLessons = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}/deleteAll`);
      dispatch(successAction(DELETEALLLESSONS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const archiveLesson = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.put(`${lessonUrl}/${id}/archive`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATELESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const restoreLesson = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.put(`${lessonUrl}/${id}/restore`);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(UPDATELESSON_SUCCESS, body.data));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const bulkArchiveLesson = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}/bulkArchive`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATELESSON_SUCCESS, {
          ids,
          update: { isArchived: true },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const bulkRestoreLesson = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}/bulkRestore`, { ids });
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(
        successAction(BULKUPDATELESSON_SUCCESS, {
          ids,
          update: { isArchived: false },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDeleteLesson = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LESSONACTION_ATTEMPT));
      const body = await axios.post(`${lessonUrl}/bulkDelete`, ids);
      if (body.data?.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: body.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      }
      dispatch(successAction(BULKDELETELESSON_SUCCESS, { ids }));
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.data?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
      dispatch(errorAction(LESSON_ERROR, err));
    }
  };
  return thunk;
};
