import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTopics } from '../../redux/actions/topic';
import { fetchAllGroups } from '../../redux/actions/group';

import { sortBy } from 'utils/sort';

import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import StyledTreeItem from './item';

import EditIcon from '@material-ui/icons/Edit';
import { ExpandMoreRounded, ChevronRightRounded } from '@material-ui/icons';
import { ArchiveIcon, DeleteIcon, RestoreIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
    flexGrow: 1,
    maxWidth: 650,
  },
  add: {
    margin: theme.spacing(2, 0),
  },
}));

export default function TopicTree({
  handleTopicAdd,
  handleChildTopicAdd,
  handleTopicEdit,
  handleTopicDelete,
  handleGroupAdd,
  handleGroupEdit,
  handleGroupDelete,
  handleTopicArchive,
  handleGroupArchive,
  handleTopicRestore,
  handleGroupRestore,
  filter = () => true,
  filterGroup = () => true,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { topics } = useSelector(({ admin }) => admin.topic);
  const { groups } = useSelector(({ admin }) => admin.group);

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    dispatch(fetchAllTopics({ pagination: false }));
    dispatch(fetchAllGroups({ pagination: false }));
  }, [dispatch]);

  const actions = [
    {
      label: 'Edit',
      icon: <EditIcon />,
      onClick: (e, isGroup = false) =>
        isGroup ? handleGroupEdit(e) : handleTopicEdit(e),
    },
    {
      label: 'Archive',
      icon: <ArchiveIcon />,
      onClick: (e, isGroup = false) =>
        isGroup ? handleGroupArchive(e) : handleTopicArchive(e),
    },
  ];

  const archiveActions = [
    {
      label: 'Restore',
      icon: <RestoreIcon />,
      onClick: (e, isGroup = false) =>
        isGroup ? handleGroupRestore(e) : handleTopicRestore(e),
    },
    {
      label: 'Permanently Delete',
      icon: <DeleteIcon />,
      onClick: (e, isGroup = false) =>
        isGroup ? handleGroupDelete(e) : handleTopicDelete(e),
    },
  ];

  const handleToggle = (event, nodeIds) => {
    event.persist();
    let iconClicked =
      event.target.closest('.MuiTreeItem-iconContainer') ||
      event.target.closest('.MuiTypography-root.makeStyles-labelText') ||
      event.target.closest('.MuiSvgIcon-root.makeStyles-labelIcon');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  return (
    <React.Fragment>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreRounded />}
        defaultExpandIcon={<ChevronRightRounded />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {topics?.docs
          ?.filter((x) => !x.isChild)
          ?.filter(filter)
          ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
          ?.map((topic) =>
            topic?.enableContentOnly ? (
              <StyledTreeItem
                nodeId={topic?._id}
                key={topic?._id}
                labelText={topic.name}
                actions={
                  topic?.isArchived
                    ? archiveActions
                        ?.filter((x) => {
                          return x.label !== 'Permanently Delete';
                        })
                        ?.map((action) => (
                          <Tooltip
                            title={action.label}
                            key={`${action.label}-${topic._id}`}
                          >
                            <IconButton onClick={() => action.onClick(topic)}>
                              {action.icon}
                            </IconButton>
                          </Tooltip>
                        ))
                    : actions
                        ?.filter((x) => {
                          return x.label !== 'Archive';
                        })
                        ?.map((action) => (
                          <Tooltip
                            title={action.label}
                            key={`${action.label}-${topic._id}`}
                          >
                            <IconButton onClick={() => action.onClick(topic)}>
                              {action.icon}
                            </IconButton>
                          </Tooltip>
                        ))
                }
              />
            ) : (
              <StyledTreeItem
                nodeId={topic?._id}
                key={topic?._id}
                labelText={topic.name}
                actions={
                  topic?.isArchived
                    ? archiveActions
                        ?.filter((x) => {
                          return x.label !== 'Permanently Delete';
                        })
                        ?.map((action) => (
                          <Tooltip
                            title={action.label}
                            key={`${action.label}-${topic._id}`}
                          >
                            <IconButton onClick={() => action.onClick(topic)}>
                              {action.icon}
                            </IconButton>
                          </Tooltip>
                        ))
                    : actions
                        ?.filter((x) => {
                          return x.label !== 'Archive';
                        })
                        ?.map((action) => (
                          <Tooltip
                            title={action.label}
                            key={`${action.label}-${topic._id}`}
                          >
                            <IconButton onClick={() => action.onClick(topic)}>
                              {action.icon}
                            </IconButton>
                          </Tooltip>
                        ))
                }
              >
                {topics?.docs
                  ?.filter((x) => x.parent === topic._id)
                  ?.filter(filter)
                  ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
                  ?.map((childTopic) => {
                    if (!childTopic.hasGroup) {
                      return (
                        <StyledTreeItem
                          nodeId={childTopic?._id}
                          key={childTopic?._id}
                          labelText={childTopic.name}
                          actions={
                            childTopic?.isArchived
                              ? archiveActions?.map((action) => (
                                  <Tooltip
                                    title={action.label}
                                    key={`${action.label}-${childTopic._id}`}
                                  >
                                    <IconButton
                                      onClick={() => action.onClick(childTopic)}
                                    >
                                      {action.icon}
                                    </IconButton>
                                  </Tooltip>
                                ))
                              : actions?.map((action) => (
                                  <Tooltip
                                    title={action.label}
                                    key={`${action.label}-${childTopic._id}`}
                                  >
                                    <IconButton
                                      onClick={() => action.onClick(childTopic)}
                                    >
                                      {action.icon}
                                    </IconButton>
                                  </Tooltip>
                                ))
                          }
                        />
                      );
                    }
                    return (
                      <StyledTreeItem
                        nodeId={childTopic?._id}
                        key={childTopic?._id}
                        labelText={childTopic.name}
                        actions={
                          childTopic?.isArchived
                            ? archiveActions
                                ?.filter((x) => {
                                  return x.label !== 'Permanently Delete';
                                })
                                ?.map((action) => (
                                  <Tooltip
                                    title={action.label}
                                    key={`${action.label}-${childTopic._id}`}
                                  >
                                    <IconButton
                                      onClick={() => action.onClick(childTopic)}
                                    >
                                      {action.icon}
                                    </IconButton>
                                  </Tooltip>
                                ))
                            : actions
                                ?.filter((x) => {
                                  return x.label !== 'Archive';
                                })
                                ?.map((action) => (
                                  <Tooltip
                                    title={action.label}
                                    key={`${action.label}-${childTopic._id}`}
                                  >
                                    <IconButton
                                      onClick={() => action.onClick(childTopic)}
                                    >
                                      {action.icon}
                                    </IconButton>
                                  </Tooltip>
                                ))
                        }
                      >
                        {childTopic?.hasGroup &&
                          groups?.docs
                            ?.filter((x) => x.topics.includes(childTopic._id))
                            ?.filter(filterGroup)
                            ?.sort((a, b) => sortBy(a, b, 'order', 'asc'))
                            ?.map((group) => (
                              <StyledTreeItem
                                nodeId={group?._id}
                                key={group?._id}
                                labelText={group.name}
                                actions={
                                  group?.isArchived
                                    ? archiveActions?.map((action) => (
                                        <Tooltip
                                          title={action.label}
                                          key={`${action.label}-${group._id}`}
                                        >
                                          <IconButton
                                            onClick={() =>
                                              action.onClick(group, true)
                                            }
                                          >
                                            {action.icon}
                                          </IconButton>
                                        </Tooltip>
                                      ))
                                    : actions?.map((action) => (
                                        <Tooltip
                                          title={action.label}
                                          key={`${action.label}-${group._id}`}
                                        >
                                          <IconButton
                                            onClick={() =>
                                              action.onClick(group, true)
                                            }
                                          >
                                            {action.icon}
                                          </IconButton>
                                        </Tooltip>
                                      ))
                                }
                              />
                            ))}
                        {childTopic?.hasGroup &&
                          groups?.docs?.filter((x) =>
                            x.topics.includes(childTopic._id)
                          )?.length > 0 && (
                            <StyledTreeItem
                              nodeId={'group-topic-add'}
                              labelText={'Add Group Topic'}
                              onClick={() => handleGroupAdd(childTopic._id)}
                              className={classes.add}
                            />
                          )}
                      </StyledTreeItem>
                    );
                  })}
                <StyledTreeItem
                  nodeId={'child-topic-add'}
                  labelText={'Add Child Topic'}
                  onClick={() => handleChildTopicAdd(topic._id)}
                  className={classes.add}
                />
              </StyledTreeItem>
            )
          )}
        <StyledTreeItem
          nodeId={'root-topic-add'}
          labelText={'Add Topic'}
          onClick={handleTopicAdd}
          className={classes.add}
        />
      </TreeView>
    </React.Fragment>
  );
}
