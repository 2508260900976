import { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import StandardDialogActions from 'components/StandardDialogActions';
import UploadField from '../UploadField';

export function ImageDialog({
  open,
  initial,
  editable,
  title,
  onClose,
  onResult,
}) {
  const [image, setImage] = useState('');

  useEffect(() => {
    setImage(initial || '');
  }, [initial]);

  function done() {
    onResult(image);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {image && (
          <Grid container justifyContent='center'>
            <img
              src={image}
              alt={title}
              style={{ width: '100%', height: '500px', objectFit: 'contain' }}
            />
          </Grid>
        )}
        {editable && (
          <Grid container style={{ marginTop: 10 }}>
            <UploadField data={image} setData={setImage} label='Image' />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={!editable}
      />
    </Dialog>
  );
}
