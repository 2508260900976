import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addPracticar } from '../../../redux/actions/practicar';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialPracticar = {
  name: '',
  image: '',
  group: '',
  words: '',
  sentences: '',
  quiz: '',
  topics: [],
  order: 0,
};

export default function NewPracticar({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionLoading } = useSelector(({ admin }) => admin.practicar);

  const handleSave = (d) => {
    dispatch(addPracticar({ ...d, group: d.group === '' ? null : d.group }));
    history.push('/superadmin/practicars');
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={initialPracticar}
        content={['image']}
        multipleFields={[
          {
            field: 'words',
            title: 'Words',
          },
          {
            field: 'sentences',
            title: 'Sentences',
          },
          {
            field: 'quiz',
            title: 'Quiz',
          },
        ]}
        onSave={handleSave}
        showTopicPicker
      />
    </div>
  );
}
