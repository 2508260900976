import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllGroups,
  addGroup,
  deleteGroup,
  updateGroup,
} from '../../redux/actions/group';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Loader from '../../../../components/Loader';
import EnhancedTable from '../../components/Table';
import PageHeader from '../../components/PageHeader';
import DeleteDialog from 'components/DeleteDialog';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import GroupDialog from '../../components/GroupDialog';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

let initialGroup = {
  name: '',
  topics: [],
};

export default function Groups({ name }) {
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [groupData, setGroupData] = useState(null);
  const [groupDialog, setGroupDialog] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [sort, setSort] = useState({ orderBy: 'name', orderDir: 'asc' });

  const { groups, fetching } = useSelector(({ admin }) => admin.group);

  const loadPage = (page, sortOptions = {}) => {
    dispatch(fetchAllGroups({ page, limit: groups.limit, ...sortOptions }));
    setSort(sortOptions);
  };

  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'order', label: 'Order' },
    {
      id: 'actions',
      label: 'Actions',
      actions: [
        {
          label: 'Edit',
          action: (r) => {
            setGroupData(r);
            setEditGroup(true);
            setGroupDialog(true);
          },
          icon: <EditIcon />,
          disabled: () => false,
        },
        {
          label: 'Permanently Delete',
          action: (r) => {
            setDeleteId(r._id);
            setOpenDeleteDialog(true);
          },
          icon: <DeleteIcon />,
          disabled: () => false,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      {fetching && <Loader fetching={fetching} />}
      <PageHeader
        disableSearch
        title={name}
        options={
          <>
            <IconButton
              onClick={() => {
                setEditGroup(false);
                setGroupData(initialGroup);
                setGroupDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />
      {groups && groups.docs.length > 0 && (
        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
          <Grid item sm={12} xs={12}>
            <EnhancedTable
              data={groups}
              headCells={headCells}
              handlePage={loadPage}
              orderField={sort.orderBy}
              orderDirection={sort.orderDir}
              handleOrder={(d) => loadPage(groups.page, d)}
            />
          </Grid>
        </Grid>
      )}
      {groupData && (
        <GroupDialog
          open={groupDialog}
          edit={editGroup}
          data={groupData}
          setData={setGroupData}
          handleClose={() => {
            setGroupData(initialGroup);
            setGroupDialog(false);
          }}
          handleConfirm={(data) => {
            if (editGroup) {
              dispatch(updateGroup(groupData._id, data));
            } else {
              dispatch(addGroup(data));
            }
            setGroupData(initialGroup);
            setGroupDialog(false);
          }}
        />
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={() => {
          dispatch(deleteGroup(deleteId));
          setDeleteId(null);
          setOpenDeleteDialog(false);
        }}
      />
    </div>
  );
}
