export const sortBy = (a, b, field, order) => {
  if (order === 'asc') {
    if (field === 'school') {
      return a.school?.name.localeCompare(b.school?.name);
    }
    if (field === 'grade') {
      return a.grade?.name.localeCompare(b.grade?.name);
    }
    if (field === 'teacher') {
      return a.teacher?.fullName.localeCompare(b.teacher?.fullName);
    }
    if (field === 'createdBy') {
      return a.createdBy?.fullName.localeCompare(b.createdBy?.fullName);
    }
    if (field === 'name') {
      return a.name.localeCompare(b.name);
    }
    if (field === 'fullName') {
      return a.fullName.localeCompare(b.fullName);
    }
    if (field === 'schoolName') {
      return a.schoolName.localeCompare(b.schoolName);
    }
    if (field === 'teacherName') {
      return a.teacherName.localeCompare(b.teacherName);
    }
    if (field === 'className') {
      return a.className.localeCompare(b.className);
    }
    if (field === 'username') {
      return a.username.localeCompare(b.username);
    }
    return a[field] > b[field] ? 1 : -1;
  } else {
    if (field === 'school') {
      return b.school?.name.localeCompare(a.school?.name);
    }
    if (field === 'grade') {
      return b.grade?.name.localeCompare(a.grade?.name);
    }
    if (field === 'teacher') {
      return b.teacher?.fullName.localeCompare(a.teacher?.fullName);
    }
    if (field === 'createdBy') {
      return b.createdBy?.fullName.localeCompare(a.createdBy?.fullName);
    }
    if (field === 'name') {
      return b.name.localeCompare(a.name);
    }
    if (field === 'fullName') {
      return b.fullName.localeCompare(a.fullName);
    }
    if (field === 'schoolName') {
      return b.schoolName.localeCompare(a.schoolName);
    }
    if (field === 'className') {
      return b.className.localeCompare(a.className);
    }
    if (field === 'teacherName') {
      return b.teacherName.localeCompare(a.teacherName);
    }
    if (field === 'username') {
      return b.username.localeCompare(a.username);
    }
    return a[field] < b[field] ? 1 : -1;
  }
};
